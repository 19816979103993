import React from 'react';
import { FormattedMessage } from 'react-intl';

const App = (): React.ReactElement => {
  return (
    <div className="container">
      <h3>
        <FormattedMessage id="home.ytbProvides" />
      </h3>
      <ul>
        <li>
          <FormattedMessage id="home.easyToUseTool" />
        </li>
        <li>
          <FormattedMessage id="home.originalTextHelp" />
        </li>
        <li>
          <FormattedMessage id="home.referencesAndResources" />
        </li>
        <li>
          <FormattedMessage id="home.computerAssistance" />
        </li>
      </ul>
    </div>
  );
};

export default App;
