import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { AppState } from '../../reducers';
import { logoutAction, confirmLogoutAction, cancelLogoutAction } from '../../actions/usernav';

interface UserNavProps extends RouteComponentProps {
  displayName: string | undefined;
  confirmLogout?: boolean;
  logoutFunc?: any;
  confirmLogoutFunc?: any;
  cancelLogoutFunc?: any;
  history: any;
  location: any;
  match: any;
}

const executeLogoutWrapper = (props?: any): void => {
  const { logoutFunc } = props;
  logoutFunc();
};

const askLogoutWrapper = (props?: any): void => {
  const { confirmLogoutFunc } = props;
  confirmLogoutFunc();
};

const cancelLogoutWrapper = (props?: any): void => {
  const { cancelLogoutFunc } = props;
  cancelLogoutFunc();
};

const navigateToProfile = (props?: any): void => {
  props.history.push('/profile');
};

export const UserNavComp: React.SFC<UserNavProps> = (props): any => {
  const { displayName, confirmLogout } = props;

  return (
    <>
      <Modal show={confirmLogout}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="usernav.logout.modalTitle" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <FormattedMessage id="usernav.logout.modalText" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button
              id="user-nav-logout-confirm"
              variant="primary"
              onClick={(): void => {
                executeLogoutWrapper(props);
              }}
            >
              <FormattedMessage id="usernav.logout" />
            </Button>
          </Link>
          <Button
            id="user-nav-logout-cancel"
            variant="secondary"
            onClick={(): void => {
              cancelLogoutWrapper(props);
            }}
          >
            <FormattedMessage id="cancel" />
          </Button>
        </Modal.Footer>
      </Modal>

      <NavDropdown title={`${displayName} `} id="user-nav-dropdown">
        <NavDropdown.Item
          id="user-nav-profile"
          onClick={(): void => {
            navigateToProfile(props);
          }}
        >
          <FormattedMessage id="usernav.profile" />
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          id="user-nav-logout"
          onClick={(): void => {
            askLogoutWrapper(props);
          }}
        >
          <FormattedMessage id="usernav.logout" />
        </NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

UserNavComp.propTypes = {
  displayName: PropTypes.string.isRequired,
  confirmLogout: PropTypes.bool,
};

UserNavComp.defaultProps = {
  confirmLogout: false,
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  logoutFunc: (): void => {
    dispatch(logoutAction());
  },
  confirmLogoutFunc: (): void => {
    dispatch(confirmLogoutAction());
  },

  cancelLogoutFunc: (): void => {
    dispatch(cancelLogoutAction());
  },
});

export const mapStateToProps = (state: AppState): any => {
  return {
    displayName: state.auth.displayName,
    confirmLogout: state.usernav.confirmLogout,
  };
};

export const UserNavRouter = withRouter(UserNavComp);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserNavComp));
