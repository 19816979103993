import React, { ReactElement } from 'react';
// import { Redirect } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { SHOW_PROJECT_LIST, ProjectProps } from '../types';
import { projectLoaded } from '../actions';
import { AppState } from '../reducers';
import ProjectBase from '../components/project/projectBase';
import { ProjectCreation, ProjectList } from '../components/project';

class Project extends ProjectBase {
  private loadComponent(): ReactElement {
    const { view } = this.props;
    if (view === SHOW_PROJECT_LIST) {
      return <ProjectList loading={false} />;
    }
    return <ProjectCreation />;
  }

  public render(): ReactElement {
    const { loading, loaded } = this.props;

    if (loading) {
      setTimeout((): void => {
        loaded();
      }, 300);
      return <div className="loader" />;
    }

    return <div className="d-flex justify-content-center">{this.loadComponent()}</div>;
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  loaded: (): void => {
    dispatch(projectLoaded());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
