import FirebaseSdkProvider from './firebase/firebaseSdkProvider';
import { BibleApiRequest, BibleApiResponse } from '../shared/structs';

export default class BibleApiClient {
  public async getBiblesList(): Promise<BibleApiResponse> {
    const sdk = await FirebaseSdkProvider.getSdk();
    this.useFunctionsEmulatorIfLocal(sdk);

    const request: BibleApiRequest = {
      list: true,
      requestedId: '',
      translation: '',
      additionalParams: {},
    };
    const response = await sdk.functions().httpsCallable('bibleApi')(request);
    return response.data;
  }

  public async getBibleByChapter(id: string, reference: string): Promise<BibleApiResponse> {
    const sdk = await FirebaseSdkProvider.getSdk();
    this.useFunctionsEmulatorIfLocal(sdk);

    const request: BibleApiRequest = {
      list: false,
      requestedId: reference,
      translation: id,
      additionalParams: {},
    };
    const response = await sdk.functions().httpsCallable('bibleApi')(request);
    return response.data;
  }

  public async getVerseSuggestion(
    projectDocId: string,
    textId: string,
    versification: string,
  ): Promise<BibleApiResponse> {
    try {
      const sdk = await FirebaseSdkProvider.getSdk();
      this.useFunctionsEmulatorIfLocal(sdk);

      const request = {
        projectDocId,
        textId,
        versification,
      };
      const response = await sdk
        .functions()
        .httpsCallable('ProjectTranslationsVersesGetSuggestionsOnCall')(request);

      const res: BibleApiResponse = {
        error: false,
        errorMessage: '',
        responseData: response.data,
      };

      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public useFunctionsEmulatorIfLocal(sdk: any): void {
    if (process.env.NODE_ENV === 'development') {
      sdk.functions().useFunctionsEmulator('http://localhost:5001');
    }
  }
}
