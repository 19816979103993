/* eslint max-classes-per-file: "off" */
export type ManuscriptLanguageCode = 'H' | 'G' | 'A';

export const CONTENT_SEGMENT_CAT = ['adj', 'noun', 'num', 'verb'];

export enum DbNames {
  LexiconsCollection = 'lexicons',
  LexiconsEntriesCollection = 'entries',
  ManuscriptsCollection = 'translations',
  TranslationsCollection = 'translations',
  ChunksCollection = 'gbiTreeChunks',
  JsonTreeCollection = 'gbiTreeJson',
  ProjectTranslationsCollection = 'projectTranslations',
  VersesCollection = 'verses',
  LinkMemoryCollection = 'linkMemory',
  ChunkMemoryCollection = 'chunkMemory',
  DefaultVersification = 'S1',
  TestProjectDocId = 'testProjectTranslationTriggers',
  TestProjectDocIdForJest = 'forJestTesting',
  SystemUser = 'system',
}

export type MemoryCollection = DbNames.LinkMemoryCollection | DbNames.ChunkMemoryCollection;

export interface TranslationLink {
  sources: number[];
  targets: number[];
}

export interface Time {
  seconds: number;
  nanoseconds: number;
}

export interface MemoryVerseReference {
  text: string;
  textId: string;
  locationKey: string;
  timestamp: Time;
}

export interface MemoryTargetData {
  targetText: string;
  verses?: MemoryVerseReference[];
}

export interface ManuscriptSuggestions {
  textSuggestion: string;
  linkSuggestions: Record<string, string[]>;
  memorySuggestions: Record<string, MemoryTargetData[]>;
  targetSuggestions: Record<string, MemoryVerseReference[]>;
}

export interface ManuscriptData {
  cat: string;
  catIsContent: boolean;
  english: string;
  lemma: string;
  positionId: string;
  segment: string;
  strongs: string;
  strongsX: string;
  analysis: string;
}

export enum SyntaxGroupType {
  ADVERB = 'adv',
  ADVERB_PHRASE = 'advp',
  CLAUSE = 'cl',
  CONJUNCTION = 'conj',
  CONJUNCTION_HEBREW = 'cjp',
  NOUN_PHRASE = 'np',
  OBJECT = 'o',
  INDIRECT_OBJECT = 'io',
  PREDICATE = 'p',
  PREP_PHRASE = 'pp',
  SUBJECT = 's',
  VERB = 'v',
  VERB_CLAUSE = 'vc',
}

export interface SyntaxGroupData {
  type: string;
  level: number;
  startIndex: number;
  endIndex: number;
  startSegmentIndex: number;
  endSegmentIndex: number;
}

export interface ChunkData {
  chunk: string;
  segments: string[];
  segmentsIndex: number[];
  contentSegmentsIndex: number[];
}

export type LexiconData = Record<string, Record<string, string>>;
export type MorphologyData = Record<string, string>;

export type ManuscriptVerseDataField =
  | ManuscriptData[]
  | SyntaxGroupData[]
  | ChunkData[]
  | LexiconData
  | MorphologyData
  | {};

export interface ChapterData {
  lexiconData: LexiconData;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
  syntaxGroupDataByVerse: Record<string, SyntaxGroupData[]>;
  textId: string;
  text: string;
  verses: string[];
}

export interface ProjectTranslationVerse {
  textId: string;
  text: string;
  textSegments: string[];

  sourceTextId?: string;
  sourceText?: string;
  sourceManuscriptData?: ManuscriptData[];
  sourceSegments?: string[];
  sourceSegmentsStrongsX?: string[];
  sourceChunkData?: ChunkData[];

  links?: TranslationLink[];
  linksVerified?: boolean;
  linksUpdatedBy?: string;
  linksUpdatedAt?: Date;

  lastVerifiedText?: string;
  lastVerifiedLinkKeys?: string[];
  lastVerifiedChunkKeys?: string[];
  lastVerifiedAt?: Date;

  complete?: boolean;

  author?: string;
  createdAt?: Date;

  updatedBy?: string;
  updatedAt?: Date;
}

export class ManuscriptVerse {
  public textId = '';

  public text = '';

  public chunkData: ChunkData[] = [];

  public manuscriptData: ManuscriptData[] = [];

  public textSegments: string[] = [];

  public textSegmentsPosition: string[] = [];

  public textSegmentsStrongsX: string[] = [];

  public constructor(
    textId: string,
    text: string,
    textSegments: string[],
    manuscriptData: ManuscriptData[],
    chunkData: ChunkData[],
  ) {
    this.textId = textId;
    this.text = text;
    this.textSegments = textSegments;
    this.chunkData = chunkData;
    this.manuscriptData = manuscriptData;
    this.textSegmentsPosition = manuscriptData.map(data => data.positionId);
    this.textSegmentsStrongsX = manuscriptData.map(data => data.strongsX);

    // Note: textSegments cannot be derived from manuscript data because it's segment includes puncutation, like period.
    // See John 1:2
    // this.textSegments = manuscriptData.map(data => data.segment);
  }
}

export interface TranslationMemory {
  readonly sourceText: string;
  readonly sourceSegments: string[];
  readonly sourceSegmentsCount?: number;

  readonly targetText: string;
  readonly targetSegments: string[];
  readonly totalSegmentsCount: number;

  readonly key?: string;
  readonly locationKey?: string;
  readonly targetSegmentsCount?: number;
  readonly count?: number;
  readonly verses?: MemoryVerseReference[];
}

export class TranslationMemoryItem implements TranslationMemory {
  key = '';

  locationKey = '';

  sourceText = '';

  sourceSegments: string[] = [];

  sourceSegmentsCount = 0;

  targetText = '';

  targetSegments: string[] = [];

  targetSegmentsCount = 0;

  totalSegmentsCount = 0;

  count = 0;

  verses: MemoryVerseReference[] = [];

  constructor(
    sourceSegments: string[] = [],
    targetSegments: string[] = [],
    sourceLocations: number[] = [],
    targetLocations: number[] = [],
  ) {
    if (sourceSegments.length && targetSegments.length) {
      const cleansedTargetSegments = targetSegments.map(segment =>
        TranslationMemoryItem.cleanseSegment(segment),
      );

      const sourceText = sourceSegments.join(' ');
      const targetText = cleansedTargetSegments.join(' ');
      this.key = `${sourceText}|${targetText}`;

      this.sourceText = sourceText;
      this.sourceSegments = sourceSegments;
      this.sourceSegmentsCount = sourceSegments.length;

      this.targetText = targetText;
      this.targetSegments = cleansedTargetSegments;
      this.targetSegmentsCount = cleansedTargetSegments.length;

      this.totalSegmentsCount = this.sourceSegmentsCount + this.targetSegmentsCount;

      this.count = 1;
    }

    if (this.key && sourceLocations.length && targetLocations.length) {
      this.locationKey = `${TranslationMemoryItem.locationsToKey(
        sourceLocations,
      )}|${TranslationMemoryItem.locationsToKey(targetLocations)}`;
    }
  }

  getKeyLocation(): string {
    return `${this.key}:${this.locationKey}`;
  }

  static locationsToKey(locations: number[]): string {
    return locations
      .sort((i1, i2) => i1 - i2)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(String)
      .join('+');
  }

  static cleanseSegment(segment: string): string {
    let cleansed = segment.replace(/[.,"“”‘¿?():;!—<>]/g, '');

    // remove single quote only if it is in the beginning or end of a segment
    cleansed = cleansed.replace(/^'+|[’']+$/g, '');

    return cleansed;
  }
}
