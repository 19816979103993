/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import * as types from '../../types';
import { MemoryVerseReference, MemoryTargetData } from '../../shared/structs';
import { VerseIdParser } from '../../shared/verseIdParser';

const VerseIdParserInstance = new VerseIdParser();

export class SegmentComp extends React.Component<types.SegmentProps, types.SegmentState> {
  private isAdvanced = false;

  private calcListPosition(el: HTMLElement): void {
    const parents = document.getElementsByClassName('verse-box');
    if (parents && parents.length && el) {
      const parentPos = parents[0].getBoundingClientRect();
      const elPos = el.getBoundingClientRect();
      const offsetLeft = elPos.right - parentPos.right;
      const childSpace = parentPos.right - elPos.right;

      if (offsetLeft > 0) {
        el.style.transform = `translate3d(${Math.ceil(offsetLeft) * -1}px, 0px, 0px)`;
      }

      const list = el.getElementsByTagName('ul');
      if (list && list.length) {
        for (let index = 0; index < list.length; index += 1) {
          if (childSpace < 300) {
            list[index].style.left = '';
            list[index].style.right = '100%';
          } else {
            list[index].style.right = '';
            list[index].style.left = '100%';
          }
        }
      }
    }
  }

  private toggleDropdownList(key: string, e: any): void {
    const wordList: HTMLCollection = document.getElementsByClassName('word-list');
    if (e && wordList && wordList.length) {
      const cssKey = 'display';
      for (let index = 0; index < wordList.length; index += 1) {
        const el: any | undefined = wordList[index];
        if (el === e.target.parentElement) {
          if (el.classList.contains(cssKey)) {
            el.classList.remove(cssKey);
            el.style.transform = '';
          } else {
            el.classList.add(cssKey);

            const nextGen = el.getElementsByClassName('word-list-main-menu ');
            if (nextGen && nextGen.length) {
              this.calcListPosition(nextGen[0]);
            }
          }
        } else {
          el.classList.remove(cssKey);
        }
      }
    }
  }

  private suggestionOptionAdditionalInfo(
    word: string,
    memoryTargetData: MemoryTargetData[],
  ): ReactElement | null {
    if (memoryTargetData) {
      let n = -1;
      let items: MemoryVerseReference[] = [];

      const suggestions = Object.values(memoryTargetData);

      for (let index = 0; index < suggestions.length; index += 1) {
        const suggestionData = suggestions[index];

        if (suggestionData && suggestionData.verses && suggestionData.targetText === word) {
          items = suggestionData.verses;
        }
      }

      const listRecord = items.reduce((acc: Record<string, string>, item) => {
        const unique = acc;
        if (!unique[item.textId]) {
          unique[item.textId] = item.text;
        }
        return unique;
      }, {});

      const list = Object.keys(listRecord)
        .sort()
        .map(textId => {
          n += 1;
          const __html = listRecord[textId].replace(new RegExp(word, 'gi'), `<b>${word}</b>`);
          const verseInfo = VerseIdParserInstance.getReadableReferenceForGbiId(textId);

          return (
            <li className="list-group-item" key={`word-list-dropdown-list-g0746-${textId}-${n}`}>
              <FormattedMessage id={verseInfo.book}>
                {(book): ReactElement => <sub>{`${book} ${verseInfo.ref}`}</sub>}
              </FormattedMessage>
              <div dangerouslySetInnerHTML={{ __html }} />
            </li>
          );
        });

      return (
        <ul className="list-group border sub-menu" style={{ left: '100%' }}>
          {list}
        </ul>
      );
    }
    return null;
  }

  private suggestionOption(targetTranslation: string, strongX: string): ReactElement {
    const { linkSuggestions, memorySuggestions } = this.props;
    if (linkSuggestions && linkSuggestions.length) {
      return (
        <div className="item item-target word-list dropdown ">
          <button
            type="button"
            className="btn dropdown-toggle"
            key={`word-list-dropdown-button-${strongX}`}
            onClick={this.toggleDropdownList.bind(this, strongX)}
          >
            {targetTranslation}
          </button>

          <ul
            className="dropdown-menu word-list-main-menu"
            key={`word-list-dropdown-list-${strongX}`}
            id={`word-list-dropdown-list-${strongX}`}
            data-key={strongX}
          >
            {((): ReactElement[] => {
              let n = 0;
              return linkSuggestions
                .sort((left: string, right: string): number => left.localeCompare(right))
                .map(
                  (item: string): ReactElement => {
                    n += 1;
                    return (
                      <li className="dropdown-item" key={`dropdown-item-${n}`}>
                        {item}

                        {this.suggestionOptionAdditionalInfo(item, memorySuggestions)}
                      </li>
                    );
                  },
                );
            })()}
          </ul>
        </div>
      );
    }
    return <span className="item item-target">{targetTranslation}</span>;
  }

  // TODO: allow users to select simple/advanced view
  public render(): ReactElement {
    const { manuscriptData, position } = this.props;

    return (
      <div
        className={`group group-${position}`}
        key={`suggestion-group-${manuscriptData.strongsX}-${position}`}
      >
        <span className="item item-number" hidden={!this.isAdvanced}>
          {position}
        </span>
        <span className="item item-strong-x" hidden={!this.isAdvanced}>
          {manuscriptData.strongsX}
        </span>
        <span className="item item-manuscript">{manuscriptData.segment}</span>
        {this.suggestionOption(manuscriptData.english, manuscriptData.strongsX)}
      </div>
    );
  }
}
const Segment = SegmentComp;
export default Segment;
