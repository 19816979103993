import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Resource from './resource';
import { AppState } from '../../reducers';
import { TranslationProps, TranslationState, DefaultTranslationProps } from '../../types';
import {
  addResourceAction,
  fetchResourceListAction,
  rememberLastThingAction,
  toggleManuscriptAction,
  updateUIConfigAction,
} from '../../actions';
import CustomDropdownMenu from '../navigator/customDropdownMenu';
import CustomDropdownToggle from '../navigator/customDropdownToggle';
import getCurrentProjectId from '../../lib/getCurrentProjectId';

export class ResourceContainerComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
    const { fetchResourceListFunc } = this.props;
    fetchResourceListFunc();
  }

  public resources(): ReactElement {
    const { resources } = this.props;
    if (resources.length > 0) {
      const resourceElements = resources.map((resource: any, index: number) => {
        const resourceKey = resource.shortName + index;
        return <Resource key={resourceKey} selectedResource={resource} />;
      });
      return <>{resourceElements}</>;
    }
    return this.emptyState();
  }

  public emptyState(): ReactElement {
    return (
      <>
        <Col className="col-md-6 text-center mt-5">
          <p className="resource-prompt">
            <FormattedMessage id="resources.prompt" />
          </p>
          <Dropdown key="resource-empty-state-dropdown">
            <Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownToggle}>
              <div>
                <span className="add-resource-empty">
                  +
                  <FormattedMessage id="resources.add" />
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomDropdownMenu}>{this.resourceList()}</Dropdown.Menu>
          </Dropdown>
        </Col>
      </>
    );
  }

  public addResource(): any {
    const { resources } = this.props;
    if (resources.length === 1) {
      return (
        <Dropdown key="add-resource-dropdown">
          <Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownToggle}>
            <i className="add-resource fas fa-plus" />
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomDropdownMenu}>{this.resourceList()}</Dropdown.Menu>
        </Dropdown>
      );
    }
    return undefined;
  }

  public resourceList(): any {
    const { availableResources, addResourceFunc, rememberLastThing, resources } = this.props;
    const items: ReactElement[] = new Array<ReactElement>();
    if (!availableResources.empty) {
      availableResources.data.forEach((resource: any, index: number) => {
        const resourceLabel = `${resource.fullName} (${resource.language})`;
        const itemIndex = resource.shortName || index;
        items.push(
          <Dropdown.Item
            key={itemIndex}
            eventKey={resourceLabel}
            onClick={(): void => {
              addResourceFunc(resource);
              rememberLastThing(getCurrentProjectId(), 'resources', resources.concat(resource));
            }}
          >
            {resourceLabel}
          </Dropdown.Item>,
        );
      });
    }
    return items;
  }

  private collapse(): ReactElement {
    const { toggleManuscript, updateUIConfig, isBottomPanelOpen } = this.props;
    return (
      <div className="resource-collapse-container">
        <button
          type="button"
          className="collapse-bottom-panel"
          onClick={(): void => {
            if (toggleManuscript) {
              toggleManuscript();
              if (updateUIConfig) {
                updateUIConfig('isBottomPanelOpen', !isBottomPanelOpen);
              }
            }
          }}
        >
          <i className="fas fa-sm fa-chevron-down" />
          <i className="fas fa-sm fa-chevron-down" />
          <i className="fas fa-sm fa-chevron-down" />
        </button>
      </div>
    );
  }

  public render(): ReactElement {
    return (
      <div className="panel bar-left resource" style={{ height: '100%' }}>
        {this.collapse()}
        {this.addResource()}
        <Row className="justify-content-md-center resource-content-container">
          {this.resources()}
        </Row>
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    isBottomPanelOpen: state.profile.isBottomPanelOpen,
  };
  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  addResourceFunc: (resource: any): void => {
    dispatch(addResourceAction(resource));
  },
  fetchResourceListFunc: (): void => {
    dispatch(fetchResourceListAction());
  },
  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },
  toggleManuscript: (): void => {
    dispatch(toggleManuscriptAction('Bottom'));
  },
  updateUIConfig: (fieldName: string, fieldValue: boolean): void => {
    dispatch(updateUIConfigAction(fieldName, fieldValue));
  },
});

const ResourceContainer = connect(mapStateToProps, mapDispatchToProps)(ResourceContainerComp);

export default ResourceContainer;
