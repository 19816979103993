/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ProjectProps, ProjectData } from '../../types';
import {
  projectChangeData,
  projectCancelForm,
  projectFormHasError,
  createProject,
  projectLoaded,
  clearError,
  ShowProjectList,
} from '../../actions';
import { AppState } from '../../reducers';
import Spinner from '../spinner';
import ProjectBase from './projectBase';
import noop from '../../lib/noop';

class ProjectCreationApp extends ProjectBase {
  public static defaultProps: ProjectProps = {
    beginningDate: '',
    description: '',
    isFetching: false,
    loading: false,
    projectName: '',
    targetCompletionDate: '',
    userEmail: '',

    cancelAddingProjectUserFunc: noop,
    changeViewFunc: noop,
    clearErrorAndMessageFunc: noop,
    closeLightboxEditingProjectFunc: noop,
    closeLightboxManageProjectUsersFunc: noop,
    closeLightboxRemovingProjectFunc: noop,
    createProjectFunc: noop,
    deleteProjectFunc: noop,
    deleteProjectUserFunc: noop,
    editProjectFunc: noop,
    fetchProjectFunc: noop,
    fetchProjectsFunc: noop,
    handleUserEmailChangeFunc: noop,
    handleUserPermissionChangeFunc: noop,
    saveProjectUserFunc: noop,
    searchProjectUserEmailFunc: noop,
    showLightboxEditingProjectFunc: noop,
    showLightboxManageProjectUsersFunc: noop,
    showLightboxRemovingProjectFunc: noop,
    ShowProjectCreationFunc: noop,
    showViewOfAddingProjectUserFunc: noop,
  };

  private projectNameLength = 3;

  private handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const { updateFrom } = this.props;
    updateFrom(e.target.id, e.target.value);
  }

  private cancelForm(): void {
    const { changeViewFunc } = this.props;
    if (changeViewFunc) {
      changeViewFunc();
    }
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    const {
      formHasError,
      projectName,
      beginningDate,
      targetCompletionDate,
      description,
      createProjectFunc,
    } = this.props;

    const projectData = {
      projectName: projectName ? projectName.trim() : projectName,
      description: description ? description.trim() : description,
      beginningDate,
      targetCompletionDate,
    };

    const { error } = this.projectValidation(projectData);

    if (error && Object.prototype.hasOwnProperty.call(error, 'details')) {
      const item = error.details.pop();
      if (Object.prototype.hasOwnProperty.call(item, 'message')) {
        formHasError(item.message);
      }
    } else {
      createProjectFunc(projectData);
    }
  }

  public render(): ReactElement {
    const {
      projectName,
      beginningDate,
      targetCompletionDate,
      description,
      error,
      message,
      clearErrorAndMessageFunc,
      isFetching,
    } = this.props;

    return (
      <div className="project-list">
        <div className="row">
          <div className="col-8">
            <h1>
              <FormattedMessage id="project.create.title" />
            </h1>
          </div>
          <div className="col-4 text-right mb-1">
            <button
              className="btn btn-link text-ytb"
              type="button"
              onClick={this.cancelForm.bind(this)}
            >
              <i className="far fa-list-alt mr-1" />
              <FormattedMessage id="project.create.list" />
            </button>
          </div>
        </div>
        <hr />
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="card card-project">
            <div className="card-header">
              <FormattedMessage id="project.create.createNew" />
            </div>
            <div className="card-body">
              {isFetching && <Spinner />}

              {error && (
                <div className="alert alert-danger mb-4" role="alert">
                  {error}
                  <button
                    type="button"
                    className="close"
                    onClick={clearErrorAndMessageFunc.bind(this)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              {message && (
                <div className="alert alert-success mb-4" role="alert">
                  {message}
                  <button
                    type="button"
                    className="close"
                    onClick={clearErrorAndMessageFunc.bind(this)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <div className="form-group">
                <label htmlFor="projectName" className="font-weight-bold required-field">
                  <FormattedMessage id="project.name" />
                </label>
                <FormattedMessage id="project.namePlaceholder">
                  {(placeholder: any): ReactElement => (
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="projectName"
                      placeholder={placeholder}
                      onChange={this.handleChange.bind(this)}
                      value={projectName}
                      required
                      minLength={this.projectNameLength}
                    />
                  )}
                </FormattedMessage>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 mt-2">
                  <label htmlFor="beginningDate" className="font-weight-bold required-field">
                    <FormattedMessage id="project.beginningDate" />
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-lg date-input "
                    id="beginningDate"
                    onChange={this.handleChange.bind(this)}
                    value={beginningDate}
                    required
                  />
                </div>
                <div className="form-group col-md-6 mt-2">
                  <label htmlFor="targetCompletionDate" className="font-weight-bold required-field">
                    <FormattedMessage id="project.targetCompletionDate" />
                  </label>

                  <input
                    type="date"
                    className="form-control form-control-lg date-input "
                    id="targetCompletionDate"
                    onChange={this.handleChange.bind(this)}
                    value={targetCompletionDate}
                    required
                  />
                </div>
              </div>

              <div className="form-group mt-2">
                <label htmlFor="description" className="font-weight-bold required-field">
                  <FormattedMessage id="project.description" />
                </label>
                <FormattedMessage id="project.descriptionPlaceholder">
                  {(placeholder: any): ReactElement => (
                    <textarea
                      className="form-control form-control-lg"
                      id="description"
                      placeholder={placeholder}
                      rows={5}
                      onChange={this.handleChange.bind(this)}
                      value={description}
                      required
                    />
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>

          <div className="form-group form-check mb-4">
            <div className="row">
              <div className="col text-right">
                <button
                  className="btn btn-lg btn-ytb btn-normal"
                  type="submit"
                  disabled={isFetching}
                >
                  <FormattedMessage id="create" />
                </button>
              </div>

              <div className="col">
                <button
                  className="btn btn-lg btn-secondary btn-normal"
                  type="button"
                  onClick={this.cancelForm.bind(this)}
                  disabled={isFetching}
                >
                  <FormattedMessage id="cancel" />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  updateFrom: (key: string, value: string): void => {
    dispatch(projectChangeData(key, value));
  },

  cancelFrom: (): void => {
    dispatch(projectCancelForm());
  },

  formHasError: (error: string): void => {
    dispatch(projectFormHasError(error));
  },

  createProjectFunc: (projectData: ProjectData): void => {
    dispatch(createProject(projectData));
  },

  loaded: (): void => {
    dispatch(projectLoaded());
  },

  clearErrorAndMessageFunc: (): void => {
    dispatch(clearError());
  },

  changeViewFunc: (): void => {
    dispatch(ShowProjectList());
  },
});

export const ProjectCreation = connect(mapStateToProps, mapDispatchToProps)(ProjectCreationApp);
