export const FACEBOOK = 'FACEBOOK';
export const GOOGLE = 'GOOGLE';
export const REDIRECT_TO_PROJECT = 'REDIRECT_TO_PROJECT';
export const REDIRECT_DONE = 'REDIRECT_DONE';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const EMAIL_ADDR_UPDATE = 'EMAIL_ADDR_UPDATE';
export const PASSWORD_UPDATE = 'PASS_UPDATE';
export const USER_IS_NOT_SIGNED = 'USER_IS_NOT_SIGNED';

export interface User {
  displayName?: string;
  email: string;
  emailVerified: boolean;
  photoURL: string;
  isAnonymous: boolean;
  uid: string;
  providerData: string[];
  profile?: any;
}
export const CLEAR_ERROR_MSG = 'CLEAR_ERROR_MSG';

export interface SignInState {
  display: boolean;
  user: User | null;
  redirect: boolean;
  error?: null;
  emailAddr: string;
  password: string;
}

export interface SignInProps {
  display: boolean;
  authenticated: boolean;
  redirectToProject: boolean;
  user: User | null;
  emailAddr: string;
  password: string;
  emailAddrUpdate?: any;
  passwordUpdate?: any;
  signinWithFacebook?: any;
  signinWithGoogle?: any;
  signinWithEmailAddr?: any;
  dispalyLightboxFunc?: any;
  closeLightboxFunc?: any;
  redirectDoneFunc?: any;
  error?: any;
}

interface RedirectAction {
  type: typeof REDIRECT_TO_PROJECT;
}

interface RedirectDoneAction {
  type: typeof REDIRECT_DONE;
}

interface SignFailAction {
  type: typeof SIGN_IN_FAIL;
  error: any;
}

interface SignEmailAddressAction {
  type: typeof EMAIL_ADDR_UPDATE;
  emailAddr: string;
}

interface SignPasswordAction {
  type: typeof PASSWORD_UPDATE;
  password: string;
}

interface ClearErrorAction {
  type: typeof CLEAR_ERROR_MSG;
}

export type SignInActionTypes =
  | RedirectAction
  | RedirectDoneAction
  | SignFailAction
  | SignEmailAddressAction
  | SignPasswordAction
  | ClearErrorAction;
