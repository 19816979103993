import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dropdown, Accordion } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Reference from './reference';
import { Translation } from '../../shared/structs';
import CustomDropdownMenu from '../navigator/customDropdownMenu';
import CustomDropdownToggle from '../navigator/customDropdownToggle';
import { AppState } from '../../reducers';
import { TranslationProps, TranslationState, DefaultTranslationProps } from '../../types';
import {
  fetchReferenceListAction,
  addReferenceAction,
  rememberLastThingAction,
  toggleManuscriptAction,
  updateUIConfigAction,
} from '../../actions';
import getCurrentProjectId from '../../lib/getCurrentProjectId';

export class ReferenceContainerComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
    const { fetchReferenceListFunc } = this.props;
    fetchReferenceListFunc();
  }

  public referenceList(): any {
    const { availableReferences, addReferenceFunc, rememberLastThing, references } = this.props;
    if (!availableReferences.empty) {
      return availableReferences.data
        .sort((a: Translation, b: Translation) => (a.fullName > b.fullName ? 1 : -1))
        .map(
          (reference: Translation, index: number): ReactElement => {
            const shortName = reference.shortName.toUpperCase();
            const referenceDescription = reference.description || shortName;
            const referenceLabel = `${reference.fullName} (${reference.language}, ${referenceDescription})`;
            const itemIndex = reference.shortName + index;
            return (
              <Dropdown.Item
                key={itemIndex}
                eventKey={referenceLabel}
                onClick={(): void => {
                  addReferenceFunc(reference);
                  rememberLastThing(
                    getCurrentProjectId(),
                    'references',
                    references.concat(reference),
                  );
                }}
              >
                {referenceLabel}
              </Dropdown.Item>
            );
          },
        );
    }
    return new Array<ReactElement>();
  }

  public emptyState(): ReactElement {
    return (
      <div className="text-center">
        <p className="reference-prompt">
          <FormattedMessage id="references.referencePrompt" />
        </p>
        <Dropdown key="reference-empty-state-dropdown">
          <Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownToggle}>
            <div>
              <span className="add-reference-empty">
                + &nbsp;
                <FormattedMessage id="references.add" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomDropdownMenu}>{this.referenceList()}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  public references(): ReactElement {
    const { references } = this.props;
    if (references.length > 0) {
      const referenceElements = references.map((reference: Translation, index: number) => {
        const refKey = `${reference.id}-${index}`;
        return <Reference key={`reference-${refKey}`} selectedReference={reference} />;
      });
      return <>{referenceElements}</>;
    }
    return this.emptyState();
  }

  private addReference(): ReactElement {
    const { references } = this.props;
    if (references.length > 0) {
      return (
        <Dropdown key="reference-add-dropdown">
          <Dropdown.Toggle id="reference-add-dropdown-custom-components" as={CustomDropdownToggle}>
            <div>
              <span className="add-reference-not-empty">
                + &nbsp;
                <FormattedMessage id="references.addAnother" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomDropdownMenu}>{this.referenceList()}</Dropdown.Menu>
        </Dropdown>
      );
    }
    return <></>;
  }

  public render(): ReactElement {
    const { toggleManuscript, updateUIConfig, isRightPanelOpen } = this.props;
    return (
      <Accordion defaultActiveKey="1" className="panel references bar-top">
        <button
          type="button"
          className="collapse-right-panel"
          onClick={(): void => {
            if (toggleManuscript) {
              toggleManuscript('Right');
              if (updateUIConfig) {
                updateUIConfig('isRightPanelOpen', !isRightPanelOpen);
              }
            }
          }}
        >
          <i className="fas fa-sm fa-chevron-right" />
          <i className="fas fa-sm fa-chevron-right" />
          <i className="fas fa-sm fa-chevron-right" />
        </button>
        {this.addReference()}
        {this.references()}
      </Accordion>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    isRightPanelOpen: state.profile.isRightPanelOpen,
  };
  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  addReferenceFunc: (reference: any): void => {
    dispatch(addReferenceAction(reference));
  },
  fetchReferenceListFunc: (): void => {
    dispatch(fetchReferenceListAction());
  },
  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },
  toggleManuscript: (): void => {
    dispatch(toggleManuscriptAction('Right'));
  },
  updateUIConfig: (fieldName: string, fieldValue: boolean): void => {
    dispatch(updateUIConfigAction(fieldName, fieldValue));
  },
});

const ReferenceContainer = connect(mapStateToProps, mapDispatchToProps)(ReferenceContainerComp);

export default ReferenceContainer;
