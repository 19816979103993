import { Dispatch } from 'redux';

import * as types from '../types';
import firebase from '../lib/firebase';
import errorMessageExtractor from '../lib/errorMessageExtractor';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export function toggleManuscriptAction(panel: types.Panel): any {
  return (dispatch: any): void => {
    dispatch({
      type: types.TOGGLE_MANUSCRIPT,
      panel,
    });
  };
}

export const updateUIConfigRequestAction = (): types.GridActionTypes => {
  return {
    type: types.UPDATE_UI_CONFIG_REQUEST,
  };
};

export const updateUIConfigFailureAction = (error: string): types.GridActionTypes => {
  return {
    type: types.UPDATE_UI_CONFIG_FAILURE,
    error,
  };
};

export const updateUIConfigSuccessAction = (): types.GridActionTypes => {
  return {
    type: types.UPDATE_UI_CONFIG_SUCCESS,
  };
};

export const updateUIConfigAction = (panelField: string, isOpen: boolean): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateUIConfigRequestAction());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        await (await FirebaseDb.init()).updateUserProfile(currentUser.uid, panelField, isOpen);
        dispatch(updateUIConfigSuccessAction());
      } else {
        throw new Error(types.USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      const errorMessage = errorMessageExtractor(error);
      if (errorMessage === types.USER_IS_NOT_SIGNED) {
        dispatch(updateUIConfigFailureAction(`You must be signed in to access alignment data.`));
      } else {
        dispatch(updateUIConfigFailureAction(errorMessage));
      }
    }
  };
};
