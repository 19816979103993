import { REHYDRATE } from 'redux-persist';
import moment from 'moment';
import { config } from '../config';

import {
  ProjectState,
  CreateProjectActionTypes,
  CHANGE_DATA,
  CANCEL_FORM,
  FORM_ERROR,
  PROJECT_LOADED,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  CLEAR_ERROR_MSG,
  SHOW_PROJECT_LIST,
  SHOW_PROJECT_CREATION,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  SHOW_LIGHTBOX_REMOVING_PROJECT,
  CLOSE_LIGHTBOX_REMOVING_PROJECT,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  SHOW_LIGHTBOX_EDITING_PROJECT,
  CLOSE_LIGHTBOX_EDITING_PROJECT,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_SUCCESS,
  SHOW_LIGHTBOX_MANAGE_PROJECT_USERS,
  CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS,
  SHOW_VIEW_OF_ADDING_PROJECT_USER,
  CANCEL_ADDING_PROJECT_USER,
  SEARCH_PROJECT_USER_EMAIL,
  HANDLE_USER_EMAIL_CHANGE,
  HANDLE_USER_PERMISSION_CHANGE,
  SAVE_PROJECT_USER_REQUEST,
  SAVE_PROJECT_USER_SUCCESS,
  SAVE_PROJECT_USER_FAILURE,
  DELETE_PROJECT_USER_REQUEST,
  DELETE_PROJECT_USER_SUCCESS,
  DELETE_PROJECT_USER_FAILURE,
} from '../types';

const today = moment(new Date()).format(config.dateFormat);

const INIT_STAT: ProjectState = {
  loading: true,
  view: 'project',
  isFetching: false,
  redirectToProjectList: false,
  error: null,
  projectName: '',
  beginningDate: today,
  targetCompletionDate: '',
  description: '',
  showLightboxRemovingProjectVar: false,
  removeProjectId: undefined,
  deletingProject: false,
  isFetchingProject: false,
  projectNameEditing: '',
  beginningDateEditing: '',
  targetCompletionDateEditing: '',
  descriptionEditing: '',
  showLightboxManageUsersVar: false,
};

const ProjectApp = (state = INIT_STAT, action: CreateProjectActionTypes): any => {
  const stateCopy = { ...state };

  switch (action.type) {
    case CHANGE_DATA:
      if (action.key === 'projectName') {
        stateCopy.projectName = action.value;
      } else if (action.key === 'description') {
        stateCopy.description = action.value;
      } else if (action.key === 'beginningDate') {
        stateCopy.beginningDate = action.value;
      } else if (action.key === 'targetCompletionDate') {
        stateCopy.targetCompletionDate = action.value;
      } else if (action.key === 'projectNameEditing') {
        stateCopy.projectNameEditing = action.value;
      } else if (action.key === 'descriptionEditing') {
        stateCopy.descriptionEditing = action.value;
      } else if (action.key === 'beginningDateEditing') {
        stateCopy.beginningDateEditing = action.value;
      } else if (action.key === 'targetCompletionDateEditing') {
        stateCopy.targetCompletionDateEditing = action.value;
      }

      return { ...stateCopy };

    case CANCEL_FORM:
      return {
        ...state,
        projectName: '',
        beginningDate: '',
        targetCompletionDate: '',
        description: '',
        error: '',
        isFetching: false,
      };

    case FORM_ERROR:
      return { ...state, error: action.error, message: action.message };

    case CLEAR_ERROR_MSG:
      return { ...state, error: '', message: '', errorProjectUser: '', messageProjectUser: '' };

    case PROJECT_LOADED:
      return { ...state, loading: false };
    case CREATE_PROJECT_REQUEST:
      return { ...state, isFetching: true };

    case CREATE_PROJECT_FAILURE:
      return { ...state, error: action.error, isFetching: false, message: '' };

    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: '',
        isFetching: false,
        message: action.message,
        redirectToProjectList: true,
      };

    case REHYDRATE:
      if (Object.prototype.hasOwnProperty.call(action, 'payload')) {
        const { payload } = action;

        if (payload !== undefined && Object.prototype.hasOwnProperty.call(payload, 'project')) {
          const { project } = payload;
          if (Object.prototype.hasOwnProperty.call(project, 'beginningDate')) {
            const { beginningDate } = project;
            if (beginningDate === '') {
              return {
                ...payload.project,
                beginningDate: moment(new Date()).format(config.dateFormat),
              };
            }
          }

          return {
            ...payload.project,
            beginningDate: moment(new Date()).format(config.dateFormat),
          };
        }
      }

      return state;

    case SHOW_PROJECT_LIST:
      return { ...state, view: SHOW_PROJECT_LIST, loading: true, error: '' };

    case SHOW_PROJECT_CREATION:
      if (state.beginningDate === '') {
        return {
          ...state,
          view: SHOW_PROJECT_CREATION,
          loading: true,
          error: '',
          beginningDate: today,
        };
      }

      return { ...state, view: SHOW_PROJECT_CREATION, loading: true, error: '' };

    case FETCH_PROJECTS_REQUEST:
      return { ...state, isFetchingList: true };

    case FETCH_PROJECTS_FAILURE:
      return { ...state, isFetchingList: false, error: action.error };

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetchingList: false,
        projectList: action.projectList,
        currentUid: action.currentUid,
      };

    case SHOW_LIGHTBOX_REMOVING_PROJECT:
      return { ...state, showLightboxRemovingProjectVar: true, removeProjectId: action.projectId };

    case CLOSE_LIGHTBOX_REMOVING_PROJECT:
      return { ...state, showLightboxRemovingProjectVar: false };

    case DELETE_PROJECT_REQUEST:
      return { ...state, deletingProject: true };

    case DELETE_PROJECT_SUCCESS:
      return { ...state, deletingProject: false, removeProjectId: undefined };

    case DELETE_PROJECT_FAILURE:
      return { ...state, deletingProject: false, deletingError: action.error };

    case SHOW_LIGHTBOX_EDITING_PROJECT:
      return { ...state, showLightboxEditingProjectVar: true, editProjectId: action.projectId };

    case CLOSE_LIGHTBOX_EDITING_PROJECT:
      return { ...state, showLightboxEditingProjectVar: false, editProjectId: undefined };

    case EDIT_PROJECT_REQUEST:
      return { ...state, editingProject: true };

    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        editingProject: false,
        showLightboxEditingProjectVar: false,
        editProjectId: undefined,
      };

    case EDIT_PROJECT_FAILURE:
      return { ...state, editingProject: false, editingError: action.error };

    case FETCH_PROJECT_REQUEST:
      return { ...state, isFetchingProject: true };

    case FETCH_PROJECT_FAILURE:
      return { ...state, isFetchingProject: false, error: action.error };

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        projectNameEditing: action.projectData.projectName,
        beginningDateEditing: action.projectData.beginningDate,
        targetCompletionDateEditing: action.projectData.targetCompletionDate,
        descriptionEditing: action.projectData.description,
        projectUsers: action.projectData.users,
        error: null,
        message: null,
        userIsProjectMember: action.userIsProjectMember,
      };

    // manage project users - begin
    case SHOW_LIGHTBOX_MANAGE_PROJECT_USERS:
      return { ...state, showLightboxManageUsersVar: true, projectIdManageUsers: action.projectId };

    case CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS:
      return {
        ...state,
        showLightboxManageUsersVar: false,
        projectIdManageUsers: undefined,
        errorProjectUser: '',
        messageProjectUser: '',
      };

    case SHOW_VIEW_OF_ADDING_PROJECT_USER:
      return { ...state, appState: 'ADD_USER' };

    case CANCEL_ADDING_PROJECT_USER:
      return { ...state, appState: 'LIST_USER', errorProjectUser: '', messageProjectUser: '' };

    case SEARCH_PROJECT_USER_EMAIL:
      return { ...state, appState: 'SEARCH_USER' };

    case HANDLE_USER_EMAIL_CHANGE:
      return { ...state, userEmail: action.email || '' };

    case HANDLE_USER_PERMISSION_CHANGE:
      return { ...state, userPermission: action.permission };

    case SAVE_PROJECT_USER_REQUEST:
      return { ...state, isFetchingProject: true };

    case SAVE_PROJECT_USER_FAILURE:
      return { ...state, isFetchingProject: false, errorProjectUser: action.error };

    case SAVE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        appState: 'LIST_USER',
        message: 'project.message.user-added',
        error: null,
        userEmail: '',
        userPermission: null,
      };

    case DELETE_PROJECT_USER_REQUEST:
      return { ...state, isFetchingProject: true };

    case DELETE_PROJECT_USER_FAILURE:
      return { ...state, isFetchingProject: false, errorProjectUser: action.error };

    case DELETE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        isFetchingProject: false,
        appState: 'LIST_USER',
        message: 'project.message.user-added',
        error: null,
      };

    default:
      return state;
  }
};

export default ProjectApp;
