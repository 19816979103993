import { ProjectUsers } from '../types/project';

export const userPermission = (uid: string | undefined, users: ProjectUsers): string => {
  return uid && users && uid in users ? users[uid].permission : 'readOnly';
};

export const userIsOwner = (uid: string | undefined, users: ProjectUsers): boolean => {
  return userPermission(uid, users) === 'owner';
};

export const userIsMember = (uid: string | undefined, users: ProjectUsers): boolean => {
  return !!(uid && users && uid in users);
};
