import { REDIRECT_TO_PROJECT, SIGN_UP_FAIL, EMAIL_ADDR_UPDATE, PASSWORD_UPDATE } from '../types';
import { publishDisplayName } from './auth';
import firebase from '../lib/firebase';
import { ShowProjectList } from './project';

const FirebaseAuth = firebase.createService('auth');
const FirebaseDb = firebase.createService('db');

export function signupSuccess(): any {
  return (dispatch: any): void => {
    dispatch({
      type: REDIRECT_TO_PROJECT,
    });
  };
}

export function signupFailed(error: any): any {
  return (dispatch: any): void => {
    dispatch({
      type: SIGN_UP_FAIL,
      error,
    });
  };
}

export function emailAddrUpdateAction(emailAddr: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: EMAIL_ADDR_UPDATE,
      emailAddr,
    });
  };
}

export function passwordUpdateAction(password: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: PASSWORD_UPDATE,
      password,
    });
  };
}

export function signupAction(username: string, password: string): any {
  return async (dispatch: any): Promise<any> => {
    try {
      const user = await (await FirebaseAuth.init()).signupWithEmailProvider(username, password);

      if (user) {
        dispatch(publishDisplayName(user.displayName));
        await (await FirebaseDb.init()).createUser(user);
        dispatch(signupSuccess());
        dispatch(ShowProjectList());
      } else {
        throw new Error('Something unexpectedly went wrong.');
      }
    } catch (error) {
      dispatch(signupFailed(error.message));
    }
  };
}
