import { User } from './signin';

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_NOT_AUTHENTICATED = 'USER_NOT_AUTHENTICATED';
export const PUBLISH_DISPLAY_NAME = 'PUBLISH_DISPLAY_NAME';

export interface AuthState {
  authenticated: boolean;
  user: User | null;
  displayName: string;
}

interface UserAuthenticatedAction {
  type: typeof USER_AUTHENTICATED;
  user: User;
}

interface UserNotAuthenticatedAction {
  type: typeof USER_NOT_AUTHENTICATED;
  user: User;
}

interface PublishDisplayNameAction {
  type: typeof PUBLISH_DISPLAY_NAME;
  displayName: string;
}

export type AuthActionTypes =
  | UserAuthenticatedAction
  | UserNotAuthenticatedAction
  | PublishDisplayNameAction;
