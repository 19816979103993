import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../reducers';
import { emailAddrUpdateAction, passwordUpdateAction, signupAction } from '../actions/signup';
import { SignUpProps } from '../types';

const emailAddrUpdateWrapper = (e?: any, props?: any): void => {
  const { emailAddrUpdate } = props;
  emailAddrUpdate(e.target.value);
};

const passwordUpdateWrapper = (e?: any, props?: any): void => {
  const { passwordUpdate } = props;
  passwordUpdate(e.target.value);
};

const signupWrapper = (e: any, props: any): void => {
  e.preventDefault();
  const { signup, emailAddr, password } = props;
  signup(emailAddr, password);
};

export const SignupComp: React.SFC<SignUpProps> = (props): any => {
  const { emailAddr, password, error } = props;
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>
              <FormattedMessage id="signup.join" />
            </h3>
            <p>
              <FormattedMessage id="signup.createAccountPrompt" />
            </p>
          </div>
          <div className="col">
            <form
              id="create-account-form"
              className="form-signin"
              onSubmit={(e): void => {
                signupWrapper(e, props);
              }}
            >
              <div className="form-group mb-2">
                <FormattedMessage id="signup.emailPlaceholder">
                  {(placeholder: any): ReactElement => (
                    <input
                      type="email"
                      className="form-control input-sm"
                      id="email-sign-up"
                      placeholder={placeholder}
                      value={emailAddr}
                      onChange={(e): void => {
                        emailAddrUpdateWrapper(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className="form-group">
                <FormattedMessage id="signup.passwordPlaceholder">
                  {(placeholder: any): ReactElement => (
                    <input
                      type="password"
                      className="form-control input-sm"
                      id="password-sign-up"
                      placeholder={placeholder}
                      value={password}
                      onChange={(e): void => {
                        passwordUpdateWrapper(e, props);
                      }}
                    />
                  )}
                </FormattedMessage>
              </div>
              {error && (
                <div className="form-group">
                  <p className="text-center text-danger">
                    <small id="signup-error">{error}</small>
                  </p>
                </div>
              )}
              <button id="create-account" className="btn btn-lg btn-ytb btn-block" type="submit">
                <FormattedMessage id="signup.createAccount" />
              </button>
            </form>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

/* eslint react/prop-types: "off" */
SignupComp.propTypes = {
  emailAddr: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string,
};

SignupComp.defaultProps = {
  error: null,
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  emailAddrUpdate: (e?: any): void => {
    dispatch(emailAddrUpdateAction(e));
  },

  passwordUpdate: (e?: any): void => {
    dispatch(passwordUpdateAction(e));
  },

  signup: (emailAddr: string, password: string): void => {
    dispatch(signupAction(emailAddr, password));
  },
});

export const mapStateToProps = (state: AppState): any => {
  return {
    redirectToProject: state.signup.redirect,
    emailAddr: state.signup.emailAddr,
    password: state.signup.password,
    error: Object.prototype.hasOwnProperty.call(state.signup, 'error') ? state.signup.error : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupComp);
