/* eslint-disable  no-param-reassign */
import * as types from '../types';

export const INIT_STAT: types.NavigatorState = {
  loading: false,
  display: false,
  defaultBookId: types.DEFAULT_BOOK,
  directionSlide: 'next',
  defaultChapter: types.DEFAULT_CHAPTER,
  keyword: '',
};

export default (state = INIT_STAT, action: types.NavigatorActionTypes): types.NavigatorState => {
  switch (action.type) {
    case types.DISPLAY_NAVIGATOR:
      return { ...state, display: true };

    case types.CLOSE_NAVIGATOR:
      return { ...state, display: false };

    case types.CHANGE_BOOK:
      return { ...state, selectedBookId: action.bookId, selectedChapter: 1, display: false };

    case types.CHANGE_CHAPTER:
      return { ...state, selectedChapter: action.chapter };

    case types.CHANGE_SLIDE:
      return { ...state, activeSlide: action.index };

    case types.SEARCH_BOOK:
      return { ...state, keyword: action.keyword };

    default:
      return { ...state, display: state.display };
  }
};
