import React, { Ref, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormControl } from 'react-bootstrap';

interface Props {
  onClick?: any;
  children: any;
  className?: any;
  show?: boolean;
}

const CustomDropdownMenu: React.FC<Props> = React.forwardRef(
  (props, ref: Ref<HTMLDivElement>): ReactElement => {
    const [value, setValue] = useState('');
    const { children, className, show } = props;

    const items = React.Children.toArray(children).filter((child: any): any => {
      const userInput = value ? value.toLowerCase() : '';
      const existingProp = child.props.eventKey
        ? child.props.eventKey.toString().toLowerCase()
        : '';
      return existingProp.includes(userInput);
    });

    if (!show && value !== '') {
      setValue('');
    }

    return (
      <div className={className} key="dropdown-chapter" ref={ref}>
        <div className="dropdown-header">
          <div className="dropdown-bar" />
          <div className="p-2">
            <FormattedMessage id="navigator.search">
              {(placeholder: any): ReactElement => (
                <FormControl
                  autoFocus
                  className="w-auto"
                  placeholder={placeholder}
                  onChange={(e: any): void => {
                    setValue(e.target.value);
                  }}
                  value={value}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <ul className="list-unstyled" key="chapter-rows">
          {items}
        </ul>
      </div>
    );
  },
);

CustomDropdownMenu.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

CustomDropdownMenu.defaultProps = {
  show: false,
  className: '',
};

export default CustomDropdownMenu;
