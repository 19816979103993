import * as React from 'react';

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

interface FetchRequestAction {
  type: typeof FETCH_REQUEST;
}

interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  user: object;
}

interface FetchFailureAction {
  type: typeof FETCH_FAILURE;
  error: any;
}

export type UserActionTypes = FetchRequestAction | FetchSuccessAction | FetchFailureAction;

export interface UserState {
  isFetching: boolean;
  error?: any;
  user?: object;
}

export interface UserProps {
  display: boolean;
  extModalCssName?: string;
  style?: React.CSSProperties;
  close?: any;
  closeHandler?: any;
}
