import { UserState, UserActionTypes } from '../types';

const INIT_STAT: UserState = {
  isFetching: false,
  error: null,
};

const users = (state = INIT_STAT, action: UserActionTypes): any => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, isFetching: true };

    case 'FETCH_SUCCESS':
      return { ...state, isFetching: false, user: action.user };
    case 'FETCH_FAILURE':
      return { ...state, isFetching: false, error: action.error };

    default:
      return state;
  }
};

export default users;
