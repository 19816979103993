import {
  SignUpState,
  SignUpActionTypes,
  REDIRECT_TO_PROJECT,
  SIGN_UP_FAIL,
  EMAIL_ADDR_UPDATE,
  PASSWORD_UPDATE,
} from '../types';

const INIT_STAT: SignUpState = {
  redirect: false,
  error: null,
  emailAddr: '',
  password: '',
};

const SignUp = (state = INIT_STAT, action: SignUpActionTypes): any => {
  switch (action.type) {
    case REDIRECT_TO_PROJECT:
      return { ...state, redirect: true, error: null };

    case SIGN_UP_FAIL:
      return { ...state, error: action.error };

    case EMAIL_ADDR_UPDATE:
      return { ...state, emailAddr: action.emailAddr };

    case PASSWORD_UPDATE:
      return { ...state, password: action.password };

    default:
      return state;
  }
};

export default SignUp;
