import { Firestore } from '@google-cloud/firestore';
import { VerseIdParser } from '../verseIdParser';
import {
  DbNames,
  ManuscriptSuggestions,
  ManuscriptVerse,
  MemoryCollection,
  TranslationMemory,
  MemoryTargetData,
} from '../structs/projectTranslations';

async function getMemory(
  db: Firestore,
  projectDocId: string,
  sourceText: string,
  memoryType = DbNames.LinkMemoryCollection,
  topOnly = false,
): Promise<MemoryTargetData[]> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular

  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(memoryType)
    .where('sourceText', '==', sourceText)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  if (topOnly) {
    query.limit(1);
  }

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach(doc => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
      verses: docData.verses,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return targets
    .sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount)
    .map(memory => {
      const memoryTargetData: MemoryTargetData = {
        targetText: memory.targetText,
        verses: memory.verses,
      };
      return memoryTargetData;
    });
}

async function getMemoryTargetTexts(
  db: Firestore,
  projectDocId: string,
  sourceText: string,
  memoryType = DbNames.LinkMemoryCollection,
  topOnly = false,
): Promise<string[]> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular

  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(memoryType)
    .where('sourceText', '==', sourceText)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  if (topOnly) {
    query.limit(1);
  }

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach(doc => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return targets
    .sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount)
    .map(memory => memory.targetText);
}

async function getMemorySuggestions(
  db: Firestore,
  projectDocId: string,
  segments: string[],
  memoryCollection: MemoryCollection,
): Promise<Record<string, MemoryTargetData[]>> {
  const memoryPromises = segments.map(
    (segment): Promise<MemoryTargetData[]> => {
      return getMemory(db, projectDocId, segment, memoryCollection);
    },
  );

  const memory = await Promise.all(memoryPromises);

  const segmentSuggestions = await segments.reduce(
    async (suggestions: Promise<Record<string, MemoryTargetData[]>>, segment, index: number) => {
      let allSuggestions;
      const previous = await suggestions;
      const memoryTargets = memory[index];
      if (memoryTargets.length) {
        allSuggestions = Object.assign(previous, { [segment]: memoryTargets });
      } else {
        allSuggestions = Object.assign(previous);
      }

      return allSuggestions;
    },
    Promise.resolve({}),
  );

  return segmentSuggestions;
}

function getTopTextSuggestion(
  db: Firestore,
  projectDocId: string,
  sourceSegments: string[],
  memory: string[][],
): string {
  return sourceSegments.reduce((suggestion: string, segment: string, index: number): string => {
    const [currentSuggestion] = memory[index];
    if (currentSuggestion) {
      return `${suggestion} ${currentSuggestion}`.trim();
    }
    return suggestion;
  }, '');
}

function getLinkSuggestions(
  db: Firestore,
  projectDocId: string,
  sourceSegments: string[],
  memory: string[][],
): Record<string, string[]> {
  return sourceSegments.reduce(
    (
      suggestions: Record<string, string[]>,
      segment: string,
      index: number,
    ): Record<string, string[]> => {
      const previousSuggestions = suggestions;
      const currentSuggestions = {
        [segment]: memory[index],
      };
      return { ...previousSuggestions, ...currentSuggestions };
    },
    {},
  );
}

/*
async function getTextSuggestions(
  projectDocId: string,
  sourceSegments: string[],
  uniqueOnly = true,
): Promise<string[]> {
  let results = await sourceSegments.reduce(
    async (suggestions: Promise<string[]>, segment: string): Promise<string[]> => {
      const previousSuggestions = await suggestions;
      const currentSuggestions = await getMemoryTargetTexts(projectDocId, segment);
      return previousSuggestions.concat(currentSuggestions);
    },
    Promise.resolve([]),
  );

  if (uniqueOnly) {
    results.sort((a, b) => a.localeCompare(b));
    results = results.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }
  return results;
}
*/

export async function getProjectTranslationVersification(
  db: Firestore,
  projectDocId: string,
): Promise<string> {
  const query = db.collection(DbNames.ProjectTranslationsCollection).doc(projectDocId);

  const snapshot = await query.get();
  let id = DbNames.DefaultVersification; // default S1
  const data = snapshot.data();
  if (data && data.versification) {
    id = data.versification;
  }
  return id;
}

export async function getManuscriptVerseData(
  db: Firestore,
  verseId: string,
  versification: string,
): Promise<ManuscriptVerse> {
  const verseIdParser = new VerseIdParser();

  const query = db
    .collection(DbNames.ManuscriptsCollection)
    .doc(verseIdParser.manuscriptId(verseId))
    .collection(DbNames.VersesCollection)
    .where(verseIdParser.versificationFieldId(versification), 'array-contains', verseId);

  const snapshot = await query.get();
  const verses: ManuscriptVerse[] = [];
  snapshot.forEach(doc => {
    const data = doc.data();
    const verse = new ManuscriptVerse(
      data.textId,
      data.text,
      data.textSegments,
      data.manuscriptData,
      data.chunkData,
    );
    verses.push(verse);
  });

  // TODO: Return lasts verse. This might need to be redesigned later to show multiple verse data
  return verses.sort((a, b) => a.textId.localeCompare(b.textId))[verses.length - 1];
}

export async function getMemoryTargetSegments(
  db: Firestore,
  projectDocId: string,
  sourceText: string,
): Promise<TranslationMemory[]> {
  // for greediness in auto-links and auto-suggestions,
  //   favor linkings with multiple target segments
  //   then most popular

  const query = db
    .collection(DbNames.ProjectTranslationsCollection)
    .doc(projectDocId)
    .collection(DbNames.LinkMemoryCollection)
    .where('sourceSegments', 'array-contains', sourceText)
    .where('count', '>', 0)
    .orderBy('count', 'desc');

  const snapshot = await query.get();
  const targets: TranslationMemory[] = [];
  snapshot.forEach(doc => {
    const docData = doc.data();
    const memory: TranslationMemory = {
      sourceText: docData.sourceText,
      sourceSegments: docData.sourceSegments,
      targetText: docData.targetText,
      targetSegments: docData.targetSegments,
      totalSegmentsCount: docData.totalSegmentsCount,
    };
    targets.push(memory);
  });

  // prefer larger segments
  return targets.sort((m1, m2) => m2.totalSegmentsCount - m1.totalSegmentsCount);
}

export async function getSuggestions(
  db: Firestore,
  projectDocId: string,
  textId: string,
  versification: string,
): Promise<ManuscriptSuggestions> {
  /* For testing
  if (projectDocId.length) {
    return {
      textSuggestion: '',
      linkSuggestions: {},
      memorySuggestions: {},
      targetSuggestions: {},
    };
  }
  */

  // manuscript data
  const source = await getManuscriptVerseData(db, textId, versification);
  const sourceChunks = source.chunkData.map(data => data.chunk);
  const sourceContentSegments = source.manuscriptData
    .filter(data => data.catIsContent)
    .map(data => data.strongsX);

  const memoryTextTopPromises = source.textSegmentsStrongsX.map(segment =>
    getMemoryTargetTexts(db, projectDocId, segment, DbNames.LinkMemoryCollection, true),
  );

  const memoryTextAllPromises = source.textSegmentsStrongsX.map(segment =>
    getMemoryTargetTexts(db, projectDocId, segment, DbNames.LinkMemoryCollection, false),
  );

  const [memoryTop, memoryAll, linkMemorySuggestions, chunkMemorySuggestions] = await Promise.all([
    Promise.all(memoryTextTopPromises),
    Promise.all(memoryTextAllPromises),
    getMemorySuggestions(db, projectDocId, sourceContentSegments, DbNames.LinkMemoryCollection),
    getMemorySuggestions(db, projectDocId, sourceChunks, DbNames.ChunkMemoryCollection),
  ]);

  const textSuggestions = getTopTextSuggestion(
    db,
    projectDocId,
    source.textSegmentsStrongsX,
    memoryTop,
  );

  const linkSuggestions = getLinkSuggestions(
    db,
    projectDocId,
    source.textSegmentsStrongsX,
    memoryAll,
  );

  const memorySuggestions = Object.assign(linkMemorySuggestions, chunkMemorySuggestions);

  const targetSuggestions = Object.values(memorySuggestions).reduce(
    (suggestions, memoryTargetData) => {
      return Object.assign(
        suggestions,
        memoryTargetData.reduce(
          (obj, data) => Object.assign(obj, { [data.targetText]: data.verses }),
          {},
        ),
      );
    },
    {},
  );

  const suggestions: ManuscriptSuggestions = {
    textSuggestion: textSuggestions,
    linkSuggestions,
    memorySuggestions,
    targetSuggestions,
  };

  return suggestions;
}
