export const LOGOUT = 'LOGOUT';
export const CONFIRM_LOGOUT = 'CONFIRM_LOGOUT';
export const CANCEL_LOGOUT = 'CANCEL_LOGOUT';

export interface UserNavState {
  confirmLogout: boolean;
}
interface LogoutAction {
  type: typeof LOGOUT;
}

interface ConfirmLogoutAction {
  type: typeof CONFIRM_LOGOUT;
}

interface CancelLogoutAction {
  type: typeof CANCEL_LOGOUT;
}

export type UserNavActionTypes = LogoutAction | ConfirmLogoutAction | CancelLogoutAction;
