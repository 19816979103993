import { LOCALE_CHANGED } from '../types';

/* eslint import/prefer-default-export: "off" */
export function changeLocale(locale: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: LOCALE_CHANGED,
      locale,
    });
  };
}
