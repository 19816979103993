import React from 'react';
import { useAccordionToggle } from 'react-bootstrap';
import PropTypes from 'prop-types';
import noop from '../../lib/noop';

const CustomAccordionToggle = (props: any): any => {
  const { children, eventKey, changeCard, activeCard } = props;
  const decoratedOnClick = useAccordionToggle(eventKey, noop);

  return (
    <button
      type="button"
      className="btn btn-link card-header"
      onClick={(e: any): void => {
        if (eventKey.toString() !== activeCard.toString()) {
          changeCard(eventKey);
          decoratedOnClick(e);
        }
      }}
    >
      {children}
    </button>
  );
};

CustomAccordionToggle.defaultProps = {
  changeCard: noop,
};

CustomAccordionToggle.prototype = {
  children: PropTypes.any,
  eventKey: PropTypes.string,
  changeCard: PropTypes.func,
};

export default CustomAccordionToggle;
