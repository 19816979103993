import React, { ReactElement } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import * as types from '../../types';
import Segment from './segment';
import { SyntaxGroupData } from '../../shared/structs';

export class SegmentGroupComp extends React.Component<
  types.SegmentGroupProps,
  types.SegmentGroupState
> {
  private nextChildrenForSegmentGroup(
    segmentGroup: SyntaxGroupData,
    childSyntaxGroupData: SyntaxGroupData[],
  ): SyntaxGroupData[] {
    return childSyntaxGroupData
      .filter(datum => datum.level > segmentGroup.level)
      .filter(datum => {
        return (
          datum.startIndex >= segmentGroup.startIndex && datum.endIndex <= segmentGroup.endIndex
        );
      });
  }

  private nextChildrenForCurrentSegmentGroup(segmentGroup: SyntaxGroupData): SyntaxGroupData[] {
    const { childSyntaxGroupData } = this.props;
    const children = this.nextChildrenForSegmentGroup(segmentGroup, childSyntaxGroupData);
    return children;
  }

  private currentLevel(): number {
    const { syntaxGroup } = this.props;
    return syntaxGroup.level;
  }

  private nextLevel(): number {
    return this.currentLevel() + 1;
  }

  private isFlat(): boolean {
    const { childSyntaxGroupData } = this.props;
    return childSyntaxGroupData.length === 0;
  }

  private createRange(start: number, end: number): number[] {
    return Array.from(Array(1 + end - start).keys()).map((index: number) => {
      return start + index;
    });
  }

  private segmentExistsAboveThisNodeAsLeaf(segmentIndex: number): boolean {
    if (this.currentLevel() === 0) {
      return false;
    }
    const { allSyntaxGroupData } = this.props;
    const otherLevelData = allSyntaxGroupData.filter(datum => datum.level !== this.currentLevel());
    const result = otherLevelData.find((datum: SyntaxGroupData) => {
      const range = this.createRange(datum.startSegmentIndex, datum.endSegmentIndex);
      const existsInOtherGroup = range.includes(segmentIndex);
      const isLeafInOtherGroup =
        this.nextChildrenForSegmentGroup(datum, allSyntaxGroupData).length === 0;
      return existsInOtherGroup && isLeafInOtherGroup;
    });
    return Boolean(result);
  }

  private renderNestedSegments(): ReactElement[] {
    const {
      manuscriptSuggestions,
      verseManuscriptData,
      allSyntaxGroupData,
      childSyntaxGroupData,
    } = this.props;
    return childSyntaxGroupData
      .filter(datum => datum.level === this.nextLevel())
      .map((childSyntaxGroupDatum: SyntaxGroupData) => {
        return (
          <SegmentGroup
            key={`segment-group-child-${childSyntaxGroupDatum.startSegmentIndex}-${childSyntaxGroupDatum.endSegmentIndex}`}
            manuscriptSuggestions={manuscriptSuggestions}
            verseManuscriptData={verseManuscriptData}
            allSyntaxGroupData={allSyntaxGroupData}
            syntaxGroup={childSyntaxGroupDatum}
            childSyntaxGroupData={this.nextChildrenForCurrentSegmentGroup(childSyntaxGroupDatum)}
          />
        );
      });
  }

  private renderFlatSegments(): (ReactElement | null)[] {
    const { verseManuscriptData, manuscriptSuggestions, syntaxGroup } = this.props;
    const segmentIndexes = this.createRange(
      syntaxGroup.startSegmentIndex,
      syntaxGroup.endSegmentIndex,
    );

    return segmentIndexes.map((segmentIndex: number): ReactElement | null => {
      const verseManuscriptDataForSegment = verseManuscriptData[segmentIndex];
      if (verseManuscriptDataForSegment && !this.segmentExistsAboveThisNodeAsLeaf(segmentIndex)) {
        const linkSuggestions =
          manuscriptSuggestions.linkSuggestions[verseManuscriptDataForSegment.strongsX];
        const memorySuggestions =
          manuscriptSuggestions.memorySuggestions[verseManuscriptDataForSegment.strongsX];

        return (
          <Segment
            position={segmentIndex + 1}
            manuscriptData={verseManuscriptDataForSegment}
            linkSuggestions={linkSuggestions}
            memorySuggestions={memorySuggestions}
            key={verseManuscriptDataForSegment.positionId}
          />
        );
      }
      return null;
    });
  }

  private renderSegments(): (ReactElement | null)[] {
    if (this.isFlat()) {
      return this.renderFlatSegments();
    }
    return this.renderNestedSegments();
  }

  public render(): ReactElement {
    const { syntaxGroup } = this.props;
    const isFlatClass = this.isFlat() ? 'flat' : '';

    return (
      <div className={`segment-group-container ${isFlatClass}`} key="segment-group">
        <OverlayTrigger
          key={`trigger-group-${syntaxGroup.startIndex}-${syntaxGroup.endIndex}`}
          trigger="hover"
          placement="top"
          popperConfig={{
            modifiers: { preventOverflow: { boundariesElement: 'offsetParent' } },
          }}
          // prettier-ignore
          overlay={(
            <Tooltip id={`tooltip-${syntaxGroup.startIndex}-${syntaxGroup.endIndex}`}>
              <b> 
                <FormattedMessage id={`syntax.${syntaxGroup.type}`} />
                :&nbsp;
              </b>
              <FormattedMessage id={`syntax.${syntaxGroup.type}.description`} />
            </Tooltip>
            )}
        >
          <span
            key={`segment-group-label-${syntaxGroup.startIndex}-${syntaxGroup.endIndex}}`}
            className="syntax-group-label"
          >
            <FormattedMessage id={`syntax.${syntaxGroup.type}`} />
          </span>
        </OverlayTrigger>

        <div className="segment-group">{this.renderSegments()}</div>
      </div>
    );
  }
}

const SegmentGroup = SegmentGroupComp;
export default SegmentGroup;
