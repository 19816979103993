import { LightboxState, LightboxActionTypes, DISPLAY_LIGHTBOX, CLOSE_LIGHTBOX } from '../types';

const INIT_STAT: LightboxState = {
  display: false,
};

export default (state = INIT_STAT, action: LightboxActionTypes): LightboxState => {
  switch (action.type) {
    case DISPLAY_LIGHTBOX:
      return { ...state, display: true };

    case CLOSE_LIGHTBOX:
      return { ...state, display: false };

    default:
      return { ...state, display: state.display };
  }
};
