/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Navigator from '../navigator';
import Spinner from '../spinner';
import { BibleChapters } from '../../shared/verseIdParser';
import Grid from './grid';
import noop from '../../lib/noop';

import {
  TranslationProps,
  TranslationState,
  VIEW_OF_BOOK,
  VIEW_OF_CHAPTER,
  DefaultTranslationProps,
} from '../../types';
import {
  translationViewIsLoaded,
  fetchProject,
  changeChapter,
  rememberLastThingAction,
  fetchVerseTranslationsAction,
  dismissVerseChangesAction,
} from '../../actions';
import { AppState } from '../../reducers';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import { confirmUnsavedVerse } from '../../shared/frontend';

class TranslationApp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
  }

  public componentDidMount(): void {
    const { fetchProjectFunc, selectedBookId, selectedChapter } = this.props;

    const projectId = getCurrentProjectId();
    if (projectId) {
      fetchProjectFunc(projectId);
    }

    if (projectId && selectedBookId && selectedChapter) {
      this.pullVerseTranslation(projectId, selectedBookId, selectedChapter);
    }
  }

  public componentDidUpdate(prevProps: any): any {
    const { selectedBookId, selectedChapter, rememberLastThing } = this.props;

    // book or chapter changed
    if (
      prevProps.selectedBookId !== selectedBookId ||
      prevProps.selectedChapter !== selectedChapter
    ) {
      const projectId = getCurrentProjectId();
      if (projectId && selectedBookId) {
        rememberLastThing(projectId, 'bookId', selectedBookId);
      }

      if (projectId && selectedChapter) {
        rememberLastThing(projectId, 'chapter', selectedChapter);
      }

      if (projectId && selectedBookId && selectedChapter) {
        this.pullVerseTranslation(projectId, selectedBookId, selectedChapter);
      }
    }
  }

  public componentWillUnmount(): void {
    const { dismissVerseChanges } = this.props;
    dismissVerseChanges();
  }

  private pullVerseTranslation(projectId: string, bookId: string, chapter: number): void {
    const { fetchVerseTranslations } = this.props;
    fetchVerseTranslations(projectId, bookId, chapter);
  }

  private showLoadingSpinner(): any {
    return (
      <div className="overlayer">
        <Spinner cssName="text-light" />
      </div>
    );
  }

  public toolbarButton(
    title: string,
    iconClass: string,
    tooltip: string,
    tooltipId: string,
    disabled: boolean,
    handleBtnClick: any,
  ): any {
    const { verseModifiedMap } = this.props;

    return (
      <OverlayTrigger
        placement="top"
        // prettier-ignore
        overlay={(
          <Tooltip id={tooltipId}>
            <FormattedMessage id={tooltip} />
          </Tooltip>
        )}
      >
        <FormattedMessage id="translation.prompt">
          {(promptMessage: any): ReactElement => {
            return (
              <Button
                variant="link"
                disabled={disabled}
                onClick={(): void => {
                  if (confirmUnsavedVerse(promptMessage, verseModifiedMap)) {
                    handleBtnClick(); // callback
                  }
                }}
              >
                <i className={iconClass} />
                <p>{title}</p>
              </Button>
            );
          }}
        </FormattedMessage>
      </OverlayTrigger>
    );
  }

  public navigateChapter(direction: string): void {
    const { changeChapterFunc, selectedBookId, selectedChapter } = this.props;
    const chapterTotal = BibleChapters[selectedBookId as keyof typeof BibleChapters];

    if (direction === 'prev' && selectedChapter && selectedChapter > 1) {
      changeChapterFunc(selectedChapter - 1);
    } else if (selectedChapter && selectedChapter < chapterTotal) {
      changeChapterFunc(selectedChapter + 1);
    }
  }

  public translationBody(): ReactElement {
    return (
      <Grid
        isLeftPanelOpen={false}
        isRightPanelOpen={false}
        isBottomPanelOpen={false}
        toggleManuscript={noop}
        updateUIConfig={noop}
      />
    );
  }

  public render(): ReactElement {
    const {
      loading,
      authenticated,
      authorizationFail,
      projectNotExisted,
      projectName,
      selectedBookId,
      selectedChapter,
      userIsProjectMember,
    } = this.props;

    const chapterTotal = BibleChapters[selectedBookId as keyof typeof BibleChapters];

    let disablePrevChapter = false;
    if (selectedChapter && selectedChapter <= 1) {
      disablePrevChapter = true;
    }

    let disableNextChapter = false;
    if (selectedChapter && selectedChapter >= chapterTotal) {
      disableNextChapter = true;
    }

    return (
      <div className="translation-container">
        {loading && this.showLoadingSpinner()}

        {((): ReactElement | null => {
          if (projectNotExisted || userIsProjectMember !== true) {
            return (
              <div className="container mt-5 text-center">
                <div className="alert alert-warning" role="alert">
                  <FormattedMessage id="Project not found" />
                </div>

                <Link className="btn-ytb btn btn-primary" to="/project">
                  <FormattedMessage id="Go to project list" />
                </Link>
              </div>
            );
          }

          if (authenticated && !loading) {
            return (
              <React.Fragment key="react-fragment-translation-body-123">
                <div className="translation-toolbar">
                  <div className="row">
                    <div className="col-sm">
                      <ul>
                        <li key="toolbarProject">
                          <OverlayTrigger
                            placement="top"
                            // prettier-ignore
                            overlay={(
                              <Tooltip id="tooltipBackToProjectList">
                                <FormattedMessage id="translation.backToProjectList" />
                              </Tooltip>
                            )}
                          >
                            <Link to="/project" className="btn btn-link division">
                              <span className="caption">
                                <FormattedMessage id="translation.project" />
                              </span>
                              <span className="link">{projectName}</span>
                            </Link>
                          </OverlayTrigger>
                        </li>
                        <li key="toolbarBook">
                          <Navigator
                            parentSelectedBookId={selectedBookId}
                            parentSelectedChapter={selectedChapter}
                            defaultView={VIEW_OF_BOOK}
                            key="navigator-book"
                          />
                        </li>
                        <li key="toolbarChapter">
                          <Navigator
                            parentSelectedBookId={selectedBookId}
                            parentSelectedChapter={selectedChapter}
                            defaultView={VIEW_OF_CHAPTER}
                            key="navigator-chapter"
                          />
                        </li>
                        <li>
                          <FormattedMessage id="translation.prev">
                            {(title: string): ReactElement => {
                              return this.toolbarButton(
                                title,
                                'fas fa-caret-left',
                                'translation.previousChapter',
                                'pre-chapter',
                                disablePrevChapter,
                                (): void => {
                                  this.navigateChapter('prev');
                                },
                              );
                            }}
                          </FormattedMessage>

                          <FormattedMessage id="translation.next">
                            {(title: string): ReactElement => {
                              return this.toolbarButton(
                                title,
                                'fas fa-caret-right',
                                'translation.nextChapter',
                                'next-chapter',
                                disableNextChapter,
                                (): void => {
                                  this.navigateChapter('next');
                                },
                              );
                            }}
                          </FormattedMessage>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm mr-5" />
                  </div>
                </div>
                <div className="translation-body">{this.translationBody()}</div>
              </React.Fragment>
            );
          }

          if (authorizationFail) {
            return <Redirect to="/project" />;
          }

          return null;
        })()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    verseModifiedMap: state.verseEditor.verseModifiedMap,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  translationViewIsLoadedFunc: (): void => {
    dispatch(translationViewIsLoaded());
  },

  fetchProjectFunc: (projectId: string): void => {
    dispatch(fetchProject(projectId));
  },

  changeChapterFunc: (chapter: number): void => {
    dispatch(changeChapter(chapter));
  },

  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },

  fetchVerseTranslations: (projectId: string, bookId: string, chapter: number): void => {
    dispatch(fetchVerseTranslationsAction(projectId, bookId, chapter));
  },

  dismissVerseChanges: (): void => {
    dispatch(dismissVerseChangesAction());
  },
});

const Translation = connect(mapStateToProps, mapDispatchToProps)(TranslationApp);

export default Translation;
