import {
  SignInState,
  SignInActionTypes,
  REDIRECT_TO_PROJECT,
  REDIRECT_DONE,
  SIGN_IN_FAIL,
  EMAIL_ADDR_UPDATE,
  PASSWORD_UPDATE,
  CLEAR_ERROR_MSG,
} from '../types';

const INIT_STAT: SignInState = {
  display: false,
  user: null,
  redirect: false,
  error: null,
  emailAddr: '',
  password: '',
};

const SignIn = (state = INIT_STAT, action: SignInActionTypes): any => {
  switch (action.type) {
    case REDIRECT_TO_PROJECT:
      return { ...state, redirect: true, error: null };

    case REDIRECT_DONE:
      return { ...state, redirect: false };

    case SIGN_IN_FAIL:
      return { ...state, error: action.error };

    case EMAIL_ADDR_UPDATE:
      return { ...state, emailAddr: action.emailAddr };

    case PASSWORD_UPDATE:
      return { ...state, password: action.password };

    case CLEAR_ERROR_MSG:
      return { ...state, error: null };

    default:
      return state;
  }
};

export default SignIn;
