import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';
import CustomAccordionToggle from './customAccordionToggle';
import Spinner from '../spinner';
import * as BookFuncs from '../../lib/book';

import { AppState } from '../../reducers';
import {
  TranslationProps,
  TranslationState,
  DefaultTranslationProps,
  AccordionCards,
  MANUSCRIPT_CARD,
  MORPHOLOGY_CARD,
} from '../../types';

import {
  changeManuscriptAccordionAction,
  fetchDataAction,
  toggleManuscriptAction,
  updateUIConfigAction,
} from '../../actions';

import ManuscriptView from './manuscriptView';
import MorphologyView from './morphologyView';

export class ManuscriptComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
    const { activeCard, selectedBookId, selectedChapter, fetchData, cardData } = props;
    this.changeCard = this.changeCard.bind(this);

    if (!cardData.has(activeCard)) {
      fetchData(activeCard, selectedBookId, selectedChapter);
    }
  }

  public componentDidMount(): void {
    const { selectedBookId, selectedChapter, fetchData, activeCard } = this.props;

    fetchData(activeCard, selectedBookId, selectedChapter);
  }

  public componentDidUpdate(prevProps: any): void {
    const { selectedBookId, selectedChapter, fetchData, activeCard } = this.props;

    // book or chapter changed
    if (
      BookFuncs.bookOrChapterChanged(
        prevProps.selectedBookId,
        prevProps.selectedChapter,
        selectedBookId,
        selectedChapter,
      )
    ) {
      fetchData(activeCard, selectedBookId, selectedChapter);
    }
  }

  public changeCard(eventKey: string): void {
    const {
      changeAccordionCard,
      selectedBookId,
      selectedChapter,
      fetchData,
      cardData,
    } = this.props;

    const activeCard = parseInt(eventKey, 10);

    if (cardData && !cardData.has(activeCard)) {
      fetchData(activeCard, selectedBookId, selectedChapter);
    }

    changeAccordionCard(activeCard);
  }

  private cardContainer(activeCard: number): ReactElement {
    const { cardData } = this.props;

    if (cardData && cardData.has(activeCard)) {
      const result = cardData.get(activeCard);
      if (!result || result.empty) {
        return <div id="manuscript-no-data">No data found.</div>;
      }

      if (activeCard === MANUSCRIPT_CARD) {
        return (
          <Card.Body className="manuscript-card">
            <ManuscriptView
              manuscriptDataByVerse={result.data.manuscriptDataByVerse}
              isOldTestament={result.isOldTestament}
            />
          </Card.Body>
        );
      }

      if (activeCard === MORPHOLOGY_CARD) {
        return (
          <Card.Body className="morphology-card">
            <MorphologyView
              lexiconData={result.data.lexiconData}
              manuscriptDataByVerse={result.data.manuscriptDataByVerse}
              isOldTestament={result.isOldTestament}
            />
          </Card.Body>
        );
      }
    }

    return <Spinner />;
  }

  public render(): ReactElement {
    let index = -1;
    const { activeCard, toggleManuscript, isLeftPanelOpen, updateUIConfig } = this.props;

    return (
      <Accordion defaultActiveKey={activeCard.toString()} className="panel bar-top">
        <button
          type="button"
          className="collapse-left-panel"
          onClick={(): void => {
            toggleManuscript();
            updateUIConfig('isLeftPanelOpen', !isLeftPanelOpen);
          }}
        >
          <i className="fas fa-sm fa-chevron-left" />
          <i className="fas fa-sm fa-chevron-left" />
          <i className="fas fa-sm fa-chevron-left" />
        </button>
        {AccordionCards.map((card: any): any => {
          index += 1;
          return (
            <Card
              key={`accordion-card-${index}`}
              className={activeCard === index ? 'active-card' : ''}
            >
              <CustomAccordionToggle
                eventKey={index.toString()}
                activeCard={activeCard}
                changeCard={this.changeCard}
              >
                <FormattedMessage id={card.name} />
                <i className={`fas fa-caret-down accordion-toggle-${index}`} />
              </CustomAccordionToggle>
              <Accordion.Collapse eventKey={index.toString()}>
                {this.cardContainer(index)}
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    isLeftPanelOpen: state.profile.isLeftPanelOpen,
  };

  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  changeAccordionCard: (activeCard: number): void => {
    dispatch(changeManuscriptAccordionAction(activeCard));
  },

  fetchData: (activeCard: number, bookId: string, chapter: number): void => {
    dispatch(fetchDataAction(activeCard, bookId, chapter));
  },

  toggleManuscript: (): void => {
    dispatch(toggleManuscriptAction('Left'));
  },
  updateUIConfig: (fieldName: string, fieldValue: boolean): void => {
    dispatch(updateUIConfigAction(fieldName, fieldValue));
  },
});

const Manuscript = connect(mapStateToProps, mapDispatchToProps)(ManuscriptComp);

export default Manuscript;
