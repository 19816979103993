export enum BibleBooks {
  Genesis = 1,
  Exodus = 2,
  Leviticus = 3,
  Numbers = 4,
  Deuteronomy = 5,
  Joshua = 6,
  Judges = 7,
  Ruth = 8,
  Samuel1 = 9,
  Samuel2 = 10,
  Kings1 = 11,
  Kings2 = 12,
  Chronicles1 = 13,
  Chronicles2 = 14,
  Ezra = 15,
  Nehemiah = 16,
  Esther = 17,
  Job = 18,
  Psalms = 19,
  Proverbs = 20,
  Ecclesiastes = 21,
  SongOfSongs = 22,
  Isaiah = 23,
  Jeremiah = 24,
  Lamentations = 25,
  Ezekiel = 26,
  Daniel = 27,
  Hosea = 28,
  Joel = 29,
  Amos = 30,
  Obadiah = 31,
  Jonah = 32,
  Micah = 33,
  Nahum = 34,
  Habakkuk = 35,
  Zephaniah = 36,
  Haggai = 37,
  Zechariah = 38,
  Malachi = 39,
  Matthew = 40,
  Mark = 41,
  Luke = 42,
  John = 43,
  Acts = 44,
  Romans = 45,
  Corinthians1 = 46,
  Corinthians2 = 47,
  Galatians = 48,
  Ephesians = 49,
  Philippians = 50,
  Colossians = 51,
  Thessalonians1 = 52,
  Thessalonians2 = 53,
  Timothy1 = 54,
  Timothy2 = 55,
  Titus = 56,
  Philemon = 57,
  Hebrews = 58,
  James = 59,
  Peter1 = 60,
  Peter2 = 61,
  John1 = 62,
  John2 = 63,
  John3 = 64,
  Jude = 65,
  Revelation = 66,
}

export enum BibleBookNames {
  Genesis = 'Genesis',
  Exodus = 'Exodus',
  Leviticus = 'Leviticus',
  Numbers = 'Numbers',
  Deuteronomy = 'Deuteronomy',
  Joshua = 'Joshua',
  Judges = 'Judges',
  Ruth = 'Ruth',
  Samuel1 = '1 Samuel',
  Samuel2 = '2 Samuel',
  Kings1 = '1 Kings',
  Kings2 = '2 Kings',
  Chronicles1 = '1 Chronicles',
  Chronicles2 = '2 Chronicles',
  Ezra = 'Ezra',
  Nehemiah = 'Nehemiah',
  Esther = 'Esther',
  Job = 'Job',
  Psalms = 'Psalms',
  Proverbs = 'Proverbs',
  Ecclesiastes = 'Ecclesiastes',
  SongOfSongs = 'Song of Songs',
  Isaiah = 'Isaiah',
  Jeremiah = 'Jeremiah',
  Lamentations = 'Lamentations',
  Ezekiel = 'Ezekiel',
  Daniel = 'Daniel',
  Hosea = 'Hosea',
  Joel = 'Joel',
  Amos = 'Amos',
  Obadiah = 'Obadiah',
  Jonah = 'Jonah',
  Micah = 'Micah',
  Nahum = 'Nahum',
  Habakkuk = 'Habakkuk',
  Zephaniah = 'Zephaniah',
  Haggai = 'Haggai',
  Zechariah = 'Zechariah',
  Malachi = 'Malachi',
  Matthew = 'Matthew',
  Mark = 'Mark',
  Luke = 'Luke',
  John = 'John',
  Acts = 'Acts',
  Romans = 'Romans',
  Corinthians1 = '1 Corinthians',
  Corinthians2 = '2 Corinthians',
  Galatians = 'Galatians',
  Ephesians = 'Ephesians',
  Philippians = 'Philippians',
  Colossians = 'Colossians',
  Thessalonians1 = '1 Thessalonians',
  Thessalonians2 = '2 Thessalonians',
  Timothy1 = '1 Timothy',
  Timothy2 = '2 Timothy',
  Titus = 'Titus',
  Philemon = 'Philemon',
  Hebrews = 'Hebrews',
  James = 'James',
  Peter1 = '1 Peter',
  Peter2 = '2 Peter',
  John1 = '1 John',
  John2 = '2 John',
  John3 = '3 John',
  Jude = 'Jude',
  Revelation = 'Revelation',
}

export enum BibleChapters {
  Genesis = 50,
  Exodus = 40,
  Leviticus = 27,
  Numbers = 36,
  Deuteronomy = 34,
  Joshua = 24,
  Judges = 21,
  Ruth = 4,
  Samuel1 = 31,
  Samuel2 = 24,
  Kings1 = 22,
  Kings2 = 25,
  Chronicles1 = 29,
  Chronicles2 = 36,
  Ezra = 10,
  Nehemiah = 13,
  Esther = 10,
  Job = 42,
  Psalms = 150,
  Proverbs = 31,
  Ecclesiastes = 12,
  SongOfSongs = 8,
  Isaiah = 66,
  Jeremiah = 52,
  Lamentations = 5,
  Ezekiel = 48,
  Daniel = 12,
  Hosea = 14,
  Joel = 3,
  Amos = 9,
  Obadiah = 1,
  Jonah = 4,
  Micah = 7,
  Nahum = 3,
  Habakkuk = 3,
  Zephaniah = 3,
  Haggai = 2,
  Zechariah = 14,
  Malachi = 4,
  Matthew = 28,
  Mark = 16,
  Luke = 24,
  John = 21,
  Acts = 28,
  Romans = 16,
  Corinthians1 = 16,
  Corinthians2 = 13,
  Galatians = 6,
  Ephesians = 6,
  Philippians = 4,
  Colossians = 4,
  Thessalonians1 = 5,
  Thessalonians2 = 3,
  Timothy1 = 6,
  Timothy2 = 4,
  Titus = 3,
  Philemon = 1,
  Hebrews = 13,
  James = 5,
  Peter1 = 5,
  Peter2 = 3,
  John1 = 5,
  John2 = 1,
  John3 = 1,
  Jude = 1,
  Revelation = 22,
}

export enum BibleBookCategory {
  // old testament
  Genesis = 'law',
  Exodus = 'law',
  Leviticus = 'law',
  Numbers = 'law',
  Deuteronomy = 'law',

  Joshua = 'history',
  Judges = 'history',
  Ruth = 'history',
  Samuel1 = 'history',
  Samuel2 = 'history',
  Kings1 = 'history',
  Kings2 = 'history',
  Chronicles1 = 'history',
  Chronicles2 = 'history',
  Ezra = 'history',
  Nehemiah = 'history',
  Esther = 'history',

  Job = 'poetry',
  Psalms = 'poetry',
  Proverbs = 'poetry',
  Ecclesiastes = 'poetry',
  SongOfSongs = 'poetry',

  Isaiah = 'prophets',
  Jeremiah = 'prophets',
  Lamentations = 'prophets',
  Ezekiel = 'prophets',
  Daniel = 'prophets',

  Hosea = 'prophets',
  Joel = 'prophets',
  Amos = 'prophets',
  Obadiah = 'prophets',
  Jonah = 'prophets',
  Micah = 'prophets',
  Nahum = 'prophets',
  Habakkuk = 'prophets',
  Zephaniah = 'prophets',
  Haggai = 'prophets',
  Zechariah = 'prophets',
  Malachi = 'prophets',

  // new testament
  Matthew = 'gospels',
  Mark = 'gospels',
  Luke = 'gospels',
  John = 'gospels',

  Acts = 'history',

  Romans = 'letters',
  Corinthians1 = 'letters',
  Corinthians2 = 'letters',
  Galatians = 'letters',
  Ephesians = 'letters',
  Philippians = 'letters',
  Colossians = 'letters',
  Thessalonians1 = 'letters',
  Thessalonians2 = 'letters',
  Timothy1 = 'letters',
  Timothy2 = 'letters',
  Titus = 'letters',
  Philemon = 'letters',
  Hebrews = 'letters',
  James = 'letters',
  Peter1 = 'letters',
  Peter2 = 'letters',
  John1 = 'letters',
  John2 = 'letters',
  John3 = 'letters',
  Jude = 'letters',

  Revelation = 'prophecy',
}

export const BIBLE_BOOK_CHAPTER_RANGE: Record<string, number[]> = {
  Genesis: [1, 50],
  Exodus: [51, 90],
  Leviticus: [91, 117],
  Numbers: [118, 153],
  Deuteronomy: [154, 187],
  Joshua: [188, 211],
  Judges: [212, 232],
  Ruth: [233, 236],
  Samuel1: [237, 267],
  Samuel2: [268, 291],
  Kings1: [292, 313],
  Kings2: [314, 338],
  Chronicles1: [339, 376],
  Chronicles2: [368, 403],
  Ezra: [404, 413],
  Nehemiah: [414, 426],
  Esther: [427, 436],
  Job: [437, 478],
  Psalms: [479, 628],
  Proverbs: [629, 659],
  Ecclesiastes: [660, 671],
  SongOfSongs: [672, 679],
  Isaiah: [680, 745],
  Jeremiah: [746],
  Lamentations: [798, 802],
  Ezekiel: [803, 850],
  Daniel: [851, 862],
  Hosea: [863, 876],
  Joel: [877, 879],
  Amos: [880, 888],
  Obadiah: [889, 889],
  Jonah: [890, 893],
  Micah: [894, 900],
  Nahum: [901, 903],
  Habakkuk: [904, 906],
  Zephaniah: [907, 909],
  Haggai: [910, 911],
  Zechariah: [912, 925],
  Malachi: [926, 929],
  Matthew: [930, 957],
  Mark: [958, 973],
  Luke: [974, 997],
  John: [998, 1018],
  Acts: [1019, 1047],
  Romans: [1047, 1062],
  Corinthians1: [1063, 1078],
  Corinthians2: [1079, 1091],
  Galatians: [1092, 1097],
  Ephesians: [1098, 1103],
  Philippians: [1104, 1107],
  Colossians: [1108, 1111],
  Thessalonians1: [1112, 1116],
  Thessalonians2: [1117, 1119],
  Timothy1: [1120, 1125],
  Timothy2: [1126, 1129],
  Titus: [1130, 1132],
  Philemon: [1133, 1133],
  Hebrews: [1134, 1146],
  James: [1147, 1151],
  Peter1: [1152, 1156],
  Peter2: [1157, 1159],
  John1: [1160, 1164],
  John2: [1165, 1165],
  John3: [1166, 1166],
  Jude: [1167, 1167],
  Revelation: [1168, 1189],
};

const LOGOS_BIBLE_ABBREVS: Record<string, BibleBooks> = {
  Ge: BibleBooks.Genesis,
  Ex: BibleBooks.Exodus,
  Le: BibleBooks.Leviticus,
  Nu: BibleBooks.Numbers,
  De: BibleBooks.Deuteronomy,
  Jos: BibleBooks.Joshua,
  Jdg: BibleBooks.Judges,
  Ru: BibleBooks.Ruth,
  '1Sa': BibleBooks.Samuel1,
  '2Sa': BibleBooks.Samuel2,
  '1Ki': BibleBooks.Kings1,
  '2Ki': BibleBooks.Kings2,
  '1Ch': BibleBooks.Chronicles1,
  '2Ch': BibleBooks.Chronicles2,
  Ezr: BibleBooks.Ezra,
  Ne: BibleBooks.Nehemiah,
  Es: BibleBooks.Esther,
  Job: BibleBooks.Job,
  Ps: BibleBooks.Psalms,
  Pr: BibleBooks.Proverbs,
  Ec: BibleBooks.Ecclesiastes,
  So: BibleBooks.SongOfSongs,
  Is: BibleBooks.Isaiah,
  Je: BibleBooks.Jeremiah,
  La: BibleBooks.Lamentations,
  Eze: BibleBooks.Ezekiel,
  Da: BibleBooks.Daniel,
  Ho: BibleBooks.Hosea,
  Joe: BibleBooks.Joel,
  Am: BibleBooks.Amos,
  Ob: BibleBooks.Obadiah,
  Jon: BibleBooks.Jonah,
  Mic: BibleBooks.Micah,
  Na: BibleBooks.Nahum,
  Hab: BibleBooks.Habakkuk,
  Zep: BibleBooks.Zephaniah,
  Hag: BibleBooks.Haggai,
  Zec: BibleBooks.Zechariah,
  Mal: BibleBooks.Malachi,
  Mt: BibleBooks.Matthew,
  Mk: BibleBooks.Mark,
  Lk: BibleBooks.Luke,
  Jn: BibleBooks.John,
  Ac: BibleBooks.Acts,
  Ro: BibleBooks.Romans,
  '1Co': BibleBooks.Corinthians1,
  '2Co': BibleBooks.Corinthians2,
  Ga: BibleBooks.Galatians,
  Eph: BibleBooks.Ephesians,
  Php: BibleBooks.Philippians,
  Col: BibleBooks.Colossians,
  '1Th': BibleBooks.Thessalonians1,
  '2Th': BibleBooks.Thessalonians2,
  '1Ti': BibleBooks.Timothy1,
  '2Ti': BibleBooks.Timothy2,
  Tit: BibleBooks.Titus,
  Phm: BibleBooks.Philemon,
  Heb: BibleBooks.Hebrews,
  Jam: BibleBooks.James,
  '1Pe': BibleBooks.Peter1,
  '2Pe': BibleBooks.Peter2,
  '1Jn': BibleBooks.John1,
  '2Jn': BibleBooks.John2,
  '3Jn': BibleBooks.John3,
  Jud: BibleBooks.Jude,
  Rev: BibleBooks.Revelation,
};

const GBI_BIBLE_ABBREVS: Record<string, BibleBooks> = {
  gn: BibleBooks.Genesis,
  ex: BibleBooks.Exodus,
  lv: BibleBooks.Leviticus,
  nu: BibleBooks.Numbers,
  dt: BibleBooks.Deuteronomy,
  js: BibleBooks.Joshua,
  ju: BibleBooks.Judges,
  ru: BibleBooks.Ruth,
  '1s': BibleBooks.Samuel1,
  '2s': BibleBooks.Samuel2,
  '1k': BibleBooks.Kings1,
  '2k': BibleBooks.Kings2,
  '1c': BibleBooks.Chronicles1,
  '2c': BibleBooks.Chronicles2,
  er: BibleBooks.Ezra,
  ne: BibleBooks.Nehemiah,
  es: BibleBooks.Esther,
  jb: BibleBooks.Job,
  ps: BibleBooks.Psalms,
  pr: BibleBooks.Proverbs,
  ec: BibleBooks.Ecclesiastes,
  ca: BibleBooks.SongOfSongs,
  is: BibleBooks.Isaiah,
  je: BibleBooks.Jeremiah,
  lm: BibleBooks.Lamentations,
  ek: BibleBooks.Ezekiel,
  da: BibleBooks.Daniel,
  ho: BibleBooks.Hosea,
  jl: BibleBooks.Joel,
  am: BibleBooks.Amos,
  ob: BibleBooks.Obadiah,
  jn: BibleBooks.Jonah,
  mi: BibleBooks.Micah,
  na: BibleBooks.Nahum,
  hb: BibleBooks.Habakkuk,
  zp: BibleBooks.Zephaniah,
  hg: BibleBooks.Haggai,
  zc: BibleBooks.Zechariah,
  ma: BibleBooks.Malachi,
  Mat: BibleBooks.Matthew,
  Mrk: BibleBooks.Mark,
  Luk: BibleBooks.Luke,
  Jhn: BibleBooks.John,
  Act: BibleBooks.Acts,
  Rom: BibleBooks.Romans,
  '1Co': BibleBooks.Corinthians1,
  '2Co': BibleBooks.Corinthians2,
  Gal: BibleBooks.Galatians,
  Eph: BibleBooks.Ephesians,
  Php: BibleBooks.Philippians,
  Col: BibleBooks.Colossians,
  '1Th': BibleBooks.Thessalonians1,
  '2Th': BibleBooks.Thessalonians2,
  '1Tm': BibleBooks.Timothy1,
  '2Tm': BibleBooks.Timothy2,
  Tit: BibleBooks.Titus,
  Phm: BibleBooks.Philemon,
  Heb: BibleBooks.Hebrews,
  Jms: BibleBooks.James,
  '1Pe': BibleBooks.Peter1,
  '2Pe': BibleBooks.Peter2,
  '1Jn': BibleBooks.John1,
  '2Jn': BibleBooks.John2,
  '3Jn': BibleBooks.John3,
  Jud: BibleBooks.Jude,
  Rev: BibleBooks.Revelation,
};

export const BIBLE_API_ABBREVS_TO_BIBLE_BOOKS: Record<string, number> = {
  GEN: BibleBooks.Genesis,
  EXO: BibleBooks.Exodus,
  LEV: BibleBooks.Leviticus,
  NUM: BibleBooks.Numbers,
  DEU: BibleBooks.Deuteronomy,
  JOS: BibleBooks.Joshua,
  JDG: BibleBooks.Judges,
  RUT: BibleBooks.Ruth,
  '1SA': BibleBooks.Samuel1,
  '2SA': BibleBooks.Samuel2,
  '1KI': BibleBooks.Kings1,
  '2KI': BibleBooks.Kings2,
  '1CH': BibleBooks.Chronicles1,
  '2CH': BibleBooks.Chronicles2,
  EZR: BibleBooks.Ezra,
  NEH: BibleBooks.Nehemiah,
  EST: BibleBooks.Esther,
  JOB: BibleBooks.Job,
  PSA: BibleBooks.Psalms,
  PRO: BibleBooks.Proverbs,
  ECC: BibleBooks.Ecclesiastes,
  SNG: BibleBooks.SongOfSongs,
  ISA: BibleBooks.Isaiah,
  JER: BibleBooks.Jeremiah,
  LAM: BibleBooks.Lamentations,
  EZK: BibleBooks.Ezekiel,
  DAN: BibleBooks.Daniel,
  HOS: BibleBooks.Hosea,
  JOL: BibleBooks.Joel,
  AMO: BibleBooks.Amos,
  OBA: BibleBooks.Obadiah,
  JON: BibleBooks.Jonah,
  MIC: BibleBooks.Micah,
  NAM: BibleBooks.Nahum,
  HAB: BibleBooks.Habakkuk,
  ZEP: BibleBooks.Zephaniah,
  HAG: BibleBooks.Haggai,
  ZEC: BibleBooks.Zechariah,
  MAL: BibleBooks.Malachi,
  MAT: BibleBooks.Matthew,
  MRK: BibleBooks.Mark,
  LUK: BibleBooks.Luke,
  JHN: BibleBooks.John,
  ACT: BibleBooks.Acts,
  ROM: BibleBooks.Romans,
  '1CO': BibleBooks.Corinthians1,
  '2CO': BibleBooks.Corinthians2,
  GAL: BibleBooks.Galatians,
  EPH: BibleBooks.Ephesians,
  PHP: BibleBooks.Philippians,
  COL: BibleBooks.Colossians,
  '1TH': BibleBooks.Thessalonians1,
  '2TH': BibleBooks.Thessalonians2,
  '1TI': BibleBooks.Timothy1,
  '2TI': BibleBooks.Timothy2,
  TIT: BibleBooks.Titus,
  PHM: BibleBooks.Philemon,
  HEB: BibleBooks.Hebrews,
  JAS: BibleBooks.James,
  '1PE': BibleBooks.Peter1,
  '2PE': BibleBooks.Peter2,
  '1JN': BibleBooks.John1,
  '2JN': BibleBooks.John2,
  '3JN': BibleBooks.John3,
  JUD: BibleBooks.Jude,
  REV: BibleBooks.Revelation,
};

export enum Manuscript {
  OT = 'wlc',
  NT = 'na28',
}

export type StrongsXPrefix = 'h' | 'g';

const bibleBooksToBibleApiAbbrevsConverter = (): Map<number, string> => {
  const map = new Map<number, string>();
  Object.keys(BIBLE_API_ABBREVS_TO_BIBLE_BOOKS).forEach((record): void => {
    map.set(BIBLE_API_ABBREVS_TO_BIBLE_BOOKS[record], record);
  });
  return map;
};

export const BIBLE_BOOKS_TO_BIBLE_API_ABBREVS: Map<
  number,
  string
> = bibleBooksToBibleApiAbbrevsConverter();

export class VerseIdParser {
  public parse(reference: string): string {
    const logosRegex = /Bible:(\w+)\s(\d+):(\d+)/;
    const parsedReference = logosRegex.exec(reference);
    if (parsedReference === null) {
      throw new Error(`Cannot parse reference: ${reference}`);
    } else {
      const [book, chapter, verse] = parsedReference.splice(1, 3);
      let bookNum = '';
      try {
        bookNum = LOGOS_BIBLE_ABBREVS[book].toString();
      } catch (e) {
        throw new Error(`Cannot find reference to: ${book}.`);
      }

      const bookId = bookNum.padStart(2, '0');
      const chapterId = chapter.padStart(3, '0');
      const verseId = verse.padStart(3, '0');
      return `${bookId}${chapterId}${verseId}`;
    }
  }

  public parseSegments(book: string, chapter: string, verse: string): string {
    let bookName: string = book;
    if (book.match(/^(\d)\s(\w+)/)) {
      bookName = book.replace(/^(\d)\s(\w+)/, '$2$1');
    }

    const bookId = String(BibleBooks[bookName as keyof typeof BibleBooks]);
    const chapterId = chapter.padStart(3, '0');
    const verseId = verse.padStart(3, '0');
    return `${bookId.padStart(2, '0')}${chapterId}${verseId}`;
  }

  public parseGbiAbbrevs(abbrev: string): string {
    const parsed = abbrev.match(/^(\d?[A-Za-z]+)(\d+):(\d+)/);
    if (parsed === null) {
      throw new Error(`Cannot parse GBI abbreviation: ${abbrev}`);
    } else {
      const bookAbbrev = parsed[1];
      const bookId = GBI_BIBLE_ABBREVS[bookAbbrev].toString().padStart(2, '0');
      const chapterId = parsed[2].padStart(3, '0');
      const verseId = parsed[3].padStart(3, '0');
      return `${bookId}${chapterId}${verseId}`;
    }
  }

  public isOT(gbiId: string): boolean {
    return Number(gbiId.substring(0, 2)) <= 39;
  }

  public isNT(gbiId: string): boolean {
    return Number(gbiId.substring(0, 2)) >= 40 && Number(gbiId.substring(0, 2)) <= 66;
  }

  public createGbiId(bookId: string, chapterId: string, verseId: string): string {
    return `${bookId.padStart(2, '0')}${chapterId.padStart(3, '0')}${verseId.padStart(3, '0')}`;
  }

  public parseGbiId(gbiId: string | undefined): any {
    if (gbiId && gbiId.length === 8) {
      return {
        book: gbiId.slice(0, 2),
        chapter: gbiId.slice(2, 5),
        verse: gbiId.slice(5),
      };
    }

    throw Error('Invalid gbiId.');
  }

  public manuscriptId(gbiId: string): string {
    let manuscript = '';
    if (this.isOT(gbiId)) {
      manuscript = Manuscript.OT;
    } else if (this.isNT(gbiId)) {
      manuscript = Manuscript.NT;
    }

    return manuscript;
  }

  public strongsXPrefix(gbiId: string): StrongsXPrefix {
    // NOTE: for memory processing, we will use lowercase for everything
    return this.isNT(gbiId) ? 'g' : 'h';
  }

  public versificationFieldId(versification: string): string {
    return `${versification.toLowerCase()}Ids`;
  }

  public getReadableReferenceForGbiId(gbiId: string | undefined): { book: string; ref: string } {
    const { book, chapter, verse } = this.parseGbiId(gbiId);
    const bookEnum = BibleBooks[Number(book)];
    const bookName = BibleBookNames[bookEnum as keyof typeof BibleBookNames];
    return { book: bookName, ref: `${Number(chapter)}:${Number(verse)}` };
  }
}
