import { UserNavState, UserNavActionTypes, CONFIRM_LOGOUT, CANCEL_LOGOUT } from '../types';

const INIT_STAT: UserNavState = {
  confirmLogout: false,
};

const UserNav = (state = INIT_STAT, action: UserNavActionTypes): any => {
  switch (action.type) {
    case CONFIRM_LOGOUT:
      return { ...state, confirmLogout: true };

    case CANCEL_LOGOUT:
      return { ...state, confirmLogout: false };

    default:
      return state;
  }
};

export default UserNav;
