import featureToggleDefaults from './featureToggleDefaults';
import { Feature } from '../../shared/structs';
import FirebaseSdkProvider from '../firebase/firebaseSdkProvider';

export default class FeatureToggle {
  public static isActivated(feature: Feature): boolean {
    const remoteConfigValue = FirebaseSdkProvider.getRemoteConfigValue(feature);
    let valueObject = featureToggleDefaults[feature];

    try {
      valueObject = JSON.parse(remoteConfigValue._value);
    } catch (error) {
      console.info(`There was a problem fetching feature toggle: ${feature}. Using default.`);
    }
    return valueObject.activated;
  }

  public static isNotActivated(feature: Feature): boolean {
    return !this.isActivated(feature);
  }
}
