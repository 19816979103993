/* eslint-disable import/prefer-default-export */
import React from 'react';
import Joi from 'joi-browser';

import { ProjectState, ProjectProps } from '../../types';

export default class ProjectBase extends React.Component<ProjectProps, ProjectState> {
  protected projectValidation(projectData: object): any {
    const schema = {
      projectName: Joi.string()
        .label('Project name')
        .min(3)
        .max(128)
        .required(),
      beginningDate: Joi.date()
        .label('Beginning date')
        .required(),
      targetCompletionDate: Joi.date()
        .required()
        .greater(Joi.ref('beginningDate'))
        .label('Target completion date')
        .error((errors: any): any => {
          return errors.map((error: any): any => {
            if (error.type === 'date.greater') {
              return {
                message: '"Target completion date" must be greater than "Beginning date"',
              };
            }

            return {};
          });
        }),
      description: Joi.string()
        .label('Description')
        .min(3)
        .max(3000)
        .required(),
    };
    return Joi.validate(projectData, schema);
  }
}
