import {
  REDIRECT_TO_PROJECT,
  REDIRECT_DONE,
  SIGN_IN_FAIL,
  EMAIL_ADDR_UPDATE,
  PASSWORD_UPDATE,
  CLEAR_ERROR_MSG,
  User,
} from '../types';
import { closeLightbox } from './lightbox';
import firebase from '../lib/firebase';
import { ShowProjectList } from './project';
import { changeLocale } from './locale';
import { updateProfile } from './profile';

const FirebaseAuth = firebase.createService('auth');
const FirebaseDb = firebase.createService('db');

export function signinSuccess(): any {
  return (dispatch: any): void => {
    dispatch({
      type: REDIRECT_TO_PROJECT,
    });
  };
}

export function redirectDone(): any {
  return (dispatch: any): void => {
    dispatch({
      type: REDIRECT_DONE,
    });
  };
}

export function signinFailed(error: any): any {
  return (dispatch: any): void => {
    dispatch({
      type: SIGN_IN_FAIL,
      error,
    });
  };
}

export function emailAddrUpdater(emailAddr: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: EMAIL_ADDR_UPDATE,
      emailAddr,
    });
  };
}

export function passwordUpdater(password: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: PASSWORD_UPDATE,
      password,
    });
  };
}

async function loadUserProfile(user: User, dispatch: any): Promise<void> {
  const dbUser = await (await FirebaseDb.init()).createOrFetchUser(user);
  if (dbUser.profile && dbUser.profile.language) {
    dispatch(changeLocale(dbUser.profile.language));
    dispatch(updateProfile(dbUser.profile));
  }
}

export function signinWithFederatedIdentityProviders(provider: string): any {
  return async (dispatch: any): Promise<any> => {
    try {
      const user = await (await FirebaseAuth.init()).signinWithFederatedIdentityProviders(provider);
      await loadUserProfile(user, dispatch);
      dispatch(closeLightbox());
      dispatch(signinSuccess());
      dispatch(ShowProjectList());
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        dispatch(signinFailed(error.message));
      } else {
        dispatch(signinFailed('Unexpected error has occurred. please try again later.'));
      }
    }
  };
}

export function signinWithEmailProvider(emailAddr: string, password: string): any {
  return async (dispatch: any): Promise<any> => {
    try {
      const user = await (await FirebaseAuth.init()).signinWithEmailProvider(emailAddr, password);
      await loadUserProfile(user, dispatch);
      dispatch(closeLightbox());
      dispatch(signinSuccess());
      dispatch(ShowProjectList());
    } catch (error) {
      dispatch(signinFailed(error.message));
    }
  };
}

export const clearError = (): any => {
  return {
    type: CLEAR_ERROR_MSG,
  };
};
