import {
  AuthState,
  AuthActionTypes,
  USER_AUTHENTICATED,
  USER_NOT_AUTHENTICATED,
  PUBLISH_DISPLAY_NAME,
} from '../types';

const INIT_STAT: AuthState = {
  authenticated: false,
  user: null,
  displayName: 'Welcome',
};

const Auth = (state = INIT_STAT, action: AuthActionTypes): any => {
  switch (action.type) {
    case USER_AUTHENTICATED:
      return { ...state, authenticated: true, user: action.user };

    case USER_NOT_AUTHENTICATED:
      return { ...state, authenticated: false, user: null };

    case PUBLISH_DISPLAY_NAME:
      return { ...state, displayName: action.displayName };

    default:
      return state;
  }
};

export default Auth;
