import React, { ReactElement } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  dispalyLightbox,
  closeLightbox,
  signinWithFederatedIdentityProviders,
  signinWithEmailProvider,
  emailAddrUpdater,
  passwordUpdater,
  redirectDone,
} from '../actions';
import UserNav from './usernav/index';
import { LightboxContainer as Lightbox } from './lightbox';
import { SignInProps, SignInState, GOOGLE, FACEBOOK } from '../types';
import { AppState } from '../reducers';

export class SignInComp extends React.Component<SignInProps, SignInState> {
  public static defaultProps: SignInProps = {
    display: false,
    authenticated: false,
    redirectToProject: false,
    error: null,
    user: null,
    emailAddr: '',
    password: '',
  };

  protected signinWithGoogle(): void {
    const { signinWithGoogle } = this.props;
    signinWithGoogle();
  }

  protected signinWithFacebook(): void {
    const { signinWithFacebook } = this.props;
    signinWithFacebook();
  }

  protected signinWithEmailAddr(): void {
    const { signinWithEmailAddr } = this.props;
    const { emailAddr, password } = this.props;
    signinWithEmailAddr(emailAddr, password);
  }

  protected emailAddrUpdate(e?: any): void {
    const { emailAddrUpdate } = this.props;
    emailAddrUpdate(e.target.value);
  }

  protected passwordUpdate(e?: any): void {
    const { passwordUpdate } = this.props;
    passwordUpdate(e.target.value);
  }

  protected displayLightbox(): void {
    const { dispalyLightboxFunc } = this.props;
    dispalyLightboxFunc();
  }

  protected closeLightbox(): void {
    const { closeLightboxFunc } = this.props;
    closeLightboxFunc();
  }

  public render(): any[] {
    const {
      redirectToProject,
      redirectDoneFunc,
      authenticated,
      display,
      user,
      closeLightboxFunc,
      error,
      emailAddr,
      password,
    } = this.props;
    const stack = [];

    let queryParams = new URLSearchParams('');
    if (typeof window !== 'undefined') {
      queryParams = new URLSearchParams(window.location.search);
    }

    if (redirectToProject) {
      stack.push(<Redirect to="/project" />);

      redirectDoneFunc();
    } else {
      if (authenticated && user) {
        stack.push(<UserNav displayName={user.displayName} key="component-user-nav" />);
      } else {
        stack.push(
          <button
            key="lightbox-btn-signin"
            type="button"
            className="btn btn-outline-light "
            onClick={(): void => {
              this.displayLightbox();
            }}
          >
            <FormattedMessage id="signin" />
          </button>,
        );
      }

      let signUpMessage: React.ReactElement;
      // Do not show signup page except in live env
      if (process.env.REACT_APP_YTB_ENV === 'ytb-live') {
        signUpMessage = (
          <Link
            to="/signupWithEmail"
            onClick={(): void => {
              this.closeLightbox();
            }}
          >
            &nbsp;
            <FormattedMessage id="signin.createOne" />
          </Link>
        );
      } else {
        signUpMessage = (
          <Link
            to="/help"
            onClick={(): void => {
              this.closeLightbox();
            }}
          >
            &nbsp;
            <FormattedMessage id="header.help" />
          </Link>
        );
      }

      stack.push(
        <Lightbox extModalCssName=" modal-dialog-login" display={display} key="lightbox-login">
          <div className="modal-dialog modal-login">
            <div className="modal-content">
              <div key="modal-header" className="modal-header">
                <h1 className="modal-title">
                  <FormattedMessage id="signin" />
                </h1>
                <button type="button" className="close" onClick={closeLightboxFunc}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body scroll bg-white" style={{ padding: 0 }} key="modal-body">
                <div className="bg-white " style={{ minHeight: '368px' }}>
                  <form className="form-signin">
                    <div className="form-group mb-4">
                      <FormattedMessage id="signin.emailPlaceholder">
                        {(placeholder: any): ReactElement => (
                          <input
                            type="email"
                            className="form-control"
                            id="email-sign-in"
                            placeholder={placeholder}
                            value={emailAddr}
                            onChange={(e): void => {
                              this.emailAddrUpdate(e);
                            }}
                          />
                        )}
                      </FormattedMessage>
                    </div>
                    <div className="form-group">
                      <FormattedMessage id="signin.passwordPlaceholder">
                        {(placeholder: any): ReactElement => (
                          <input
                            type="password"
                            className="form-control"
                            id="password-sign-in"
                            placeholder={placeholder}
                            value={password}
                            onChange={(e): void => {
                              this.passwordUpdate(e);
                            }}
                          />
                        )}
                      </FormattedMessage>
                    </div>

                    <div className="form-group">
                      <Link className="small" to="/">
                        <FormattedMessage id="signin.forgotPassword" />
                      </Link>
                    </div>

                    <div className="form-group form-check mb-4">
                      <div className="row">
                        <div className="col">
                          <label className="form-check-label" htmlFor="remember_me">
                            <input type="checkbox" className="form-check-input" id="remember_me" />
                            <FormattedMessage id="signin.rememberMe" />
                          </label>
                        </div>
                        <div className="col">
                          <button
                            className="btn btn-lg btn-ytb btn-block"
                            type="button"
                            onClick={(): void => this.signinWithEmailAddr()}
                          >
                            <FormattedMessage id="signin" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {error && (
                      <div className="form-group">
                        <p className="text-danger">
                          <small>{error}</small>
                        </p>
                      </div>
                    )}

                    {((): ReactElement => {
                      if (queryParams.get('ver') === 'full') {
                        return (
                          <>
                            <hr />

                            <button
                              type="button"
                              id="signin-with-facebook"
                              className="btn btn-facebook btn-block mb-4"
                              onClick={(): void => {
                                this.signinWithFacebook();
                              }}
                            >
                              <span className="btn-icon">
                                <i className="fab fa-facebook-f" />
                              </span>
                              <FormattedMessage id="signin.facebook" />
                            </button>

                            <button
                              type="button"
                              className="btn btn-google btn-block"
                              onClick={(): void => {
                                this.signinWithGoogle();
                              }}
                            >
                              <span className="btn-icon">
                                <i className="fab fa-google" />
                              </span>
                              <FormattedMessage id="signin.google" />
                            </button>
                          </>
                        );
                      }

                      return <></>;
                    })()}
                  </form>
                </div>
              </div>

              <div className="modal-footer mt-4" key="modal-footer">
                <FormattedMessage id="signin.dontHaveAccount" />
                {signUpMessage}
              </div>
            </div>
          </div>
        </Lightbox>,
      );
    }

    return stack;
  }
}

const mapStateToProps = (state: AppState): any => {
  return {
    redirectToProject: state.signin.redirect,
    authenticated: state.auth.authenticated,
    emailAddr: state.signin.emailAddr,
    user: state.auth.user,
    password: state.signin.password,
    error: Object.prototype.hasOwnProperty.call(state.signin, 'error') ? state.signin.error : null,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  signinWithGoogle: (): void => {
    dispatch(signinWithFederatedIdentityProviders(GOOGLE));
  },

  signinWithFacebook: (): void => {
    dispatch(signinWithFederatedIdentityProviders(FACEBOOK));
  },

  emailAddrUpdate: (e?: any): void => {
    dispatch(emailAddrUpdater(e));
  },

  passwordUpdate: (e?: any): void => {
    dispatch(passwordUpdater(e));
  },

  signinWithEmailAddr: (emailAddr: string, password: string): void => {
    dispatch(signinWithEmailProvider(emailAddr, password));
  },

  dispalyLightboxFunc: (): void => {
    dispatch(dispalyLightbox());
  },

  closeLightboxFunc: (): void => {
    dispatch(closeLightbox());
  },

  redirectDoneFunc: (): void => {
    dispatch(redirectDone());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInComp);
