import { Dispatch, AnyAction } from 'redux';
import { ProfileState, UPDATE_PROFILE, UPDATE_LANGUAGE } from '../types';
import firebase from '../lib/firebase';

const FirebaseDb = firebase.createService('db');

export function updateProfile(profile: ProfileState): any {
  return (dispatch: any): void => {
    dispatch({
      type: UPDATE_PROFILE,
      profile,
    });
  };
}

export function updateLanguage(language: string): any {
  return (dispatch: any): void => {
    dispatch({
      type: UPDATE_LANGUAGE,
      language,
    });
  };
}

export function updateProfileAction(profile: ProfileState, uid: string, language: string): any {
  return async (dispatch: Dispatch<AnyAction>): Promise<void> => {
    try {
      await (await FirebaseDb.init()).updateUserProfileUILanguage(uid, language);
      dispatch(updateProfile(profile));
    } catch (error) {
      console.log('There was a problem', error);
    }
  };
}
