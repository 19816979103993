import Base from '../base';
import FirebaseAuth from './firebase.auth';
import FirebaseDB from './firebase.database';

class Firebase extends Base {
  private static serviceMap = new Map<string, any>();

  public static registerService(serviceId: string, instance: any): void {
    this.serviceMap.set(serviceId, instance);
  }

  public static createService(serviceId: string): any {
    return this.serviceMap.get(serviceId);
  }
}

Firebase.registerService('auth', new FirebaseAuth());
Firebase.registerService('db', new FirebaseDB());

export default Firebase;
