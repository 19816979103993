import { UndefinedAction } from './lightbox';

export const DISPLAY_NAVIGATOR = 'DISPLAY_NAVIGATOR';
export const CLOSE_NAVIGATOR = 'CLOSE_NAVIGATOR';
export const CHANGE_BOOK = 'CHANGE_BOOK';
export const CHANGE_CHAPTER = 'CHANGE_CHAPTER';
export const CHANGE_SLIDE = 'CHANGE_SLIDE';
export const SEARCH_BOOK = 'SEARCH_BOOK';

export const VIEW_OF_BOOK = 'VIEW_OF_BOOK';
export const VIEW_OF_CHAPTER = 'VIEW_OF_CHAPTER';
export type CurrentView = typeof VIEW_OF_BOOK | typeof VIEW_OF_CHAPTER;

interface DisplayNavigatorAction {
  type: typeof DISPLAY_NAVIGATOR;
}

interface CloseNavigatorAction {
  type: typeof CLOSE_NAVIGATOR;
}

interface ChangeBookAction {
  type: typeof CHANGE_BOOK;
  bookId: string;
}

interface ChangeChapterAction {
  type: typeof CHANGE_CHAPTER;
  chapter: number;
}

interface ChangeSlideAction {
  type: typeof CHANGE_SLIDE;
  index: number;
}

interface SearchBookAction {
  type: typeof SEARCH_BOOK;
  keyword: string;
}

export type NavigatorActionTypes =
  | DisplayNavigatorAction
  | CloseNavigatorAction
  | ChangeBookAction
  | ChangeChapterAction
  | ChangeSlideAction
  | UndefinedAction
  | SearchBookAction;

export interface NavigatorState {
  loading: boolean;
  display: boolean;
  selectedBookId?: string;
  defaultBookId?: string;
  activeSlide?: number;
  directionSlide?: string;
  selectedChapter?: number;
  defaultChapter?: number;
  defaultView?: CurrentView;
  keyword?: string;
}

export interface NavigatorProps {
  loading: boolean;
  display: boolean;
  parentSelectedBookId?: string;
  selectedBookId?: string;
  defaultBookId?: string;
  displayNavigatorFunc?: any;
  closeNavigatorFunc?: any;
  changeBookFunc?: any;
  activeSlide?: number;
  directionSlide?: string;
  changeSlideFunc?: any;
  parentSelectedChapter?: number;
  selectedChapter?: number;
  defaultChapter?: number;
  defaultView?: CurrentView;
  changeChapterFunc?: any;
  keyword?: string;
  searchBookFunc?: any;
  verseModifiedMap?: Map<string, boolean>;
}
