import FirebaseSdkProvider from './firebaseSdkProvider';
import Base from '../base';
import {
  ProjectData,
  CardData,
  User,
  DEFAULT_BOOK,
  DEFAULT_CHAPTER,
  LastThingWorkedOn,
  ProjectUsers,
} from '../../types';
import BibleApiClient from '../bibleApiClient';
import { DataResult, Translation, Resource, TranslationLink } from '../../shared/structs';
import { getSuggestions } from '../../shared/firestore/suggestions';
import { ManuscriptSuggestions } from '../../shared/structs/projectTranslations';

import * as bookFunc from '../book';

export default class FirebaseDB extends Base {
  protected db: any;

  private collections = new Map();

  public constructor() {
    super();
    this.setCollections('project', 'projects');
    this.setCollections('user', 'users');
    this.setCollections('translation', 'translations');
    this.setCollections('na28', 'na28');
    this.setCollections('net', 'net');
    this.setCollections('wlc', 'wlc');
    this.setCollections('projectTranslation', 'projectTranslations');
  }

  public async init(): Promise<any> {
    if (this.firebaseSdk === undefined) {
      this.firebaseSdk = await FirebaseSdkProvider.getSdk();
      this.db = this.firebaseSdk.firestore();
    }
    return this;
  }

  public getDb(): any {
    return this.db;
  }

  public setCollections(key: string, name: string): void {
    this.collections.set(key.toLowerCase(), name);
  }

  public getCollections(key: string): string {
    return this.collections.get(key.toLowerCase());
  }

  public async addProject(
    uid: string,
    projectData: ProjectData,
    displayName: string,
    email: string,
  ): Promise<any> {
    try {
      this.checkUserId(uid);

      const { projectName } = projectData;

      const lastThingWorkedOn: LastThingWorkedOn = {
        bookId: DEFAULT_BOOK,
        chapter: DEFAULT_CHAPTER,
        resources: new Array<Resource>(),
        references: new Array<Translation>(),
      };

      const users: ProjectUsers = {};
      users[uid] = {
        displayName,
        email,
        permission: 'owner',
        addedAt: new Date(),
      };

      const shared = [];
      shared.push(uid);

      const postData = {
        ...projectData,
        author: uid,
        createdAt: new Date(),
        updatedAt: null,
        name_uppercase: projectName.trim().toUpperCase(),
        lastThingWorkedOn,
        users,
        shared,
      };

      const collectionName = this.getCollections('project');

      return await this.db.collection(collectionName).add(postData);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async editProject(uid: string, projectId: string, projectData: ProjectData): Promise<any> {
    try {
      this.checkUserId(uid);

      const { projectName } = projectData;
      const postData = {
        ...projectData,
        updatedAt: new Date(),
        name_uppercase: projectName.trim().toUpperCase(),
      };

      const collectionName = this.getCollections('project');
      const ref = this.db.collection(collectionName).doc(projectId);
      return await ref.update(postData);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async addProjectUser(
    projectId: string,
    uid: string,
    displayName: string,
    email: string,
    permission: string,
  ): Promise<any> {
    const collectionName = this.getCollections('project');
    const ref = this.db.collection(collectionName).doc(projectId);

    const doc = await ref.get();
    if (doc.exists) {
      const projectData = doc.data();
      const userData = projectData.users;
      userData[uid] = {
        displayName,
        email,
        permission,
        addedAt: new Date(),
      };

      const shared = 'shared' in projectData ? projectData.shared : [];
      shared.push(uid);

      return ref.update({
        ...projectData,
        users: userData,
        shared,
        updatedAt: new Date(),
      });
    }

    throw Error('No such document!');
  }

  public async deleteProjectUser(projectId: string, uid: string): Promise<any> {
    const collectionName = this.getCollections('project');
    const ref = this.db.collection(collectionName).doc(projectId);

    const doc = await ref.get();

    if (doc && doc.exists) {
      const projectData = doc.data();
      const userData = projectData.users;
      delete userData[uid];

      const shared =
        'shared' in projectData
          ? projectData.shared.filter((userId: string): boolean => userId !== uid)
          : [];

      ref.update({
        ...projectData,
        users: userData,
        shared,
        updatedAt: new Date(),
      });
    } else {
      throw Error('No such document!');
    }
  }

  public async getProjectByName(uid: string, projectName: string): Promise<any> {
    try {
      if (uid === '') {
        throw Error('You must be signed in to create project.');
      } else if (typeof projectName !== 'string') {
        throw TypeError('Project name must be a string');
      } else if (projectName === '') {
        throw Error('Project name cannot be empty.');
      }

      const collectionName = this.getCollections('project');

      // query projects
      const ref = this.db.collection(collectionName);
      const query = ref
        .where('author', '==', uid)
        .where('name_uppercase', '==', projectName.trim().toUpperCase());

      const querySnapshot = await query.get();

      return querySnapshot.docs;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getUserByUid(uid: string): Promise<User> {
    let returnVal = null;
    const collection = this.getCollections('user');
    const ref = this.db.collection(collection);
    const query = ref.where('uid', '==', uid);
    const querySnapshot = await query.get();
    if (querySnapshot.docs.length > 0) {
      returnVal = querySnapshot.docs[0].data();
    }
    return returnVal;
  }

  public async getUserByEmail(email: string): Promise<User> {
    let returnVal = null;
    const collection = this.getCollections('user');
    const ref = this.db.collection(collection);
    const query = ref.where('email_uppercase', '==', email.trim().toUpperCase());
    const querySnapshot = await query.get();
    if (querySnapshot.docs.length > 0) {
      returnVal = querySnapshot.docs[0].data();
    }
    return returnVal;
  }

  public async createUser(user: User): Promise<User> {
    const collection = this.getCollections('user');
    const created = await this.db.collection(collection).add({
      displayName: user.displayName,
      email: user.email,
      email_uppercase: user.email.toUpperCase(),
      emailVerified: user.emailVerified,
      photoURL: user.photoURL,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      profile: {},
    });
    return created.data;
  }

  public async updateUserProfileUILanguage(uid: string, language: string): Promise<void> {
    await this.updateUserProfile(uid, 'language', language);
  }

  public async updateUserProfile(
    uid: string,
    field: string,
    value: string | boolean,
  ): Promise<void> {
    const fieldName = `profile.${field}`;
    const collection = this.getCollections('user');
    const ref = this.db.collection(collection);
    const query = ref.where('uid', '==', uid);
    const querySnapshot = await query.get();
    const { id } = querySnapshot.docs[0];
    await ref.doc(id).update({ [fieldName]: value });
  }

  public async createOrFetchUser(user: User): Promise<User> {
    let dbUser = await this.getUserByUid(user.uid);
    if (!dbUser) {
      dbUser = await this.createUser(user);
    }
    return { ...dbUser };
  }

  public async getProjectById(uid: string, projectId: string): Promise<any> {
    try {
      if (uid === '') {
        throw Error('You must be signed in to create project.');
      } else if (projectId === '') {
        throw Error('Project ID is required.');
      }

      const collectionName = this.getCollections('project');

      const ref = await (await this.init())
        .getDb()
        .collection(collectionName)
        .doc(projectId);
      const doc = await ref.get();
      if (doc.exists) {
        const data = await doc.data();
        return data;
      }

      throw Error('Project does not exist.');
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getProjectsByUid(uid: string): Promise<any> {
    try {
      this.checkUserId(uid);

      const collectionName = this.getCollections('project');
      // query projects
      const ref = this.db.collection(collectionName);
      const query = ref.where('author', '==', uid);
      const querySnapshot = await query.get();

      const projectList: object[] = [];
      querySnapshot.forEach((doc: any): void => {
        projectList.push({
          id: doc.id,
          data: doc.data(),
        });
      });

      return projectList;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // get user's all projects include collaborative projects
  public async getUserProjects(uid: string): Promise<any> {
    this.checkUserId(uid);

    const collectionName = this.getCollections('project');
    // query projects
    const ref = this.db.collection(collectionName);
    const query = ref.where('shared', 'array-contains', uid);
    const querySnapshot = await query.get();

    const projectList: object[] = [];
    querySnapshot.forEach((doc: any): void => {
      projectList.push({
        id: doc.id,
        data: doc.data(),
      });
    });

    return projectList;
  }

  public async removeProjectById(projectId: string, uid: string): Promise<any> {
    try {
      this.checkUserId(uid);

      const collectionName = this.getCollections('project');
      return await this.db
        .collection(collectionName)
        .doc(projectId)
        .delete();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Retrieve the BHS (WLC) Hebrew text from Firestore when working in the Old Testament
   * Retrieve NA28 Greek text from Firestore when working in the New Testament
   *
   * @param uid
   * @param bookId
   * @param chapter
   */
  public async getManuscript(bookId: string, chapter: number): Promise<CardData> {
    try {
      if (bookId === '') {
        throw Error('You must select a book.');
      } else if (chapter < 1 || chapter === undefined) {
        throw Error('You must select a chapter.');
      }

      const collectionName = this.getCollections('translation');

      let documentName;
      const isOldTestament = bookFunc.isOldTestament(bookId);
      if (isOldTestament) {
        documentName = this.getCollections('wlc');
      } else {
        documentName = this.getCollections('na28');
      }

      const subCollectionName = 'chaptersS1'; // chaptersS1 | verses

      const bookChapterCode = bookFunc.generateBookChapterCode(bookId, chapter);

      // query projects
      const ref = this.db
        .collection(collectionName)
        .doc(documentName)
        .collection(subCollectionName);

      const query = ref.where('textId', '==', bookChapterCode.toString());

      const querySnapshot = await query.get();

      const data = querySnapshot.docs && querySnapshot.docs[0] ? querySnapshot.docs[0].data() : {};

      const manuscriptData: CardData = {
        empty: querySnapshot.empty,
        bookId,
        chapter,
        isOldTestament,
        data,
      };

      return manuscriptData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Retrieve list of resources
   */
  public async getResourceList(): Promise<DataResult> {
    try {
      const snapshot = await this.db.collection('resources').get();
      const resources = snapshot.docs.map((doc: any) => {
        return doc.data();
      });
      return { empty: false, data: resources };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Retrieve list of References
   * Sourced from multiple locations
   */
  public async getReferenceList(): Promise<DataResult> {
    const [dblResults, firestoreResults] = await Promise.all([
      this.getDblReferenceList(),
      this.getFirestoreReferenceList(),
    ]);
    const combinedResults = dblResults.data.concat(firestoreResults.data);
    return Promise.resolve({ empty: false, data: combinedResults });
  }

  /**
   * Retrieve list of References
   * Sourced from DBL
   */
  public async getDblReferenceList(): Promise<DataResult> {
    const bibClient = new BibleApiClient();
    let result: DataResult = { empty: true, data: [] };
    try {
      const response = await bibClient.getBiblesList();
      if (response.responseData) {
        result = { empty: false, data: response.responseData };
      }
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  /**
   * Retrieve list of References
   * Sourced from Firestore
   */
  public async getFirestoreReferenceList(): Promise<DataResult> {
    try {
      const ref = await this.db.collection('translations');
      const snapshot = await ref.get();
      const translations = snapshot.docs.map(
        (doc: any): Translation => {
          return doc.data();
        },
      );
      return { empty: false, data: translations };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Retrieve Resource on a per-chapter basis
   *
   * @param resource
   * @param bookId
   * @param chapterId
   */
  public async getResourceByChapter(
    resource: string,
    bookId: string,
    chapterId: number,
  ): Promise<DataResult> {
    try {
      if (bookId === '') {
        throw Error('You must select a book.');
      } else if (chapterId < 1 || chapterId === undefined) {
        throw Error('You must select a chapter.');
      }
      const bookChapterCode = bookFunc.generateBookChapterCode(bookId, chapterId);

      const ref = this.db
        .collection('resources')
        .doc(resource)
        .collection('chapters');

      const query = ref.where('textId', '==', bookChapterCode).limit(1);
      const querySnapshot = await query.get();
      const data = querySnapshot.docs[0] ? querySnapshot.docs[0].data() : {};

      const notesData: DataResult = {
        empty: querySnapshot.empty,
        data,
      };
      return notesData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * Retrieve Reference on a per-chapter basis
   *
   * @param reference
   * @param bookId
   * @param chapterId
   */
  public async getReferenceByChapter(
    reference: string,
    bookId: string,
    chapterId: number,
  ): Promise<DataResult> {
    try {
      if (bookId === '') {
        throw Error('You must select a book.');
      } else if (chapterId < 1 || chapterId === undefined) {
        throw Error('You must select a chapter.');
      }
      const referenceLocation = bookFunc.generateBookChapterCode(bookId, chapterId);
      if (this.referenceIsInFirestore(reference)) {
        return await this.getFirestoreReferenceByChapter(reference, referenceLocation);
      }
      return await this.getDblReferenceByChapter(reference, referenceLocation);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private referenceIsInFirestore(referenceId: string): boolean {
    return referenceId.length <= 4;
  }

  private async getDblReferenceByChapter(
    referenceText: string,
    referenceLocation: string,
  ): Promise<DataResult> {
    const bibleApiClient = new BibleApiClient();
    const data = await bibleApiClient.getBibleByChapter(referenceText, referenceLocation);
    const empty = Boolean(data);
    return { empty, data };
  }

  private async getFirestoreReferenceByChapter(
    referenceText: string,
    referenceLocation: string,
  ): Promise<DataResult> {
    let ref = this.db
      .collection('translations')
      .doc(referenceText)
      .collection('chapters');

    let query = ref.where('textId', '==', referenceLocation).limit(1);
    let querySnapshot = await query.get();

    if (querySnapshot.empty) {
      ref = this.db
        .collection('translations')
        .doc(referenceText)
        .collection('chaptersS1');

      query = ref.where('textId', '==', referenceLocation).limit(1);
      querySnapshot = await query.get();
    }
    const data = querySnapshot.docs[0] ? querySnapshot.docs[0].data() : {};
    const referenceData: DataResult = {
      empty: querySnapshot.empty,
      data: { responseData: data.text },
    };
    return referenceData;
  }

  public async updateUserWorkedLastThing(
    uid: string,
    projectId: string,
    fieldToUpdate: string,
    valueToUpdate: any,
  ): Promise<any> {
    try {
      const fieldName = `lastThingWorkedOn.${fieldToUpdate}`;
      return this.db
        .collection('projects')
        .doc(projectId)
        .update({
          [fieldName]: valueToUpdate,
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // *********************** projectTranslations - start ***********************
  public async getProjectTranslation(uid: string, projectId: string): Promise<any> {
    try {
      this.checkUserId(uid);

      if (projectId === '') {
        throw Error('Project ID is required to query project translation.');
      }

      const collectionName = this.getCollections('projectTranslation');

      const query = await (await this.init())
        .getDb()
        .collection(collectionName)
        .where('id', '==', projectId);

      return await query.get();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getTranslationVerse(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
  ): Promise<any> {
    try {
      this.checkUserId(uid);

      if (projectId === '') {
        throw Error('Project ID is required to query project translation.');
      }

      if (gbiVerseCode === '') {
        throw Error('Verse code is required to query project translation.');
      }

      const collectionName = this.getCollections('projectTranslation');
      const subCollection = 'verses';

      const query = await (await this.init())
        .getDb()
        .collection(collectionName)
        .doc(projectId)
        .collection(subCollection)
        .where('textId', '==', gbiVerseCode);

      return await query.get();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getTranslationVerseData(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
  ): Promise<DataResult> {
    const snapshot = await this.getTranslationVerse(uid, projectId, gbiVerseCode);
    let docs = [];
    if (!snapshot.empty) {
      docs = snapshot.docs.map((doc: any) => {
        return doc.data();
      });
    }
    return { empty: snapshot.empty, data: docs[0] || {} };
  }

  public async createProjectTranslationCollection(uid: string, projectId: string): Promise<any> {
    const collectionName = this.getCollections('projectTranslation');
    return this.db
      .collection(collectionName)
      .doc(projectId)
      .set({
        id: projectId,
        author: uid,
        createdAt: new Date(),
      });
  }

  public async subscribeToTranslationVerse(
    projectId: string,
    gbiVerseCode: string,
    handler: Function,
  ): Promise<any> {
    const collectionName = this.getCollections('projectTranslation');
    const subCollection = 'verses';
    return this.db
      .collection(collectionName)
      .doc(projectId)
      .collection(subCollection)
      .doc(gbiVerseCode)
      .onSnapshot(handler);
  }

  public async verifyLinks(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    verified: boolean,
    links: TranslationLink,
  ): Promise<void> {
    const collectionName = this.getCollections('projectTranslation');
    const subCollection = 'verses';
    const ref = this.getDb()
      .collection(collectionName)
      .doc(projectId)
      .collection(subCollection);

    const query = ref.where('textId', '==', gbiVerseCode);
    const querySnapshot = await query.get();
    const { id } = querySnapshot.docs[0];
    const linksVerifiedDoc = { linksVerified: verified, links };
    await ref.doc(id).update(linksVerifiedDoc);
  }

  public async createVerseTranslation(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    verseTranslation: string,
    complete: boolean,
  ): Promise<any> {
    try {
      this.checkUserId(uid);
      const collectionName = this.getCollections('projectTranslation');
      const subCollection = 'verses';
      // update

      const data = {
        author: uid,
        createdAt: new Date(),
        updatedAt: null,
        textId: gbiVerseCode,
        text: verseTranslation,
        complete,
      };

      return await this.db
        .collection(collectionName)
        .doc(projectId)
        .collection(subCollection)
        .doc(gbiVerseCode)
        .set(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async updateVerseTranslation(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    verseTranslation: string,
    complete: boolean,
  ): Promise<any> {
    try {
      this.checkUserId(uid);
      const collectionName = this.getCollections('projectTranslation');
      const subCollection = 'verses';
      // update
      const data = {
        updatedAt: new Date(),
        updatedBy: uid,
        textId: gbiVerseCode,
        text: verseTranslation,
        complete,
      };
      return await this.db
        .collection(collectionName)
        .doc(projectId)
        .collection(subCollection)
        .doc(gbiVerseCode)
        .update(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  /**
   * @param uid string
   * @param projectId string
   * @param textId string - textId should be the document ID as well as a document field called textId. e.g. 01001001 for Genesis 1:1
   * @param sourceRefId string - Gen 1:1,  'translations/wlc/verses/01001001'
   *                         Matthew 1:1, 'translations/na28/verses/40001001'.
   */
  public async saveProjectTranslation(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    verseTranslation: string,
    complete: boolean,
  ): Promise<any> {
    try {
      this.checkUserId(uid);
      const projectDoc = await this.getProjectTranslation(uid, projectId);

      if (!projectDoc || projectDoc.size < 1) {
        // creates collection
        await this.createProjectTranslationCollection(uid, projectId);
      }
      // sub-collection
      const verseDoc = await this.getTranslationVerse(uid, projectId, gbiVerseCode);

      if (verseDoc.size) {
        // update
        return await this.updateVerseTranslation(
          uid,
          projectId,
          gbiVerseCode,
          verseTranslation,
          complete,
        );
      } // end if

      // create
      return await this.createVerseTranslation(
        uid,
        projectId,
        gbiVerseCode,
        verseTranslation,
        complete,
      );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getTranslationVerseList(
    uid: string,
    projectId: string,
    bookId: string,
    chapter: number,
  ): Promise<any> {
    try {
      this.checkUserId(uid);

      if (projectId === '') {
        throw Error('Project ID is required to query project translation.');
      } else if (bookId === '') {
        throw Error('Book ID is required to query project translation.');
      } else if (chapter < 1) {
        throw Error('Invalid chapter number.');
      }

      const collectionName = this.getCollections('projectTranslation');
      const subCollection = 'verses';
      const bookChapterCode = bookFunc.generateBookChapterCode(bookId, chapter);
      const textId = `${bookChapterCode}000`;

      const query = await (await this.init())
        .getDb()
        .collection(collectionName)
        .doc(projectId)
        .collection(subCollection)
        .where('textId', '>=', textId);

      const querySnapshot = await query.get();

      const docs: any[] = [];
      querySnapshot.forEach((doc: any): void => {
        docs.push(doc.data());
      });

      return docs;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async updateVerseTranslationStatus(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    complete: boolean,
  ): Promise<any> {
    try {
      this.checkUserId(uid);
      const collectionName = this.getCollections('projectTranslation');
      const subCollection = 'verses';

      const data = {
        updatedAt: new Date(),
        updatedBy: uid,
        complete,
      };

      const result = await this.db
        .collection(collectionName)
        .doc(projectId)
        .collection(subCollection)
        .doc(gbiVerseCode)
        .update(data);

      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getVerseSuggestions(
    uid: string,
    projectId: string,
    gbiVerseCode: string,
    versification: string,
  ): Promise<ManuscriptSuggestions> {
    try {
      this.checkUserId(uid);

      const suggestions = await getSuggestions(
        (await this.init()).getDb(),
        projectId,
        gbiVerseCode,
        versification,
      );

      return suggestions;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
