import { CLOSE_LIGHTBOX, DISPLAY_LIGHTBOX, LightboxActionTypes } from '../types';

export const closeLightbox = (): LightboxActionTypes => {
  return {
    type: CLOSE_LIGHTBOX,
  };
};

export const dispalyLightbox = (): LightboxActionTypes => {
  return {
    type: DISPLAY_LIGHTBOX,
  };
};
