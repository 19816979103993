/* eslint-disable import/prefer-default-export */
import { createTransform } from 'redux-persist';

export const transformer = createTransform(
  // inboundState
  (inboundState: any, key: number | string): any => {
    if (key === 'project') {
      return {
        ...inboundState,
        loading: true,
        error: '',
        editingError: '',
        deletingError: '',
        errorProjectUser: '',
        isFetching: false,
        isFetchingProject: false,
        editingProject: false,
        message: '',
        messageProjectUser: '',
        projectList: [],
        deletingProject: false,
        projectNameEditing: '',
        beginningDateEditing: '',
        targetCompletionDateEditing: '',
        descriptionEditing: '',
        authenticated: false,
        authorizationFail: false,
        keyword: '',
      };
    }

    if (key === 'signin') {
      return { ...inboundState, error: '' };
    }

    if (key === 'verseEditor') {
      return {
        ...inboundState,
        verseTextMap: Object.fromEntries(inboundState.verseTextMap),
        verseCompleteMap: Object.fromEntries(inboundState.verseCompleteMap),
        verseModifiedMap: Object.fromEntries(inboundState.verseModifiedMap),
        autoSuggestionMap: Object.fromEntries(inboundState.autoSuggestionMap),
        updating: false,
      };
    }

    return inboundState;
  },

  // outboundState
  (outboundState: any, key: number | string): any => {
    if (key === 'verseEditor') {
      return {
        ...outboundState,
        verseTextMap: new Map(Object.entries(outboundState.verseTextMap)),
        verseCompleteMap: new Map(Object.entries(outboundState.verseCompleteMap)),
        verseModifiedMap: new Map(Object.entries(outboundState.verseModifiedMap)),
        autoSuggestionMap: new Map(Object.entries(outboundState.autoSuggestionMap)),
      };
    }

    return outboundState;
  },
);
