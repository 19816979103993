/* eslint-disable import/prefer-default-export */
export const confirmUnsavedVerse = (message: string, verseModifiedMap: any): boolean => {
  if (verseModifiedMap && verseModifiedMap.size) {
    try {
      // eslint-disable-next-line no-alert
      return window.confirm(message);
    } catch (err) {
      // eslint-disable-next-line no-alert
      window.alert(err.message());
    }
  }

  return true;
};
