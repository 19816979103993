/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Card, Button } from 'react-bootstrap';

import { ProfileProps, ProfileState } from '../../types';
import { changeLocale, updateLanguage, updateProfileAction } from '../../actions';
import { AppState } from '../../reducers';
import languageObject from '../../translations/messages';

export class ProfileComp extends React.Component<ProfileProps, ProfileState> {
  public static defaultProps: ProfileProps = {
    language: 'en',
    uid: '',
    profile: {},
  };

  private updateLanguageWrapper(e?: any): void {
    const { updateLanguageFunc } = this.props;
    updateLanguageFunc(e.target.value);
  }

  private updateProfileWrapper(e?: any): void {
    e.preventDefault();
    const { updateProfileFunc, profile, uid } = this.props;
    updateProfileFunc(profile, uid);
  }

  public render(): any {
    const { language } = this.props;

    const languageOptions = Object.keys(languageObject).map(languageKey => {
      const languageKeyLabel = `languages.${languageKey}`;
      return <option value={languageKey}>{languageObject[languageKey][languageKeyLabel]}</option>;
    });

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <Card>
              <Card.Header>
                <FormattedMessage id="profile.userProfile" />
              </Card.Header>
              <Card.Body>
                <form
                  id="profile-form"
                  onSubmit={(e): void => {
                    this.updateProfileWrapper(e);
                  }}
                >
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="language" className="font-weight-bold">
                        <FormattedMessage id="profile.language" />
                      </label>
                      <select
                        className="form-control form-control-lg"
                        id="language"
                        value={language}
                        onChange={(e): void => {
                          this.updateLanguageWrapper(e);
                        }}
                        placeholder="Select language for UI"
                      >
                        {languageOptions}
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-md-3">
                      <Button id="submit-profile" className="btn-ytb" type="submit">
                        <FormattedMessage id="profile.update" />
                      </Button>
                    </div>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  return {
    language: state.profile.language,
    uid: state.auth.user ? state.auth.user.uid : '',
    profile: state.profile,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  updateProfileFunc: (profile: ProfileState, uid: string): void => {
    dispatch(changeLocale(profile.language));
    dispatch(updateProfileAction(profile, uid, profile.language));
  },
  updateLanguageFunc: (language: string): void => {
    dispatch(updateLanguage(language));
  },
});

export const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileComp);
