/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ProjectProps, ProjectData } from '../../types';
import { config } from '../../config';

import {
  projectChangeData,
  projectCancelForm,
  editProjectFailureRequest,
  projectLoaded,
  clearError,
  closeLightboxManageProjectUsers,
  fetchProject,
  editProject,
  showViewOfAddingProjectUser,
  cancelAddingProjectUserAction,
  handleUserEmailChangeAction,
  handleUserPermissionChangeAction,
  saveProjectUserAction,
  deleteProjectUserAction,
} from '../../actions';
import { AppState } from '../../reducers';
import Spinner from '../spinner';
import ProjectBase from './projectBase';

const userPermissionMap = new Map(Object.entries(config.userPermissions));

export class ProjectUsersApp extends ProjectBase {
  public static defaultProps: ProjectProps = {
    beginningDate: '',
    description: '',
    isFetching: false,
    loading: false,
    projectName: '',
    targetCompletionDate: '',
    userEmail: '',

    cancelAddingProjectUserFunc: () => null,
    changeViewFunc: () => null,
    clearErrorAndMessageFunc: () => null,
    closeLightboxEditingProjectFunc: () => null,
    closeLightboxManageProjectUsersFunc: () => null,
    closeLightboxRemovingProjectFunc: () => null,
    createProjectFunc: () => null,
    deleteProjectFunc: () => null,
    deleteProjectUserFunc: () => null,
    editProjectFunc: () => null,
    fetchProjectFunc: () => null,
    fetchProjectsFunc: () => null,
    handleUserEmailChangeFunc: () => null,
    handleUserPermissionChangeFunc: () => null,
    saveProjectUserFunc: () => null,
    searchProjectUserEmailFunc: () => null,
    showLightboxEditingProjectFunc: () => null,
    showLightboxManageProjectUsersFunc: () => null,
    showLightboxRemovingProjectFunc: () => null,
    ShowProjectCreationFunc: () => null,
    showViewOfAddingProjectUserFunc: () => null,
  };

  public componentDidMount(): void {
    const { projectIdManageUsers, fetchProjectFunc } = this.props;

    if (projectIdManageUsers) {
      fetchProjectFunc(projectIdManageUsers);
    }
  }

  private deleteUser(userId: string): void {
    const { projectIdManageUsers, deleteProjectUserFunc } = this.props;

    deleteProjectUserFunc(projectIdManageUsers, userId);
  }

  private userActionMenu(userRole: string, targetUserId: string): ReactElement {
    return (
      <Dropdown drop="left">
        <Dropdown.Toggle
          variant="link"
          id="dropdown-manage-project-user-action"
          className="text-ytb"
        >
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <FormattedMessage id="general.delete-user">
            {(message: string): ReactElement => (
              <Dropdown.Item
                disabled={userRole === 'owner'}
                onClick={(): void => {
                  this.deleteUser(targetUserId);
                }}
              >
                {message}
              </Dropdown.Item>
            )}
          </FormattedMessage>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  private listProjectUsers(): ReactElement {
    const { projectNameEditing, showViewOfAddingProjectUserFunc, projectUsers } = this.props;

    return (
      <div key="list-project-users">
        <div className="row">
          <div className="col-sm-9">
            <h5 className="my-0">{projectNameEditing}</h5>
          </div>
          <div className="col-sm-3 text-right mb-2">
            <button
              type="submit"
              className="btn btn-ytb"
              style={{ minWidth: '100px' }}
              onClick={showViewOfAddingProjectUserFunc.bind(this)}
            >
              <i className="fas fa-user-plus mr-2" />
              <FormattedMessage id="project.add-user" />
            </button>
          </div>
        </div>

        <table className="table table-hover" key="table-project-users">
          <thead className="thead-light" key="table-project-users-head">
            <tr>
              <th scope="col" style={{ width: '5%' }}>
                #
              </th>
              <th scope="col" style={{ width: '30%' }}>
                <FormattedMessage id="general.name" />
              </th>
              <th scope="col">
                <FormattedMessage id="general.email" />
              </th>
              <th scope="col" style={{ width: '20%' }}>
                <FormattedMessage id="general.role" />
              </th>
              <th scope="col" style={{ width: '10%' }}>
                <FormattedMessage id="general.action" />
              </th>
            </tr>
          </thead>
          <tbody key="table-project-users-body">
            {((): any => {
              if (projectUsers) {
                const users = Object.keys(projectUsers).map(key => {
                  return {
                    ...projectUsers[key],
                    uid: key,
                  };
                });

                users.sort((a: any, b: any): number => {
                  if (a.addedAt > b.addedAt) {
                    return 1;
                  }

                  if (a.addedAt < b.addedAt) {
                    return -1;
                  }

                  return 0;
                });

                let userIndex = 0;
                return users.map(userData => {
                  userIndex += 1;

                  return (
                    <tr key={`table-project-users-${userIndex}`}>
                      <th scope="row">{userIndex}</th>
                      <td>{userData.displayName}</td>
                      <td>{userData.email}</td>
                      <td>
                        <FormattedMessage id={userPermissionMap.get(userData.permission)}>
                          {(message: string): any => <p>{message}</p>}
                        </FormattedMessage>
                      </td>
                      <td>{this.userActionMenu(userData.permission, userData.uid)}</td>
                    </tr>
                  );
                });
              }

              return undefined;
            })()}
          </tbody>
        </table>
      </div>
    );
  }

  public handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    const { handleUserEmailChangeFunc } = this.props;
    handleUserEmailChangeFunc(e.target.value);
  }

  public handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    const { saveProjectUserFunc, projectIdManageUsers, userEmail } = this.props;

    e.preventDefault();

    saveProjectUserFunc(projectIdManageUsers, userEmail);
  }

  private addProjectUser(): ReactElement {
    const { cancelAddingProjectUserFunc, userEmail } = this.props;

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <div className="row mb-2">
          <div className="col-sm align-baseline">
            <h5>
              <FormattedMessage id="project.new-user" />
            </h5>
          </div>
          <div className="col-sm text-right" />
        </div>

        <div className="card bg-light mb-2">
          <div className="card-body mb-2">
            <h6 className="required-field">
              <FormattedMessage id="general.email" />
            </h6>

            <div className="input-group my-2">
              <input
                type="email"
                className="form-control"
                id="projectUserEmail"
                placeholder="Enter user email"
                onChange={this.handleChangeEmail.bind(this)}
                value={userEmail}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-group text-right mt-4">
          <button type="submit" className="btn btn-ytb mr-2" style={{ minWidth: '100px' }}>
            <i className="fas fa-save mr-2" />
            <FormattedMessage id="add" />
          </button>

          <button
            type="button"
            className="btn btn-secondary "
            style={{ minWidth: '100px' }}
            onClick={cancelAddingProjectUserFunc.bind(this)}
          >
            <i className="fas fa-ban mr-2" />
            <FormattedMessage id="cancel" />
          </button>
        </div>
      </form>
    );
  }

  public render(): ReactElement {
    const {
      errorProjectUser,
      messageProjectUser,
      clearErrorAndMessageFunc,
      closeLightboxManageProjectUsersFunc,
      isFetchingProject,
      appState,
    } = this.props;

    return (
      <div className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <FormattedMessage id="project.users" />
              </h5>
              <button
                type="button"
                className="close"
                onClick={(): void => {
                  closeLightboxManageProjectUsersFunc();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ minHeight: '300px' }}>
              {isFetchingProject && <Spinner />}

              {errorProjectUser && (
                <div className="alert alert-danger mb-4" role="alert">
                  <FormattedMessage id={errorProjectUser} />
                  <button
                    type="button"
                    className="close"
                    onClick={clearErrorAndMessageFunc.bind(this)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              {messageProjectUser && (
                <div className="alert alert-success mb-4" role="alert">
                  <FormattedMessage id={messageProjectUser} />
                  <button
                    type="button"
                    className="close"
                    onClick={clearErrorAndMessageFunc.bind(this)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              {((): any => {
                if (appState === 'ADD_USER') {
                  return this.addProjectUser();
                }
                return this.listProjectUsers();
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  updateFrom: (key: string, value: string): void => {
    dispatch(projectChangeData(key, value));
  },

  cancelFrom: (): void => {
    dispatch(projectCancelForm());
  },

  formHasError: (error: string): void => {
    dispatch(editProjectFailureRequest(error));
  },

  loaded: (): void => {
    dispatch(projectLoaded());
  },

  clearErrorAndMessageFunc: (): void => {
    dispatch(clearError());
  },

  closeLightboxManageProjectUsersFunc: (): void => {
    dispatch(closeLightboxManageProjectUsers());
  },

  fetchProjectFunc: (projectId: string): void => {
    dispatch(fetchProject(projectId));
  },

  editProjectFunc: (projectId: string, projectData: ProjectData): void => {
    dispatch(editProject(projectId, projectData));
  },

  showViewOfAddingProjectUserFunc: (): void => {
    dispatch(showViewOfAddingProjectUser());
  },

  cancelAddingProjectUserFunc: (): void => {
    dispatch(cancelAddingProjectUserAction());
  },

  handleUserEmailChangeFunc: (email: string): void => {
    dispatch(handleUserEmailChangeAction(email));
  },

  handleUserPermissionChangeFunc: (permission: string): void => {
    dispatch(handleUserPermissionChangeAction(permission));
  },

  saveProjectUserFunc: (projectId: string, email: string): void => {
    dispatch(saveProjectUserAction(projectId, email));
  },

  deleteProjectUserFunc: (projectId: string, targetUid: string): void => {
    dispatch(deleteProjectUserAction(projectId, targetUid));
  },
});

export const ProjectUsers = connect(mapStateToProps, mapDispatchToProps)(ProjectUsersApp);
