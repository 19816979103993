/* eslint-disable import/prefer-default-export */
import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal, Dropdown, OverlayTrigger, Tooltip, FormControl } from 'react-bootstrap';
import {
  BibleBooks,
  BibleBookNames,
  BibleChapters,
  BibleBookCategory,
} from '../../shared/verseIdParser';

import { NavigatorProps, NavigatorState, VIEW_OF_BOOK } from '../../types';
import {
  displayNavigator,
  closeNavigator,
  changeBook,
  changeChapter,
  searchBook,
} from '../../actions';
import { AppState } from '../../reducers';
import CustomDropdownToggle from './customDropdownToggle';
import CustomDropdownMenu from './customDropdownMenu';
import { confirmUnsavedVerse } from '../../shared/frontend';
import { I18nDropdown } from './i18nDropdown';

class NavigatorApp extends React.Component<NavigatorProps, NavigatorState> {
  public static defaultProps: NavigatorProps = {
    loading: false,
    display: false,
    activeSlide: 0,
    keyword: '',
  };

  public constructor(props: any) {
    super(props);
    this.handleBookSearch = this.handleBookSearch.bind(this);
  }

  public bookCategory(bookId: string): string {
    return BibleBookCategory[bookId as keyof typeof BibleBookCategory];
  }

  protected bookRows(isOldTestament: boolean, items: any[]): any {
    const {
      changeBookFunc,
      keyword,
      selectedBookId,
      parentSelectedBookId,
      verseModifiedMap,
    } = this.props;

    const selectedBookIdLocal = parentSelectedBookId || selectedBookId;

    return items.map((bookId: string): any => {
      const bookCate = this.bookCategory(bookId);
      const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];
      const words = bookName
        .toString()
        .toLowerCase()
        .split(' ');

      words.push(bookName.toLowerCase());

      const showBook =
        keyword === undefined ||
        words.filter((str: string): boolean => {
          return str.startsWith(keyword.toLowerCase());
        }).length;

      if (showBook) {
        if (selectedBookIdLocal !== bookId) {
          return (
            <li className="list-group-item" key={`bookRow-${bookId}`}>
              <FormattedMessage id="translation.prompt">
                {(promptMessage: any): ReactElement => {
                  return (
                    <button
                      key={`bookRowBtn-${bookId}`}
                      type="button"
                      className="btn btn-link"
                      onClick={(): void => {
                        if (confirmUnsavedVerse(promptMessage, verseModifiedMap)) {
                          changeBookFunc(bookId);
                        }
                      }}
                    >
                      <i
                        key={`bookRowIcon-${bookId}`}
                        className={`fas fa-bible color-${bookCate}`}
                      />
                      <FormattedMessage id={bookName} />
                    </button>
                  );
                }}
              </FormattedMessage>
            </li>
          );
        }

        return (
          <li className="list-group-item book-selected" key={`bookRow-${bookId}`}>
            <button key={`bookRowBtn-${bookId}`} type="button" className="btn ">
              <i key={`bookRowIcon-${bookId}`} className={`fas fa-bible color-${bookCate}`} />
              <FormattedMessage id={bookName} />
            </button>
          </li>
        );
      }

      return undefined;
    });
  }

  protected bookColumn(isOldTestament: boolean): any {
    const bookRange = { fm: 1, to: 39 };
    if (!isOldTestament) {
      bookRange.fm = 40;
      bookRange.to = 66;
    }

    const books: string[] = [];
    let count = 0;
    Object.entries(BibleBooks).forEach(([key, val]): void => {
      if (typeof val === 'number') {
        count += 1;
        if (count >= bookRange.fm && count <= bookRange.to) {
          books.push(key);
        }
      }
    });

    const mid: number = Math.ceil(books.length / 2);

    return (
      <div className="row book-list-body" key="book-list-body">
        <div className="col-sm px-0 " key="book-list-body-left">
          <ul className="list-group" key="book-list-group-left">
            {this.bookRows(isOldTestament, books.slice(0, mid))}
          </ul>
        </div>
        <div className="col-sm px-0 " key="book-list-body-right">
          <ul className="list-group " key="book-list-group-right">
            {this.bookRows(isOldTestament, books.slice(mid, books.length))}
          </ul>
        </div>
      </div>
    );
  }

  protected handleBookSearch(e: any): void {
    const { searchBookFunc } = this.props;
    const keyword = e.target.value;
    searchBookFunc(keyword);
  }

  protected bookList(): any {
    const { keyword, searchBookFunc } = this.props;

    return (
      <React.Fragment key="react-fragment-booklist">
        <h1>
          <FormattedMessage id="navigator.selectBook" />
        </h1>
        <div className="form-group position-relative">
          <FormattedMessage id="navigator.search">
            {(placeholder: any): ReactElement => (
              <FormControl
                autoFocus
                className="w-100"
                placeholder={placeholder}
                onChange={this.handleBookSearch}
                value={keyword}
              />
            )}
          </FormattedMessage>
          <button
            type="button"
            className={`btn btn-link btn-clear  ${keyword === '' ? 'd-none' : ''}`}
            onClick={(): void => {
              searchBookFunc('');
            }}
          >
            <i className="fas fa-times" />
          </button>
        </div>
        <div className="row">
          <div className="col-sm d-flex flex-column align-items-stretch">
            <h2 className="text-center">
              <FormattedMessage id="navigator.oldTestament" />
            </h2>
            {this.bookColumn(true)}
          </div>
          <div className="col-sm d-flex flex-column align-items-stretch">
            <h2 className="text-center">
              <FormattedMessage id="navigator.newTestament" />
            </h2>
            {this.bookColumn(false)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  protected chapterColumns(bookId: string | undefined): any {
    const totalNo = BibleChapters[bookId as keyof typeof BibleChapters];

    const chapters = [];
    if (totalNo) {
      for (let index = 1; index <= totalNo; index += 1) {
        chapters.push(
          <div className="col-2" key={`chapter-list-row-${index}`}>
            {index}
          </div>,
        );
      }
    }

    return (
      <div className="row book-list-body" key="chapter-list-body">
        {chapters}
      </div>
    );
  }

  protected chapterList(): any {
    const { selectedBookId } = this.props;

    return (
      <React.Fragment key="react-fragment-chapter-list">
        <h1 className="border-bottom">Select a chapter</h1>
        <div className="row" key="chapter-list">
          <div className="col-sm">
            <h2 className="text-center">
              {BibleBookNames[selectedBookId as keyof typeof BibleBookNames]}
            </h2>
            {this.chapterColumns(selectedBookId)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  protected lightBox(): any {
    const { display, closeNavigatorFunc } = this.props;

    return (
      <Modal
        size="lg"
        backdrop
        show={display}
        onHide={closeNavigatorFunc}
        dialogClassName="book-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{this.bookList()}</Modal.Body>
      </Modal>
    );
  }

  protected activateNavigator(): void {
    const { displayNavigatorFunc } = this.props;

    displayNavigatorFunc();
  }

  public dropdownItems(chapters: number): any {
    const {
      changeChapterFunc,
      parentSelectedChapter,
      selectedChapter,
      verseModifiedMap,
    } = this.props;
    const items = [];

    const selectedChapterLocal = parentSelectedChapter || selectedChapter;

    for (let index = 1; index <= chapters; index += 1) {
      if (selectedChapterLocal === index) {
        items.push(
          <Dropdown.Item eventKey={index.toString()} key={`dropdown-item-chapter-${index}`}>
            {index}
          </Dropdown.Item>,
        );
      } else {
        items.push(
          <I18nDropdown
            index={index}
            verseModifiedMap={verseModifiedMap}
            changeChapterFunc={changeChapterFunc}
            eventKey={index.toString()}
            key={`chapter-dropdown-item-i18n-${index}`}
          />,
        );
      }
    }

    return items;
  }

  public render(): any {
    const {
      defaultView,
      parentSelectedBookId,
      selectedBookId,
      defaultBookId,
      parentSelectedChapter,
      selectedChapter,
      defaultChapter,
    } = this.props;

    let bookId = selectedBookId || defaultBookId;
    if (parentSelectedBookId) {
      bookId = parentSelectedBookId;
    }
    const bookName = BibleBookNames[bookId as keyof typeof BibleBookNames];

    let chapter = selectedChapter || defaultChapter;
    if (parentSelectedChapter) {
      chapter = parentSelectedChapter;
    }
    const chapterTotal = BibleChapters[bookId as keyof typeof BibleChapters];

    if (defaultView === VIEW_OF_BOOK) {
      return (
        <>
          <OverlayTrigger
            placement="top"
            // prettier-ignore
            overlay={(
              <Tooltip id="tooltipChangeBook">
                <FormattedMessage id="navigator.changeBook" />
              </Tooltip>
            )}
          >
            <button
              type="button"
              className="btn btn-link division"
              onClick={this.activateNavigator.bind(this)}
            >
              <span className="caption">
                <FormattedMessage id="navigator.book" />
              </span>
              <span className="link">
                <FormattedMessage id={bookName} />
              </span>
            </button>
          </OverlayTrigger>

          {this.lightBox()}
        </>
      );
    }

    return (
      <OverlayTrigger
        key="overlay-trigger-chapter"
        placement="top"
        // prettier-ignore
        overlay={(
          <Tooltip id="tooltipChangeChapter">
            <FormattedMessage id="navigator.changeChapter" />
          </Tooltip>
        )}
      >
        <Dropdown key="overlay-trigger-dropdown-chapter">
          <Dropdown.Toggle id="dropdown-custom-components" as={CustomDropdownToggle}>
            <span className="caption">
              <FormattedMessage id="navigator.chapter" />
            </span>
            <span className="link">{chapter}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomDropdownMenu}>{this.dropdownItems(chapterTotal)}</Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>
    );
  } // end func
}

const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.navigator,
    verseModifiedMap: state.verseEditor.verseModifiedMap,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  searchBookFunc: (keyword: string): void => {
    dispatch(searchBook(keyword));
  },

  displayNavigatorFunc: (): void => {
    dispatch(displayNavigator());
  },

  closeNavigatorFunc: (): void => {
    dispatch(closeNavigator());
    dispatch(searchBook(''));
  },

  changeBookFunc: (bookId: string): void => {
    dispatch(changeBook(bookId));
    dispatch(searchBook(''));
  },

  changeChapterFunc: (chapter: number): void => {
    dispatch(changeChapter(chapter));
  },
});

const Navigator = connect(mapStateToProps, mapDispatchToProps)(NavigatorApp);

export default Navigator;
