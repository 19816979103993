import { REHYDRATE } from 'redux-persist';
import { CLEAR_ERROR_MSG } from './signin';
import { Translation, Resource } from '../shared/structs';

export const CHANGE_DATA = 'CHANGE_DATA';
export const CANCEL_FORM = 'CANCEL_FORM';
export const FORM_ERROR = 'FORM_ERROR';
export const PROJECT_LOADED = 'PROJECT_LOADED';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const SHOW_PROJECT_LIST = 'SHOW_PROJECT_LIST';
export const SHOW_PROJECT_CREATION = 'SHOW_PROJECT_CREATION';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';
export const SHOW_LIGHTBOX_REMOVING_PROJECT = 'SHOW_LIGHTBOX_REMOVING_PROJECT';
export const CLOSE_LIGHTBOX_REMOVING_PROJECT = 'CLOSE_LIGHTBOX_REMOVING_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';
export const SHOW_LIGHTBOX_EDITING_PROJECT = 'SHOW_LIGHTBOX_EDITING_PROJECT';
export const CLOSE_LIGHTBOX_EDITING_PROJECT = 'CLOSE_LIGHTBOX_EDITING_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';
export const FETCH_PROJECT = 'FETCH_PROJECT';
export const FETCH_PROJECT_REQUEST = 'FETCH_PROJECT_REQUEST';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';
export const SHOW_LIGHTBOX_MANAGE_PROJECT_USERS = 'SHOW_LIGHTBOX_MANAGE_PROJECT_USERS';
export const CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS = 'CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS';
export const SHOW_VIEW_OF_ADDING_PROJECT_USER = 'SHOW_VIEW_OF_ADDING_PROJECT_USER';
export const CANCEL_ADDING_PROJECT_USER = 'CANCEL_ADDING_PROJECT_USER';
export const SEARCH_PROJECT_USER_EMAIL = 'SEARCH_PROJECT_USER_EMAIL';
export const HANDLE_USER_EMAIL_CHANGE = 'HANDLE_USER_EMAIL_CHANGE';
export const HANDLE_USER_PERMISSION_CHANGE = 'HANDLE_USER_PERMISSION_CHANGE';
export const SAVE_PROJECT_USER = 'SAVE_PROJECT_USER';
export const SAVE_PROJECT_USER_REQUEST = 'SAVE_PROJECT_USER_REQUEST';
export const SAVE_PROJECT_USER_SUCCESS = 'SAVE_PROJECT_USER_SUCCESS';
export const SAVE_PROJECT_USER_FAILURE = 'SAVE_PROJECT_USER_FAILURE';
export const DELETE_PROJECT_USER = 'DELETE_PROJECT_USER';
export const DELETE_PROJECT_USER_REQUEST = 'DELETE_PROJECT_USER_REQUEST';
export const DELETE_PROJECT_USER_SUCCESS = 'DELETE_PROJECT_USER_SUCCESS';
export const DELETE_PROJECT_USER_FAILURE = 'DELETE_PROJECT_USER_FAILURE';

export interface LastThingWorkedOn {
  bookId: string;
  chapter: number;
  references: Translation[];
  resources: Resource[];
}

export interface ProjectUser {
  displayName: string;
  email: string;
  permission: string; // read, write
  addedAt: Date;
}

export type ProjectUsers = {
  [uid: string]: ProjectUser;
};

export interface ProjectData {
  projectName: string;
  beginningDate: string;
  targetCompletionDate: string;
  description: string;
  lastThingWorkedOn: LastThingWorkedOn;
  users: ProjectUsers;
}

interface ChangeDataAction {
  type: typeof CHANGE_DATA;
  key: string;
  value: string;
}

interface CancelFormAction {
  type: typeof CANCEL_FORM;
}

interface FormHasErrorAction {
  type: typeof FORM_ERROR;
  error?: string;
  message?: string;
}

interface CreateProjectAction {
  type: typeof CREATE_PROJECT;
  projectData: ProjectData;
}

interface CreateProjectRequestAction {
  type: typeof CREATE_PROJECT_REQUEST;
}

interface CreateProjectSuccessAction {
  type: typeof CREATE_PROJECT_SUCCESS;
  message?: string;
}

interface CreateProjectFailureAction {
  type: typeof CREATE_PROJECT_FAILURE;
  error: any;
}

interface FetchProjectsAction {
  type: typeof FETCH_PROJECTS;
}

interface FetchProjectsRequestAction {
  type: typeof FETCH_PROJECTS_REQUEST;
}

interface FetchProjectsSuccessAction {
  type: typeof FETCH_PROJECTS_SUCCESS;
  projectList?: any[];
  currentUid: string;
}

interface FetchProjectsFailureAction {
  type: typeof FETCH_PROJECTS_FAILURE;
  error: any;
}

interface ProjectLoadedAction {
  type: typeof PROJECT_LOADED;
}

interface ClearErrorAction {
  type: typeof CLEAR_ERROR_MSG;
}

interface ShowProjectListAction {
  type: typeof SHOW_PROJECT_LIST;
}

interface ShowProjectCreationAction {
  type: typeof SHOW_PROJECT_CREATION;
}

interface RehydrateAction {
  type: typeof REHYDRATE;
  payload: {
    project: {
      beginningDate: string;
    };
  };
}

interface CloseLightboxRemovingProjectAction {
  type: typeof CLOSE_LIGHTBOX_REMOVING_PROJECT;
}

interface ShowLightboxRemovingProjectAction {
  type: typeof SHOW_LIGHTBOX_REMOVING_PROJECT;
  projectId: string;
}

interface DeleteProjectAction {
  type: typeof DELETE_PROJECT;
}

interface DeleteProjectRequestAction {
  type: typeof DELETE_PROJECT_REQUEST;
}

interface DeleteProjectSuccessAction {
  type: typeof DELETE_PROJECT_SUCCESS;
  projectList?: any[];
}

interface DeleteProjectFailureAction {
  type: typeof DELETE_PROJECT_FAILURE;
  error: any;
}

interface CloseLightboxEditingProjectAction {
  type: typeof CLOSE_LIGHTBOX_EDITING_PROJECT;
}

interface ShowLightboxEditingProjectAction {
  type: typeof SHOW_LIGHTBOX_EDITING_PROJECT;
  projectId: string;
}

interface EditProjectAction {
  type: typeof EDIT_PROJECT;
}

interface EditProjectRequestAction {
  type: typeof EDIT_PROJECT_REQUEST;
}

interface EditProjectSuccessAction {
  type: typeof EDIT_PROJECT_SUCCESS;
  result?: any[];
}

interface EditProjectFailureAction {
  type: typeof EDIT_PROJECT_FAILURE;
  error: any;
}

interface FetchProjectAction {
  type: typeof FETCH_PROJECT;
}

interface FetchProjectRequestAction {
  type: typeof FETCH_PROJECT_REQUEST;
}

interface FetchProjectSuccessAction {
  type: typeof FETCH_PROJECT_SUCCESS;
  projectData: ProjectData;
  projectId: string;
  userIsProjectMember: boolean;
}

interface FetchProjectFailureAction {
  type: typeof FETCH_PROJECT_FAILURE;
  error: any;
}

interface ShowLightboxManageProjectUsersAction {
  type: typeof SHOW_LIGHTBOX_MANAGE_PROJECT_USERS;
  projectId: string;
}

interface CloseLightboxManageProjectUsersAction {
  type: typeof CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS;
}

interface ShowViewOfAddingProjectUserAction {
  type: typeof SHOW_VIEW_OF_ADDING_PROJECT_USER;
}

interface CancelAddingProjectUserAction {
  type: typeof CANCEL_ADDING_PROJECT_USER;
}

interface SearchProjectUserEmailAction {
  type: typeof SEARCH_PROJECT_USER_EMAIL;
}

interface HandleUserEmailChangeAction {
  type: typeof HANDLE_USER_EMAIL_CHANGE;
  email: string;
}

interface HandleUserPermissionChangeAction {
  type: typeof HANDLE_USER_PERMISSION_CHANGE;
  permission: string;
}

interface SaveProjectUserAction {
  type: typeof SAVE_PROJECT_USER;
  projectId: string;
  email: string;
  permission: string;
}

interface SaveProjectUserRequestAction {
  type: typeof SAVE_PROJECT_USER_REQUEST;
}

interface SaveProjectUserSuccessAction {
  type: typeof SAVE_PROJECT_USER_SUCCESS;
  result?: any[];
}

interface SaveProjectUserFailureAction {
  type: typeof SAVE_PROJECT_USER_FAILURE;
  error: any;
}

interface DeleteProjectUserAction {
  type: typeof DELETE_PROJECT_USER;
  targetUid: string;
}

interface DeleteProjectUserRequestAction {
  type: typeof DELETE_PROJECT_USER_REQUEST;
}

interface DeleteProjectUserSuccessAction {
  type: typeof DELETE_PROJECT_USER_SUCCESS;
  result?: any[];
}

interface DeleteProjectUserFailureAction {
  type: typeof DELETE_PROJECT_USER_FAILURE;
  error: any;
}

export type CreateProjectActionTypes =
  | ChangeDataAction
  | CancelFormAction
  | FormHasErrorAction
  | CreateProjectAction
  | CreateProjectRequestAction
  | CreateProjectSuccessAction
  | CreateProjectFailureAction
  | ProjectLoadedAction
  | ClearErrorAction
  | RehydrateAction
  | ShowProjectListAction
  | ShowProjectCreationAction
  | FetchProjectsAction
  | FetchProjectsRequestAction
  | FetchProjectsSuccessAction
  | FetchProjectsFailureAction
  | ShowLightboxRemovingProjectAction
  | CloseLightboxRemovingProjectAction
  | DeleteProjectAction
  | DeleteProjectRequestAction
  | DeleteProjectSuccessAction
  | DeleteProjectFailureAction
  | CloseLightboxEditingProjectAction
  | ShowLightboxEditingProjectAction
  | EditProjectAction
  | EditProjectRequestAction
  | EditProjectSuccessAction
  | EditProjectFailureAction
  | FetchProjectAction
  | FetchProjectRequestAction
  | FetchProjectSuccessAction
  | FetchProjectFailureAction
  | ShowLightboxManageProjectUsersAction
  | CloseLightboxManageProjectUsersAction
  | ShowViewOfAddingProjectUserAction
  | CancelAddingProjectUserAction
  | SearchProjectUserEmailAction
  | HandleUserEmailChangeAction
  | HandleUserPermissionChangeAction
  | SaveProjectUserAction
  | SaveProjectUserRequestAction
  | SaveProjectUserSuccessAction
  | SaveProjectUserFailureAction
  | DeleteProjectUserAction
  | DeleteProjectUserRequestAction
  | DeleteProjectUserSuccessAction
  | DeleteProjectUserFailureAction;

export interface ProjectState {
  loading: boolean;
  view?: string;
  isFetching?: boolean;
  redirectToProjectList?: boolean;
  error?: any;
  message?: string;
  errorProjectUser?: any;
  messageProjectUser?: string;
  projectName: string;
  beginningDate: string;
  targetCompletionDate: string;
  description: string;
  isFetchingList?: boolean;
  projectList?: any[] | undefined;
  showLightboxRemovingProjectVar?: boolean;
  removeProjectId?: string;
  deletingProject?: boolean;
  showLightboxEditingProjectVar?: boolean;
  closeLightboxEditingProjectFunc?: Function;
  showLightboxEditingProjectFunc?: Function;
  editProjectId?: string;
  editingProject?: boolean;
  editProjectFunc?: Function;
  isFetchingProject?: boolean;
  projectNameEditing?: string;
  beginningDateEditing?: string;
  targetCompletionDateEditing?: string;
  descriptionEditing?: string;
  editingError?: string;
  deletingError?: string;
  showLightboxManageProjectUsersFunc?: Function;
  closeLightboxManageProjectUsersFunc?: Function;
  showLightboxManageUsersVar?: boolean;
  projectIdManageUsers?: string;
  appState?: string;
  showViewOfAddingProjectUserFunc?: Function;
  cancelAddingProjectUserFunc?: Function;
  searchProjectUserEmailFunc?: Function;
  saveProjectUserFunc?: Function;
  userEmail?: string;
  userPermission?: string;
  currentUid?: string;
}

export interface ProjectProps {
  appState?: string;
  beginningDate?: string;
  beginningDateEditing?: string;
  cancelFrom?: any;
  currentUid?: string;
  deletingError?: string;
  deletingProject?: boolean;
  description?: string;
  descriptionEditing?: string;
  editingError?: string;
  editingProject?: boolean;
  editProjectId?: string;
  error?: any;
  errorProjectUser?: any;
  formHasError?: any;
  initialize?: any;
  isFetching?: boolean;
  isFetchingList?: boolean;
  isFetchingProject?: boolean;
  loaded?: any;
  loading: boolean;
  message?: string;
  messageProjectUser?: string;
  projectIdManageUsers?: string;
  projectList?: any[] | undefined;
  projectName?: string;
  projectNameEditing?: string;
  projectUsers?: ProjectUsers;
  redirectToProjectList?: boolean;
  removeProjectId?: string;
  showLightboxEditingProjectVar?: boolean;
  showLightboxManageUsersVar?: boolean;
  showLightboxRemovingProjectVar?: boolean;
  targetCompletionDate?: string;
  targetCompletionDateEditing?: string;
  updateFrom?: any;
  userEmail?: string;
  userPermission?: string;
  view?: string;

  cancelAddingProjectUserFunc: Function;
  changeViewFunc: Function;
  clearErrorAndMessageFunc: Function;
  closeLightboxEditingProjectFunc: Function;
  closeLightboxManageProjectUsersFunc: Function;
  closeLightboxRemovingProjectFunc: Function;
  createProjectFunc: Function;
  deleteProjectFunc: Function;
  deleteProjectUserFunc: Function;
  editProjectFunc: Function;
  fetchProjectFunc: Function;
  fetchProjectsFunc: Function;
  handleUserEmailChangeFunc: Function;
  handleUserPermissionChangeFunc: Function;
  saveProjectUserFunc: Function;
  searchProjectUserEmailFunc: Function;
  showLightboxEditingProjectFunc: Function;
  showLightboxManageProjectUsersFunc: Function;
  showLightboxRemovingProjectFunc: Function;
  ShowProjectCreationFunc: Function;
  showViewOfAddingProjectUserFunc: Function;
}
