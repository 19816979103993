import axios from 'axios';
import * as firebaseSdk from 'firebase';

import featureToggleDefaults from '../feature/featureToggleDefaults';

// Commonly used intervals for testing:
// 90000 (1.5 min)
// 3600000 (1 hr)
// 3600000 * 12 (12 hr, firebase default)
const remoteConfigSettings = { minimumFetchIntervalMillis: 3600000 };
const remoteConfigDefaults = featureToggleDefaults;

export default class FirebaseSdkProvider {
  private static initialized = false;

  private static appCount = 0;

  private static remoteConfig: any = null;

  public static async getSdk(): Promise<any> {
    if (!this.initialized) {
      await this.initializeSdk();
      await this.initializeRemoteConfig();
    }

    return firebaseSdk;
  }

  public static async initializeSdk(): Promise<any> {
    if (!firebaseSdk.apps.length && !this.initialized) {
      const response = await axios.get('/__/firebase/init.json');

      if (response.data) {
        // In case Firebase throws error if multiple apps initialized at the same time.
        // Error: `Firebase: Firebase App named '[DEFAULT]' already exists (app/duplicate-app).`
        if (this.appCount < 1) {
          this.appCount += 1;
          return firebaseSdk.initializeApp(response.data); // default
        }
        this.appCount += 1;
        const appName = Math.random()
          .toString(36)
          .substring(8);
        return firebaseSdk.initializeApp(response.data, appName); // random name
      }
    }
    return null;
  }

  public static async initializeRemoteConfig(): Promise<void> {
    this.remoteConfig = firebaseSdk.remoteConfig();
    this.remoteConfig.settings = remoteConfigSettings;
    this.remoteConfig.defaultConfig = remoteConfigDefaults;
    await this.remoteConfig.fetchAndActivate();
  }

  public static deinitializeSdk(): void {
    firebaseSdk.app().delete();
    this.initialized = false;
    this.appCount = 0;
  }

  public static getRemoteConfigValue(key: string): { source: string; _value: string } {
    return this.remoteConfig.getValue(key);
  }
}
