// React
import React from 'react';
import { render } from 'react-dom';

// Redux
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { transformer } from './transformer';
import rootReducer from './reducers';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';

// ABS Scripture Styles
import 'scripture-styles/dist/css/scripture-styles.css';

import FirebaseSdkProvider from './lib/firebase/firebaseSdkProvider';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { createAuthListener } from './actions/auth';

(async (): Promise<void> => {
  await FirebaseSdkProvider.initializeSdk();

  // config redux-persist
  const persistConfig = {
    key: 'root',
    storage,
    transforms: [transformer],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
  await createAuthListener(store.dispatch);

  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App locale="en" />
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
