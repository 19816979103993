export const LOCALE_CHANGED = 'LOCALE_CHANGED';
export default LOCALE_CHANGED;

export interface LocaleState {
  locale: string;
}

interface LocaleChangedAction {
  type: typeof LOCALE_CHANGED;
  locale: string;
}

interface UndefinedAction {
  type: typeof undefined;
}

export type LocaleActionTypes = LocaleChangedAction | UndefinedAction;
