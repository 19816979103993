import 'firebase/auth';

import FirebaseSdkProvider from './firebaseSdkProvider';
import Base from '../base';
import { FACEBOOK, User, USER_IS_NOT_SIGNED } from '../../types';

export default class FirebaseAuth extends Base {
  private provider: any;

  public async init(): Promise<any> {
    if (this.firebaseSdk === undefined) {
      this.firebaseSdk = await FirebaseSdkProvider.getSdk();

      this.provider = {
        google: new this.firebaseSdk.auth.GoogleAuthProvider(),
        facebook: new this.firebaseSdk.auth.FacebookAuthProvider(),
      };

      this.provider.google.addScope('https://www.googleapis.com/auth/contacts.readonly');
    }
    return this;
  }

  public auth(): any {
    return this.firebaseSdk.auth();
  }

  public async signinWithFederatedIdentityProviders(provider: string): Promise<User> {
    try {
      const authResult = await (await this.init()).auth();
      await authResult.signInWithPopup(
        provider === FACEBOOK ? this.provider.facebook : this.provider.google,
      );
      const currentUser = await (await this.init()).getCurrentSignedInUser();
      const userData = await (await this.init()).extractUserData(currentUser);
      return userData;
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        await (await this.init()).handleAccountExistsWithDifferentCredential(
          error.email,
          error.credential,
        );
        const currentUser = await (await this.init()).getCurrentSignedInUser();
        return (await this.init()).extractUserData(currentUser);
      }
      throw error;
    }
  }

  public async signinWithEmailProvider(emailAddr: string, password: string): Promise<any> {
    try {
      await this.firebaseSdk.auth().signInWithEmailAndPassword(emailAddr, password);
      const user = await (await this.init()).getCurrentSignedInUser();
      return this.extractUserData(user);
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        throw new Error('User does not exist.');
      } else {
        throw error;
      }
    }
  }

  public async signout(): Promise<void> {
    await this.firebaseSdk.auth().signOut();
  }

  public async signupWithEmailProvider(emailAddr: string, password: string): Promise<User> {
    await this.firebaseSdk.auth().createUserWithEmailAndPassword(emailAddr, password);
    const user = await (await this.init()).getCurrentSignedInUser();
    const updatedUser = this.extractUserData(user);
    try {
      await user.updateProfile({ displayName: updatedUser.displayName });
    } catch (error) {
      console.log(error);
    }
    return updatedUser;
  }

  public async handleAccountExistsWithDifferentCredential(
    email: string,
    pendingCred: any,
  ): Promise<any> {
    try {
      const firebaseResult = await this.auth();
      const methods = await firebaseResult.fetchSignInMethodsForEmail(email);
      const provider = methods[0] === 'google.com' ? this.provider.google : this.provider.facebook;

      const result = await firebaseResult.signInWithPopup(provider);

      if (result.user) {
        await result.user.linkAndRetrieveDataWithCredential(pendingCred);
      } else {
        throw new Error('Link account failed.');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public extractUserData(user: any): User {
    let { displayName } = user;
    if (!user.displayName) {
      displayName = this.extractDisplayNameFromEmail(user.email);
    }
    const data = {
      displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL: user.photoURL,
      isAnonymous: user.isAnonymous,
      uid: user.uid,
      providerData: user.providerData,
    };

    return data;
  }

  private extractDisplayNameFromEmail(email: string): string {
    const displayNameMatch = email.match(/^(.*)@.*$/);
    if (displayNameMatch && displayNameMatch[1]) {
      return displayNameMatch[1];
    }
    return '';
  }

  public async getCurrentSignedInUser(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.auth().onAuthStateChanged((user: any): any => {
        if (user) {
          resolve(this.auth().currentUser);
        } else {
          reject(Error(USER_IS_NOT_SIGNED));
        }
      });
    });
  }
}
