import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Spinner from '../spinner';
import {
  fetchAlignmentDataAction,
  dismissAlignment,
  verifyAlignmentAction,
  addLinkAction,
  clearLinkSelectionsAction,
  removeSelectedLinkAction,
  reverseAlignmentDisplayAction,
  uncheckCompleteBoxAction,
  updateVerseStatusAction,
  openEditorAction,
  fetchSuggestionAction,
} from '../../actions';
import { AlignmentProps, AlignmentState, DefaultAlignmentProps } from '../../types';
import { AppState } from '../../reducers';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import LinksContainer from './linksContainer';
import { SelectedTextSegment, TranslationLink } from '../../shared/structs';
import { VerseIdParser } from '../../shared/verseIdParser';

export class AlignmentComp extends React.Component<AlignmentProps, AlignmentState> {
  private verseIdParser: VerseIdParser = new VerseIdParser();

  public static defaultProps: AlignmentProps = DefaultAlignmentProps;

  private createLink(): void {
    if (this.hasSourceAndTargetSelection()) {
      const { source, target, verseCode, addLinkFunc, clearLinkSelectionsFunc } = this.props;
      addLinkFunc(verseCode, source, target);
      clearLinkSelectionsFunc();
    }
  }

  private removeLink(verseCode: string): void {
    const { linkSelected, removeSelectedLinkFunc } = this.props;
    if (linkSelected) {
      removeSelectedLinkFunc(verseCode);
    }
  }

  private handleKeyboard(event: any): void {
    if (event.key === ' ') {
      const { verseCode, linkSelected } = this.props;
      if (linkSelected) {
        this.removeLink(verseCode);
      } else if (this.hasSourceAndTargetSelection()) {
        this.createLink();
      }
    }
  }

  private hasSelection(): boolean {
    const { source, target } = this.props;
    return Boolean(source.length) || Boolean(target.length);
  }

  private hasSourceAndTargetSelection(): boolean {
    const { source, target } = this.props;
    return Boolean(source.length) && Boolean(target.length);
  }

  public instruction(): ReactElement {
    const { linkSelected } = this.props;
    let message: ReactElement = <FormattedMessage id="alignment.addOrRemove" />;
    if (this.hasSelection()) {
      message = <FormattedMessage id="alignment.selectAndAdd" />;
    }

    if (linkSelected) {
      message = <FormattedMessage id="alignment.removeOrClear" />;
    }
    return <span className="alignment-action message">{message}</span>;
  }

  public mode(): ReactElement {
    const {
      verseCode,
      linkSelected,
      clearLinkSelectionsFunc,
      reverseAlignmentDisplayFunc,
    } = this.props;
    let addClasses = 'alignment-action add disabled';
    let removeClasses = 'alignment-action remove disabled';
    let clearClasses = 'alignment-action clear disabled';

    if (this.hasSelection()) {
      clearClasses = 'alignment-action';
    }

    if (this.hasSourceAndTargetSelection()) {
      addClasses = 'alignment-action add';
    }

    if (linkSelected) {
      addClasses = 'alignment-action add disabled';
      removeClasses = 'alignment-action remove';
    }
    return (
      <span className="alignment-modal-mode">
        <OverlayTrigger
          key="tooltip-alignment-add"
          trigger="hover"
          placement="top"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-alignment-add">
              <FormattedMessage id="alignment.add" />
            </Tooltip>
        )}
        >
          <i
            tabIndex={0}
            aria-label="Add Link"
            role="button"
            className={`fas fa-link ${addClasses}`}
            onClick={(): void => {
              this.createLink();
            }}
            onKeyPress={(): void => {
              this.createLink();
            }}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="tooltip-alignment-delete"
          trigger="hover"
          placement="top"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-alignment-delete">
              <FormattedMessage id="alignment.delete" />
            </Tooltip>
        )}
        >
          <i
            tabIndex={0}
            aria-label="Remove Link"
            role="button"
            className={`fas fa-unlink ${removeClasses}`}
            onClick={(): void => {
              this.removeLink(verseCode);
            }}
            onKeyDown={(): void => {
              this.removeLink(verseCode);
            }}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="tooltip-alignment-clear"
          trigger="hover"
          placement="top"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-alignment-clear">
              <FormattedMessage id="alignment.clear" />
            </Tooltip>
        )}
        >
          <i
            tabIndex={0}
            aria-label="Clear Selection"
            role="button"
            className={`fas fa-recycle ${clearClasses}`}
            onClick={(): void => {
              clearLinkSelectionsFunc(verseCode);
            }}
            onKeyDown={(): void => {
              clearLinkSelectionsFunc();
            }}
          />
        </OverlayTrigger>
        <OverlayTrigger
          key="tooltip-alignment-reverse"
          trigger="hover"
          placement="top"
          // prettier-ignore
          overlay={(
            <Tooltip id="tooltip-alignment-reverse">
              <FormattedMessage id="alignment.reverse" />
            </Tooltip>
        )}
        >
          <i
            aria-label="Reverse Display"
            className="alignment-action reverse fas fa-exchange-alt fa-rotate-90"
            role="button"
            tabIndex={0}
            onKeyDown={(): void => {
              reverseAlignmentDisplayFunc();
            }}
            onClick={(): void => {
              reverseAlignmentDisplayFunc();
            }}
          />
        </OverlayTrigger>
      </span>
    );
  }

  public content(): ReactElement {
    const { alignmentData, verseCode } = this.props;
    const verseAlignmentData = alignmentData[verseCode];
    if (!Object.keys(alignmentData[verseCode]).length) {
      return (
        <>
          <FormattedMessage id="alignment.error" />
        </>
      );
    }
    if (
      verseAlignmentData === 'loading' ||
      !(verseAlignmentData.sourceText && verseAlignmentData.text)
    ) {
      return (
        <div key="alignment-data-spinner-container" className="alignment-spinner">
          <Spinner key="alignment-data-loading-spinner" />
        </div>
      );
    }
    return (
      <LinksContainer
        sourceText={verseAlignmentData.sourceManuscriptData}
        targetText={verseAlignmentData.textSegments}
        links={verseAlignmentData.links}
        verseCode={verseCode}
      />
    );
  }

  public reference(): ReactElement {
    const { verseCode } = this.props;
    if (verseCode) {
      const { book, ref } = this.verseIdParser.getReadableReferenceForGbiId(verseCode);
      const reference = ` ${ref}`;
      return (
        <>
          <FormattedMessage id={book} />
          {reference}
        </>
      );
    }
    return <></>;
  }

  public render(): ReactElement {
    const {
      isVisible,
      verseCode,
      alignmentData,
      verifyAlignmentFunc,
      closeAlignmentFunc,
      uncheckCompleteBoxFunc,
      openEditorFunc,
      fetchDataFunc,
      fetchSuggestionFunc,
      updateVerseStatusFunc,
    } = this.props;
    if (!alignmentData[verseCode]) {
      fetchDataFunc(getCurrentProjectId(), verseCode);
      return <Spinner />;
    }

    return (
      <div role="none" onKeyDown={this.handleKeyboard.bind(this)}>
        <Modal className="alignment-modal" show={isVisible} dialogClassName="modal-full">
          <Modal.Header closeButton={false}>
            <div className="header-container">
              <Modal.Title>
                <FormattedMessage id="checking" />
                :&nbsp;
                <div className="alignment-reference">{this.reference()}</div>
              </Modal.Title>
              {this.instruction()}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div key="alignment-modal-content" className="alignment-modal-content">
              {this.content()}
            </div>
          </Modal.Body>

          <Modal.Footer className="alignment-modal-footer">
            {this.mode()}

            <div className="verify">
              <OverlayTrigger
                key="tooltip-alignment-close"
                trigger="hover"
                placement="top"
                // prettier-ignore
                overlay={(
                  <Tooltip id="tooltip-alignment-close">
                    <FormattedMessage id="alignment.backToEditor" />
                  </Tooltip>
                )}
              >
                <i
                  tabIndex={0}
                  id="close-alignment"
                  aria-label="Close Alignment"
                  role="button"
                  className="fas fa-reply"
                  onClick={(): void => {
                    updateVerseStatusFunc(getCurrentProjectId(), verseCode, false);
                    closeAlignmentFunc();
                    uncheckCompleteBoxFunc(verseCode);
                    openEditorFunc(verseCode);
                    fetchSuggestionFunc(getCurrentProjectId(), verseCode, 'S1');
                  }}
                  onKeyDown={(): void => {
                    updateVerseStatusFunc(getCurrentProjectId(), verseCode, false);
                    closeAlignmentFunc();
                    uncheckCompleteBoxFunc(verseCode);
                    openEditorFunc(verseCode);
                    fetchSuggestionFunc(getCurrentProjectId(), verseCode, 'S1');
                  }}
                />
              </OverlayTrigger>

              <OverlayTrigger
                key="tooltip-alignment-verify"
                trigger="hover"
                placement="top"
                // prettier-ignore
                overlay={(
                  <Tooltip id="tooltip-alignment-verify">
                    <FormattedMessage id="alignment.verify" />
                  </Tooltip>
                )}
              >
                <i
                  tabIndex={0}
                  id="verify-alignment"
                  aria-label="Verify Alignment"
                  role="button"
                  className="fas fa-check"
                  onClick={(): void => {
                    verifyAlignmentFunc(
                      getCurrentProjectId(),
                      verseCode,
                      alignmentData[verseCode].links,
                    );
                  }}
                  onKeyDown={(): void => {
                    verifyAlignmentFunc(
                      getCurrentProjectId(),
                      verseCode,
                      alignmentData[verseCode].links,
                    );
                  }}
                />
              </OverlayTrigger>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  return {
    alignmentData: state.alignment.alignmentData,
    isVisible: state.verseEditor.triggerAlignmentFlag,
    verseCode: state.alignment.verseCode,
    source: state.alignment.source,
    target: state.alignment.target,
    linkSelected: state.alignment.linkSelected,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchDataFunc: (projectId: string, verseId: string): void => {
    dispatch(fetchAlignmentDataAction(projectId, verseId));
  },

  addLinkFunc: (
    verseCode: string,
    sources: SelectedTextSegment[],
    targets: SelectedTextSegment[],
  ): void => {
    const convertedSources = sources.map(source => {
      return Number(source.position);
    });
    const convertedTargets = targets.map(target => {
      return Number(target.position);
    });
    dispatch(addLinkAction(verseCode, convertedSources, convertedTargets));
  },

  clearLinkSelectionsFunc: (): void => {
    dispatch(clearLinkSelectionsAction());
  },

  removeSelectedLinkFunc: (verseCode: string): void => {
    dispatch(removeSelectedLinkAction(verseCode));
  },

  closeAlignmentFunc: (): void => {
    dispatch(dismissAlignment());
  },

  verifyAlignmentFunc: (projectId: string, verseId: string, links: TranslationLink): void => {
    dispatch(verifyAlignmentAction(projectId, verseId, links));
  },

  reverseAlignmentDisplayFunc: (): void => {
    dispatch(reverseAlignmentDisplayAction());
  },

  uncheckCompleteBoxFunc: (verseCode: string): void => {
    dispatch(uncheckCompleteBoxAction(verseCode));
  },

  openEditorFunc: (verseCode: string): void => {
    dispatch(openEditorAction(verseCode));
  },

  fetchSuggestionFunc: (projectId: string, textId: string, versification: string): void => {
    dispatch(fetchSuggestionAction(projectId, textId, versification));
  },

  updateVerseStatusFunc: (projectId: string, textId: string, complete: boolean): void => {
    dispatch(updateVerseStatusAction(projectId, textId, complete));
  },
});

const Alignment = connect(mapStateToProps, mapDispatchToProps)(AlignmentComp);

export default Alignment;
