import { ManuscriptData } from '../shared/structs';

export interface ManuscriptViewState {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
}

export interface ManuscriptViewProps {
  isOldTestament: boolean;
  manuscriptDataByVerse: Record<string, ManuscriptData[]>;
}

/* eslint import/prefer-default-export: "off" */
export const DefaultManuscriptViewProps = {
  isOldTestament: false,
  manuscriptDataByVerse: {},
};
