/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

const App = (): React.ReactElement => {
  return (
    <div className="container-fluid help-html">
      <div className="row">
        <div className="d-flex help-left">
          <div className="help-title">
            <ul>
              <li>
                <a href="#Freely_Offers">Welcome to YouTranslate.Bible</a>
              </li>
              <li>
                <a href="#Quick_Start">How to Quickly Start Using YouTranslate.Bible</a>
              </li>
              <li>
                <a href="#User_Account">How to Manage Your User Account</a>
                <ul>
                  <li>
                    <a href="#Sign_In">How to Sign In</a>
                  </li>
                  <li>
                    <a href="#Change_Profile">How to Change User Interface Language</a>
                  </li>
                  <li>
                    <a href="#Log_Out">How to Log Out</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#User_Projects">How to Manage Your Projects</a>
                <ul>
                  <li>
                    <a href="#Create_Projects">How to Create A New Project</a>
                  </li>
                  <li>
                    <a href="#Navigate_Projects">How to Go to Any of Your Translation Projects</a>
                  </li>
                  <li>
                    <a href="#Edit_Project_Info">How to Edit Project Info</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#Translate_Bible">How to Translate the Bible</a>
                <ul>
                  <li>
                    <a href="#Navigate_Book_or_Chapter">How to Go to Any Book or Chapter</a>
                  </li>
                  <li>
                    <a href="#Enter_Text">How to Enter Translation Text</a>
                  </li>
                  <li>
                    <a href="#Translation_Complete">How to Mark Translation Complete</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#Checking_Window">How to Do Checking</a>
                <ul>
                  <li>
                    <a href="#Add_Link">How to Add Links</a>
                  </li>
                  <li>
                    <a href="#Remove_Link">How to Remove Links</a>
                  </li>
                  <li>
                    <a href="#Clear_Selection">How to Clear Selections</a>
                  </li>
                  <li>
                    <a href="#Verify_Checking">How to Verify that Checking is Complete</a>
                  </li>
                  <li>
                    <a href="#Back_to_Editor">How to Exit Checking Without Saving</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#Auto_Suggestions">How to Use Auto Suggestions</a>
                <ul>
                  <li>
                    <a href="#Suggestions_Summary">How to See Top Suggestions Summary</a>
                  </li>
                  <li>
                    <a href="#Suggestion_Choices">How to See Suggestion Choices</a>
                  </li>
                  <li>
                    <a href="#Auto_Complete">How to Use Auto Complete</a>
                  </li>
                  <li>
                    <a href="#Live_Concordance">How to See Live Concordance</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#Auto_Linking">How Auto Linking Helps</a>
              </li>
              <li>
                <a href="#Find_References">How to Find Bible Translations as References</a>
              </li>
              <li>
                <a href="#Find_Resource">How to Find Bible Study Resources</a>
              </li>
              <li>
                <a href="#Find_Original_Text">How to Find More Original Text Information</a>
                <ul>
                  <li>
                    <a href="#By_Word">How to View Original Text Word by Word</a>
                  </li>
                  <li>
                    <a href="#By_Verse">How to View Full Chapter of Original Text </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#Syntax_View">How to Use the Syntax View</a>
              </li>
            </ul>
          </div>
        </div>

        <main className="d-flex help-right">
          <div className="help-doc">
            <h1 id="Freely_Offers">Welcome to YouTranslate.Bible</h1>
            <p>
              Welcome to <strong>YouTranslate.Bible (YTB</strong>). YTB freely provides:
            </p>
            <ul>
              <li>An easy-to-use online translation tool</li>
              <li>Help to understand the text of the Bible in its original languages</li>
              <li>Reference and training resources</li>
              <li>State-of-the-art computer technology to assist translators</li>
            </ul>

            <h1 id="Quick_Start">How to Quickly Start Using YouTranslate.Bible</h1>
            <p>
              At <a href="/">YouTranslate.Bible</a>, users can sign in and jump right into doing
              Bible translation quickly.
            </p>
            <h2 id="Quick_Start_Translation">Quick Start to Translating</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/La_494xyhiU">
                Quick Start to Translating
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              To get started quickly, you only need to follow three simple steps: (1){' '}
              <a href="#Sign_In">
                <strong>Sign in</strong>
              </a>
              ; (2){' '}
              <a href="#Create_Projects">
                <strong>Create a new project</strong>
              </a>{' '}
              (or{' '}
              <a href="#Navigate_Projects">
                <strong>choose an existing project</strong>
              </a>
              ); and (3){' '}
              <a href="#Enter_Text">
                <strong>Start</strong>
              </a>{' '}
              translating.
            </p>
            <h2 id="Quick_Start_References">Quick Start to Using References and Resources</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/lKlx0o-Ix74">
                Quick Start to Using References and Resources
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              When you first enter the Translation page, currently only the “
              <a href="#Enter_Text">
                <strong>Your Translation</strong>
              </a>
              ” panel is shown by default. While this panel is helpful, you will usually want to
              take advantage of additional references and resources. You can access these by
              clicking to expand three collapsed panels: (1) the “
              <a href="#Find_References">
                <strong>Translations of the Bible</strong>
              </a>
              ” panel on the right; (2) the “
              <a href="#Find_Resource">
                <strong>Resources for the Bible</strong>
              </a>
              ” panel on the bottom; and (3) the “
              <a href="#Find_Original_Text">
                <strong>Original Text of the Bible</strong>
              </a>
              ” panel on the left. The “Translations of the Bible” panel shows Bible translations in
              various languages as references and models for translation. The “Resources for the
              Bible” panel shows Bible Study resources that explain the meaning of the biblical
              text. The “Original Text of the Bible” panel shows additional information on the
              original Greek, Hebrew, and Aramaic Scriptures.
            </p>
            <p>
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1PNY4dJJwVIxDXlGhq9ZlwefU9Ft7BDkn"
                alt="All Panels"
              />
            </p>
            <h2 id="Quick_Start_Checking">Quick Start to Checking and Computer Assistance</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/ywNkKIdbtgE">
                How to Do Checking
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              If you{' '}
              <a href="#Checking_Window">
                <strong>check your work</strong>
              </a>
              , you can take full advantage of computer assistance with (1){' '}
              <a href="#Auto_Suggestions">
                <strong>Auto Suggestions</strong>
              </a>
              ; and (2){' '}
              <a href="#Auto_Linking">
                <strong>Auto Linking</strong>
              </a>
              .
            </p>

            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/eofG9ebrLE8">
                How to Use Auto Suggestions
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/crknFo6Kz7I">
                How Auto Linking Helps
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <h1 id="User_Account">How to Manage Your User Account</h1>
            <p>
              In the future, the only requirement to start using YouTranslate.Bible is to sign up
              for a free account. To sign up you will only need a valid e-mail account or an
              existing social media account. For example, you will be able to sign in using your
              Facebook account or your Google account.
            </p>
            <h2 id="Sign_In">How to Sign In</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/fW5FP4nr_sc">
                How to Sign in
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              When you are signed into your account, you gain access to your own translation
              projects. Click on the <strong>Sign in</strong> button on the top right to sign in:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=17Y07urmNekju6NnObJ5xN0Mo4hq7LZkT"
                alt="Sign In Button"
              />
            </p>
            <p>
              During this initial phase, you can only sign in to YTB if you have been invited to be
              a member of a pilot project. To sign in, please use the email and password you were
              previously assigned:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1x2EBZgTtOXl0B61qyeP_yW_lUNP_x_0v"
                alt="Temporary Sign in"
              />
              <br />
              At this time, you cannot sign in through social media or create a new account on your
              own. These features will be activated when YTB is released as a public Beta. For
              assistance with sign in or any other issues, please email us at
              help@youtranslate.bible.
            </p>

            <h2 id="Change_Profile">How to Change User Interface Language</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/C3WD39N0LUY">
                How to Change User Interface Language
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              The default user interface language is English. You can change the user interface
              language in your user Profile settings.
            </p>
            <p>
              When you sign in, your <strong>user name</strong> appears on the top right corner.
              Click on your user name to find the user <strong>Profile</strong> settings. Click on{' '}
              <strong>Profile</strong>:<br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=11e00OhVJ3fIHo7wu0tumZOID18rHxGW8"
                alt="Change Profile"
              />
            </p>
            <p>
              Then, click on the current language under the word “<strong>Language</strong>”:
              <br />
              <img src="/image/help/Language-Options.png" alt="Language Options" />
              <br />
              Scroll down and choose a language. Then, click on the <strong>Update</strong> button.
              The labels and instructions in the user interface changes to that language. Click on{' '}
              <a href="/project">Project</a> to go back to your projects.
            </p>
            <h2 id="Log_Out">How to Log Out</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/lttr2msMGN8">
                How to Log Out
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              When you sign in, your <strong>user name</strong> appears on the top right corner.
              Click on your user name to see the logout option:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1M5B0VCMpA6joW-xTldEkDMNoNBrXpfNh"
                alt="Log Out"
              />
              <br />
              Click on <strong>Logout</strong> to log out of YouTranslate.Bible.
            </p>
            <h1 id="User_Projects">How to Manage Your Projects</h1>
            <p>
              After you{' '}
              <a href="#Sign_In">
                <strong>sign in</strong>
              </a>
              , your projects appear. If you have not yet created a project, no projects appear:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1NhfCgZE7SDDD7ALmrzVxltY0o2HFbOZw"
                alt="No Projects"
              />
            </p>
            <p>
              You must create a project on the <a href="/project">Project</a> page to use the
              translation features of YouTranslate.Bible (YTB).
            </p>
            <h2 id="Create_Projects">How to Create A New Project</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/UrMaBxgZBMY">
                How to Create a New Project
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>To create a new project:</p>
            <ol>
              <li>
                Click on <strong>+ New Project</strong>.
              </li>
              <li>
                Enter a Project Name, Beginning Date, Target Completion Date, and a brief
                Description. (This info can be edited at any time. See{' '}
                <a href="#Edit_Project_Info">
                  <strong>Edit Project Info</strong>
                </a>
                .)
              </li>
              <li>
                Then click on the <strong>Create</strong> button:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=10QN6KVXN6HN_nDE1Hj4YocHkwz0CGef8"
                  alt="Enter Project Info"
                />
              </li>
            </ol>
            <p>
              After you create a project, it will appear in your Translation Projects list:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1GtDr5fUT9ujDl0aTVykR55tggnyM3Zkd"
                alt="View Project Info"
              />
            </p>
            <h2 id="Navigate_Projects">How to Go to Any of Your Translation Projects</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/ypNb_j6a9-4">
                How to Go to Any of Your Translation Projects
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              Go to your <strong>Translation Projects list</strong> first to go to any of your
              translation projects. To go to your Translation Projects list:
            </p>
            <ul>
              <li>
                <a href="#Sign_In">
                  <strong>Sign in</strong>
                </a>
                . When you sign into your account, by default you automatically go to your
                Translation Projects list.
              </li>
              <li>
                Or click on <a href="/project">Project</a> on the top right:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1B2JwdPb8m958uDzIh0cmtvAXH35O38vu"
                  alt="Project Button"
                />
              </li>
            </ul>
            <p>
              The example below shows a choice of two projects on the translation projects lists:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1GzBv0JFZ3RX1IW4bQKRx9i9bJvCDKl5I"
                alt="Project List"
              />
            </p>
            <p>To go to a different project when you are already translating in a project:</p>
            <ul>
              <li>
                Click on <a href="/project">Project</a> on the menu at the top of the page:{' '}
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1B2JwdPb8m958uDzIh0cmtvAXH35O38vu"
                  alt="Project Button"
                />
              </li>
              <li>
                Or click on the current project name to the left of the{' '}
                <a href="#Navigate_Book_or_Chapter">
                  <strong>navigation controls</strong>
                </a>
                .<br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=10DcvKZtjusUxvWVyLRyuGNzCN1LM5v1S"
                  alt="Project Name"
                />
              </li>
            </ul>
            <h2 id="Edit_Project_Info">How to Edit Project Info</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/9Cev0LsVpuo">
                How to Edit Project Info
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>To edit project info:</p>
            <ol>
              <li>
                Click on <a href="/project">Project</a>
              </li>
              <li>Find the project you want to edit</li>
              <li>
                Click on the <strong>Edit Project</strong> button:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1sGRpDaCLhZfG7Z2vt7BGcLu9DlomF790"
                  alt="Edit Project button"
                />
              </li>
              <li>
                Edit the Project Name, Beginning Date, Target Completion Date, or Description:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=17HnaJuO7L1mZt4ZhIwNpzBSRwkzHohLe"
                  alt="Edit Project Info"
                />
              </li>
              <li>
                Click on the <strong>Update</strong> button to update the info.
              </li>
              <li>
                The edited project info appears on the translation projects list:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1JqI5HwI6BrkjaxFLgIDoFijk4qbYkd0D"
                  alt="Updated Project Info"
                />
              </li>
            </ol>
            <h1 id="Translate_Bible">How to Translate the Bible</h1>
            <p>To start translating the Bible:</p>
            <ul>
              <li>
                You must first{' '}
                <a href="#Sign_In">
                  <strong>sign in</strong>
                </a>
                .
              </li>
              <li>
                After you sign in, your projects appear. (If you have not yet created a project, you
                must first{' '}
                <a href="#Create_Projects">
                  <strong>create a project</strong>
                </a>
                .)
              </li>
              <li>Find the project you want to work on.</li>
              <li>
                Click on the <strong>Translate Bible</strong> button.
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1Uz18AkHFosSDk69dXWzQ6Juf76ZLZlR5"
                  alt="Translate Bible Button"
                />
              </li>
            </ul>
            <p>
              After you click on the <strong>Translate Bible</strong> button for a project, the
              Translation page for that project appears. When the Translation page first appears,
              only the “<strong>Your Translation</strong>” panel is shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=18dRbtTuYDpkrn2LMqmvZFNve5VzAhBcy"
                alt="Main Translation Page"
              />
              <br />
              With a newly-created project, the “<strong>Your Translation</strong>” panel opens to
              Genesis chapter 1 by default. For an existing project, the panel opens to the chapter
              you worked on last.
            </p>
            <h2 id="Navigate_Book_or_Chapter">How to Go to Any Book or Chapter</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/8oUY-G7DBRM">
                How to Go to Any Book or Chapter
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              Use the <strong>navigation controls</strong> directly below the YouTranslate.Bible
              logo to go to any Bible book or chapter.
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1hJOvwFOZ1C5EDVNXrYJwsRNEqNVAkuPu"
                alt="Navigation Controls"
              />
            </p>
            <h3 id="Next_Chapter">How to Go to the Next Chapter</h3>
            <p>
              Click on the <strong>Next</strong> button to go to the next chapter
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1eEY6jpt3cCR7NcujFdSw_hmO1I4T5lgH"
                alt="Next Button"
              />
            </p>
            <h3 id="Previous_Chapter">How to Go to the Previous Chapter</h3>
            <p>
              Click on the <strong>Previous</strong> button to go to the previous chapter
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1sFCzIcL_fxWKKZsaQhB9KgA9PZN6t35_"
                alt="Previous Button"
              />
            </p>
            <h3 id="Any_Chapter">How to Go to Any Chapter</h3>
            <p>
              Click on the chapter number on the navigation controls. In the example below, click on
              the number “<strong>1</strong>” that follows the word “<strong>CHAPTER</strong>”:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=194KuV1MwlOdQWGXGaqff15Ksm7zv9T9l"
                alt="Change Chapter"
              />
            </p>
            <p>
              A list of the chapters for the current book appears:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1bV-_Z-KybTjrC3aSbxiDWnpcr0U-gqAT"
                alt="Chapter List"
              />
              <br />
              Click on the chapter number for the chapter you want.
            </p>
            <p>
              You can also type in part of or the full number in the Search Box to filter the list.
              For example, if you type “2” in the Search Box, you filter the list down to chapters
              that contain the number “2,” which include 2, 12, 20, 21, 22, etc.:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1X6en9u-aoEtGfgXUtIznogN97mzSnxrY"
                alt="Chapter Search"
              />
            </p>
            <h3 id="Any_Book">How to Go to Any Book</h3>
            <p>
              To see a list of the books of the Bible, click on the Bible book name on the
              navigation controls. In the example below, click on the Bible book name “
              <strong>Matthew</strong>” that follows the word “<strong>BOOK</strong>”:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1p-yVRpuP2I5iHDewA-A1w6DJblC42VxW"
                alt="Change Book"
              />
            </p>
            <p>
              After you click on the Bible book name, a list of the books of the Bible appears:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1A1F5WfyvQtdIml03s9bEa3BodN8-oC9j"
                alt="Book List"
              />
              <br />
              Click on the book you want.
            </p>
            <p>
              You can also type in part of or the full book name in the Search Box to filter the
              list. For example, if you type “jo” in the Search Box, you filter the list down to
              books that contain the letters “jo”, which include Joshua, Joel, John, 1 John, etc.:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1PTAQOw2FR8QEEfT2wpSkdVDZF7nJe20A"
                alt="Book Search"
              />
              <br />
              Click on the book you want.
            </p>
            <h2 id="Enter_Text">How to Enter Translation Text</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/ZWWiqRyuTFY">
                How to Enter Translation Text
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              After{' '}
              <a href="#Navigate_Book_or_Chapter">
                <strong>going to the book and chapter you want</strong>
              </a>
              , simply click on the verse you want to work on. You can click anywhere in the column
              of the verse.
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1nuN6fqVZOHLem1eRnHB8qnBXlHoPF7Rs"
                alt="Translation Panel"
              />
              <br />
              By default the “<strong>Your Translation</strong>” panel currently allows you to work
              verse by verse only. In the future, you will be able to work on different chunks of
              texts (whether by using pre-defined templates or defining your own chunks).
            </p>
            <p>
              A vertical line will appear to the left of the number of the column you are on. Click
              when the line appears beside the number you want:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1R8DQF5LkQdG9r5zCIhKmV7C_YJGC20cn"
                alt="Line On Verse"
              />
            </p>
            <p>
              When you click on a verse, the original text for the verse, some help to understand
              the original text, and a text box appear. For example, after clicking on verse 1, you
              see the original Greek text, some help to understand the meaning of the Greek words,
              and a text box where you can enter your translation:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1telZ0z13IsTDws5gtVfEs40YOVHj6n4X"
                alt="Translate Verse"
              />
              <br />
              The help with understanding the meaning of the Greek words is in English in the
              screenshot above. In the future, the help with word meanings can be swapped for any
              language of wider communication that you understand.
            </p>
            <h3 id="Auto_Save">How to Save Your Work</h3>
            <p>To automatically save your work:</p>
            <ul>
              <li>
                It is strongly recommended that you click on the{' '}
                <strong>Translation Complete</strong> indicator for the current verse:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=15x6GqFalOPe8df0XifLt1OEyxJ_ThJys"
                  alt="Translation Complete Indicator"
                />
                <br />
                Besides saving your work, you will be asked to check your work. See further the
                section on{' '}
                <a href="#Checking_Window">
                  <strong>how to do checking</strong>
                </a>
                .
              </li>
              <li>
                Alternatively, you can click on <strong>X</strong> on the top right corner to{' '}
                <strong>close</strong> the text box for the current verse:
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1ihZ4FASYw_NjQAcTflRgYKwYJCp4gmgt"
                  alt="Close Text Box"
                />
                <br />
                If you choose this option, you will not be asked to check your work.
              </li>
            </ul>
            <h3 id="Discard_Changes">How to Discard Changes</h3>
            <p>
              To <strong>discard</strong> changes in the verse you are currently on without saving
              it:
            </p>
            <ul>
              <li>Close your Web Browser, or</li>
              <li>
                <a href="#Log_Out">
                  <strong>Log out</strong>
                </a>{' '}
                , or
              </li>
              <li>
                Click on any of the{' '}
                <a href="#Navigate_Book_or_Chapter">
                  <strong>navigation controls</strong>
                </a>
                .
              </li>
            </ul>
            <p>
              When you click to go to another Bible book or chapter without first saving, a warning
              appears:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1jdYuq8-iCuWjvfV3qni5XHk3miNooysB"
                alt="Not Saved Warning"
              />
              <br />
              If you want to go elsewhere without saving, click on the <strong>OK</strong> button.
              If not, click on the Cancel button and{' '}
              <a href="#Auto_Save">
                <strong>save</strong>
              </a>{' '}
              your work first.
            </p>
            <h3 id="Draft_Quickly">How to Draft Quickly</h3>
            <p>
              When you just want to quickly translate some verses, you can simply translate one
              verse after another:
            </p>
            <ol>
              <li>Click on a column for the verse you want to translate.</li>
              <li>Type in a quick translation for the verse.</li>
              <li>
                Ignore the Translation Complete indicator and simply click on the column for the
                next verse.
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1_kCM3kwlVp51Edq2CcLsv5JZ-vbsx1hT"
                  alt="Click Another Verse"
                />
              </li>
              <li>Type in a quick translation for the verse.</li>
              <li>
                Repeat the process.
                <br />
                <img
                  src="https://drive.google.com/uc?export=view&amp;id=1NMYSQN6V3YKLg17z5rXv9uD9H4rqK2vG"
                  alt="Quick Drafting"
                />
              </li>
            </ol>
            <h2 id="Translation_Complete">How to Mark Translation Complete</h2>
            <p>
              When you complete your translation of a verse, we recommend that you click on the{' '}
              <strong>Translation Complete</strong> indicator:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=15x6GqFalOPe8df0XifLt1OEyxJ_ThJys"
                alt="Translation Complete Indicator"
              />
              <br />
              When you click on <strong>Translation Complete</strong>, YTB assumes two things: (1)
              You want to the system to save your work; and (2) you are ready to{' '}
              <a href="#Checking_Window">
                <strong>check your work</strong>
              </a>
              .
            </p>
            <h1 id="Checking_Window">How to Do Checking</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/ywNkKIdbtgE">
                How to Do Checking
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              When you click on the <strong>Translation Complete</strong> indicator, the checking
              window appears. Our recommendation is that you always check your work immediately
              after entering your translation. The default checking view has the original text on
              top and the translation text on the bottom:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1dUHO8-J1-cUpjHRH7P4GD5khVMlOC73a"
                alt="Checking Page"
              />
            </p>
            <p>
              To reverse the display of the original text and the translation text, click on the{' '}
              <strong>Reverse Display</strong> icon:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1jBeOrpKeCRp-CggRMV6YcwpzNwtMUpB6"
                alt="Reversed Checking Page"
              />
            </p>
            <p>
              In both views, the main content words in the original text, e.g., the verbs, nouns,
              and adjectives, are highlighted to make checking easier. You only have to check if
              your translation has accounted for these main content words. In John 1:1, only 9 out
              of 17 Greek words are highlighted for checking. Check to see if your translation has
              words or phrases that correspond to each main content word.
            </p>
            <h2 id="Add_Link">How to Add Links</h2>
            <p>
              If you see correspondence between any words, add links between them. The first step is
              to select the words that correspond. Tap on your touchscreen or click on the left
              button of your mouse on the words to select. Below the Greek word explained as meaning
              “[the] beginning” and the translation word “beginning” are selected:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1bSihmb5gdPb-q8rDuLElUMOwEarsZASF"
                alt="Selecting Words"
              />
            </p>
            <p>
              To add the link, click on the <strong>Add Link</strong> icon or press the spacebar on
              your keyboard. A line will appear to link the words selected, as shown below:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1mozfOHqghSh8J3Td8bSk-Yl8ZUlPPiQ5"
                alt="Adding Links"
              />
            </p>
            <p>
              Links can be one to one, one to many, or many to many. The process is the same no
              matter how many words are linked to however many words. To select multiple words,
              simply select all applicable words before you add the link. In the example below one
              word in the Greek (“God”) and two words in the translation (“fully God”) are selected:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1zxRdojTV7-oweH6-ifg7x7EPSfDd2RDU"
                alt="Selecting Multiple Words"
              />
            </p>
            <p>
              To add the link, click on the <strong>Add Link</strong> icon or press the spacebar on
              your keyboard. A line will appear to link the words selected, as shown below:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1xpDgjmUhFPpY1XRuQ5kDv7PmlhY9hJ0o"
                alt="Linking Multiple Words"
              />
              <br />
              The only difference is that one-to-many and many-to-many links are highlighted in
              color to distinguish them from one-to-one links. (When there are multiple one-to-many
              and many-to-many links, each set of links will be in different colors. The colors do
              not have any special meaning other than making it easier to distinguish the different
              sets of links.)
            </p>
            <h2 id="Remove_Link">How to Remove Links</h2>
            <p>
              If you change your mind about a link you added, the first step is to select the link
              to remove. Click anywhere on the line that links the words involved to select it:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1eEW48I2M5julHg68-v8YL2EuZmsY7yOM"
                alt="Selecting Links"
              />
            </p>
            <p>
              To remove the link, click on the <strong>Remove Link</strong> icon or press the
              spacebar on your keyboard. The line linking the words will disappear and the words
              will be deselected, as shown below:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1xV7HBI_TKwwVnrnxYxQNzSNxKkU9-dcp"
                alt="Removing Links"
              />
            </p>
            <h2 id="Clear_Selection">How to Clear Selections</h2>
            <p>
              If you change your mind about words or links selected, you can deselect them
              individually with your touchscreen or mouse. A faster way to deselect multiple items
              is to click on the <strong>Clear Selection</strong> icon:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1LuvF4Qbv9oriJtI7BAsgJSE_j-K6Dzwm"
                alt="Clearing Selection"
              />
              <br />
              When you click on the <strong>Clear Selection</strong> icon, all the words or links
              you have selected will be deselected. You are then free to select other words or
              links.
            </p>
            <h2 id="Verify_Checking">How to Verify that Checking is Complete</h2>
            <p>
              When you have added links for all main content words in the original text, click on
              the <strong>Verify</strong> icon:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=16MLl1atLygIUOHnqQLgbjFLqx4kQJ8Av"
                alt="Verify Checking"
              />
              <br />
              When you click on the <strong>Verify</strong> icon, you verify that checking is
              complete.
            </p>
            <p>
              A check mark appears to the right of the verse as a visual reminder that it has been
              completed:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1GqefImBSWh0hEsQuA73NEcxzzcSRSPmP"
                alt="Check Mark"
              />
              <br />
              This check mark only appears if you have clicked on both{' '}
              <a href="#Translation_Complete">
                <strong>Translation Complete</strong>
              </a>{' '}
              and the{' '}
              <a href="#Verify_Checking">
                <strong>Verify</strong>
              </a>{' '}
              icon.
            </p>
            <h2 id="Back_to_Editor">How to Exit Checking Without Saving</h2>
            <p>
              During checking you may discover the need to edit your translation text. Click on the{' '}
              <strong>Back to Editor</strong> icon to abandon all the links and go back to editing
              in the Editor:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1FMiEQfYUI6RvE5r0FcyosabvIB-xHwyi"
                alt="Back to Editor"
              />
            </p>
            <p>
              The links are not saved, but the translation text (saved when you previously clicked
              on Translation Complete) is shown again. However, there is no check mark and
              Translation Complete is off in the Verse Editor:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1hnEFh3qrO6GG-4MzfM4FY0EoDtNdGqL4"
                alt="No Check Mark"
              />
            </p>
            <p>
              Once back in the Editor, you can edit your translation text . To enter the checking
              window again, click on <strong>Translation Complete</strong>.
            </p>
            <h1 id="Auto_Suggestions">How to Use Auto Suggestions</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/eofG9ebrLE8">
                How to Use Auto Suggestions
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              Besides double-checking your work,{' '}
              <a href="#Add_Link">
                <strong>adding links</strong>
              </a>{' '}
              provides a second benefit. The system remembers the links you make between words and
              automatically suggests that you reuse your previous work.
            </p>
            <p>
              In the example below, the system is able to make some suggestions for John 1:2 on the
              basis of previous work on John 1:1. The so-called “auto suggestions” are shown in 3
              ways.
            </p>
            <h2 id="Suggestions_Summary">How to See Top Suggestions Summary</h2>
            <p>
              First, you see the words “was,” “beginning,” and “fully God” listed in summary form
              after the label “Suggestions”:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1ANYfbQx1gdaJSqtKjmi4V4bWiDuasvIP"
                alt="Top Suggestions Summary"
              />
              <br />
              This first view provides a quick summary of top suggestions available for John 1:2 on
              the basis of previous work on John 1:1.
            </p>
            <h2 id="Suggestion_Choices">How to See Suggestion Choices</h2>
            <p>
              Second, downward arrows appear under the words “was,” “[the] beginning,” and “God.”
              When you click on a downward arrow, suggestion choices are listed. For example, when
              you click on “was,” it shows “existed” and “was” as suggestion choices:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1z4XpYrLlbj4thAZS2hbZSwNRPN2rjWwu"
                alt="Suggestion Choices"
              />
              <br />
              This second view allows you to explore suggestion choices.
            </p>
            <h2 id="Auto_Complete">How to Use Auto Complete</h2>
            <p>
              Third, as you type, the system suggests words to auto complete your typing. This third
              view allows you to type more efficiently. In the example below, the system suggests
              “God” to auto complete when you type “G” in John 1:2:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1wIwrTRCqxPmv_nCltflDtw_Q1Imm2K-z"
                alt="Auto Suggestion"
              />
              <br />
              To auto complete your typing, select a suggestion with your touchscreen, mouse, or
              Enter key.
            </p>
            <h2 id="Live_Concordance">How to See Live Concordance</h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/_wNOuzENh6g">
                How to See Live Concordance
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              The auto suggestion of “God” in John 1:2 is based on previous translation of the same
              Greek word as “God” in John 1:1. When you hover your mouse over the suggested word
              “God,” a live concordance is shown. It confirms that the suggestion came from the
              previous translation of this Greek word as “God” in John 1:1:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1lQWaJgSMgD-usiHMWnes3dj7ns6n7sGI"
                alt="Live Concordance"
              />
            </p>
            <p>
              The more of your past work you have previously linked, the more auto suggestions the
              system is able to make. After you have already worked on several chapters, you will
              receive helpful auto suggestions more often than not.
            </p>
            <p>
              Auto suggestion improves as you go. However, you must always carefully choose when and
              whether to use any suggestions the system makes.
            </p>
            <h1 id="Auto_Linking">How Auto Linking Helps</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/crknFo6Kz7I">
                How Auto Linking Helps
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              Auto linking and auto suggestion both work on the basis of the system remembering your
              previously linked work. The more of your past work you have previously linked, the
              more of your future work will be automatically linked. After you have already worked
              on several chapters, you will receive helpful auto linking more often than not.
            </p>
            <p>
              For example, if you use the auto suggestions for John 1:2 (based on your previous work
              on John 1:1), the main content words in John 1:2 will be automatically linked:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1Wwg5tCpY-JjwUNNNQFEiBGUNRZOtsRGB"
                alt="Auto Linking"
              />
              <br />
              In this case, the main content words in John 1:1 happen to all occur again in John
              1:2. In most cases, you will need to work on more than one verse to receive so much
              help. For a review on auto suggestions, see the section on{' '}
              <a href="#Auto_Suggestions">
                <strong>How to Use Auto Suggestions</strong>
              </a>
              .
            </p>
            <p>
              Like auto suggestion, auto linking improves as you go. However, it always requires
              human checking. For maximum benefit, you need to consistently{' '}
              <a href="#Remove_Link">
                <strong>remove any unhelpful links</strong>
              </a>{' '}
              and{' '}
              <a href="#Add_Link">
                <strong>add any helpful links</strong>
              </a>{' '}
              the system has not yet learned,
            </p>
            <h1 id="Find_References">How to Find Bible Translations as References</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/QWAR-auzQYY">
                How to Find Bible Translations as References
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              On the <strong>Translation page</strong>, move your cursor over to the collapsed right
              panel with a book icon. A pop-up that says “<strong>Translations of the Bible</strong>
              ” will show when you can select the panel:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1629n3jTqU98Yl_J_mCI9XJrz78cs5tKj"
                alt="Collapsed Reference Panel"
              />
            </p>
            <p>
              Click anywhere within the highlighted panel area to expand the panel:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=17Mb6UBnKkJKeuQwixJh8yiF42QBfkgc2"
                alt="Add Reference"
              />
            </p>
            <p>
              Click on <strong>+ Add a Bible Translation</strong> to see a list of available Bibles.
              Many Bibles in various languages are available as references to help you translate the
              Bible into your native language. We will continually add many more.
            </p>
            <p>
              Scroll down the panel to find Bibles in languages you can understand:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1uMySgRrVm8I3uOJ8IpHtdKMVPtcQr7bh"
                alt="Scroll Reference"
              />
            </p>
            <p>
              Click on a Bible to use it as a reference. The text for the current active chapter of
              the Bible appears in the panel. In the example below, the text of the English Standard
              Version appears:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1mdS7Gcf-_s8qLr5CHYkazlF6HBloLscc"
                alt="ESV Reference"
              />
            </p>
            <p>
              You can also type in part of or the full name of a Bible in the Search Box to filter
              the list:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1cU5qCJ2-Euzcpv2v10ncYD4eX4T-DScH"
                alt="Search Reference List"
              />
            </p>
            <p>
              Click on a Bible to use it as a reference. In the example below, the text of the NET
              Bible appears under the text of the English Standard Version (which was previously
              clicked above):
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1PF_s2On7NVoFLK8oQQXsjVtEABZBIhz_"
                alt="NET Reference"
              />
              <br />
              You can view multiple Bibles as references. The only limit is how much screen space
              you have to view them.
            </p>
            <h1 id="Find_Resource">How to Find Bible Study Resources</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/AhLijGI23yo">
                How to Find Bible Study Resources
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              On the <strong>Translation page</strong>, move your cursor over to the collapsed
              bottom panel with a reader icon. A pop-up that says “
              <strong>Resources for the Bible</strong>” will show when you can select the panel:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1GZ3FyJF5qZkgTG2aOdv4h3XKwQXMgqy7"
                alt="Collapsed Resource Panel"
              />
            </p>
            <p>
              Click anywhere within the highlighted panel area to expand the panel:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=17RBuqbVJkr4lEid1ywGtm0vkyqkM54MN"
                alt="Add Resource"
              />
              <br />
              Click on <strong>+ Add a Biblical Resource</strong> to see a list of available
              resources. We will continually add more.
            </p>
            <p>
              Scroll down the panel to find Bible Study resources in languages you can understand:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1J8b9m-L1JnRIiR9nvE1dT5VDWQX5WbqM"
                alt="Scroll Resource List"
              />
            </p>
            <p>
              Click on a resource to use it as a reference. The text of the resource that is related
              to the current active chapter of the Bible (where available) appears in the panel. The
              text of the NET Bible Notes appears below:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1yp8yv-W6eeeW9wubBc0_vSyH7dz41xo4"
                alt="NET Bible Notes"
              />
            </p>
            <p>
              You can also type in part of or the full name of a resource in the Search Box to
              filter the list:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1tmK_2M7IcQd0Vg1sf53X7OzxxVItZ8Hx"
                alt="Search Resource"
              />
              <br />
              Click on a resource to use it as a reference.
            </p>
            <p>
              Because space is limited you can currently view only two resources at the same time as
              references:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1Th4Za_ekEKAUeVeE-PxUULbBdzVnz3l1"
                alt="Two Resource Limit"
              />
            </p>
            <h1 id="Find_Original_Text">How to Find More Original Text Information</h1>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/v9CLKpLI5bQ">
                How to View Original Text Word by Word
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              On the <strong>Translation page</strong>, move your cursor over to the collapsed left
              panel with a manuscript icon. A pop-up that says “
              <strong>Original Text of the Bible</strong>” will show when you can select the panel:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1s1e8fSqrAl4W42YMe9aCFmZm2m--OQAc"
                alt="Collapsed Manuscript Panel"
              />
              <br />
              Click anywhere within the highlighted panel area to expand the panel.
            </p>
            <h2 id="By_Word">How to View Original Text Word by Word</h2>
            <p>
              The default view shows the original text word by word in vertical format:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1FcaYppjV8_pV79FJAKLpsxchQ4aoAxSv"
                alt="By Word"
              />
            </p>
            <p>
              If you hover over a word in this view, more dictionary explanation for the word is
              shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1QKhrXGjdNp40p74wId7HcvOP1efnj8UB"
                alt="By Word Lexicon"
              />
            </p>
            <h2 id="By_Verse">How to View Full Chapter of Original Text </h2>
            <p>
              Click on this{' '}
              <a target="_blank" href="https://youtu.be/6qmZuNS-clI">
                How to View Full Chapter of Original Text
              </a>{' '}
              link to see closed-captioned video instructions on YouTube.
            </p>
            <p>
              To view the full chapter of the original text, click on the By Verse tab. When you are
              translating in the New Testament, the original Greek text is shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1gua0DeScM7fJQACIK_Dx8lgMWlyCzK7V"
                alt="Manuscript Panel Greek"
              />
              <br />
              The Greek text shown is from the latest version of the Nestle Aland Greek New
              Testament (Novum Testamentum Graece, 28th revised edition, Edited by Barbara Aland and
              others, © 2012 Deutsche Bibelgesellschaft, Stuttgart).
            </p>
            <p>
              If you hover over a Greek word in this view, more dictionary explanation for the word
              is shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1InVbwv2kp53r5QrMtFFwNI3SflP1Kwnm"
                alt="Manuscript Greek Lexicon"
              />
            </p>
            <p>
              When you are translating in the Old Testament, the original Hebrew and Aramaic text is
              shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1jg3mN-7DVaO8rjNxIAnIhlohPdSBjxwz"
                alt="Manuscript Panel Hebrew"
              />
              <br />
              The Hebrew text shown is from the latest version of the Westminster Leningrad Codex of
              the Hebrew Bible (version 4.20, Public Domain).
            </p>
            <p>
              If you hover over a Hebrew word in this view, more dictionary explanation for the word
              is shown:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1xFF2JWU7yF0jfu83noY06HMBtSb58YiB"
                alt="Manuscript Hebrew Lexicon"
              />
            </p>
            <h1 id="Syntax_View">How to Use the Syntax View</h1>
            <p>
              Click on the <strong>Syntax View</strong> icon on the top right corner of the “
              <strong>Your Translation</strong>” panel to view the syntax of the original text:
              <br />
              <img
                src="https://drive.google.com/uc?export=view&amp;id=1AtkjZJG5HF6nncUULJ-kaMifKYoCzan8"
                alt="Syntax View Icon"
              />
            </p>
            <p>
              [Note: Changes are still being made to the new syntax view. So, the documentation for
              this section is still pending.]
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;
