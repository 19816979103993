import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Spinner from '../spinner';
import VerseEditorContainer from './verseEditorContainer';
import { AppState } from '../../reducers';
import { TranslationProps, TranslationState, DefaultTranslationProps } from '../../types';

export class TranslationComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: TranslationProps) {
    super(props);
  }

  public componentDidMount(): void {
    this.enableSynchronousScroll();
  }

  public enableSynchronousScroll(): void {
    const catchVerse = (): void => {
      const verseNumber = this.computeClosestVerse();
      const verseIndex = verseNumber - 1;
      this.scrollView(verseIndex, '.manuscript-card', '.manuscript-list');
      this.scrollView(verseIndex, '.morphology-card', '.morphology-list');
    };

    const el = document.getElementById('translation-list');
    if (el) {
      el.addEventListener('scroll', (): void => {
        catchVerse();
      });
    }
  }

  public computeClosestVerse(): number {
    const el = document.getElementById('translation-list');
    if (el) {
      const ancestorPosition = el.getBoundingClientRect();

      const list = document.querySelector('.verse-list');
      if (list) {
        const items = Array.prototype.slice.call(list.children);
        for (let index = 0; index < items.length; index += 1) {
          const item = items[index];
          const myPosition = item.getBoundingClientRect();
          const relativeTop = myPosition.top - ancestorPosition.top + myPosition.height / 2;
          if (relativeTop > 0) {
            return item.dataset.id;
          }
        } // end for
      } // end if
    }

    return 0;
  }

  public scrollView(verseIndex: number, containerSelector: string, parentSelector: string): void {
    const bias = 15;
    const container = document.querySelector(containerSelector);
    const parent = document.querySelector(parentSelector);

    if (parent && parent.children) {
      const child = parent.children[verseIndex];

      if (child instanceof HTMLElement) {
        const childPos = child.getBoundingClientRect();

        if (container) {
          const scrollTop = child.offsetTop - childPos.height - bias;

          container.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
          });
        }
      }
    }
  }

  private verseList(): ReactElement | null {
    const { selectedBookId, selectedChapter, cardData } = this.props;
    const manuscripts = cardData.get(0);

    return (
      <>
        <ul className="list-group list-group-flush verse-list" key="verse-list">
          {((): ReactElement[] | null => {
            if (manuscripts && Object.prototype.hasOwnProperty.call(manuscripts, 'data')) {
              const stack: ReactElement[] = [];
              Object.keys(manuscripts.data.manuscriptDataByVerse).forEach((textId: string) => {
                const verseIdNum = Number(textId.substring(5));
                stack.push(
                  <li
                    className="list-group-item"
                    key={`verse-li-${verseIdNum}`}
                    data-id={verseIdNum}
                  >
                    <VerseEditorContainer
                      bookId={selectedBookId}
                      chapter={selectedChapter}
                      textId={textId}
                      verseManuscriptData={manuscripts.data.manuscriptDataByVerse[textId]}
                      syntaxGroupData={manuscripts.data.syntaxGroupDataByVerse[textId]}
                      verseId={verseIdNum}
                      key={`com-VerseEditor-${verseIdNum}`}
                      verses={[]}
                    />
                  </li>,
                );
              });

              return stack;
            }

            return null;
          })()}
        </ul>
      </>
    );
  }

  public render(): ReactElement {
    const { isFetchingData, loadingVerses } = this.props;

    return (
      <>
        <div className="translation-panel panel bar-top">
          <div className="card">
            <div className="card-header">
              <FormattedMessage id="Your Translation" />
            </div>

            <div className="translation-list" id="translation-list">
              {isFetchingData || loadingVerses ? <Spinner /> : this.verseList()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
    verseCompleteMap: state.verseEditor.verseCompleteMap,
  };

  return props;
};

const Translation = connect(mapStateToProps, () => {
  return {};
})(TranslationComp);
export default Translation;
