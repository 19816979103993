import { LocaleState, LocaleActionTypes, LOCALE_CHANGED } from '../types';

const INIT_STAT: LocaleState = {
  locale: 'en',
};

const Locale = (state = INIT_STAT, action: LocaleActionTypes): any => {
  switch (action.type) {
    case LOCALE_CHANGED:
      return { ...state, locale: action.locale };

    default:
      return state;
  }
};

export default Locale;
