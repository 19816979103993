import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { AlignmentProps, AlignmentState, DefaultAlignmentProps } from '../../types';
import LinkLines from './linkLines';
import TextSegment from './textSegment';
import { AppState } from '../../reducers';
import { reverseAlignmentDisplayAction } from '../../actions';

/* eslint react/prefer-stateless-function: "off" */
export class LinksContainerComp extends React.Component<AlignmentProps, AlignmentState> {
  private refDict: Map<string, any>;

  public static defaultProps: AlignmentProps = DefaultAlignmentProps;

  public constructor(props: AlignmentProps) {
    super(props);
    this.refDict = new Map();
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.reRender.bind(this));
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.reRender.bind(this));
  }

  private reRender(): void {
    const { reverseAlignmentDisplayFunc } = this.props;
    reverseAlignmentDisplayFunc();
    reverseAlignmentDisplayFunc();
  }

  private hasGroup(link: any): boolean {
    return link.sources.length > 1 || link.targets.length > 1;
  }

  private groupSegments(sourceSegments: any[], targetSegments: any[], links: any[]): any {
    const groupedSourceSegments = sourceSegments.map(segment => {
      return {
        text: segment.segment,
        group: 0,
        color: 0,
        catIsContent: segment.catIsContent,
        strongsX: segment.strongsX,
        english: segment.english,
        lemma: segment.lemma,
      };
    });
    const groupedTargetSegments = targetSegments.map(segment => {
      return { text: segment, group: 0, color: 0 };
    });
    let group = 1;
    let color = 1;
    if (links && links.length) {
      links.forEach(link => {
        if (this.hasGroup(link)) {
          link.sources.forEach((sourceSegmentIndex: number) => {
            groupedSourceSegments[sourceSegmentIndex].group = group;
            groupedSourceSegments[sourceSegmentIndex].color = color;
          });
          link.targets.forEach((targetSegmentIndex: number) => {
            groupedTargetSegments[targetSegmentIndex].group = group;
            groupedTargetSegments[targetSegmentIndex].color = color;
          });
          color = color > 2 ? 1 : (color += 1);
          group += 1;
        }
      });
    }
    return { groupedSourceSegments, groupedTargetSegments };
  }

  public displaySegments(
    groupedSourceSegments: any,
    groupedTargetSegments: any,
    isSource: boolean,
  ): ReactElement[] {
    const { reverseAlignmentDisplay } = this.props;
    let returnedSegments = isSource ? groupedSourceSegments : groupedTargetSegments;
    let returnedType = isSource ? 'source' : 'target';
    if (reverseAlignmentDisplay) {
      returnedSegments = !isSource ? groupedSourceSegments : groupedTargetSegments;
      returnedType = !isSource ? 'source' : 'target';
    }
    return this.textMapper(returnedSegments, returnedType);
  }

  public content(): ReactElement[] {
    const { sourceText, targetText, links, reverseAlignmentDisplay } = this.props;
    const stack = new Array<ReactElement>();
    const { groupedSourceSegments, groupedTargetSegments } = this.groupSegments(
      sourceText,
      targetText,
      links,
    );
    const linked = links && links.length;
    const linkedClass = linked ? 'linked' : 'unlinked';
    const reversedClass = reverseAlignmentDisplay ? 'reverse' : '';
    if (linked) {
      stack.push(<LinkLines key="link-lines" links={links} refDict={this.refDict} />);
    }

    stack.push(
      <div
        key="target-text-container"
        className={`target-container ${linkedClass} ${reversedClass}`}
      >
        {this.displaySegments(groupedSourceSegments, groupedTargetSegments, false)}
      </div>,
    );

    if (!linked) {
      stack.push(
        <div key="no-links-container" className="no-links-exist">
          <span className="text-segment default">No Links Exist</span>
        </div>,
      );
    }

    stack.push(
      <div key="source-text-container" className={`source-container ${reversedClass}`}>
        {this.displaySegments(groupedSourceSegments, groupedTargetSegments, true)}
      </div>,
    );

    return stack;
  }

  public textMapper(segments: any[], type: string): ReactElement[] {
    return segments.map((segment: any, index: number) => {
      const refName = `${type}-${index}`;
      const ref = React.createRef<HTMLSpanElement>();
      const isLinkable = type === 'target' || segment.catIsContent;
      this.refDict.set(refName, ref);
      return (
        <TextSegment
          refName={refName}
          theRef={ref}
          key={`text-segment-${refName}`}
          segment={segment}
          isLinkable={isLinkable}
        />
      );
    });
  }

  public render(): ReactElement {
    return (
      <div key="links-container" className="links-container">
        {this.content()}
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  return {
    source: state.alignment.source,
    target: state.alignment.target,
    reverseAlignmentDisplay: state.alignment.reverseAlignmentDisplay,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  reverseAlignmentDisplayFunc: (): void => {
    dispatch(reverseAlignmentDisplayAction());
  },
});
const LinksContainer = connect(mapStateToProps, mapDispatchToProps)(LinksContainerComp);

export default LinksContainer;
