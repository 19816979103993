import { LOGOUT, CONFIRM_LOGOUT, CANCEL_LOGOUT } from '../types/usernav';
import firebase from '../lib/firebase';

const FirebaseAuth = firebase.createService('auth');

export function logoutAction(): any {
  return async (dispatch: any): Promise<void> => {
    await (await FirebaseAuth.init()).signout();
    dispatch({
      type: LOGOUT,
    });
  };
}

export function confirmLogoutAction(): any {
  return (dispatch: any): void => {
    dispatch({
      type: CONFIRM_LOGOUT,
    });
  };
}

export function cancelLogoutAction(): any {
  return (dispatch: any): void => {
    dispatch({
      type: CANCEL_LOGOUT,
    });
  };
}
