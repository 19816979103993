import * as types from '../types';

export const closeNavigator = (): types.NavigatorActionTypes => {
  return {
    type: types.CLOSE_NAVIGATOR,
  };
};

export const displayNavigator = (): types.NavigatorActionTypes => {
  return {
    type: types.DISPLAY_NAVIGATOR,
  };
};

export const changeBook = (bookId: string): types.NavigatorActionTypes => {
  return {
    type: types.CHANGE_BOOK,
    bookId,
  };
};

export const changeChapter = (chapter: number): types.NavigatorActionTypes => {
  return {
    type: types.CHANGE_CHAPTER,
    chapter,
  };
};

export const searchBook = (keyword: string): types.NavigatorActionTypes => {
  return {
    type: types.SEARCH_BOOK,
    keyword,
  };
};
