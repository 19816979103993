import { DataResult, Translation, ChapterData, Resource } from '../shared/structs';
import noop from '../lib/noop';

import { CHANGE_BOOK } from './navigator';
import { UndefinedAction } from './lightbox';

export const DEFAULT_BOOK = 'Genesis';
export const DEFAULT_CHAPTER = 1;

export const IS_LOADING = 'IS_LOADING';
export const IS_LOADED = 'IS_LOADED';
export const CHANGE_MANUSCRIPT_ACCORDION = 'CHANGE_MANUSCRIPT_ACCORDION';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const FETCH_RESOURCE_DATA = 'FETCH_RESOURCE_DATA';
export const FETCH_RESOURCE_DATA_REQUEST = 'FETCH_DATA_RESOURCE_REQUEST';
export const FETCH_RESOURCE_DATA_SUCCESS = 'FETCH_DATA_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_DATA_FAILURE = 'FETCH_DATA_RESOURCE_FAILURE';
export const FETCH_RESOURCE_LIST = 'FETCH_RESOURCE_LIST';
export const FETCH_RESOURCE_LIST_REQUEST = 'FETCH_LIST_RESOURCE_REQUEST';
export const FETCH_RESOURCE_LIST_SUCCESS = 'FETCH_LIST_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_LIST_FAILURE = 'FETCH_LIST_RESOURCE_FAILURE';

export const ADD_REFERENCE = 'ADD_REFERENCE ';
export const CLOSE_REFERENCE = 'CLOSE_REFERENCE ';
export const FETCH_REFERENCE_LIST = 'FETCH_REFERENCE_LIST';
export const FETCH_REFERENCE_LIST_REQUEST = 'FETCH_LIST_REFERENCE_REQUEST';
export const FETCH_REFERENCE_LIST_SUCCESS = 'FETCH_LIST_REFERENCE_SUCCESS';
export const FETCH_REFERENCE_LIST_FAILURE = 'FETCH_LIST_REFERENCE_FAILURE';
export const FETCH_REFERENCE_DATA = 'FETCH_REFERENCE_DATA';
export const FETCH_REFERENCE_DATA_REQUEST = 'FETCH_DATA_REFERENCE_REQUEST';
export const FETCH_REFERENCE_DATA_SUCCESS = 'FETCH_DATA_REFERENCE_SUCCESS';
export const FETCH_REFERENCE_DATA_FAILURE = 'FETCH_DATA_REFERENCE_FAILURE';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const CLOSE_RESOURCE = 'CLOSE_RESOURCE';
export const REHYDRATE = 'persist/REHYDRATE';
export const REMEMBER_LAST_THING = 'REMEMBER_LAST_THING';
export const REMEMBER_LAST_THING_REQUEST = 'REMEMBER_LAST_THING_REQUEST';
export const REMEMBER_LAST_THING_SUCCESS = 'REMEMBER_LAST_THING_SUCCESS';
export const REMEMBER_LAST_THING_FAILURE = 'REMEMBER_LAST_THING_FAILURE';

export const FETCH_VERSE_TRANSLATIONS = 'FETCH_VERSE_TRANSLATIONS';
export const FETCH_VERSE_TRANSLATIONS_REQUEST = 'FETCH_VERSE_TRANSLATIONS_REQUEST';
export const FETCH_VERSE_TRANSLATIONS_FAILURE = 'FETCH_VERSE_TRANSLATIONS_FAILURE';
export const FETCH_VERSE_TRANSLATIONS_SUCCESS = 'FETCH_VERSE_TRANSLATIONS_SUCCESS';

export const MORPHOLOGY_CARD = 0;
export const MANUSCRIPT_CARD = 1;

export interface REHYDRATE {
  type: typeof REHYDRATE;
}

interface IsLoadingAction {
  type: typeof IS_LOADING;
}

interface IsLoadedAction {
  type: typeof IS_LOADED;
}

interface ChangeBookAction {
  type: typeof CHANGE_BOOK;
  bookId: string;
}

interface ChangeManuscriptAccordion {
  type: typeof CHANGE_MANUSCRIPT_ACCORDION;
  activeCard: number;
}

interface Card {
  key: string;
  name: string;
}

export const AccordionCards: Card[] = [
  {
    key: 'morphology',
    name: 'words',
  },
  {
    key: 'manuscript',
    name: 'verses',
  },
];

interface FetchDataRequest {
  type: typeof FETCH_DATA_REQUEST;
}

interface FetchDataSuccess {
  type: typeof FETCH_DATA_SUCCESS;
  activeCard: number;
  data: CardData;
}

interface FetchDataFailure {
  type: typeof FETCH_DATA_FAILURE;
  error: any;
}

interface FetchData {
  type: typeof FETCH_DATA;
  activeCard: number;
}

interface FetchResourceData {
  type: typeof FETCH_RESOURCE_DATA;
}

interface FetchResourceDataRequest {
  type: typeof FETCH_RESOURCE_DATA_REQUEST;
}

interface FetchResourceDataSuccess {
  type: typeof FETCH_RESOURCE_DATA_SUCCESS;
  resourceName: string;
  data: any[];
}

interface FetchResourceDataFailure {
  type: typeof FETCH_RESOURCE_DATA_FAILURE;
  error: any;
}

interface FetchResourceList {
  type: typeof FETCH_RESOURCE_LIST;
}

interface FetchResourceListRequest {
  type: typeof FETCH_RESOURCE_LIST_REQUEST;
}

interface FetchResourceListSuccess {
  type: typeof FETCH_RESOURCE_LIST_SUCCESS;
  data: any;
}

interface FetchResourceListFailure {
  type: typeof FETCH_RESOURCE_LIST_FAILURE;
  error: any;
}

interface AddResource {
  type: typeof ADD_RESOURCE;
  resource: string;
}

interface CloseResource {
  type: typeof CLOSE_RESOURCE;
  resource: string;
}

interface FetchReferenceData {
  type: typeof FETCH_REFERENCE_DATA;
}

interface FetchReferenceDataRequest {
  type: typeof FETCH_REFERENCE_DATA_REQUEST;
  referenceId: string;
}

interface FetchReferenceDataSuccess {
  type: typeof FETCH_REFERENCE_DATA_SUCCESS;
  referenceId: string;
  data: any[];
}

interface FetchReferenceDataFailure {
  type: typeof FETCH_REFERENCE_DATA_FAILURE;
  error: any;
}

interface AddReference {
  type: typeof ADD_REFERENCE;
  reference: Translation;
}

interface CloseReference {
  type: typeof CLOSE_REFERENCE;
  reference: Translation;
}

interface RememberLastThingRequest {
  type: typeof REMEMBER_LAST_THING_REQUEST;
}

interface RememberLastThingSuccess {
  type: typeof REMEMBER_LAST_THING_SUCCESS;
}

interface RememberLastThingFailure {
  type: typeof REMEMBER_LAST_THING_FAILURE;
  error: any;
}

interface RememberLastThing {
  type: typeof REMEMBER_LAST_THING;
  data: object;
}

interface FetchReferenceList {
  type: typeof FETCH_REFERENCE_LIST;
}

interface FetchReferenceListRequest {
  type: typeof FETCH_REFERENCE_LIST_REQUEST;
}

interface FetchReferenceListSuccess {
  type: typeof FETCH_REFERENCE_LIST_SUCCESS;
  data: any;
}

interface FetchReferenceListFailure {
  type: typeof FETCH_REFERENCE_LIST_FAILURE;
  error: any;
}

/** **************** Verse translation data - begin **************** */
interface FetchVerseTranslationsRequest {
  type: typeof FETCH_VERSE_TRANSLATIONS_REQUEST;
}
interface FetchVerseTranslationsSuccess {
  type: typeof FETCH_VERSE_TRANSLATIONS_SUCCESS;
  data: any[];
}
interface FetchVerseTranslationsFailure {
  type: typeof FETCH_VERSE_TRANSLATIONS_FAILURE;
  error: any;
}
interface FetchVerseTranslations {
  type: typeof FETCH_VERSE_TRANSLATIONS;
  projectId: string;
  bookId: string;
  chapter: number;
}
/** **************** Verse translation data - end **************** */

export type TranslationActionTypes =
  | REHYDRATE
  | IsLoadingAction
  | IsLoadedAction
  | ChangeBookAction
  | UndefinedAction
  | ChangeManuscriptAccordion
  | FetchDataRequest
  | FetchDataSuccess
  | FetchDataFailure
  | FetchData
  | FetchResourceData
  | FetchResourceDataRequest
  | FetchResourceDataSuccess
  | FetchResourceDataFailure
  | FetchResourceList
  | FetchResourceListRequest
  | FetchResourceListSuccess
  | FetchResourceListFailure
  | FetchReferenceList
  | FetchReferenceListRequest
  | FetchReferenceListSuccess
  | FetchReferenceListFailure
  | FetchReferenceData
  | FetchReferenceDataRequest
  | FetchReferenceDataSuccess
  | FetchReferenceDataFailure
  | AddResource
  | CloseResource
  | AddReference
  | CloseReference
  | RememberLastThingRequest
  | RememberLastThingSuccess
  | RememberLastThingFailure
  | RememberLastThing
  | FetchVerseTranslations
  | FetchVerseTranslationsFailure
  | FetchVerseTranslationsSuccess
  | FetchVerseTranslationsRequest;

export interface CardData {
  data: ChapterData;
  empty: boolean;
  isOldTestament: boolean;
  bookId: string;
  chapter: number;
}

export interface TranslationState {
  loading: boolean;
  loadingVerses: boolean;
  error?: any | undefined;
  authenticated: boolean;
  authorizationFail: boolean;
  projectName?: string;
  defaultBookId?: string;
  defaultChapter?: number;
  selectedBookId?: string;
  selectedChapter?: number;
  activeCard?: number;
  resourceData: any;
  referenceData: any;
  resources: any[];
  references: Translation[];
  cardData: Map<number, CardData>;
  isFetching?: boolean;
  isFetchingData?: boolean;
  projectNotExisted?: boolean;
  verseId?: any;
  projectId?: string;
  verseTranslations?: any[];
  verseCompleteMap?: Map<string, boolean>;
  availableResources: DataResult;
  availableReferences: DataResult;
  userIsProjectMember: boolean;
}

export interface TranslationProps {
  loading: boolean;
  loadingVerses: boolean;
  error?: any | undefined;
  authenticated: boolean;
  authorizationFail: boolean;
  fetchProjectFunc?: any;
  projectName?: string;
  defaultBookId?: string;
  defaultChapter?: number;
  selectedBookId?: string | undefined;
  selectedChapter?: number | undefined;
  changeChapterFunc?: any;
  addResourceFunc?: any;
  addReferenceFunc?: any;
  fetchResourceListFunc?: any;
  fetchReferenceListFunc?: any;
  closeResourceFunc?: any;
  closeReferenceFunc?: any;
  activeCard: number;
  resourceData: any;
  referenceData: any;
  fetchResourceData?: any;
  resources: any[];
  references: Translation[];
  availableResources: DataResult;
  availableReferences: DataResult;
  selectedResource?: Resource;
  selectedReference: Translation;
  cardData: Map<number, CardData>;
  changeAccordionCard?: any;
  fetchData?: any;
  isFetching?: boolean;
  isFetchingData?: boolean;
  rememberLastThing?: any;
  projectNotExisted?: boolean;
  verseId?: any;
  projectId?: string;
  fetchVerseTranslations?: any;
  verseTranslations?: any[];
  verseCompleteMap?: Map<string, boolean>;
  verseModifiedMap?: Map<string, boolean>;
  dismissVerseChanges?: any;
  toggleManuscript: Function;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isBottomPanelOpen: boolean;
  updateUIConfig: Function;
  userIsProjectMember: boolean;
}

export const DefaultTranslationProps: TranslationProps = {
  loading: false,
  loadingVerses: false,
  isFetching: false,
  isFetchingData: false,
  authenticated: false,
  authorizationFail: false,
  activeCard: 0,
  cardData: new Map<number, CardData>(),
  resourceData: {},
  referenceData: {},
  resources: [],
  references: [],
  selectedResource: {
    id: '',
    shortName: '',
    fullName: '',
    language: '',
    about: '',
    versification: '',
  },
  selectedReference: { id: '', shortName: '', fullName: '', description: '', language: '' },
  availableResources: { empty: true, data: new Array<Translation>() },
  availableReferences: { empty: true, data: [] },
  verseCompleteMap: new Map<string, boolean>(),
  verseModifiedMap: new Map<string, boolean>(),
  toggleManuscript: noop,
  isLeftPanelOpen: false,
  isRightPanelOpen: false,
  isBottomPanelOpen: false,
  updateUIConfig: noop,
  userIsProjectMember: false,
};
