import * as types from '../types';
import { ManuscriptSuggestions } from '../shared/structs';

export const INIT_STAT_EDITOR: types.VerseEditorState = {
  loading: false,
  editingMode: false,
  verseTranslation: '',
  verseTextMap: new Map<string, string>(),
  verseCompleteMap: new Map<string, boolean>(),
  verseModifiedMap: new Map<string, boolean>(),
  editingGbiVerseCode: '',
  updating: false,
  error: '',
  updatingGbiVerseCode: '',
  triggerAlignmentFlag: false,
  showSuggestionList: true,
  suggestionListTop: 0,
  suggestionListLeft: 0,
  autoSuggestionMap: new Map<string, ManuscriptSuggestions>(),
};

export default (
  state = INIT_STAT_EDITOR,
  action: types.VerseEditorTypes | types.TranslationActionTypes | types.NavigatorActionTypes,
): types.VerseEditorState => {
  let { verseTextMap, verseCompleteMap, verseModifiedMap, autoSuggestionMap } = state;

  // CHANGE_CHAPTER | CHANGE_BOOK

  if (verseTextMap === undefined) {
    verseTextMap = new Map<string, string>();
  }

  if (verseCompleteMap === undefined) {
    verseCompleteMap = new Map<string, boolean>();
  }

  if (verseModifiedMap === undefined) {
    verseModifiedMap = new Map<string, boolean>();
  }

  if (autoSuggestionMap === undefined) {
    autoSuggestionMap = new Map<string, ManuscriptSuggestions>();
  }

  switch (action.type) {
    case types.CHANGE_BOOK:
    case types.CHANGE_CHAPTER:
    case types.DISMISS_VERSE_CHANGES:
      verseTextMap = new Map<string, string>();
      verseCompleteMap = new Map<string, boolean>();
      verseModifiedMap = new Map<string, boolean>();

      return {
        ...state,
        verseTextMap,
        verseCompleteMap,
        verseModifiedMap,
        editingGbiVerseCode: undefined,
      };

    case types.CHANGE_VERSE_TRANSLATION:
      verseModifiedMap.set(action.textId, true);
      verseTextMap.set(action.textId, action.verseTranslation);

      return { ...state, verseTextMap, verseModifiedMap };

    case types.SHOW_ERROR:
      return { ...state, error: action.error };

    case types.OPEN_EDITOR:
      return {
        ...state,
        editingGbiVerseCode: action.textId,
        autoSuggestionMap: new Map<string, ManuscriptSuggestions>(),
      };

    case types.CLOSE_EDITOR:
      return { ...state, editingGbiVerseCode: undefined };

    case types.UPDATE_VERSE_REQUEST:
      verseCompleteMap.set(action.textId, action.complete);
      return { ...state, updating: true, verseCompleteMap, updatingGbiVerseCode: action.textId };

    case types.UPDATE_VERSE_SUCCESS:
      verseModifiedMap.delete(action.textId);
      return { ...state, updating: false, verseModifiedMap };

    case types.UPDATE_VERSE_FAILURE:
      return { ...state, updating: false, error: action.error };

    case types.FETCH_VERSE_TRANSLATIONS_SUCCESS:
      verseTextMap = new Map<string, string>();
      verseCompleteMap = new Map<string, boolean>();

      if (Array.isArray(action.data) && action.data.length) {
        action.data.forEach((doc: any): void => {
          verseTextMap.set(doc.textId, doc.text);
          verseCompleteMap.set(doc.textId, doc.complete);
        });
      }

      return { ...state, verseTextMap, verseCompleteMap };

    case types.UNCHECK_COMPLETE_BOX:
      verseCompleteMap.set(action.textId, false);

      return { ...state, verseCompleteMap, random: Math.random() };

    case types.TRIGGER_ALIGNMENT_FLAG:
      return { ...state, triggerAlignmentFlag: true };

    case types.DISMISS_ALIGNMENT:
      return { ...state, triggerAlignmentFlag: false };

    case types.FETCH_SUGGESTION_REQUEST:
      return { ...state, loading: true };
    case types.FETCH_SUGGESTION_SUCCESS:
      autoSuggestionMap.set(action.textId, action.data);

      return { ...state, loading: false, autoSuggestionMap };

    default:
      return state;
  }
};
