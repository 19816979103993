import { REDIRECT_TO_PROJECT, EMAIL_ADDR_UPDATE, PASSWORD_UPDATE } from './signin';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export interface SignUpState {
  redirect: boolean;
  error?: null;
  emailAddr: string;
  password: string;
}

export interface SignUpProps {
  redirectToProject: boolean;
  emailAddr: string;
  password: string;
  emailAddrUpdate?: any;
  passwordUpdate?: any;
  signup?: any;
  redirectDoneFunc?: any;
  error?: any;
}

interface RedirectAction {
  type: typeof REDIRECT_TO_PROJECT;
}

interface SignupFailAction {
  type: typeof SIGN_UP_FAIL;
  error: any;
}

interface UpdateEmailAddressAction {
  type: typeof EMAIL_ADDR_UPDATE;
  emailAddr: string;
}

interface UpdatePasswordAction {
  type: typeof PASSWORD_UPDATE;
  password: string;
}

interface SignUpAction {
  type: typeof SIGN_UP;
  emailAddr: string;
  password: string;
}

export type SignUpActionTypes =
  | RedirectAction
  | SignupFailAction
  | SignUpAction
  | UpdateEmailAddressAction
  | UpdatePasswordAction;
