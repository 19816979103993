import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { AppState } from '../../reducers';
import Spinner from '../spinner';
import { TranslationProps, TranslationState, DefaultTranslationProps } from '../../types';
import {
  fetchResourceDataAction,
  closeResourceAction,
  rememberLastThingAction,
} from '../../actions';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import { Resource } from '../../shared/structs';

export class ResourceComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: any) {
    super(props);
    const { fetchData, selectedResource, selectedBookId, selectedChapter } = this.props;
    if (selectedResource) {
      fetchData(selectedResource.shortName, selectedBookId, selectedChapter);
    }
  }

  public componentDidMount(): void {
    const { fetchData, selectedReference, selectedBookId, selectedChapter } = this.props;
    fetchData(selectedReference.id, selectedBookId, selectedChapter);
  }

  public content(): ReactElement {
    const {
      resourceData,
      fetchData,
      selectedResource,
      selectedBookId,
      selectedChapter,
      isFetching,
    } = this.props;
    if (selectedResource && selectedResource.id && resourceData) {
      if (resourceData[selectedResource.id]) {
        const { text } = resourceData[selectedResource.id].data;
        return <p className="resource-text" dangerouslySetInnerHTML={{ __html: text }} />;
      }

      if (!isFetching && !resourceData[selectedResource.id]) {
        fetchData(selectedResource.shortName, selectedBookId, selectedChapter);
      }
    }
    return <Spinner />;
  }

  public render(): ReactElement | null {
    const { selectedResource, closeResourceFunc, resources, rememberLastThing } = this.props;
    if (selectedResource) {
      return (
        <Col className="col-sm-5 mt-1 resource-content-container">
          <h4 className="resource-header position-sticky sticky-top">
            {selectedResource.fullName}

            <OverlayTrigger
              placement="top"
              // prettier-ignore
              overlay={(
                <Tooltip id="resourceLicense">
                  {selectedResource.license}
                </Tooltip>
            )}
            >
              <Button
                size="sm"
                variant="light"
                className="pull-right close-resource"
                onClick={(): void => {
                  window.open(selectedResource.about, '_blank');
                }}
              >
                <i className="fa sm fa-question" />
              </Button>
            </OverlayTrigger>

            <Button
              id="close-resource"
              size="sm"
              variant="light"
              className="pull-right close-resource"
              onClick={(): void => {
                closeResourceFunc(selectedResource);
                rememberLastThing(
                  getCurrentProjectId(),
                  'resources',
                  resources.filter((resource: Resource): boolean => {
                    return resource.shortName !== selectedResource.shortName;
                  }),
                );
              }}
            >
              <i className="fa sm fa-times" />
            </Button>
          </h4>
          <div className="resource-content-container text">{this.content()}</div>
        </Col>
      );
    }
    return null;
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
  };
  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchData: (selectedResource: string, bookId: string, chapterId: number): void => {
    dispatch(fetchResourceDataAction(selectedResource, bookId, chapterId));
  },
  closeResourceFunc: (resource: string): void => {
    dispatch(closeResourceAction(resource));
  },
  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },
});

const ResourceCompConnected = connect(mapStateToProps, mapDispatchToProps)(ResourceComp);

export default ResourceCompConnected;
