import { USER_IS_NOT_SIGNED } from '../types';

export default class Base {
  protected firebaseSdk: any = undefined;

  public checkUserId(uid: string): boolean {
    if (uid === '') {
      throw Error(USER_IS_NOT_SIGNED);
    } else {
      return true;
    }
  }
}
