export const DISPLAY_LIGHTBOX = 'DISPLAY_LIGHTBOX';
export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX';

interface DisplayLightboxAction {
  type: typeof DISPLAY_LIGHTBOX;
}

interface CloseLightboxAction {
  type: typeof CLOSE_LIGHTBOX;
}

export interface UndefinedAction {
  type: typeof undefined;
}

export type LightboxActionTypes = DisplayLightboxAction | CloseLightboxAction | UndefinedAction;

export interface LightboxState {
  display: boolean;
}

export interface LightboxProps {
  display: boolean;
  extModalCssName?: string;
  style?: React.CSSProperties;
  close?: any;
  closeHandler?: any;
  children?: any;
}
