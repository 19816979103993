/* eslint-disable import/prefer-default-export */
export const config = {
  domainName: 'YouTranslate.bible',
  supportEmail: 'help@youtranslate.bible',
  dateFormat: 'YYYY-MM-DD',
  dateFormatUs: 'MM/DD/YYYY',
  userPermissions: {
    owner: 'project.owner',
    user: 'project.translator',
  },
};
