/* eslint-disable import/prefer-default-export */

import { BibleBooks, BibleChapters } from '../shared/verseIdParser';

export const isOldTestament = (bookId: string): boolean => {
  const index = BibleBooks[bookId as keyof typeof BibleBooks];

  if (index > 0 && index <= 39) {
    return true;
  }

  return false;
};

export const generateBookChapterCode = (bookId: string, chapter: number): string => {
  try {
    const bookIndex = BibleBooks[bookId as keyof typeof BibleBooks];
    if (typeof bookIndex !== 'number') {
      throw Error('Invalid book.');
    }

    const chapterMaxNumber = BibleChapters[bookId as keyof typeof BibleChapters];

    if (chapter < 1 || chapter > chapterMaxNumber) {
      throw Error('Invalid chapter number.');
    }

    // book code length: 2
    const bookCode = `00${bookIndex}`.slice(-2);

    // chapter code length: 3
    const chapterCode = `000${chapter}`.slice(-3);

    return `${bookCode}${chapterCode}`;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const bookOrChapterChanged = (
  bookId: string,
  chapter: number,
  selectedBookId: string | undefined,
  selectedChapter: number | undefined,
): boolean => {
  if (selectedBookId !== undefined && selectedChapter !== undefined) {
    return bookId !== selectedBookId || chapter !== selectedChapter;
  }

  return false;
};
