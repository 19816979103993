const languageObject: Record<string, Record<string, string>> = {
  en: {
    'languages.en': 'English',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Language',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'German Language',
    'languages.es-419': 'Spanish Language',
    'languages.fr': 'French Language',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Language',
    'languages.hu': 'Hungarian Language',
    'languages.id': 'Indonesian Language',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Korean Language',
    'languages.lo': 'Laotian Language',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysian',
    'languages.my': 'Burmese Language',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Malagasy Language',
    'languages.pt-br': 'Portuguese (Brazil) Language',
    'languages.ru': 'Russian Language',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Language',
    'languages.th': 'Thai Language',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Language',
    'languages.vi': 'Vietnamese Language',
    'languages.zh-hans': 'Chinese Language (Simplified)',
    'languages.zh-hant': 'Chinese Language (Traditional)',
    'header.home': 'Home',
    'header.help': 'Help',
    'header.project': 'Project',
    'help.contactSupport': 'Please email us at help@youtranslate.bible for assistance.',
    'home.ytbProvides': 'YouTranslate.Bible freely provides:',
    'home.easyToUseTool': 'An easy-to-use online translation tool',
    'home.originalTextHelp': 'Help to understand the text of the Bible in its original languages',
    'home.referencesAndResources': 'Reference and training resources',
    'home.computerAssistance': 'State-of-the-art computer technology to assist translators',
    'profile.userProfile': 'User Profile',
    'profile.language': 'Language',
    'profile.update': 'Update',
    'usernav.profile': 'Profile',
    'usernav.logout': 'Logout',
    'usernav.logout.modalTitle': 'Logout of YouTranslate.Bible',
    'usernav.logout.modalText': 'Are you sure you want to logout? All unsaved work will be lost.',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'Create',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'Project list',
    'project.list.deleteProject': 'Delete Project?',
    'project.list.areYouSure': 'Are you sure you want to delete this project?',
    'project.list.workWillBeDeleted': 'The project and any work associated will be deleted.',
    'project.list.completion': 'Completion:',
    'project.list.createdDate': 'Created Date:',
    'project.list.beginningDate': 'Beginning Date:',
    'project.list.targetCompletionDate': 'Target Completion Date:',
    'project.list.description': 'Description:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Translate Bible',
    'project.list.empty': 'Your translation project list is empty.',
    'project.list.myProjects': 'My Bible Translation Projects',
    'project.list.newProject': 'New Project',
    'project.create.createNew': 'Create a new Project',
    'project.namePlaceholder': 'Enter project name',
    'project.descriptionPlaceholder': 'Enter project description',
    'project.name': 'Project name',
    'project.beginningDate': 'Beginning Date',
    'project.targetCompletionDate': 'Target Completion Date',
    'project.description': 'Description',
    'project.manage-users': 'Manage Translators',
    'project.new-user': 'New translator',
    'project.add-user': 'Add translator',
    'project.users': 'Project translators',
    'project.read-only': 'Read only',
    'project.read&write': 'Read & Write',
    'project.owner': 'Owner',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'No such translator exists',
    'project.message.user-added': 'Translator added successfully',
    'project.message.user-deleted': 'Translator removed.',
    'project.message.only-owner-can-manage-users': 'Only project owner can manage users',
    'project.shared': 'Collaborative project',
    'general.delete-user': 'Delete translator',
    'general.edit-user': 'Edit user',
    'general.name': 'Name',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'Role',
    signin: 'Sign in',
    'signin.emailPlaceholder': 'Email address',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'Forgot Your Password?',
    'signin.rememberMe': 'Remember me',
    'signin.facebook': 'Sign in with Facebook',
    'signin.google': 'Sign in with Google',
    'signin.dontHaveAccount': "Don't have an account?",
    'signin.createOne': 'Create one',
    'signup.join': 'Join YouTranslate.Bible',
    'signup.createAccountPrompt': 'Create your free account to begin.',
    'signup.createAccount': 'Create Account',
    'signup.emailPlaceholder': 'Email address',
    'signup.passwordPlaceholder': 'Password',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Back to project list',
    'translation.book': 'Book:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Are you sure you want to leave this chapter? All unsaved work will be lost.',
    'translation.prev': 'Prev',
    'translation.next': 'Next',
    'translation.previousChapter': 'Previous chapter',
    'translation.nextChapter': 'Next chapter',
    'navigator.book': 'Book:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Old Testament',
    'navigator.newTestament': 'New Testament',
    'navigator.selectBook': 'Select a book',
    'navigator.search': 'Search',
    'navigator.changeBook': 'Change book',
    'navigator.changeChapter': 'Change chapter',
    Genesis: 'Genesis',
    Exodus: 'Exodus',
    Leviticus: 'Leviticus',
    Numbers: 'Numbers',
    Deuteronomy: 'Deuteronomy',
    Joshua: 'Joshua',
    Judges: 'Judges',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalms',
    Proverbs: 'Proverbs',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakkuk',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Zechariah',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Acts',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Galatians',
    Ephesians: 'Ephesians',
    Philippians: 'Philippians',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebrews',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Revelation',
    'resources.prompt':
      'Add Biblical resources for additional insight into the passage you are translating.',
    'resources.add': 'Add a Biblical resource',
    Manuscript: 'Manuscript',
    Morphology: 'Morphology',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Tree',
    'Go to project list': 'Go to project list',
    'Project not found': 'Project not found or insufficient permissions.',
    'error.referenceDoesNotExistForResource':
      'This Bible translation does not contain content for the selected passage.',
    'references.addAnother': 'Add another translation',
    'references.referencePrompt': 'Add other Bible translations for additional insight.',
    'references.add': 'Add a Bible translation',
    'translation.complete': 'Translation complete',
    Alignment: 'Alignment',
    Share: 'Share',
    'Your Translation': 'Your Translation',
    'Enter your translation': 'Enter your translation',
    OK: 'OK',
    'error.verseTranslationEmpty': 'You cannot leave the translation content empty.',
    'translation.hide.completed': 'Hide completed',
    alignment: 'Alignment',
    'alignment.verify': 'Verify',
    'alignment.backToEditor': 'Back to Editor',
    'alignment.error':
      'Something went wrong. If the problem persists, please contact the YouTranslate.Bible team.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Remove Link',
    'alignment.clear': 'Clear Selection',
    'alignment.reverse': 'Reverse Display',
    'alignment.removeOrClear': 'Remove the selected link or clear selection.',
    'alignment.addOrRemove': 'Create a new link or delete a previous one.',
    'alignment.selectAndAdd': 'Select corresponding segments to link.',
    'error.mustBeSignedToMakeChanges': 'You must be signed in to make changes.',
    'error.mustBeSignedToTranslateBible': 'You must be signed in to translate the Bible.',
    checking: 'Checking',
    'verseEditor.verseSuggestions': 'Suggestions',
    verses: 'By Verse',
    words: 'By Word',
    'leftPanel.name': 'Original Text of the Bible',
    'rightPanel.name': 'Translations of the Bible',
    'bottomPanel.name': 'Resources for the Bible',
    'syntax.adv': 'Adverb',
    'syntax.advp': 'Adverb Phrase',
    'syntax.cl': 'Clause',
    'syntax.conj': 'Conjunction',
    'syntax.cjp': 'Conjunction',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'Object',
    'syntax.io': 'Indirect Object',
    'syntax.p': 'Predicate',
    'syntax.pp': 'Prepositional Phrase',
    'syntax.s': 'Subject',
    'syntax.v': 'Verb',
    'syntax.vc': 'Verb Clause',
    'syntax.adv.description':
      'Modifies another part of speech. Typically express manner, place, time, frequency, degree, level of certainty, etc.',
    'syntax.advp.description':
      'A multi-word expression that modifies another part of speech. Typically express manner, place, time, frequency, degree, level of certainty, etc...',
    'syntax.cl.description': 'A part of a sentence that contains a verb.',
    'syntax.conj.description': 'Connects words, phrases, and clauses.',
    'syntax.cjp.description': 'Connects words, phrases, and clauses.',
    'syntax.np.description': 'A multi-word expression that performs the function of a noun.',
    'syntax.o.description': 'The entity that is acted upon by the subject.',
    'syntax.io.description': 'This one is hard to explain.',
    'syntax.p.description': 'Modifies the subject. Usually stars with a verb.',
    'syntax.pp.description': 'This one is hard to explain.',
    'syntax.s.description': 'The entity which controls the verb of the clause.',
    'syntax.v.description': 'Conveys an action.',
    'syntax.vc.description':
      'A multi-word expression that performs the function of a verb (conveys an action).',
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Select...',
    'general.back': 'Back',
  },
  am: {
    'languages.en': 'እንግሊዝኛ',
    'languages.am': 'አማርኛ',
    'languages.bn': 'ቤንጋሊ ቋንቋ',
    'languages.ceb': 'ሴብዋኖ ቋንቋ',
    'languages.de': 'የጀርመን ቋንቋ',
    'languages.es-419': 'ስፓኒሽ ቋንቋ',
    'languages.fr': 'ፈረንሳይኛ ቋንቋ',
    'languages.gu': 'ጉጅራቲ',
    'languages.hi': 'ሂንዲ',
    'languages.hr': 'ክሮኤሽያኛ ቋንቋ',
    'languages.hu': 'ሀንጋሪኛ ቋንቋ',
    'languages.id': 'የኢንዶኔዥያ ቋንቋ',
    'languages.km': 'ክመርኛ',
    'languages.kn': 'ካናዳ',
    'languages.ko': 'የኮሪያ ቋንቋ',
    'languages.lo': 'የላኦስ ቋንቋ',
    'languages.ml': 'ማላያላም',
    'languages.mr': 'ማራቲ',
    'languages.ms': 'የማሌዥያ',
    'languages.my': 'በበርማ ቋንቋ',
    'languages.ne': 'ኔፓሊኛ ቋንቋ',
    'languages.plt': 'የማደጋስካር ቋንቋ',
    'languages.pt-br': 'ፖርቱጋልኛ (ብራዚል) ቋንቋ',
    'languages.ru': 'የሩስያ ቋንቋ',
    'languages.sw': 'ስዋሕሊ',
    'languages.ta': 'የታሚል ቋንቋ',
    'languages.th': 'የታይ ቋንቋ',
    'languages.tl': 'ታንጋሎግ',
    'languages.te': 'Telegu ቋንቋ',
    'languages.vi': 'ቪትናምኛ ቋንቋ',
    'languages.zh-hans': 'ቻይንኛ ቋንቋ (ቀለል ያለ)',
    'languages.zh-hant': 'ቻይንኛ ቋንቋ (ባህላዊ)',
    'header.home': 'መኖሪያ ቤት',
    'header.help': 'እርዳታ',
    'header.project': 'ፕሮጀክት',
    'help.contactSupport': 'እርዳታ ለማግኘት help@youtranslate.bible ላይ ኢሜይል ይላኩልን.',
    'home.ytbProvides': 'YouTranslate.Bible በነፃ ይሰጣል:',
    'home.easyToUseTool': 'አንድ ቀላል-ወደ-ለመጠቀም መስመር ላይ የትርጉም መሣሪያ',
    'home.originalTextHelp': 'እገዛ መጀመሪያ በተጻፈባቸው ቋንቋዎች ውስጥ የመጽሐፍ ቅዱስ ጽሑፍ ለመረዳት',
    'home.referencesAndResources': 'ማጣቀሻ እና ስልጠና ሀብቶች',
    'home.computerAssistance': 'መንግስት-ኦቭ-ዘ-አርት የኮምፒውተር ቴክኖሎጂ ተርጓሚዎችን ለመርዳት',
    'profile.userProfile': 'የተጠቃሚ መገለጫ',
    'profile.language': 'ቋንቋ',
    'profile.update': 'አዘምን',
    'usernav.profile': 'መገለጫ',
    'usernav.logout': 'ውጣ',
    'usernav.logout.modalTitle': 'YouTranslate.Bible መካከል አስወጣ',
    'usernav.logout.modalText': 'እርስዎ ለመውጣት እንደሚፈልጉ እርግጠኛ ነዎት? ሁሉም ያልዳነው ሥራ ይጠፋል.',
    cancel: 'ሰርዝ',
    delete: 'ሰርዝ',
    create: 'ፈጠረ',
    update: 'አዘምን',
    edit: 'አርትዕ',
    save: 'አስቀምጥ',
    add: 'ያክሉ',
    'project.create.title': 'የመጽሐፍ ቅዱስ ትርጉም ፕሮጀክት',
    'project.create.list': 'የፕሮጀክት ዝርዝር',
    'project.list.deleteProject': 'ፕሮጀክት ሰርዝ?',
    'project.list.areYouSure': 'ይህን ፕሮጀክት መሰረዝ ይፈልጋሉ እርግጠኛ ነዎት?',
    'project.list.workWillBeDeleted': 'ፕሮጀክቱ እና የተጎዳኙ ማንኛውም ሥራ ይሰረዛል.',
    'project.list.completion': 'መጠናቀቅ:',
    'project.list.createdDate': 'የተፈጠረ ቀን:',
    'project.list.beginningDate': 'ቀን ጀምሮ:',
    'project.list.targetCompletionDate': 'የዒላማ ማጠናቀቂያ ቀን:',
    'project.list.description': 'መግለጫ:',
    'project.edit': 'ፕሮጀክት አርትዕ',
    'project.list.translateBible': 'የመጽሐፍ ቅዱስ መተርጎም',
    'project.list.empty': 'የእርስዎ የትርጉም ፕሮጀክት ዝርዝር ባዶ ነው.',
    'project.list.myProjects': 'የእኔ የመጽሐፍ ቅዱስ ትርጉም ፕሮጀክቶች',
    'project.list.newProject': 'አዲስ ፕሮጀክት',
    'project.create.createNew': 'አዲስ ፕሮጀክት ፍጠር',
    'project.namePlaceholder': 'የፕሮጀክት ስም ያስገቡ',
    'project.descriptionPlaceholder': 'የፕሮጀክት መግለጫ አስገባ',
    'project.name': 'የፕሮጀክት ስም',
    'project.beginningDate': 'ጀምሮ ቀን',
    'project.targetCompletionDate': 'ዒላማ ማጠናቀቂያ ቀን',
    'project.description': 'መግለጫ',
    'project.manage-users': 'ተርጓሚዎች ያቀናብሩ',
    'project.new-user': 'አዲስ ተርጓሚ',
    'project.add-user': 'ተጨማሪው ተርጓሚ',
    'project.users': 'የፕሮጀክት ተርጓሚዎች',
    'project.read-only': 'ለማንበብ ብቻ የተፈቀደ',
    'project.read&write': 'አንብብ እና ፃፍ',
    'project.owner': 'ባለቤት',
    'project.translator': 'አስተርጓሚ',
    'project.message.no-such-user': 'ምንም እንደዚህ ተርጓሚ አለ',
    'project.message.user-added': 'ተርጓሚ በተሳካ ሁኔታ ታክሏል',
    'project.message.user-deleted': 'ተርጓሚ ተወግዷል.',
    'project.message.only-owner-can-manage-users': 'ተጠቃሚዎችን ማስተዳደር የሚችለው ብቻ የፕሮጀክቱ ባለቤት',
    'project.shared': 'የትብብር ፕሮጄክት',
    'general.delete-user': 'ሰርዝ ተርጓሚ',
    'general.edit-user': 'ተጠቃሚን ያርትዑ',
    'general.name': 'ስም',
    'general.email': 'ኢ-ሜል',
    'general.permissions': 'ፍቃዶች',
    'general.role': 'ሚና',
    signin: 'ስግን እን',
    'signin.emailPlaceholder': 'የ ኢሜል አድራሻ',
    'signin.passwordPlaceholder': 'ፕስወርድ',
    'signin.forgotPassword': 'የሚስጥራዊውን ቁጥር ረሳህው?',
    'signin.rememberMe': 'አስታወስከኝ',
    'signin.facebook': 'ፌስቡክ ጋር ይግቡ',
    'signin.google': 'በ Google ይግቡ',
    'signin.dontHaveAccount': 'መለያ የለህም?',
    'signin.createOne': 'አንድ ፍጠር',
    'signup.join': 'YouTranslate.Bible ይቀላቀሉ',
    'signup.createAccountPrompt': 'ለመጀመር የ ነጻ መለያ ፍጠር.',
    'signup.createAccount': 'መለያ ፍጠር',
    'signup.emailPlaceholder': 'የ ኢሜል አድራሻ',
    'signup.passwordPlaceholder': 'ፕስወርድ',
    'translation.project': 'ፕሮጀክት:',
    'translation.backToProjectList': 'ፕሮጀክት ዝርዝር ተመለስ',
    'translation.book': 'መጽሐፍ:',
    'translation.chapter': 'ምዕራፍ:',
    'translation.prompt': 'ይህን ምዕራፍ መተው ይፈልጋሉ እርግጠኛ ነዎት? ሁሉም ያልዳነው ሥራ ይጠፋል.',
    'translation.prev': 'የቀድሞው',
    'translation.next': 'ቀጣይ',
    'translation.previousChapter': 'ቀዳሚ ምዕራፍ',
    'translation.nextChapter': 'በሚቀጥለው ምዕራፍ',
    'navigator.book': 'መጽሐፍ:',
    'navigator.chapter': 'ምዕራፍ:',
    'navigator.oldTestament': 'ብሉይ ኪዳን',
    'navigator.newTestament': 'አዲስ ኪዳን',
    'navigator.selectBook': 'አንድ መጽሐፍ ይምረጡ',
    'navigator.search': 'ፈልግ',
    'navigator.changeBook': 'ለውጥ መጽሐፍ',
    'navigator.changeChapter': 'ለውጥ ምዕራፍ',
    Genesis: 'ዘፍጥረት',
    Exodus: 'ዘፀአት',
    Leviticus: 'ዘሌዋውያን',
    Numbers: 'ቁጥሮች',
    Deuteronomy: 'ዘዳግም',
    Joshua: 'ኢያሱ',
    Judges: 'ዳኞች',
    Ruth: 'ሩት',
    '1 Samuel': '1 ሳሙኤል',
    '2 Samuel': '2 ሳሙኤል',
    '1 Kings': '1 ነገሥት',
    '2 Kings': '2 ነገሥት',
    '1 Chronicles': '1 ዜና መዋዕል',
    '2 Chronicles': '2 ዜና መዋዕል',
    Ezra: 'ዕዝራ',
    Nehemiah: 'ነህምያ',
    Esther: 'አስቴር',
    Job: 'ሥራ',
    Psalms: 'መዝሙረ',
    Proverbs: 'ምሳሌ',
    Ecclesiastes: 'መክብብ',
    'Song of Songs': 'መኃልየ መኃልይ',
    Isaiah: 'ኢሳይያስ',
    Jeremiah: 'ኤርምያስ',
    Lamentations: 'ሰቆቃወ',
    Ezekiel: 'ሕዝቅኤል',
    Daniel: 'ዳንኤል',
    Hosea: 'ሆሴዕ',
    Joel: 'ኢዩኤል',
    Amos: 'አሞጽ',
    Obadiah: 'አብድዩ',
    Jonah: 'ዮናስ',
    Micah: 'ሚክያስ',
    Nahum: 'ናሆም',
    Habakkuk: 'ዕንባቆም',
    Zephaniah: 'ሶፎንያስ',
    Haggai: 'ሐጌ',
    Zechariah: 'ዘካርያስ',
    Malachi: 'ሚልክያስ',
    Matthew: 'በማቴዎስ',
    Mark: 'ምልክት',
    Luke: 'ሉቃስ',
    John: 'ዮሐንስ',
    Acts: 'የሐዋርያት ሥራ',
    Romans: 'ወደ ሮሜ ሰዎች',
    '1 Corinthians': '1 ቆሮንቶስ',
    '2 Corinthians': '2 ቆሮንቶስ',
    Galatians: 'ገላትያ',
    Ephesians: 'ወደ ኤፌሶን ሰዎች',
    Philippians: 'ወደ ፊልጵስዩስ ሰዎች',
    Colossians: 'ቆላስይስ',
    '1 Thessalonians': '1 ተሰሎንቄ',
    '2 Thessalonians': '2 ተሰሎንቄ',
    '1 Timothy': '1 ጢሞቴዎስ',
    '2 Timothy': '2 ጢሞቴዎስ',
    Titus: 'ቲቶ',
    Philemon: 'ፊልሞና',
    Hebrews: 'ዕብራውያን',
    James: 'ያዕቆብ',
    '1 Peter': '1 የጴጥሮስ',
    '2 Peter': '2 የጴጥሮስ',
    '1 John': '1 ዮሐንስ',
    '2 John': '2 ዮሐንስ',
    '3 John': '3 ዮሐንስ',
    Jude: 'ይሁዳ',
    Revelation: 'የዮሐንስ ራእይ',
    'resources.prompt': 'የሚተረጉሙትን ምንባብ ተጨማሪ ማስተዋል ለማግኘት መጽሐፍ ቅዱሳዊ ምንጮች ያክሉ.',
    'resources.add': 'አንድ የመጽሐፍ ቅዱስ ሀብት ያክሉ',
    Manuscript: 'የእጅ ጽሑፍ',
    Morphology: 'ሞርፎሎጂ',
    Lexicon: 'ሌክሲከን',
    'Syntactic Tree': 'Syntactic ዛፍ',
    'Go to project list': 'ፕሮጀክት ዝርዝር ይሂዱ',
    'Project not found': 'ፕሮጀክት አልተገኘም ወይም በቂ ፍቃዶች አይደለም.',
    'error.referenceDoesNotExistForResource': 'ይህ የመጽሐፍ ቅዱስ ትርጉም ለተመረጠው ምንባብ የሚሆን ይዘት የለውም.',
    'references.addAnother': 'ሌላ ትርጉም አክል',
    'references.referencePrompt': 'ተጨማሪ ማስተዋል ለማግኘት ሌሎች የመጽሐፍ ቅዱስ ትርጉሞችን ያክሉ.',
    'references.add': 'አንድ የመጽሐፍ ቅዱስ ትርጉም አክል',
    'translation.complete': 'ትርጉም ተጠናቅቋል',
    Alignment: 'ስለፋ',
    Share: 'አጋራ',
    'Your Translation': 'የእርስዎ ትርጉም',
    'Enter your translation': 'የእርስዎ ትርጉም ያስገቡ',
    OK: 'እሺ',
    'error.verseTranslationEmpty': 'አንተ የትርጉም ይዘት ባዶ መተው አይችሉም.',
    'translation.hide.completed': 'ደብቅ ተጠናቋል',
    alignment: 'ስለፋ',
    'alignment.verify': 'አረጋግጥ',
    'alignment.backToEditor': 'አርታኢ ተመለስ',
    'alignment.error': 'የሆነ ስህተት ተከስቷል. ችግሩ ከቀጠለ, YouTranslate.Bible ቡድን ያነጋግሩ.',
    'alignment.add': 'አገናኝ አክል',
    'alignment.delete': 'አገናኝ አስወግድ',
    'alignment.clear': 'ምርጫ አጽዳ',
    'alignment.reverse': 'ግልብጥ ማሳያ',
    'alignment.removeOrClear': 'የተመረጠው አገናኝ ወይም ግልጽ ምርጫ አስወግድ.',
    'alignment.addOrRemove': 'አዲስ አገናኝ ይፍጠሩ ወይም ቀዳሚ አንድ ሰርዝ.',
    'alignment.selectAndAdd': 'አገናኝ ወደ አንጓዎች ተጓዳኝ ይምረጡ.',
    'error.mustBeSignedToMakeChanges': 'እንድታደርገው ለውጦች ውስጥ መግባት አለበት.',
    'error.mustBeSignedToTranslateBible': 'መጽሐፍ ቅዱስ ለመተርጎም ውስጥ መግባት አለብዎት.',
    checking: 'በማረጋገጥ ላይ',
    'verseEditor.verseSuggestions': 'ጥቆማዎች',
    verses: 'ቁጥር በ',
    words: 'ቃል',
    'leftPanel.name': 'የመጽሐፍ ቅዱስ የመጀመሪያው ጽሑፍ',
    'rightPanel.name': 'መጽሐፍ ቅዱስ ትርጉሞች',
    'bottomPanel.name': 'መጽሐፍ ቅዱስ ስለ መርጃዎች',
    'syntax.adv': 'ተውሳከ ግስ',
    'syntax.advp': 'ተውሳከ ሐረግ',
    'syntax.cl': 'አንቀጽ',
    'syntax.conj': 'መስተፃምር',
    'syntax.cjp': 'መስተፃምር',
    'syntax.np': 'ስም ሐረግ',
    'syntax.o': 'ነገር',
    'syntax.io': 'በተዘዋዋሪ የነገር',
    'syntax.p': 'ተሳቢው',
    'syntax.pp': 'Prepositional ሐረግ',
    'syntax.s': 'ትምህርት',
    'syntax.v': 'ግሥ',
    'syntax.vc': 'ግሥ አንቀጽ',
    'syntax.adv.description':
      'ይቀይረዋል ንግግር ሌላ ክፍል. በተለምዶ ወዘተ በሆነ መንገድ, ቦታ, ጊዜ, ድግግሞሽ, ዲግሪ, በእርግጠኝነት ደረጃ, ለመግለጽ',
    'syntax.advp.description':
      'ባለብዙ-ቃል አገላለጽ ንግግር ይቀይረዋል ሌላ ክፍል. በተለምዶ ወዘተ በሆነ መንገድ, ቦታ, ጊዜ, ድግግሞሽ, ዲግሪ, በእርግጠኝነት ደረጃ, ለመግለጽ ...',
    'syntax.cl.description': 'አንድ ግስ የያዘ ዓረፍተ ነገር አንድ ክፍል.',
    'syntax.conj.description': 'ቃላት, ሐረጎች, እና ሐረጎች ያገናኛል.',
    'syntax.cjp.description': 'ቃላት, ሐረጎች, እና ሐረጎች ያገናኛል.',
    'syntax.np.description': 'አንድ ስም ተግባር ያከናውናል መሆኑን ባለብዙ-ቃል አገላለጽ.',
    'syntax.o.description': 'ርዕሰ በማድረግ ላይ እርምጃ ነው የሚለው አካል.',
    'syntax.io.description': 'ይህ ሰው ማስረዳት ከባድ ነው.',
    'syntax.p.description': 'ርዕሰ ይቀይረዋል. አብዛኛውን ጊዜ አንድ ግስ ጋር ኮከብ.',
    'syntax.pp.description': 'ይህ ሰው ማስረዳት ከባድ ነው.',
    'syntax.s.description': 'ወደ ሐረግ ያለውን ግስ የሚቆጣጠረው የትኛው አካል.',
    'syntax.v.description': 'አንድ እርምጃ ያስተላልፋል.',
    'syntax.vc.description': 'ባለብዙ-ቃል አገላለጽ መሆኑን እንደሚሰራ አንድ ግስ ተግባር (የያዘ ድርጊት).',
    'general.close': 'ገጠመ',
    'general.action': 'አክሽን',
    'general.select': 'ምረጥ ...',
    'general.back': 'ወደኋላ',
  },
  bn: {
    'languages.en': 'ইংরেজি',
    'languages.am': 'আমহারিক',
    'languages.bn': 'বাংলা ভাষা',
    'languages.ceb': 'চেবুয়ানো ভাষা',
    'languages.de': 'জার্মান ভাষা',
    'languages.es-419': 'স্প্যানিশ ভাষা',
    'languages.fr': 'ফ্রঞ্চ ভাষা',
    'languages.gu': 'গুজরাটি',
    'languages.hi': 'হিন্দি',
    'languages.hr': 'ক্রোয়েশীয় ভাষা',
    'languages.hu': 'হাঙ্গেরীয় ভাষা',
    'languages.id': 'ভাষা ইন্দোনেশীয়',
    'languages.km': 'খেমের',
    'languages.kn': 'কন্নড',
    'languages.ko': 'কোরিয়ান ভাষা',
    'languages.lo': 'লাওসের ভাষা',
    'languages.ml': 'মালায়ালম',
    'languages.mr': 'মারাঠি',
    'languages.ms': 'মালয়েশিয়ার',
    'languages.my': 'বর্মি ভাষা',
    'languages.ne': 'নেপালি ভাষা',
    'languages.plt': 'মালাগাসি ভাষা',
    'languages.pt-br': 'পর্তুগিজ (ব্রাজিল) ভাষা',
    'languages.ru': 'রুশ ভাষা',
    'languages.sw': 'সোয়াহিলি',
    'languages.ta': 'তামিল ভাষা',
    'languages.th': 'থাই ভাষা',
    'languages.tl': 'তাগালোগ',
    'languages.te': 'তেলেগু ভাষা',
    'languages.vi': 'ভাষা ভিয়েতনামী',
    'languages.zh-hans': 'চীনা ভাষা (সরলীকৃত)',
    'languages.zh-hant': 'চীনা ভাষা (ঐতিহ্যবাহী)',
    'header.home': 'বাড়ি',
    'header.help': 'সাহায্য',
    'header.project': 'প্রকল্প',
    'help.contactSupport': 'দয়া করে আমাদের সহায়তার জন্য help@youtranslate.bible এ ইমেইল করুন।',
    'home.ytbProvides': 'YouTranslate.Bible অবাধে প্রদান করে:',
    'home.easyToUseTool': 'একটি সহজ-থেকে-ব্যবহার অনলাইন অনুবাদ সরঞ্জাম',
    'home.originalTextHelp': 'তার মূল ভাষায় বাইবেল টেক্সট বুঝতে সাহায্য',
    'home.referencesAndResources': 'রেফারেন্স ও প্রশিক্ষণ সম্পদ',
    'home.computerAssistance': 'রাজ্য-অত্যাধুনিক কম্পিউটার প্রযুক্তি অনুবাদক সহায়তা করার',
    'profile.userProfile': 'ব্যাবহারকারীর বিস্তারিত',
    'profile.language': 'ভাষা',
    'profile.update': 'হালনাগাদ',
    'usernav.profile': 'প্রোফাইল',
    'usernav.logout': 'প্রস্থান',
    'usernav.logout.modalTitle': 'YouTranslate.Bible এর লগআউট',
    'usernav.logout.modalText': 'আপনি লগ আউট করতে চান? সমস্ত অসংরক্ষিত কাজ হারিয়ে যাবে।',
    cancel: 'বাতিল',
    delete: 'মুছে ফেলা',
    create: 'সৃষ্টি',
    update: 'হালনাগাদ',
    edit: 'সম্পাদন করা',
    save: 'সংরক্ষণ',
    add: 'যোগ',
    'project.create.title': 'বাইবেল অনুবাদ প্রকল্প',
    'project.create.list': 'প্রকল্পের তালিকা',
    'project.list.deleteProject': 'প্রকল্প মুছে ফেলতে চান?',
    'project.list.areYouSure': 'আপনি এই প্রকল্পের মুছে ফেলতে চান আপনি কি নিশ্চিত?',
    'project.list.workWillBeDeleted': 'প্রোজেক্ট এবং সম্পর্কিত কোন কাজ মুছে ফেলা হবে।',
    'project.list.completion': 'সম্পূর্ণকরণ:',
    'project.list.createdDate': 'তৈরীর তারিখ:',
    'project.list.beginningDate': 'শুরুতে তারিখ:',
    'project.list.targetCompletionDate': 'উদ্দিষ্ট সম্পূর্ণতা তারিখ:',
    'project.list.description': 'বর্ণনা:',
    'project.edit': 'প্রকল্প সম্পাদনা',
    'project.list.translateBible': 'অনুবাদ বাইবেল',
    'project.list.empty': 'আপনার অনুবাদ প্রকল্প তালিকা খালি।',
    'project.list.myProjects': 'আমার বাইবেল অনুবাদ প্রকল্প',
    'project.list.newProject': 'নতুন প্রকল্প',
    'project.create.createNew': 'একটি নতুন প্রকল্প তৈরি',
    'project.namePlaceholder': 'প্রকল্পের নাম লিখুন',
    'project.descriptionPlaceholder': 'প্রকল্পের বিবরণ লিখুন',
    'project.name': 'প্রকল্পের নাম',
    'project.beginningDate': 'শুরুতে তারিখ',
    'project.targetCompletionDate': 'উদ্দিষ্ট সমাপ্তির তারিখ',
    'project.description': 'বিবরণ',
    'project.manage-users': 'অনুবাদক পরিচালনা',
    'project.new-user': 'নিউ অনুবাদক',
    'project.add-user': 'অনুবাদক যোগ',
    'project.users': 'প্রকল্পের অনুবাদকদের',
    'project.read-only': 'শুধুমাত্র পাঠযোগ্য',
    'project.read&write': 'পড়া & লেখা',
    'project.owner': 'মালিক',
    'project.translator': 'অনুবাদক',
    'project.message.no-such-user': 'জাতীয় কোনও অনুবাদক বিদ্যমান',
    'project.message.user-added': 'অনুবাদক সফলভাবে যোগ করা',
    'project.message.user-deleted': 'অনুবাদক সরানো হয়েছে।',
    'project.message.only-owner-can-manage-users':
      'শুধু প্রকল্প মালিক ব্যবহারকারীদের পরিচালনা করতে পারেন',
    'project.shared': 'সহযোগিতামূলক প্রকল্প',
    'general.delete-user': 'মুছে ফেলুন অনুবাদক',
    'general.edit-user': 'ব্যবহারকারী সম্পাদনা করুন',
    'general.name': 'নাম',
    'general.email': 'ই-মেইল',
    'general.permissions': 'অনুমতিসমূহ',
    'general.role': 'ভূমিকা',
    signin: 'সাইন ইন করুন',
    'signin.emailPlaceholder': 'ই-মেইল ঠিকানা',
    'signin.passwordPlaceholder': 'পাসওয়ার্ড',
    'signin.forgotPassword': 'আপনি কি পাসওয়ার্ড ভুলে গেছেন?',
    'signin.rememberMe': 'আমাকে মনে কর',
    'signin.facebook': 'ফেসবুকে সাইন - ইন করুন',
    'signin.google': 'Google দিয়ে সাইন ইন',
    'signin.dontHaveAccount': 'একটি অ্যাকাউন্ট নেই?',
    'signin.createOne': 'একটি তৈরী কর',
    'signup.join': 'YouTranslate.Bible যোগদান',
    'signup.createAccountPrompt': 'আপনার বিনামূল্যে একাউন্ট শুরু করার তৈরি করুন।',
    'signup.createAccount': 'হিসাব তৈরি কর',
    'signup.emailPlaceholder': 'ই-মেইল ঠিকানা',
    'signup.passwordPlaceholder': 'পাসওয়ার্ড',
    'translation.project': 'প্রকল্প:',
    'translation.backToProjectList': 'প্রকল্পের তালিকা ফিরে যান',
    'translation.book': 'বই:',
    'translation.chapter': 'অধ্যায়:',
    'translation.prompt':
      'আপনি এই অধ্যায়ের ছেড়ে দিতে চান আপনি কি নিশ্চিত? সমস্ত অসংরক্ষিত কাজ হারিয়ে যাবে।',
    'translation.prev': 'পূর্ববর্তী',
    'translation.next': 'পরবর্তী',
    'translation.previousChapter': 'পূর্ববর্তী অধ্যায়ে',
    'translation.nextChapter': 'পরের অধ্যায়',
    'navigator.book': 'বই:',
    'navigator.chapter': 'অধ্যায়:',
    'navigator.oldTestament': 'ওল্ড টেস্টামেন্ট',
    'navigator.newTestament': 'নববিধান',
    'navigator.selectBook': 'একটি বই নির্বাচন করুন',
    'navigator.search': 'অনুসন্ধান করুন',
    'navigator.changeBook': 'বই পরিবর্তন',
    'navigator.changeChapter': 'অধ্যায় পরিবর্তন',
    Genesis: 'জনন',
    Exodus: 'প্রস্থান',
    Leviticus: 'লেবীয় পুস্তক',
    Numbers: 'নাম্বার',
    Deuteronomy: 'দ্বিতীয় বিবরণ',
    Joshua: 'জশুয়া',
    Judges: 'বিচারকদের',
    Ruth: 'করুণা',
    '1 Samuel': '1 শমূয়েল',
    '2 Samuel': '2 স্যামুয়েল',
    '1 Kings': '1 রাজাবলি',
    '2 Kings': '2 কিংস',
    '1 Chronicles': 'বংশাবলি 1',
    '2 Chronicles': '2 ক্রনিকলস',
    Ezra: 'ইষ্রা',
    Nehemiah: 'নেহেমিয়া',
    Esther: 'ইষ্টের',
    Job: 'কাজ',
    Psalms: 'সাম',
    Proverbs: 'বাগধারা',
    Ecclesiastes: 'উপদেশক',
    'Song of Songs': 'গান গানের',
    Isaiah: 'ইশাইয়ার',
    Jeremiah: 'যিরমিয়',
    Lamentations: 'মাতম',
    Ezekiel: 'এজেকিয়েল',
    Daniel: 'ড্যানিয়েল',
    Hosea: 'হোসেয়া',
    Joel: 'জোএল',
    Amos: 'আমোস',
    Obadiah: 'ওবদিয়',
    Jonah: 'দুর্ভাগ্যআনয়নকারী',
    Micah: 'মীখা',
    Nahum: 'নহূম',
    Habakkuk: 'হবক্কূক',
    Zephaniah: 'সফনিয়',
    Haggai: 'হগয়',
    Zechariah: 'সখরিয়',
    Malachi: 'মালাখি',
    Matthew: 'ম্যাথু',
    Mark: 'ছাপ',
    Luke: 'লুক',
    John: 'জন',
    Acts: 'প্রেরিত',
    Romans: 'রোমানরা',
    '1 Corinthians': '1 করিন্থীয়',
    '2 Corinthians': '2 করিন্থিয়ান্স',
    Galatians: 'গালাতীয়',
    Ephesians: 'ইফিষীয়',
    Philippians: 'ফিলিপীয়',
    Colossians: 'কলসীয়',
    '1 Thessalonians': '1 থিষলনীকীয়',
    '2 Thessalonians': '2 থিষলনীকীয়',
    '1 Timothy': '1 তীমথিয়',
    '2 Timothy': '2 টিমোথি',
    Titus: 'তিতাস',
    Philemon: 'ফিলীমন',
    Hebrews: 'হিব্রুদের কাছে পত্র',
    James: 'জেমস',
    '1 Peter': 'পিতরের 1 ম পত্র',
    '2 Peter': '2 পিটার',
    '1 John': '1 যোহন',
    '2 John': '2 জন',
    '3 John': '3 যোহন',
    Jude: 'যিহূদা',
    Revelation: 'উদ্ঘাটন',
    'resources.prompt':
      'উত্তরণ আপনি অনুবাদ করছেন অতিরিক্ত অন্তর্দৃষ্টি জন্য বাইবেলের সম্পদ যুক্ত করো।',
    'resources.add': 'একটি বাইবেলের রিসোর্স যোগ করুন',
    Manuscript: 'পাণ্ডুলিপি',
    Morphology: 'অঙ্গসংস্থানবিদ্যা',
    Lexicon: 'শব্দকোষ',
    'Syntactic Tree': 'অন্বিত বৃক্ষ',
    'Go to project list': 'প্রকল্পের তালিকাতে যান',
    'Project not found': 'প্রকল্প খুঁজে পাওয়া যায়নি অথবা অপর্যাপ্ত অনুমতির।',
    'error.referenceDoesNotExistForResource':
      'এই বাইবেল অনুবাদ নির্বাচিত উত্তরণ জন্য সামগ্রী ধারণ করে না।',
    'references.addAnother': 'অন্য অনুবাদ যোগ করুন',
    'references.referencePrompt': 'অতিরিক্ত অন্তর্দৃষ্টি জন্য বাইবেল অন্যান্য অনুবাদের যুক্ত করো।',
    'references.add': 'একটি বাইবেল অনুবাদ যোগ করুন',
    'translation.complete': 'অনুবাদ সম্পুর্ণ',
    Alignment: 'শ্রেণীবিন্যাস',
    Share: 'ভাগ',
    'Your Translation': 'আপনার অনুবাদ',
    'Enter your translation': 'আপনার অনুবাদ লিখুন',
    OK: 'ঠিক আছে',
    'error.verseTranslationEmpty': 'আপনি অনুবাদ বিষয়বস্তু খালি রাখুন করতে পারবে না।',
    'translation.hide.completed': 'সম্পন্ন লুকান',
    alignment: 'শ্রেণীবিন্যাস',
    'alignment.verify': 'যাচাই করুন',
    'alignment.backToEditor': 'সম্পাদনায় ফিরুন',
    'alignment.error':
      'কিছু ভুল হয়েছে. সমস্যা থেকে গেলে, YouTranslate.Bible দলের সাথে যোগাযোগ করুন।',
    'alignment.add': 'লিংক যোগ করুন',
    'alignment.delete': 'লিঙ্ক সরান',
    'alignment.clear': 'সাফ নির্বাচন',
    'alignment.reverse': 'বিপরীত প্রদর্শন',
    'alignment.removeOrClear': 'নির্বাচিত লিঙ্ক অথবা স্পষ্ট নির্বাচন সরান।',
    'alignment.addOrRemove': 'একটি নতুন লিঙ্ক তৈরি করুন বা একটি পূর্ববর্তী মুছে ফেলুন।',
    'alignment.selectAndAdd': 'লিঙ্কে অংশ সংশ্লিষ্ট নির্বাচন করুন।',
    'error.mustBeSignedToMakeChanges': 'আপনার করা পরিবর্তনগুলি এ সাইন ইন করতে হবে।',
    'error.mustBeSignedToTranslateBible': 'আপনি বাইবেল অনুবাদ করতে সাইন ইন করতে হবে।',
    checking: 'পরীক্ষা করা হচ্ছে',
    'verseEditor.verseSuggestions': 'পরামর্শ',
    verses: 'দ্বারা আয়াত',
    words: 'শব্দ অনুসারে',
    'leftPanel.name': 'বাইবেলের মূল পাঠ্য',
    'rightPanel.name': 'বাইবেল অনুবাদ',
    'bottomPanel.name': 'বাইবেল জন্য সম্পদগুলি',
    'syntax.adv': 'বিশেষণের বিশেষণ',
    'syntax.advp': 'ক্রিয়া বিশেষণ শব্দবন্ধ',
    'syntax.cl': 'দফা',
    'syntax.conj': 'সংযোগ',
    'syntax.cjp': 'সংযোগ',
    'syntax.np': 'বিশেষ্য ফ্রেজ',
    'syntax.o': 'উদ্দেশ্য',
    'syntax.io': 'পরোক্ষ বস্তু',
    'syntax.p': 'সূত্রের বিধেয়',
    'syntax.pp': 'পদান্বয়ী অব্যয় ফ্রেজ',
    'syntax.s': 'বিষয়',
    'syntax.v': 'ক্রিয়া',
    'syntax.vc': 'ক্রিয়া ধারা',
    'syntax.adv.description':
      'বাক অন্য অংশ পরিবর্তন করে। সাধারণত পদ্ধতিতে, স্থান, সময়, পৌনঃপুনিকতা, ডিগ্রী, নিশ্চিতভাবে মাত্রা, ইত্যাদি প্রকাশ',
    'syntax.advp.description':
      'একটি বহু-শব্দ অভিব্যক্তি মডিফাই বাক অন্য অংশ। সাধারণত পদ্ধতিতে, স্থান, সময়, পৌনঃপুনিকতা, ডিগ্রী, নিশ্চিতভাবে মাত্রা, ইত্যাদি প্রকাশ করার ...',
    'syntax.cl.description': 'একটি বাক্য যে একটি ক্রিয়া রয়েছে একটি অংশ।',
    'syntax.conj.description': 'শব্দ, বাক্যাংশ, এবং ক্লজ সংযোগ করে।',
    'syntax.cjp.description': 'শব্দ, বাক্যাংশ, এবং ক্লজ সংযোগ করে।',
    'syntax.np.description': 'একটি বহু-শব্দ অভিব্যক্তি একটি বিশেষ্য এর ফাংশন সম্পাদন করে।',
    'syntax.o.description': 'অস্তিত্বের বিষয় অনুসারে সে অনুপাতে কাজ করা হয়।',
    'syntax.io.description': 'এই এক ব্যাখ্যা করা কঠিন।',
    'syntax.p.description': 'বিষয় পরিবর্তন করে। সাধারণত একটি ক্রিয়াপদ সঙ্গে বড়।',
    'syntax.pp.description': 'এই এক ব্যাখ্যা করা কঠিন।',
    'syntax.s.description': 'বাণিজ্যিক সত্বা যার দফা ক্রিয়া নিয়ন্ত্রণ করে।',
    'syntax.v.description': 'একটি কর্ম conveys।',
    'syntax.vc.description':
      'একটি বহু-শব্দ অভিব্যক্তি সঞ্চালিত একটি ক্রিয়াপদ ফাংশন (conveys একটি কর্ম)।',
    'general.close': 'ঘনিষ্ঠ',
    'general.action': 'কর্ম',
    'general.select': 'নির্বাচন করুন ...',
    'general.back': 'পেছনে',
  },
  ceb: {
    'languages.en': 'Iningles',
    'languages.am': 'Amharic',
    'languages.bn': 'Bangla Pinulongan',
    'languages.ceb': 'Cebuano',
    'languages.de': 'German nga Pinulongan',
    'languages.es-419': 'Espanyol Pinulongan',
    'languages.fr': 'Pranses Pinulongan',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Dili',
    'languages.hr': 'nga Croatian Pinulongan',
    'languages.hu': 'Hinungriyanon Pinulongan',
    'languages.id': 'Indonesian nga Pinulongan',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Korean Language',
    'languages.lo': 'Laotian Pinulongan',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Burmese Pinulongan',
    'languages.ne': 'Minepalhon Pinulongan',
    'languages.plt': 'Malagasy Pinulongan',
    'languages.pt-br': 'Portuges (Brazil) Pinulongan',
    'languages.ru': 'Russian nga Pinulongan',
    'languages.sw': 'sa Swahili',
    'languages.ta': 'Tamil nga Pinulongan',
    'languages.th': 'Thailand Pinulongan',
    'languages.tl': 'Tinagalog',
    'languages.te': 'Telegu Pinulongan',
    'languages.vi': 'Vietnamese Pinulongan',
    'languages.zh-hans': 'Sa China Language (Simplified)',
    'languages.zh-hant': 'Sa China Language (Tradisyonal)',
    'header.home': 'Panimalay',
    'header.help': 'Tabang',
    'header.project': 'Project',
    'help.contactSupport': 'Palihug email kanato sa help@youtranslate.bible alang sa tabang.',
    'home.ytbProvides': 'YouTranslate.Bible sa walay bayad naghatag:',
    'home.easyToUseTool': 'Usa ka sayon-sa-paggamit sa online nga himan sa paghubad',
    'home.originalTextHelp':
      'Tabang sa pagsabut sa teksto sa Bibliya diha sa iyang orihinal nga mga pinulongan',
    'home.referencesAndResources': 'Reference ug pagbansay mga kapanguhaan',
    'home.computerAssistance':
      'State-of-the-art nga computer teknolohiya sa pagtabang sa mga maghuhubad',
    'profile.userProfile': 'profile user',
    'profile.language': 'pinulongan',
    'profile.update': 'update',
    'usernav.profile': 'profile',
    'usernav.logout': 'Logout',
    'usernav.logout.modalTitle': 'Logout sa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sigurado nga gusto mo sa logout? Ang tanan nga dili-luwas nga buhat mawala.',
    cancel: 'Cancel',
    delete: 'pagwagtang',
    create: 'Paghimo',
    update: 'update',
    edit: 'edit',
    save: 'Luwasa',
    add: 'Idugang',
    'project.create.title': 'Paghubad sa Bibliya Project',
    'project.create.list': 'listahan Project',
    'project.list.deleteProject': 'Panas Project?',
    'project.list.areYouSure': 'Ikaw ba sigurado nga imong gusto sa panas niini nga proyekto?',
    'project.list.workWillBeDeleted':
      'Ang proyekto ug sa bisan unsa nga buhat nga nalangkit nga thread.',
    'project.list.completion': 'pagkompleto:',
    'project.list.createdDate': 'Gilalang Date:',
    'project.list.beginningDate': 'Sugod Date:',
    'project.list.targetCompletionDate': 'Target mahuman Date:',
    'project.list.description': 'Description:',
    'project.edit': 'edit Project',
    'project.list.translateBible': 'paghubad sa Bibliya',
    'project.list.empty': 'Ang imong listahan sa paghubad nga proyekto mao ang walay sulod.',
    'project.list.myProjects': 'Ang akong Bibliya Translation proyekto',
    'project.list.newProject': 'Bag-ong Project',
    'project.create.createNew': 'Create sa usa ka bag-o nga Project',
    'project.namePlaceholder': 'Pagsulod sa ngalan nga proyekto',
    'project.descriptionPlaceholder': 'Pagsulod sa proyekto nga paghulagway',
    'project.name': 'ngalan Project',
    'project.beginningDate': 'sugod Petsa',
    'project.targetCompletionDate': 'Target mahuman Petsa',
    'project.description': 'Description',
    'project.manage-users': 'pagdumala sa mga maghuhubad',
    'project.new-user': 'Bag-ong maghuhubad',
    'project.add-user': 'add maghuhubad',
    'project.users': 'maghuhubad Project',
    'project.read-only': 'Basaha lamang',
    'project.read&write': 'Basaha & Isulat',
    'project.owner': 'Tag-iya',
    'project.translator': 'Maghuhubad',
    'project.message.no-such-user': 'Walay ingon nga maghuhubad anaa',
    'project.message.user-added': 'Maghuhubad malampuson dugang pa',
    'project.message.user-deleted': 'gikuha Maghuhubad.',
    'project.message.only-owner-can-manage-users':
      'Lamang nga tag-iya nga proyekto makahimo sa pagdumala sa tiggamit',
    'project.shared': 'collaborative proyekto',
    'general.delete-user': 'pagwagtang maghuhubad',
    'general.edit-user': 'edit user',
    'general.name': 'Ngalan',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'papel',
    signin: 'Sign sa',
    'signin.emailPlaceholder': 'email address',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Nakalimot sa imong Password?',
    'signin.rememberMe': 'Hinumdumi ako',
    'signin.facebook': 'Sign sa uban Facebook',
    'signin.google': 'Sign sa uban Google',
    'signin.dontHaveAccount': 'Ayaw adunay usa ka asoy?',
    'signin.createOne': 'Paghimo sa usa ka',
    'signup.join': 'Apil sa YouTranslate.Bible',
    'signup.createAccountPrompt': 'Paghimo sa inyong free account sa pagsugod.',
    'signup.createAccount': 'Paghimo Account',
    'signup.emailPlaceholder': 'email address',
    'signup.passwordPlaceholder': 'password',
    'translation.project': 'PROYEKTO:',
    'translation.backToProjectList': 'Balik sa listahan proyekto',
    'translation.book': 'basahon:',
    'translation.chapter': 'kapitulo:',
    'translation.prompt':
      'Ikaw ba sigurado nga imong gusto nga mobiya sa niini nga kapitulo? Ang tanan nga dili-luwas nga buhat mawala.',
    'translation.prev': 'Prev',
    'translation.next': 'sunod',
    'translation.previousChapter': 'miaging kapitulo',
    'translation.nextChapter': 'sunod nga kapitulo',
    'navigator.book': 'basahon:',
    'navigator.chapter': 'kapitulo:',
    'navigator.oldTestament': 'Daang Tugon',
    'navigator.newTestament': 'Bag-ong Tugon',
    'navigator.selectBook': 'Pagpili og usa ka basahon',
    'navigator.search': 'Pangitaa',
    'navigator.changeBook': 'Change nga basahon',
    'navigator.changeChapter': 'Change kapitulo',
    Genesis: 'Genesis',
    Exodus: 'Exodo',
    Leviticus: 'Levitico',
    Numbers: 'Numeros',
    Deuteronomy: 'Deuteronomio',
    Joshua: 'Josue',
    Judges: 'Maghuhukom',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Hari',
    '1 Chronicles': '1 Cronicas',
    '2 Chronicles': '2 Cronicas',
    Ezra: 'Esdras',
    Nehemiah: 'Nehemias',
    Esther: 'Ester',
    Job: 'Job',
    Psalms: 'Salmo',
    Proverbs: 'Proverbio',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Awit sa mga Awit',
    Isaiah: 'Isaias',
    Jeremiah: 'Jeremias',
    Lamentations: 'Lamentaciones',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oseas',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdias',
    Jonah: 'Jonas',
    Micah: 'Miqueas',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sofonias',
    Haggai: 'Haggeo',
    Zechariah: 'Zacarias',
    Malachi: 'Malaquias',
    Matthew: 'Mateo',
    Mark: 'Marcos',
    Luke: 'Lucas',
    John: 'Juan',
    Acts: 'Buhat',
    Romans: 'Taga-Roma',
    '1 Corinthians': '1 Mga Taga-Corinto',
    '2 Corinthians': '2 Corinto',
    Galatians: 'Galacia',
    Ephesians: 'Taga-Efeso',
    Philippians: 'Filipos',
    Colossians: 'Colosas',
    '1 Thessalonians': '1 Tesalonica',
    '2 Thessalonians': '2 Tesalonica',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Filemon',
    Hebrews: 'Hebreohanon',
    James: 'Santiago',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 Juan',
    '3 John': '3 Juan',
    Jude: 'Judas',
    Revelation: 'Pinadayag',
    'resources.prompt':
      'Idugang mga kapanguhaan sa Bibliya alang sa dugang pagsabot sa tudling nga imong paghubad.',
    'resources.add': 'Add usa ka Biblikanhong kapanguhaan',
    Manuscript: 'Manuscript',
    Morphology: 'morpolohiya',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Kahoy',
    'Go to project list': 'Lakaw ngadto sa listahan sa proyekto',
    'Project not found': 'Project dili makaplagan o insufficient Permissions.',
    'error.referenceDoesNotExistForResource':
      'Kini nga hubad sa Bibliya wala sulod alang sa pinili nga tudling.',
    'references.addAnother': 'Idugang sa laing hubad',
    'references.referencePrompt':
      'Idugang sa ubang mga hubad sa Bibliya alang sa dugang nga panabut.',
    'references.add': 'Add usa ka hubad sa Bibliya',
    'translation.complete': 'Hubad bug-os nga',
    Alignment: 'paglaray, pagtalay',
    Share: 'Share',
    'Your Translation': 'Ang imong Hubad',
    'Enter your translation': 'Pagsulod sa imong hubad',
    OK: 'OK',
    'error.verseTranslationEmpty': 'Ikaw dili mobiya sa sulod sa paghubad biyaan.',
    'translation.hide.completed': 'panit nahuman',
    alignment: 'paglaray, pagtalay',
    'alignment.verify': 'Susiha',
    'alignment.backToEditor': 'Balik sa Editor',
    'alignment.error':
      'Usa ka butang nga miadto sayop. Kon ang nagapadayon nga problema, palihog kontaka ang YouTranslate.Bible team.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Kuhaa Link',
    'alignment.clear': 'Tin-aw Pagpili',
    'alignment.reverse': 'reverse Ipakita',
    'alignment.removeOrClear': 'Kuhaa ang mga pinili nga link o tin-aw nga pagpili.',
    'alignment.addOrRemove': 'Create sa usa ka bag-o nga link o panas usa ka miaging usa.',
    'alignment.selectAndAdd': 'Pagpili katugbang bahin sa link.',
    'error.mustBeSignedToMakeChanges':
      'Kamo kinahanglan gayud nga nga gipirmahan sa sa sa paghimo sa mga kausaban.',
    'error.mustBeSignedToTranslateBible':
      'Kamo kinahanglan gayud nga nga gipirmahan sa sa paghubad sa Bibliya.',
    checking: 'pagsusi',
    'verseEditor.verseSuggestions': 'Sugyot',
    verses: 'pinaagi sa Bersikulo',
    words: 'pinaagi sa Pulong sa',
    'leftPanel.name': 'Orihinal nga teksto sa Bibliya',
    'rightPanel.name': 'Hubad sa Bibliya',
    'bottomPanel.name': 'Mga kapanguhaan alang sa mga Bibliya',
    'syntax.adv': 'adverbio',
    'syntax.advp': 'adverbio nga Hugpong sa Pulong',
    'syntax.cl': 'clause',
    'syntax.conj': 'inubanan sa',
    'syntax.cjp': 'inubanan sa',
    'syntax.np': 'noun Hugpong sa Pulong',
    'syntax.o': 'butang',
    'syntax.io': 'dili direkta nga Object',
    'syntax.p': 'predicate',
    'syntax.pp': 'Prepositional Hugpong sa Pulong',
    'syntax.s': 'subject',
    'syntax.v': 'berbo',
    'syntax.vc': 'berbo nga Clause',
    'syntax.adv.description':
      'Modifies laing bahin sa sinultihan. Kasagaran sa pagpahayag nga paagi, dapit, panahon, frequency, matang, ang-ang sa pagkatinuod, ug uban pa',
    'syntax.advp.description':
      'Usa ka multi-pulong nga ekspresyon nga modifies laing bahin sa sinultihan. Kasagaran sa pagpahayag nga paagi, dapit, panahon, frequency, matang, ang-ang sa pagkatinuod, ug uban pa ...',
    'syntax.cl.description':
      'Usa ka bahin sa usa ka tudling-pulong nga naglangkob sa usa ka berbo.',
    'syntax.conj.description': 'Nagsumpay sa mga pulong, hugpong sa mga pulong, ug mga pulong.',
    'syntax.cjp.description': 'Nagsumpay sa mga pulong, hugpong sa mga pulong, ug mga pulong.',
    'syntax.np.description':
      'Usa ka multi-pulong nga ekspresyon nga nagabuhat sa function sa usa ka noun.',
    'syntax.o.description': 'Ang kompaniya nga milihok diha sa ibabaw sa hilisgutan.',
    'syntax.io.description': 'Kini mao ang lisud nga sa pagpatin-aw.',
    'syntax.p.description': 'Modifies sa hilisgutan. Kasagaran mga bituon sa usa ka berbo.',
    'syntax.pp.description': 'Kini mao ang lisud nga sa pagpatin-aw.',
    'syntax.s.description': 'Ang kompaniya nga kontrol sa berbo sa clause.',
    'syntax.v.description': 'Nagpasabot sa usa ka aksyon.',
    'syntax.vc.description':
      'Usa ka multi-pulong nga ekspresyon nga gihimo sa ang function sa usa ka berbo (gipadapat magpasabot sa usa ka aksyon).',
    'general.close': 'Suod nga',
    'general.action': 'Action',
    'general.select': 'Pagpili ...',
    'general.back': 'balik',
  },
  de: {
    'languages.en': 'Englisch',
    'languages.am': 'Amharisch',
    'languages.bn': 'Bengali Sprache',
    'languages.ceb': 'Cebuano',
    'languages.de': 'Deutsche Sprache',
    'languages.es-419': 'Spanische Sprache',
    'languages.fr': 'Französisch Sprache',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroatische Sprache',
    'languages.hu': 'Ungarische Sprache',
    'languages.id': 'Indonesische Sprache',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Koreanische Sprache',
    'languages.lo': 'laotische Sprache',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'malaysisch',
    'languages.my': 'burmesischen Sprache',
    'languages.ne': 'Nepali Sprache',
    'languages.plt': 'Malagasy Sprache',
    'languages.pt-br': 'Portugiesisch (Brasilien) Sprache',
    'languages.ru': 'Russische Sprache',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamilische Sprache',
    'languages.th': 'Thai Sprache',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Sprache',
    'languages.vi': 'Vietnamesische Sprache',
    'languages.zh-hans': 'Chinesische Sprache (vereinfacht)',
    'languages.zh-hant': 'Chinesische Sprache (traditionell)',
    'header.home': 'Zuhause',
    'header.help': 'Hilfe',
    'header.project': 'Projekt',
    'help.contactSupport': 'Bitte mailen Sie an help@youtranslate.bible für Unterstützung.',
    'home.ytbProvides': 'YouTranslate.Bible bietet frei:',
    'home.easyToUseTool': 'Ein einfach zu bedienende Online-Übersetzungs-Tool',
    'home.originalTextHelp':
      'Helfen Sie den Text der Bibel in ihren ursprünglichen Sprachen zu verstehen',
    'home.referencesAndResources': 'Referenz und Schulungsressourcen',
    'home.computerAssistance': 'State-of-the-art Computer-Technologie Übersetzer zu unterstützen,',
    'profile.userProfile': 'Benutzerprofil',
    'profile.language': 'Sprache',
    'profile.update': 'Aktualisieren',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Ausloggen',
    'usernav.logout.modalTitle': 'Logout von YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sind Sie sicher, dass Sie sich ausloggen? Alle nicht gespeicherten Daten gehen verloren.',
    cancel: 'Stornieren',
    delete: 'Löschen',
    create: 'Erstellen',
    update: 'Aktualisieren',
    edit: 'Bearbeiten',
    save: 'speichern',
    add: 'Hinzufügen',
    'project.create.title': 'Bibel Übersetzungsprojekt',
    'project.create.list': 'Projektliste',
    'project.list.deleteProject': 'Projekt löschen?',
    'project.list.areYouSure': 'Sind Sie sicher, dass Sie dieses Projekt löschen?',
    'project.list.workWillBeDeleted':
      'Das Projekt und jede Arbeit verbunden sind, werden gelöscht.',
    'project.list.completion': 'Fertigstellung:',
    'project.list.createdDate': 'Erstellt am:',
    'project.list.beginningDate': 'Anfangsdatum:',
    'project.list.targetCompletionDate': 'Ziel Fertigstellung:',
    'project.list.description': 'Beschreibung:',
    'project.edit': 'Projekt bearbeiten',
    'project.list.translateBible': 'Übersetzen Bibel',
    'project.list.empty': 'Ihr Übersetzungsprojekt Liste ist leer.',
    'project.list.myProjects': 'Meine Bibel Übersetzungsprojekte',
    'project.list.newProject': 'Neues Projekt',
    'project.create.createNew': 'Erstellen Sie ein neues Projekt',
    'project.namePlaceholder': 'Geben Sie Projektnamen',
    'project.descriptionPlaceholder': 'Geben Sie Projektbeschreibung',
    'project.name': 'Projektname',
    'project.beginningDate': 'Anfangsdatum',
    'project.targetCompletionDate': 'Ziel Fertigstellung',
    'project.description': 'Beschreibung',
    'project.manage-users': 'verwalten Übersetzer',
    'project.new-user': 'New Übersetzer',
    'project.add-user': 'In Übersetzer',
    'project.users': 'Projekt Übersetzer',
    'project.read-only': 'Schreibgeschützt',
    'project.read&write': 'Lesen Schreiben',
    'project.owner': 'Inhaber',
    'project.translator': 'Übersetzer',
    'project.message.no-such-user': 'Eine solche Übersetzer vorhanden',
    'project.message.user-added': 'Übersetzer erfolgreich hinzugefügt',
    'project.message.user-deleted': 'Übersetzer entfernt.',
    'project.message.only-owner-can-manage-users': 'Nur Projektinhaber können Benutzer verwalten',
    'project.shared': 'Verbundprojekt',
    'general.delete-user': 'Delete Übersetzer',
    'general.edit-user': 'Benutzer bearbeiten',
    'general.name': 'Name',
    'general.email': 'Email',
    'general.permissions': 'Berechtigungen',
    'general.role': 'Rolle',
    signin: 'Einloggen',
    'signin.emailPlaceholder': 'E-Mail-Addresse',
    'signin.passwordPlaceholder': 'Passwort',
    'signin.forgotPassword': 'Haben Sie Ihr Passwort vergessen?',
    'signin.rememberMe': 'Erinnere dich an mich',
    'signin.facebook': 'Mit Facebook anmelden',
    'signin.google': 'Anmeldung mit Google',
    'signin.dontHaveAccount': 'Sie haben noch kein Konto?',
    'signin.createOne': 'Erstelle einen',
    'signup.join': 'Registriert YouTranslate.Bible',
    'signup.createAccountPrompt': 'Legen Sie Ihr gratis Konto zu beginnen.',
    'signup.createAccount': 'Benutzerkonto anlegen',
    'signup.emailPlaceholder': 'E-Mail-Addresse',
    'signup.passwordPlaceholder': 'Passwort',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Zurück zur Projektliste',
    'translation.book': 'Buch:',
    'translation.chapter': 'Kapitel:',
    'translation.prompt':
      'Sind Sie sicher, dass Sie dieses Kapitel verlassen? Alle nicht gespeicherten Daten gehen verloren.',
    'translation.prev': 'Zurück',
    'translation.next': 'Nächster',
    'translation.previousChapter': 'Vorheriges Kapitel',
    'translation.nextChapter': 'Nächstes Kapitel',
    'navigator.book': 'Buch:',
    'navigator.chapter': 'Kapitel:',
    'navigator.oldTestament': 'Altes Testament',
    'navigator.newTestament': 'Neues Testament',
    'navigator.selectBook': 'Wählen Sie ein Buch',
    'navigator.search': 'Suche',
    'navigator.changeBook': 'ändern Buch',
    'navigator.changeChapter': 'ändern Kapitel',
    Genesis: 'Genesis',
    Exodus: 'Exodus',
    Leviticus: 'Leviticus',
    Numbers: 'Zahlen',
    Deuteronomy: 'Deuteronomium',
    Joshua: 'Joshua',
    Judges: 'Jury',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 King',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalter',
    Proverbs: 'Sprüche',
    Ecclesiastes: 'Kohelet',
    'Song of Songs': 'Lied',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jona',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Sacharja',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Kennzeichen',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Acts',
    Romans: 'Römer',
    '1 Corinthians': '1.Korinther',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Epheser',
    Philippians: 'Philipper',
    Colossians: 'Kolosser',
    '1 Thessalonians': '1. Thessalonicher',
    '2 Thessalonians': '2.Thessalonicher',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebräer',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Offenbarung',
    'resources.prompt':
      'In biblischen Ressourcen für zusätzliche Einblicke in die Passage Sie übersetzen.',
    'resources.add': 'Fügen Sie eine biblische Ressource',
    Manuscript: 'Manuskript',
    Morphology: 'Morphologie',
    Lexicon: 'Lexikon',
    'Syntactic Tree': 'syntaktischer Baum',
    'Go to project list': 'Zum Projektliste',
    'Project not found': 'Projekt nicht oder nicht genügend Berechtigungen gefunden.',
    'error.referenceDoesNotExistForResource':
      'Diese Bibelübersetzung enthält keinen Inhalt für die ausgewählte Passage.',
    'references.addAnother': 'Fügen Sie eine weitere Übersetzung',
    'references.referencePrompt': 'In anderen Bibelübersetzungen für zusätzliche Einblicke.',
    'references.add': 'Fügen Sie eine Bibelübersetzung',
    'translation.complete': 'Übersetzung komplett',
    Alignment: 'Ausrichtung',
    Share: 'Teilen',
    'Your Translation': 'Ihre Übersetzung',
    'Enter your translation': 'Geben Sie Ihre Übersetzung',
    OK: 'in Ordnung',
    'error.verseTranslationEmpty': 'Sie können die Übersetzung Inhalt nicht leer lassen.',
    'translation.hide.completed': 'ausblenden abgeschlossen',
    alignment: 'Ausrichtung',
    'alignment.verify': 'Überprüfen',
    'alignment.backToEditor': 'Zurück zum Editor',
    'alignment.error':
      'Etwas ist schief gelaufen. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte das YouTranslate.Bible Team.',
    'alignment.add': 'Link hinzufügen',
    'alignment.delete': 'Link entfernen',
    'alignment.clear': 'Auswahl löschen',
    'alignment.reverse': 'Reverse-Anzeige',
    'alignment.removeOrClear':
      'Entfernen Sie die ausgewählte Verbindung oder übersichtliche Auswahl.',
    'alignment.addOrRemove': 'Erstellen Sie einen neuen Link oder einen vorherigen löschen.',
    'alignment.selectAndAdd': 'Wählen Segmente Verbindung entspricht.',
    'error.mustBeSignedToMakeChanges': 'Sie müssen, um Änderungen zu unterzeichnen.',
    'error.mustBeSignedToTranslateBible': 'Sie müssen zu übersetzen, die Bibel zu unterzeichnen.',
    checking: 'Überprüfung',
    'verseEditor.verseSuggestions': 'Vorschläge',
    verses: 'durch Verse',
    words: 'Mit dem Wort',
    'leftPanel.name': 'Originaltext der Bibel',
    'rightPanel.name': 'Übersetzungen der Bibel',
    'bottomPanel.name': 'Ressourcen für die Bibel',
    'syntax.adv': 'Adverb',
    'syntax.advp': 'adverb Phrase',
    'syntax.cl': 'Klausel',
    'syntax.conj': 'Verbindung',
    'syntax.cjp': 'Verbindung',
    'syntax.np': 'Nominalphrase',
    'syntax.o': 'Objekt',
    'syntax.io': 'Indirektes Objekt',
    'syntax.p': 'Prädikat',
    'syntax.pp': 'Präpositionalphrase',
    'syntax.s': 'Gegenstand',
    'syntax.v': 'Verb',
    'syntax.vc': 'verb-Klausel',
    'syntax.adv.description':
      'Modifiziert ein anderer Teil der Rede. Typischerweise exprimieren Weise, Ort, Zeit, Frequenz, Grad, Grad der Sicherheit usw.',
    'syntax.advp.description':
      'Ein Mehrwort-Ausdruck, dass ein anderer Teil ändert der Sprache. Typischerweise exprimieren Weise, Ort, Zeit, Frequenz, Grad, Grad der Gewißheit, etc ...',
    'syntax.cl.description': 'Ein Teil eines Satzes, der ein Verb enthält.',
    'syntax.conj.description': 'Verbindet Wörter, Phrasen und Klauseln.',
    'syntax.cjp.description': 'Verbindet Wörter, Phrasen und Klauseln.',
    'syntax.np.description': 'Ein Mehrwort-Ausdruck, der die Funktion eines Substantivs ausführt.',
    'syntax.o.description': 'Die Einheit, die auf durch das Subjekt beaufschlagt wird.',
    'syntax.io.description': 'Dieser ist schwer zu erklären.',
    'syntax.p.description': 'Modifiziert das Thema. In der Regel Stern mit einem Verb.',
    'syntax.pp.description': 'Dieser ist schwer zu erklären.',
    'syntax.s.description': 'Die Einheit, die das Verb der Klausel steuert.',
    'syntax.v.description': 'Vermittelt eine Aktion aus.',
    'syntax.vc.description':
      'Ein Mehrwort-Ausdruck, erfüllt die Funktion eines Verbs (vermittelt eine Aktion).',
    'general.close': 'Schließen',
    'general.action': 'Aktion',
    'general.select': 'Wählen...',
    'general.back': 'Zurück',
  },
  'es-419': {
    'languages.en': 'Inglés',
    'languages.am': 'amárico',
    'languages.bn': 'bengalí',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Idioma aleman',
    'languages.es-419': 'Lengua española',
    'languages.fr': 'Idioma francés',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'idioma croata',
    'languages.hu': 'Idioma húngaro',
    'languages.id': 'Idioma indonesio',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Lenguaje Koreano',
    'languages.lo': 'Lengua de Laos',
    'languages.ml': 'malayalam',
    'languages.mr': 'marathi',
    'languages.ms': 'malasio',
    'languages.my': 'birmano idioma',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Madagascar idioma',
    'languages.pt-br': 'Portugués (Brasil) Idioma',
    'languages.ru': 'Idioma ruso',
    'languages.sw': 'swahili',
    'languages.ta': 'Lenguaje tamil',
    'languages.th': 'Idioma tailandés',
    'languages.tl': 'Tagalo',
    'languages.te': 'telegu idioma',
    'languages.vi': 'Idioma vietnamita',
    'languages.zh-hans': 'Idioma Chino (simplificado)',
    'languages.zh-hant': 'Idioma Chino (tradicional)',
    'header.home': 'Hogar',
    'header.help': 'Ayuda',
    'header.project': 'Proyecto',
    'help.contactSupport':
      'Por favor, envíenos un email a help@youtranslate.bible para obtener ayuda.',
    'home.ytbProvides': 'YouTranslate.Bible ofrece libremente:',
    'home.easyToUseTool': 'Una herramienta de traducción en línea fácil de usar',
    'home.originalTextHelp': 'Ayuda a entender el texto de la Biblia en sus idiomas originales',
    'home.referencesAndResources': 'recursos de referencia y formación',
    'home.computerAssistance':
      'Estado actual de la técnica de la tecnología informática para ayudar a los traductores',
    'profile.userProfile': 'Perfil del usuario',
    'profile.language': 'Idioma',
    'profile.update': 'Actualizar',
    'usernav.profile': 'Perfil',
    'usernav.logout': 'Cerrar sesión',
    'usernav.logout.modalTitle': 'Salir de YouTranslate.Bible',
    'usernav.logout.modalText':
      '¿Seguro que quieres salir? se perderá todo el trabajo no guardado.',
    cancel: 'Cancelar',
    delete: 'Eliminar',
    create: 'Crear',
    update: 'Actualizar',
    edit: 'Editar',
    save: 'Salvar',
    add: 'Añadir',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'Lista de proyectos',
    'project.list.deleteProject': 'Eliminar Proyecto?',
    'project.list.areYouSure': '¿Está seguro de que quiere eliminar este proyecto?',
    'project.list.workWillBeDeleted': 'Se eliminarán del proyecto y cualquier trabajo asociado.',
    'project.list.completion': 'Terminación:',
    'project.list.createdDate': 'Fecha de creación:',
    'project.list.beginningDate': 'Fecha de comienzo:',
    'project.list.targetCompletionDate': 'Fecha límite de finalización:',
    'project.list.description': 'Descripción:',
    'project.edit': 'Editar Proyecto',
    'project.list.translateBible': 'traducir la Biblia',
    'project.list.empty': 'Su lista de proyectos de traducción está vacío.',
    'project.list.myProjects': 'Mi Biblia Proyectos de traducción',
    'project.list.newProject': 'Nuevo proyecto',
    'project.create.createNew': 'Crear un nuevo proyecto',
    'project.namePlaceholder': 'Introduzca el nombre del proyecto',
    'project.descriptionPlaceholder': 'Introduzca la descripción del proyecto',
    'project.name': 'Nombre del proyecto',
    'project.beginningDate': 'Fecha de comienzo',
    'project.targetCompletionDate': 'Fecha límite de finalización',
    'project.description': 'Descripción',
    'project.manage-users': 'Manejo de Traductores',
    'project.new-user': 'nueva Traductor',
    'project.add-user': 'Agregar Traductor',
    'project.users': 'traductores del proyecto',
    'project.read-only': 'Solo lectura',
    'project.read&write': 'Leer escribir',
    'project.owner': 'Propietario',
    'project.translator': 'Traductor',
    'project.message.no-such-user': 'No existe tal Traductor',
    'project.message.user-added': 'Traductor añadido correctamente',
    'project.message.user-deleted': 'Traductor eliminado.',
    'project.message.only-owner-can-manage-users':
      'propietario del proyecto sólo puede administrar usuarios',
    'project.shared': 'Proyecto colaborativo',
    'general.delete-user': 'Traductor de eliminación',
    'general.edit-user': 'Editar usuario',
    'general.name': 'Nombre',
    'general.email': 'Email',
    'general.permissions': 'permisos',
    'general.role': 'Papel',
    signin: 'Registrarse',
    'signin.emailPlaceholder': 'Dirección de correo electrónico',
    'signin.passwordPlaceholder': 'Contraseña',
    'signin.forgotPassword': '¿Olvidaste tu contraseña?',
    'signin.rememberMe': 'Recuérdame',
    'signin.facebook': 'Iniciar sesión usando Facebook',
    'signin.google': 'Inicia sesión con Google',
    'signin.dontHaveAccount': '¿No tienes una cuenta?',
    'signin.createOne': 'Crea uno',
    'signup.join': 'Únete YouTranslate.Bible',
    'signup.createAccountPrompt': 'Crea tu cuenta gratis para empezar.',
    'signup.createAccount': 'Crear una cuenta',
    'signup.emailPlaceholder': 'Dirección de correo electrónico',
    'signup.passwordPlaceholder': 'Contraseña',
    'translation.project': 'PROYECTO:',
    'translation.backToProjectList': 'Volver a la lista de proyectos',
    'translation.book': 'Libro:',
    'translation.chapter': 'Capítulo:',
    'translation.prompt':
      '¿Seguro que quieres dejar este capítulo? se perderá todo el trabajo no guardado.',
    'translation.prev': 'Anterior',
    'translation.next': 'próximo',
    'translation.previousChapter': 'Capítulo previo',
    'translation.nextChapter': 'Siguiente capítulo',
    'navigator.book': 'Libro:',
    'navigator.chapter': 'Capítulo:',
    'navigator.oldTestament': 'Viejo Testamento',
    'navigator.newTestament': 'Nuevo Testamento',
    'navigator.selectBook': 'Seleccione un libro',
    'navigator.search': 'Buscar',
    'navigator.changeBook': 'cambio de libros',
    'navigator.changeChapter': 'cambio capítulo',
    Genesis: 'Génesis',
    Exodus: 'éxodo',
    Leviticus: 'Levíticio',
    Numbers: 'Números',
    Deuteronomy: 'Deuteronomio',
    Joshua: 'Joshua',
    Judges: 'jueces',
    Ruth: 'Piedad',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Reyes',
    '2 Kings': '2 Reyes',
    '1 Chronicles': '1 Crónicas',
    '2 Chronicles': '2 Crónicas',
    Ezra: 'Esdras',
    Nehemiah: 'Nehemías',
    Esther: 'Esther',
    Job: 'Trabajo',
    Psalms: 'Salmos',
    Proverbs: 'Proverbios',
    Ecclesiastes: 'Eclesiastés',
    'Song of Songs': 'Canción de canciones',
    Isaiah: 'Isaías',
    Jeremiah: 'Jeremías',
    Lamentations: 'Lamentaciones',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oseas',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdías',
    Jonah: 'Jonás',
    Micah: 'Miqueas',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sofonías',
    Haggai: 'Ageo',
    Zechariah: 'Zacarías',
    Malachi: 'Malaquías',
    Matthew: 'Mateo',
    Mark: 'marca',
    Luke: 'Lucas',
    John: 'John',
    Acts: 'Hechos',
    Romans: 'Romanos',
    '1 Corinthians': '1 Corintios',
    '2 Corinthians': '2 Corintios',
    Galatians: 'Gálatas',
    Ephesians: 'Efesios',
    Philippians: 'filipenses',
    Colossians: 'Colosenses',
    '1 Thessalonians': '1 Tesalonicenses',
    '2 Thessalonians': '2 Tesalonicenses',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Filemón',
    Hebrews: 'Hebreos',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 Juan',
    '3 John': '3 Juan',
    Jude: 'Jude',
    Revelation: 'Revelación',
    'resources.prompt':
      'Añadir recursos bíblicos para la penetración adicional en el paso que se está traduciendo.',
    'resources.add': 'Añadir un recurso bíblico',
    Manuscript: 'Manuscrito',
    Morphology: 'Morfología',
    Lexicon: 'Léxico',
    'Syntactic Tree': 'árbol sintáctico',
    'Go to project list': 'Ir a la lista de proyectos',
    'Project not found': 'Proyecto no encontrado o permisos insuficientes.',
    'error.referenceDoesNotExistForResource':
      'Esta traducción de la Biblia no contiene contenido para el pasaje seleccionado.',
    'references.addAnother': 'Añadir otra traducción',
    'references.referencePrompt':
      'Añadir otras traducciones de la Biblia para la penetración adicional.',
    'references.add': 'Añadir una traducción de la Biblia',
    'translation.complete': 'La traducción completa',
    Alignment: 'Alineación',
    Share: 'Compartir',
    'Your Translation': 'su traducción',
    'Enter your translation': 'Introduzca su traducción',
    OK: 'Okay',
    'error.verseTranslationEmpty': 'No se puede dejar vacía el contenido de la traducción.',
    'translation.hide.completed': 'Ocultar completado',
    alignment: 'Alineación',
    'alignment.verify': 'Verificar',
    'alignment.backToEditor': 'Volver al Editor',
    'alignment.error':
      'Algo salió mal. Si persiste el problema, póngase en contacto con el equipo YouTranslate.Bible.',
    'alignment.add': 'Añadir enlace',
    'alignment.delete': 'Remover enlace',
    'alignment.clear': 'Selección clara',
    'alignment.reverse': 'Visualización inversa',
    'alignment.removeOrClear': 'Retire el enlace seleccionado o una selección clara.',
    'alignment.addOrRemove': 'Crear un nuevo enlace o eliminar una anterior.',
    'alignment.selectAndAdd': 'Seleccionar segmentos correspondientes a enlace.',
    'error.mustBeSignedToMakeChanges': 'Debe haber iniciado sesión en Realizar cambios.',
    'error.mustBeSignedToTranslateBible': 'Debe haber iniciado sesión en traducir la Biblia.',
    checking: 'Comprobación',
    'verseEditor.verseSuggestions': 'sugerencias',
    verses: 'por Verso',
    words: 'por Palabra',
    'leftPanel.name': 'El texto original de la Biblia',
    'rightPanel.name': 'Las traducciones de la Biblia',
    'bottomPanel.name': 'Los recursos para la Biblia',
    'syntax.adv': 'Adverbio',
    'syntax.advp': 'Frase adverbio',
    'syntax.cl': 'Cláusula',
    'syntax.conj': 'Conjunción',
    'syntax.cjp': 'Conjunción',
    'syntax.np': 'sintagma nominal',
    'syntax.o': 'Objeto',
    'syntax.io': 'Objeto indirecto',
    'syntax.p': 'Predicado',
    'syntax.pp': 'Frase preposicional',
    'syntax.s': 'Tema',
    'syntax.v': 'Verbo',
    'syntax.vc': 'Cláusula verbo',
    'syntax.adv.description':
      'Modifica otra parte de la oración. Típicamente expresar manera, lugar, tiempo, frecuencia, grado, nivel de certeza, etc.',
    'syntax.advp.description':
      'Una expresión de varias palabras que modifica otra parte de la oración. Normalmente expresar de forma, lugar, tiempo, frecuencia, grado, nivel de certeza, etc ...',
    'syntax.cl.description': 'Una parte de una frase que contiene un verbo.',
    'syntax.conj.description': 'Se conecta las palabras, frases y oraciones.',
    'syntax.cjp.description': 'Se conecta las palabras, frases y oraciones.',
    'syntax.np.description':
      'Una expresión de varias palabras que realiza la función de un sustantivo.',
    'syntax.o.description': 'La entidad que se actúa por el sujeto.',
    'syntax.io.description': 'Éste es difícil de explicar.',
    'syntax.p.description': 'Modifica el tema. Por lo general, las estrellas con un verbo.',
    'syntax.pp.description': 'Éste es difícil de explicar.',
    'syntax.s.description': 'La entidad que controla el verbo de la cláusula.',
    'syntax.v.description': 'Transmite una acción.',
    'syntax.vc.description':
      'Una expresión de palabras múltiples que realiza la función de un verbo (transmite una acción).',
    'general.close': 'Cerca',
    'general.action': 'Acción',
    'general.select': 'Seleccione...',
    'general.back': 'atrás',
  },
  fr: {
    'languages.en': 'Anglais',
    'languages.am': 'amharique',
    'languages.bn': 'Bengali',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Langue allemande',
    'languages.es-419': 'Langue espagnole',
    'languages.fr': 'Langue française',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'Langue croate',
    'languages.hu': 'Langue hongroise',
    'languages.id': 'Langue indonésienne',
    'languages.km': 'khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'La langue coréenne',
    'languages.lo': 'Langue laotien',
    'languages.ml': 'malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaisie',
    'languages.my': 'Langue birmane',
    'languages.ne': 'népalais',
    'languages.plt': 'Langue malgache',
    'languages.pt-br': 'Portugais (Brésil) Langue',
    'languages.ru': 'Langue russe',
    'languages.sw': 'swahili',
    'languages.ta': 'Langue Tamil',
    'languages.th': 'Langue thai',
    'languages.tl': 'Tagalog',
    'languages.te': 'télougou Langue',
    'languages.vi': 'Langue vietnamienne',
    'languages.zh-hans': 'Langue Chinois (simplifié)',
    'languages.zh-hant': 'Langue Chinois (traditionnel)',
    'header.home': 'Accueil',
    'header.help': 'Aidez-moi',
    'header.project': 'Projet',
    'help.contactSupport':
      "S'il vous plaît écrivez-nous à help@youtranslate.bible pour l'assistance.",
    'home.ytbProvides': 'YouTranslate.Bible fournit librement:',
    'home.easyToUseTool': 'Un outil de traduction en ligne facile à utiliser',
    'home.originalTextHelp': 'Aide à comprendre le texte de la Bible dans ses langues originales',
    'home.referencesAndResources': 'ressources de référence et de formation',
    'home.computerAssistance':
      "L'état de la technologie informatique de pointe pour aider les traducteurs",
    'profile.userProfile': "Profil de l'utilisateur",
    'profile.language': 'Langue',
    'profile.update': 'Mise à jour',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Se déconnecter',
    'usernav.logout.modalTitle': 'Déconnexion de YouTranslate.Bible',
    'usernav.logout.modalText':
      'Êtes-vous sûr de vouloir vous déconnecter? Tous les travaux non enregistré sera perdu.',
    cancel: 'Annuler',
    delete: 'Supprimer',
    create: 'Créer',
    update: 'Mise à jour',
    edit: 'Éditer',
    save: 'sauvegarder',
    add: 'Ajouter',
    'project.create.title': 'Bible Projet de traduction',
    'project.create.list': 'liste des projets',
    'project.list.deleteProject': 'Supprimer le projet?',
    'project.list.areYouSure': 'Etes-vous sûr de vouloir supprimer ce projet?',
    'project.list.workWillBeDeleted': 'Le projet et les travaux associés seront supprimés.',
    'project.list.completion': 'Achèvement:',
    'project.list.createdDate': 'Date de création:',
    'project.list.beginningDate': 'Date de début:',
    'project.list.targetCompletionDate': "Date d'achèvement cible:",
    'project.list.description': 'La description:',
    'project.edit': 'Modifier le projet',
    'project.list.translateBible': 'Traduire la Bible',
    'project.list.empty': 'Votre liste de projets de traduction est vide.',
    'project.list.myProjects': 'Ma Bible Projets de traduction',
    'project.list.newProject': 'Nouveau projet',
    'project.create.createNew': 'Créer un nouveau projet',
    'project.namePlaceholder': 'Entrez le nom du projet',
    'project.descriptionPlaceholder': 'Entrez description du projet',
    'project.name': 'Nom du projet',
    'project.beginningDate': 'Date de début',
    'project.targetCompletionDate': "Date d'achèvement cible",
    'project.description': 'La description',
    'project.manage-users': 'gérer les traducteurs',
    'project.new-user': 'nouveau traducteur',
    'project.add-user': 'Ajouter Traducteur',
    'project.users': 'traducteurs projet',
    'project.read-only': 'Lecture seulement',
    'project.read&write': 'Lire écrire',
    'project.owner': 'Propriétaire',
    'project.translator': 'Traducteur',
    'project.message.no-such-user': 'Pas de traducteur existe',
    'project.message.user-added': 'Traducteur ajouté avec succès',
    'project.message.user-deleted': 'Traducteur supprimé.',
    'project.message.only-owner-can-manage-users':
      'Seul le propriétaire du projet peut gérer les utilisateurs',
    'project.shared': 'projet de collaboration',
    'general.delete-user': 'Traducteur Supprimer',
    'general.edit-user': "Modifier l'utilisateur",
    'general.name': 'Nom',
    'general.email': 'Email',
    'general.permissions': 'autorisations',
    'general.role': 'Rôle',
    signin: 'se connecter',
    'signin.emailPlaceholder': 'Adresse électronique',
    'signin.passwordPlaceholder': 'Mot de passe',
    'signin.forgotPassword': 'Mot de passe oublié?',
    'signin.rememberMe': 'Souviens-toi de moi',
    'signin.facebook': 'Connectez-vous avec Facebook',
    'signin.google': 'Connectez-vous avec Google',
    'signin.dontHaveAccount': 'Ne pas avoir un compte?',
    'signin.createOne': 'Créer une',
    'signup.join': 'Inscrivez-vous YouTranslate.Bible',
    'signup.createAccountPrompt': 'Créez votre compte gratuit pour commencer.',
    'signup.createAccount': 'Créer un compte',
    'signup.emailPlaceholder': 'Adresse électronique',
    'signup.passwordPlaceholder': 'Mot de passe',
    'translation.project': 'PROJET:',
    'translation.backToProjectList': 'Retour à la liste des projets',
    'translation.book': 'Livre:',
    'translation.chapter': 'Chapitre:',
    'translation.prompt':
      'Etes-vous sûr de vouloir quitter ce chapitre? Tous les travaux non enregistré sera perdu.',
    'translation.prev': 'Précédent',
    'translation.next': 'Prochain',
    'translation.previousChapter': 'chapitre précédent',
    'translation.nextChapter': 'Chapitre suivant',
    'navigator.book': 'Livre:',
    'navigator.chapter': 'Chapitre:',
    'navigator.oldTestament': "L'Ancien Testament",
    'navigator.newTestament': 'Nouveau Testament',
    'navigator.selectBook': 'Sélectionnez un livre',
    'navigator.search': 'Chercher',
    'navigator.changeBook': 'Changer Réservez',
    'navigator.changeChapter': 'Modifier le chapitre',
    Genesis: 'Genèse',
    Exodus: 'Exode',
    Leviticus: 'Lévitique',
    Numbers: 'Nombres',
    Deuteronomy: 'Deutéronome',
    Joshua: 'Joshua',
    Judges: 'Les juges',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Rois',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chroniques',
    Ezra: 'Ezra',
    Nehemiah: 'Néhémie',
    Esther: 'Esther',
    Job: 'Emploi',
    Psalms: 'Psaumes',
    Proverbs: 'les proverbes',
    Ecclesiastes: "livre de l'Ecclésiaste",
    'Song of Songs': 'Cantique des cantique',
    Isaiah: 'Isaïe',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentations',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Osée',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdias',
    Jonah: 'Jonas',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Sophonie',
    Haggai: 'Haggai',
    Zechariah: 'Zacharie',
    Malachi: 'Malachie',
    Matthew: 'Matthieu',
    Mark: 'marque',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Actes',
    Romans: 'Romains',
    '1 Corinthians': '1 Co',
    '2 Corinthians': '2 Corinthiens',
    Galatians: 'Galates',
    Ephesians: 'Ephésiens',
    Philippians: 'Philippiens',
    Colossians: 'Colossiens',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessaloniciens',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothée',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hébreux',
    James: 'James',
    '1 Peter': '1 Pierre',
    '2 Peter': '2 Pierre',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Révélation',
    'resources.prompt':
      'Ajouter des ressources bibliques pour des informations supplémentaires sur le passage que vous traduisez.',
    'resources.add': 'Ajouter une ressource biblique',
    Manuscript: 'Manuscrit',
    Morphology: 'Morphologie',
    Lexicon: 'Lexique',
    'Syntactic Tree': 'Arbre Syntactic',
    'Go to project list': 'Aller à la liste des projets',
    'Project not found': 'Projet introuvable ou autorisations insuffisantes.',
    'error.referenceDoesNotExistForResource':
      'Cette traduction de la Bible ne contient pas de contenu pour le passage sélectionné.',
    'references.addAnother': 'Ajouter une autre traduction',
    'references.referencePrompt':
      "Ajouter d'autres traductions de la Bible pour un aperçu supplémentaire.",
    'references.add': 'Ajouter une traduction de la Bible',
    'translation.complete': 'complète de traduction',
    Alignment: 'Alignement',
    Share: 'Partager',
    'Your Translation': 'votre traduction',
    'Enter your translation': 'Entrez votre traduction',
    OK: "D'accord",
    'error.verseTranslationEmpty': 'Vous ne pouvez pas laisser le contenu de la traduction vide.',
    'translation.hide.completed': 'Masquer terminé',
    alignment: 'Alignement',
    'alignment.verify': 'Vérifier',
    'alignment.backToEditor': "Retour à l'éditeur",
    'alignment.error':
      "Quelque chose a mal tourné. Si le problème persiste à, s'il vous plaît contacter l'équipe YouTranslate.Bible.",
    'alignment.add': 'Ajouter un lien',
    'alignment.delete': 'Supprimer le lien',
    'alignment.clear': 'Effacer la sélection',
    'alignment.reverse': 'Affichage inverse',
    'alignment.removeOrClear': "Retirez le lien sélectionné ou d'une sélection claire.",
    'alignment.addOrRemove': 'Créer un nouveau lien ou supprimer un précédent.',
    'alignment.selectAndAdd': 'Sélectionnez segments correspondant à la liaison.',
    'error.mustBeSignedToMakeChanges': 'Vous devez être connecté pour effectuer des modifications.',
    'error.mustBeSignedToTranslateBible': 'Vous devez être connecté pour traduire la Bible.',
    checking: 'Vérification',
    'verseEditor.verseSuggestions': 'Suggestions',
    verses: 'par Verse',
    words: 'par Word',
    'leftPanel.name': 'Texte original de la Bible',
    'rightPanel.name': 'Les traductions de la Bible',
    'bottomPanel.name': 'Ressources pour la Bible',
    'syntax.adv': 'Adverbe',
    'syntax.advp': 'adverbe Phrase',
    'syntax.cl': 'Clause',
    'syntax.conj': 'Conjonction',
    'syntax.cjp': 'Conjonction',
    'syntax.np': 'Phrase nominale',
    'syntax.o': 'Objet',
    'syntax.io': 'Objet indirect',
    'syntax.p': 'Prédicat',
    'syntax.pp': 'Préposition',
    'syntax.s': 'Matière',
    'syntax.v': 'Verbe',
    'syntax.vc': 'verbe article',
    'syntax.adv.description':
      'Une autre partie de modifie la parole. exprimer généralement de manière, le lieu, le temps, la fréquence, le degré, le niveau de certitude, etc.',
    'syntax.advp.description':
      'Une expression à mots multiples qui modifie une autre partie du discours. Typiquement exprimer mode, le lieu, le temps, la fréquence, le degré degré de certitude, etc ...',
    'syntax.cl.description': "Une partie d'une phrase qui contient un verbe.",
    'syntax.conj.description': 'Relie mots, des phrases et des clauses.',
    'syntax.cjp.description': 'Relie mots, des phrases et des clauses.',
    'syntax.np.description': "Une expression à mots multiples qui remplit la fonction d'un nom.",
    'syntax.o.description': "L'entité qui est sollicité par le sujet.",
    'syntax.io.description': 'Celui-ci est difficile à expliquer.',
    'syntax.p.description': 'Modifie le sujet. En général, les étoiles avec un verbe.',
    'syntax.pp.description': 'Celui-ci est difficile à expliquer.',
    'syntax.s.description': "L'entité qui contrôle le verbe de la clause.",
    'syntax.v.description': 'Transmet une action.',
    'syntax.vc.description':
      "Une expression à mots multiples qui remplit la fonction d'un verbe (véhicule une action).",
    'general.close': 'Fermer',
    'general.action': 'action',
    'general.select': 'Sélectionner...',
    'general.back': 'Retour',
  },
  gu: {
    'languages.en': 'ઇંગલિશ',
    'languages.am': 'એમ્હારિક',
    'languages.bn': 'બંગાળી ભાષા',
    'languages.ceb': 'સિબુઆનો ભાષા',
    'languages.de': 'જર્મન ભાષા',
    'languages.es-419': 'સ્પેનીશ ભાષા',
    'languages.fr': 'ફ્રેન્ચ ભાષા',
    'languages.gu': 'ગુજરાતી',
    'languages.hi': 'હિન્દી',
    'languages.hr': 'ક્રોએશિયન ભાષા',
    'languages.hu': 'હંગેરીયન ભાષા',
    'languages.id': 'ઇન્ડોનેશિયન ભાષા',
    'languages.km': 'ખ્મેર',
    'languages.kn': 'કન્નડા',
    'languages.ko': 'કોરિયન ભાષા',
    'languages.lo': 'લાઓશિયન ભાષા',
    'languages.ml': 'મલયાલમ',
    'languages.mr': 'મરાઠી',
    'languages.ms': 'મલેશિયન',
    'languages.my': 'બર્મીઝ ભાષા',
    'languages.ne': 'નેપાળી ભાષા',
    'languages.plt': 'મલાગસી ભાષા',
    'languages.pt-br': 'પોર્ટુગીઝ (બ્રાઝીલ) ભાષા',
    'languages.ru': 'રશિયન ભાષા',
    'languages.sw': 'સ્વાહિલી',
    'languages.ta': 'તમિલ ભાષા',
    'languages.th': 'થાઇ ભાષા',
    'languages.tl': 'ટાગાલોગ',
    'languages.te': 'Telegu ભાષા',
    'languages.vi': 'વિયેટનામી ભાષા',
    'languages.zh-hans': 'ચિની ભાષા (સરળ)',
    'languages.zh-hant': 'ચિની ભાષા (પરંપરાગત)',
    'header.home': 'ઘર',
    'header.help': 'સહાય',
    'header.project': 'પ્રોજેક્ટ',
    'help.contactSupport': 'કૃપા કરીને અમને સહાય માટે help@youtranslate.bible ખાતે ઇમેઇલ કરો.',
    'home.ytbProvides': 'YouTranslate.Bible મુક્તપણે પૂરા પાડે છે:',
    'home.easyToUseTool': 'એક સરળ-થી-ઉપયોગ ઓનલાઇન અનુવાદ સાધન',
    'home.originalTextHelp': 'તેના મૂળ ભાષાઓમાં બાઇબલ લખાણ સમજવા માટે મદદ',
    'home.referencesAndResources': 'સંદર્ભ અને તાલીમ સાધનો',
    'home.computerAssistance': 'સ્ટેટ-ઓફ-ધી-આર્ટ કોમ્પ્યુટર ટેકનોલોજી અનુવાદકો સહાય કરવા',
    'profile.userProfile': 'વપરાશકર્તા પ્રોફાઇલ',
    'profile.language': 'ભાષાની',
    'profile.update': 'અપડેટ',
    'usernav.profile': 'પ્રોફાઇલ',
    'usernav.logout': 'લૉગ આઉટ',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ના લૉગઆઉટ',
    'usernav.logout.modalText':
      'જો તમને ખાતરી છે તમે લૉગઆઉટ કરવા માંગો છો? તમામ વણસાચવેલ કાર્ય ગુમ થશે.',
    cancel: 'રદ કરો',
    delete: 'કાઢી નાંખો',
    create: 'બનાવો',
    update: 'અપડેટ',
    edit: 'સંપાદિત કરો',
    save: 'સેવ',
    add: 'ઉમેરો',
    'project.create.title': 'બાઇબલ અનુવાદ પ્રોજેક્ટ',
    'project.create.list': 'પ્રોજેક્ટ યાદી',
    'project.list.deleteProject': 'પ્રોજેક્ટ કાઢી નાખીએ?',
    'project.list.areYouSure': 'જો તમે આ પ્રોજેક્ટ કાઢી નાખવા માંગો છો?',
    'project.list.workWillBeDeleted': 'પ્રોજેક્ટ અને સંકળાયેલ કોઈપણ કામ કાઢી નાખવામાં આવશે.',
    'project.list.completion': 'સમાપ્તિ:',
    'project.list.createdDate': 'તારીખ તારીખ:',
    'project.list.beginningDate': 'શરુ તારીખ:',
    'project.list.targetCompletionDate': 'લક્ષ્યાંક સમાપ્તિ તારીખ:',
    'project.list.description': 'વર્ણન:',
    'project.edit': 'પ્રોજેક્ટ સંપાદિત કરો',
    'project.list.translateBible': 'ભાષાંતર બાઇબલ',
    'project.list.empty': 'તમારી અનુવાદ પ્રોજેક્ટ સૂચિ ખાલી છે.',
    'project.list.myProjects': 'મારા બાઇબલ અનુવાદ પ્રોજેક્ટ્સ',
    'project.list.newProject': 'ન્યૂ પ્રોજેક્ટ',
    'project.create.createNew': 'એક નવો પ્રોજેક્ટ બનાવો',
    'project.namePlaceholder': 'પ્રોજેક્ટ નામ દાખલ કરો',
    'project.descriptionPlaceholder': 'પ્રોજેક્ટ વર્ણન દાખલ કરો',
    'project.name': 'પ્રોજેક્ટનું નામ',
    'project.beginningDate': 'શરુ તારીખ',
    'project.targetCompletionDate': 'લક્ષ્યાંક સમાપ્તિ તારીખ',
    'project.description': 'વર્ણન',
    'project.manage-users': 'અનુવાદકો વ્યવસ્થા',
    'project.new-user': 'ન્યૂ અનુવાદક',
    'project.add-user': 'અનુવાદક ઉમેરો',
    'project.users': 'પ્રોજેક્ટ અનુવાદકો',
    'project.read-only': 'ફક્ત વાંચી',
    'project.read&write': 'વાંચો લખો',
    'project.owner': 'માલિકનું',
    'project.translator': 'અનુવાદક',
    'project.message.no-such-user': 'આવી કોઈ અનુવાદક અસ્તિત્વમાં',
    'project.message.user-added': 'અનુવાદક સફળતાપૂર્વક ઉમેરાયું',
    'project.message.user-deleted': 'અનુવાદક દૂર કર્યું.',
    'project.message.only-owner-can-manage-users':
      'માત્ર પ્રોજેક્ટ માલિક વપરાશકર્તાને સંચાલિત કરી શકે',
    'project.shared': 'સહયોગી પ્રોજેક્ટ',
    'general.delete-user': 'કાઢી નાંખો અનુવાદક',
    'general.edit-user': 'વપરાશકર્તા સંપાદિત કરો',
    'general.name': 'નામ',
    'general.email': 'ઇ-મેઇલ',
    'general.permissions': 'પરવાનગીઓ',
    'general.role': 'રોલ',
    signin: 'સાઇન ઇન કરો',
    'signin.emailPlaceholder': 'ઈ - મેઈલ સરનામું',
    'signin.passwordPlaceholder': 'પાસવર્ડ',
    'signin.forgotPassword': 'તમારો પાસવર્ડ ભૂલી ગયા છો?',
    'signin.rememberMe': 'મને યાદ',
    'signin.facebook': 'ફેસબુક સાથે સાઇન ઇન કરો',
    'signin.google': 'Google સાથે સાઇન ઇન',
    'signin.dontHaveAccount': 'એક એકાઉન્ટ નથી?',
    'signin.createOne': 'એક બનાવો',
    'signup.join': 'YouTranslate.Bible જોડાઓ',
    'signup.createAccountPrompt': 'તમારા મફત એકાઉન્ટ શરૂ કરવા માટે બનાવો.',
    'signup.createAccount': 'ખાતું બનાવો',
    'signup.emailPlaceholder': 'ઈ - મેઈલ સરનામું',
    'signup.passwordPlaceholder': 'પાસવર્ડ',
    'translation.project': 'પ્રોજેક્ટ:',
    'translation.backToProjectList': 'પ્રોજેક્ટ સૂચિ પર પાછા',
    'translation.book': 'પુસ્તક:',
    'translation.chapter': 'પ્રકરણ:',
    'translation.prompt': 'તમે આ પ્રકરણમાં છોડવા માંગો છો? તમામ વણસાચવેલ કાર્ય ગુમ થશે.',
    'translation.prev': 'પૂર્વ',
    'translation.next': 'આગળ',
    'translation.previousChapter': 'ગત પ્રકરણ',
    'translation.nextChapter': 'આવતા પ્રકરણમાં',
    'navigator.book': 'પુસ્તક:',
    'navigator.chapter': 'પ્રકરણ:',
    'navigator.oldTestament': 'ઓલ્ડ ટેસ્ટામેન્ટ',
    'navigator.newTestament': 'ધ ન્યૂ ટેસ્ટામેન્ટ',
    'navigator.selectBook': 'એક પુસ્તક પસંદ',
    'navigator.search': 'શોધો',
    'navigator.changeBook': 'પુસ્તક બદલો',
    'navigator.changeChapter': 'પ્રકરણ બદલો',
    Genesis: 'ઉત્પત્તિ',
    Exodus: 'હિજરત',
    Leviticus: 'લેવિટિકસ',
    Numbers: 'નંબર્સ',
    Deuteronomy: 'પુનર્નિયમ',
    Joshua: 'જોશુઆ',
    Judges: 'ન્યાયાધીશો',
    Ruth: 'રુથ',
    '1 Samuel': '1 સેમ્યુઅલ',
    '2 Samuel': '2 સેમ્યુઅલ',
    '1 Kings': '1 કિંગ્સ',
    '2 Kings': '2 કિંગ્સ',
    '1 Chronicles': '1 ક્રોનિકલ્સ',
    '2 Chronicles': '2 ક્રોનિકલ્સ',
    Ezra: 'એઝરા',
    Nehemiah: 'નહેમ્યા',
    Esther: 'એસ્થર',
    Job: 'જોબ',
    Psalms: 'સેલ્મસ',
    Proverbs: 'ઉકિતઓ',
    Ecclesiastes: 'સભાશિક્ષક',
    'Song of Songs': 'ગીતો ગીત',
    Isaiah: 'ઇસાઇઆહ',
    Jeremiah: 'Jeremiah',
    Lamentations: 'આક્રંદથી',
    Ezekiel: 'એઝેકીલ',
    Daniel: 'ડેનિયલ',
    Hosea: 'હોસિયા',
    Joel: 'જોએલ',
    Amos: 'આમોસ',
    Obadiah: 'ઓબાદ્યા',
    Jonah: 'જોનાહ',
    Micah: 'મીખાહ',
    Nahum: 'નાહૂમ',
    Habakkuk: 'હબાક્કૂક',
    Zephaniah: 'સફાન્યાહ',
    Haggai: 'હાગ્ગાય',
    Zechariah: 'ઝખાર્યાહ',
    Malachi: 'માલાચી',
    Matthew: 'મેથ્યુ',
    Mark: 'ચિહ્ન',
    Luke: 'લ્યુક',
    John: 'જ્હોન',
    Acts: 'કાયદાઓ',
    Romans: 'રોમનો',
    '1 Corinthians': '1 કોરીન્થિયન્સ',
    '2 Corinthians': '2 કોરીન્થિયન્સ',
    Galatians: 'ગલાતી',
    Ephesians: 'એફેસિઅન્સ',
    Philippians: 'ફિલિપી',
    Colossians: 'કોલોસી',
    '1 Thessalonians': '1 થેસ્સાલોનીકી',
    '2 Thessalonians': '2 થેસ્સાલોનીકી',
    '1 Timothy': '1 તિમોથી',
    '2 Timothy': '2 તિમોથી',
    Titus: 'ટાઇટસ',
    Philemon: 'ફિલેમોન',
    Hebrews: 'હર્બુઝ',
    James: 'જેમ્સ',
    '1 Peter': '1 પીટર',
    '2 Peter': '2 પીટર',
    '1 John': '1 યોહાન',
    '2 John': '2 યોહાન',
    '3 John': '3 યોહાન',
    Jude: 'જ્યુડ',
    Revelation: 'રેવિલેશન',
    'resources.prompt': 'માર્ગ તમે જે અનુવાદ વધારાના સૂઝ માટે બિબ્લીકલ સાધનો ઉમેરો.',
    'resources.add': 'એક બિબ્લીકલ સાધન ઉમેરો',
    Manuscript: 'હસ્તપ્રત',
    Morphology: 'મોર્ફોલોજી',
    Lexicon: 'લેક્સિકોન',
    'Syntactic Tree': 'વાક્યરચના વૃક્ષ',
    'Go to project list': 'પ્રોજેક્ટ સૂચિ પર જાઓ',
    'Project not found': 'પ્રોજેક્ટ મળ્યો નથી અથવા અપર્યાપ્ત પરવાનગીઓ.',
    'error.referenceDoesNotExistForResource':
      'આ બાઇબલ અનુવાદ પસંદગી પસાર કરવા માટેનો સામગ્રી શામેલ નથી.',
    'references.addAnother': 'અન્ય અનુવાદ ઉમેરો',
    'references.referencePrompt': 'વધારાના સૂઝ માટે બાઇબલ અન્ય અનુવાદ ઉમેરો.',
    'references.add': 'બાઇબલ અનુવાદ ઉમેરો',
    'translation.complete': 'અનુવાદ પૂર્ણ',
    Alignment: 'ગોઠવણી',
    Share: 'શેર',
    'Your Translation': 'તમારી ભાષાંતર',
    'Enter your translation': 'તમારી અનુવાદ દાખલ',
    OK: 'બરાબર',
    'error.verseTranslationEmpty': 'તમે અનુવાદ સામગ્રી ખાલી છોડી શકતા નથી.',
    'translation.hide.completed': 'પૂર્ણ છુપાવો',
    alignment: 'ગોઠવણી',
    'alignment.verify': 'ચકાસો',
    'alignment.backToEditor': 'સંપાદક પર પાછા',
    'alignment.error': 'કંઈક ખોટું થયું. જો સમસ્યા ચાલુ રહે છે, YouTranslate.Bible ટીમ સંપર્ક કરો.',
    'alignment.add': 'લિંક ઉમેરો',
    'alignment.delete': 'લિંક દૂર',
    'alignment.clear': 'પસંદગી સાફ કરો',
    'alignment.reverse': 'રિવર્સ પ્રદર્શન',
    'alignment.removeOrClear': 'પસંદ કરેલ લિંક અથવા સ્પષ્ટ પસંદગી દૂર કરો.',
    'alignment.addOrRemove': 'એક નવી લિંક બનાવો અથવા પહેલાંના એક કાઢી નાખો.',
    'alignment.selectAndAdd': 'લિંક સેગમેન્ટમાં અનુરૂપ પસંદ કરો.',
    'error.mustBeSignedToMakeChanges': 'તમે ફેરફારો કરવા માટે સાઇન ઇન કરવું આવશ્યક છે.',
    'error.mustBeSignedToTranslateBible': 'તમે બાઇબલ ભાષાંતર કરવા માટે સાઇન ઇન કરવું આવશ્યક છે.',
    checking: 'તપાસવું',
    'verseEditor.verseSuggestions': 'સૂચનો',
    verses: 'દ્વારા શ્લોક',
    words: 'દ્વારા વર્ડ',
    'leftPanel.name': 'બાઇબલના મૂળ લખાણ',
    'rightPanel.name': 'બાઇબલના ભાષાંતરો',
    'bottomPanel.name': 'બાઇબલ માટે સંસાધનો',
    'syntax.adv': 'ક્રિયાવિશેષણ',
    'syntax.advp': 'ક્રિયાવિશેષણ શબ્દસમૂહ',
    'syntax.cl': 'કલમ',
    'syntax.conj': 'જોડાણમાં',
    'syntax.cjp': 'જોડાણમાં',
    'syntax.np': 'સંજ્ઞા શબ્દસમૂહના',
    'syntax.o': 'ઑબ્જેક્ટ',
    'syntax.io': 'અપ્રત્યક્ષ',
    'syntax.p': 'વિશેષણો',
    'syntax.pp': 'નામયોગી મહાવરો',
    'syntax.s': 'વિષય',
    'syntax.v': 'ક્રિયાપદ',
    'syntax.vc': 'ક્રિયાપદ કલમ',
    'syntax.adv.description':
      'વાણી બીજા ભાગમાં સુધારે. ખાસ કરીને રીતે, સ્થળ, સમય, આવર્તન, ડિગ્રી, નિશ્ચિતતા સ્તર, વગેરે વ્યક્ત',
    'syntax.advp.description':
      'અ મલ્ટી-શબ્દ અભિવ્યક્તિ કે સુધારે વાણી અન્ય ભાગ છે. ખાસ કરીને રીતે, સ્થળ, સમય, આવર્તન, ડિગ્રી, નિશ્ચિતતા સ્તર, વગેરે વ્યક્ત ...',
    'syntax.cl.description': 'એક વાક્ય છે કે ક્રિયાપદ સમાવે એક ભાગ છે.',
    'syntax.conj.description': 'શબ્દો, શબ્દસમૂહો અને કલમો સાથે જોડે છે.',
    'syntax.cjp.description': 'શબ્દો, શબ્દસમૂહો અને કલમો સાથે જોડે છે.',
    'syntax.np.description': 'અ મલ્ટી-શબ્દ અભિવ્યક્તિ કે સંજ્ઞા કાર્ય કરે છે.',
    'syntax.o.description': 'અસ્તિત્વ છે જે વિષય દ્વારા પર કામ કર્યું છે.',
    'syntax.io.description': 'આ એક તે સમજાવવા માટે મુશ્કેલ છે.',
    'syntax.p.description': 'વિષય સુધારે. સામાન્ય રીતે એક ક્રિયાપદ સાથે તારાઓ.',
    'syntax.pp.description': 'આ એક તે સમજાવવા માટે મુશ્કેલ છે.',
    'syntax.s.description': 'એન્ટિટી જે વાક્યના ક્રિયાપદ નિયંત્રિત કરે છે.',
    'syntax.v.description': 'ક્રિયા રજૂ કરે છે.',
    'syntax.vc.description': 'અ મલ્ટી-શબ્દ અભિવ્યક્તિ કે કરે ક્રિયાપદના કાર્ય (આપે એક ક્રિયા).',
    'general.close': 'બંધ',
    'general.action': 'ક્રિયા',
    'general.select': 'પસંદ કરો...',
    'general.back': 'પાછા',
  },
  hi: {
    'languages.en': 'अंग्रेज़ी',
    'languages.am': 'अम्हारिक्',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सिबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पनिश भाषा',
    'languages.fr': 'फ्रांसीसी भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिंदी',
    'languages.hr': 'क्रोएशियाई भाषा',
    'languages.hu': 'हंगेरियन भाषा',
    'languages.id': 'इंडोनेशियाई भाषा',
    'languages.km': 'खमेर',
    'languages.kn': 'कन्नड़',
    'languages.ko': 'कोरियाई भाषा',
    'languages.lo': 'Laotian भाषा',
    'languages.ml': 'मलयालम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियाई',
    'languages.my': 'बर्मी भाषा',
    'languages.ne': 'नेपाली भाषा',
    'languages.plt': 'मालागासी भाषा',
    'languages.pt-br': 'पुर्तगाली (ब्राजील) भाषा',
    'languages.ru': 'रूसी भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तमिल भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'तागालोग',
    'languages.te': 'तेलुगु भाषा',
    'languages.vi': 'वियतनामी भाषा',
    'languages.zh-hans': 'चीनी भाषा (सरलीकृत)',
    'languages.zh-hant': 'चीनी भाषा (पारंपरिक)',
    'header.home': 'घर',
    'header.help': 'मदद',
    'header.project': 'परियोजना',
    'help.contactSupport': 'कृपया हमें सहायता के लिए help@youtranslate.bible पर ईमेल करें।',
    'home.ytbProvides': 'YouTranslate.Bible स्वतंत्र रूप से प्रदान करता है:',
    'home.easyToUseTool': 'एक आसान से उपयोग ऑनलाइन विनिमय टूल',
    'home.originalTextHelp': 'अपने मूल भाषाओं में बाइबिल के पाठ को समझने के लिए सहायता',
    'home.referencesAndResources': 'संदर्भ और प्रशिक्षण संसाधनों',
    'home.computerAssistance':
      'राज्य के अत्याधुनिक कंप्यूटर प्रौद्योगिकी अनुवादकों की सहायता के लिए',
    'profile.userProfile': 'उपयोगकर्ता प्रोफ़ाइल',
    'profile.language': 'भाषा: हिन्दी',
    'profile.update': 'अपडेट करें',
    'usernav.profile': 'प्रोफ़ाइल',
    'usernav.logout': 'लॉग आउट',
    'usernav.logout.modalTitle': 'YouTranslate.Bible की लॉगआउट',
    'usernav.logout.modalText': 'क्या आप लॉग आउट करना चाहते हैं? सभी बिना सहेजे कार्य खो जाएगा।',
    cancel: 'रद्द करना',
    delete: 'हटाएं',
    create: 'सृजन करना',
    update: 'अपडेट करें',
    edit: 'संपादित करें',
    save: 'सहेजें',
    add: 'जोड़ना',
    'project.create.title': 'बाइबिल अनुवाद परियोजना',
    'project.create.list': 'परियोजना सूची',
    'project.list.deleteProject': 'परियोजना हटाना चाहते हैं?',
    'project.list.areYouSure': 'आप इस प्रोजेक्ट को हटाना चाहते आपको यकीन है?',
    'project.list.workWillBeDeleted': 'परियोजना और संबद्ध किसी भी काम से हटा दिया जाएगा।',
    'project.list.completion': 'समापन:',
    'project.list.createdDate': 'रचना तिथि:',
    'project.list.beginningDate': 'प्रारंभिक दिनांक:',
    'project.list.targetCompletionDate': 'लक्ष्य हासिल करने की तारीख:',
    'project.list.description': 'विवरण:',
    'project.edit': 'प्रोजेक्ट संपादित करें',
    'project.list.translateBible': 'अनुवाद बाइबिल',
    'project.list.empty': 'अपना अनुवाद प्रोजेक्ट सूची खाली है।',
    'project.list.myProjects': 'मेरे बाइबिल अनुवाद परियोजनाएं',
    'project.list.newProject': 'नया काम',
    'project.create.createNew': 'कोई नया प्रोजेक्ट बनाएं',
    'project.namePlaceholder': 'इस परियोजना का नाम दर्ज करें',
    'project.descriptionPlaceholder': 'परियोजना विवरण दर्ज करें',
    'project.name': 'परियोजना का नाम',
    'project.beginningDate': 'प्रारंभिक दिनांक',
    'project.targetCompletionDate': 'लक्ष्य हासिल करने की तारीख',
    'project.description': 'विवरण',
    'project.manage-users': 'अनुवादक की व्यवस्था करें',
    'project.new-user': 'न्यू अनुवादक',
    'project.add-user': 'अनुवादक जोड़ें',
    'project.users': 'परियोजना अनुवादकों',
    'project.read-only': 'सिफ़ पढ़िये',
    'project.read&write': 'पढ़ना लिखना',
    'project.owner': 'मालिक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'ऐसा कोई अनुवादक मौजूद है',
    'project.message.user-added': 'अनुवादक को सफलतापूर्वक जोड़',
    'project.message.user-deleted': 'अनुवादक हटा दिया।',
    'project.message.only-owner-can-manage-users':
      'केवल परियोजना स्वामी उपयोगकर्ताओं को प्रबंधित कर सकते हैं',
    'project.shared': 'सहयोगी परियोजना',
    'general.delete-user': 'हटाएं अनुवादक',
    'general.edit-user': 'यूजर को संपादित करो',
    'general.name': 'नाम',
    'general.email': 'ईमेल',
    'general.permissions': 'अनुमतियां',
    'general.role': 'भूमिका',
    signin: 'साइन इन करें',
    'signin.emailPlaceholder': 'ईमेल पता',
    'signin.passwordPlaceholder': 'कुंजिका',
    'signin.forgotPassword': 'क्या आप पासवर्ड भूल गए?',
    'signin.rememberMe': 'मुझे याद रखना',
    'signin.facebook': 'फ़ेसबुक से साइन इन करें',
    'signin.google': 'गूगल के साथ साइन इन',
    'signin.dontHaveAccount': 'एक खाता नहीं है?',
    'signin.createOne': 'एक बनाए',
    'signup.join': 'YouTranslate.Bible शामिल हों',
    'signup.createAccountPrompt': 'अपने नि: शुल्क खाता शुरू करने के लिए बनाएँ।',
    'signup.createAccount': 'खाता बनाएं',
    'signup.emailPlaceholder': 'ईमेल पता',
    'signup.passwordPlaceholder': 'कुंजिका',
    'translation.project': 'परियोजना:',
    'translation.backToProjectList': 'परियोजना सूची पर वापस',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'अध्याय:',
    'translation.prompt':
      'आप इस अध्याय को छोड़ना चाहते हैं आप सुनिश्चित हैं? सभी बिना सहेजे कार्य खो जाएगा।',
    'translation.prev': 'पिछला',
    'translation.next': 'आगे',
    'translation.previousChapter': 'पिछले अध्याय',
    'translation.nextChapter': 'अगला अध्याय',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'अध्याय:',
    'navigator.oldTestament': 'पुराना वसीयतनामा',
    'navigator.newTestament': 'नए करार',
    'navigator.selectBook': 'एक पुस्तक का चयन करें',
    'navigator.search': 'खोज',
    'navigator.changeBook': 'पुस्तक बदलें',
    'navigator.changeChapter': 'अध्याय बदलें',
    Genesis: 'उत्पत्ति',
    Exodus: 'एक्सोदेस',
    Leviticus: 'छिछोरापन',
    Numbers: 'नंबर',
    Deuteronomy: 'व्यवस्था विवरण',
    Joshua: 'यहोशू',
    Judges: 'न्यायाधीशों',
    Ruth: 'दया',
    '1 Samuel': '1 शमूएल',
    '2 Samuel': '2 शमूएल',
    '1 Kings': '1 किंग्स',
    '2 Kings': '2 राजा',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एजरा',
    Nehemiah: 'नहेमायाह',
    Esther: 'एस्थर',
    Job: 'काम',
    Psalms: 'भजन',
    Proverbs: 'कहावत का खेल',
    Ecclesiastes: 'ऐकलेसिस्टास',
    'Song of Songs': 'गीत के गीत',
    Isaiah: 'यशायाह',
    Jeremiah: 'यिर्मयाह',
    Lamentations: 'विलाप',
    Ezekiel: 'ईजेकील',
    Daniel: 'डैनियल',
    Hosea: 'होशे',
    Joel: 'जोएल',
    Amos: 'अमोस',
    Obadiah: 'ओबद्याह',
    Jonah: 'जोनाह',
    Micah: 'मीका',
    Nahum: 'नहूम',
    Habakkuk: 'हबक्कूक',
    Zephaniah: 'सपन्याह',
    Haggai: 'हाग्गै',
    Zechariah: 'जकर्याह',
    Malachi: 'मालाची',
    Matthew: 'मैथ्यू',
    Mark: 'निशान',
    Luke: 'ल्यूक',
    John: 'जॉन',
    Acts: 'अधिनियमों',
    Romans: 'रोमनों',
    '1 Corinthians': '1 कुरिन्थियों',
    '2 Corinthians': '2 कुरिन्थियों',
    Galatians: 'गलाटियन्स',
    Ephesians: 'इफिसियों',
    Philippians: 'फिलिप्पियों',
    Colossians: 'कुलुस्सियों',
    '1 Thessalonians': '1 थिस्सलुनीकियों',
    '2 Thessalonians': '2 थिस्सलुनीकियों',
    '1 Timothy': '1 टिमोथी',
    '2 Timothy': '2 टिमोथी',
    Titus: 'टाइटस',
    Philemon: 'फिलेमोन',
    Hebrews: 'इब्रियों',
    James: 'जेम्स',
    '1 Peter': '1 पीटर',
    '2 Peter': '2 पीटर',
    '1 John': '1 जॉन',
    '2 John': '2 जॉन',
    '3 John': '3 जॉन',
    Jude: 'जूदास',
    Revelation: 'रहस्योद्घाटन',
    'resources.prompt':
      'पारित होने के आप अनुवाद कर रहे बारे में अतिरिक्त जानकारी के लिए बाइबिल संसाधनों को जोड़ें।',
    'resources.add': 'एक बाइबिल संसाधन जोड़ें',
    Manuscript: 'हस्तलिपि',
    Morphology: 'आकृति विज्ञान',
    Lexicon: 'शब्दकोश',
    'Syntactic Tree': 'वाक्यात्मक ट्री',
    'Go to project list': 'परियोजना सूची पर जाएँ',
    'Project not found': 'प्रोजेक्ट नहीं मिला या अपर्याप्त अनुमतियां।',
    'error.referenceDoesNotExistForResource':
      'यह बाइबिल अनुवाद चयनित पारित होने के लिए सामग्री नहीं है।',
    'references.addAnother': 'एक और अनुवाद जोड़ें',
    'references.referencePrompt': 'अतिरिक्त जानकारी के लिए बाइबिल अन्य अनुवाद जोड़ें।',
    'references.add': 'एक बाइबल अनुवाद जोड़ें',
    'translation.complete': 'अनुवाद पूर्ण',
    Alignment: 'संरेखण',
    Share: 'शेयर',
    'Your Translation': 'आपका अनुवाद',
    'Enter your translation': 'आपके अनुवाद दर्ज',
    OK: 'ठीक',
    'error.verseTranslationEmpty': 'आप अनुवाद सामग्री खाली नहीं छोड़ सकते।',
    'translation.hide.completed': 'पूरा छिपाएं',
    alignment: 'संरेखण',
    'alignment.verify': 'सत्यापित करें',
    'alignment.backToEditor': 'संपादक पर वापस',
    'alignment.error':
      'कुछ गलत हो गया। यदि समस्या बनी रहती, YouTranslate.Bible टीम से संपर्क करें।',
    'alignment.add': 'लिंक जोड़ें',
    'alignment.delete': 'लिंक निकालें',
    'alignment.clear': 'चयन साफ़ करें',
    'alignment.reverse': 'रिवर्स प्रदर्शन',
    'alignment.removeOrClear': 'चयनित लिंक या स्पष्ट चयन निकालें।',
    'alignment.addOrRemove': 'नया लिंक बनाने या पिछले एक को हटा दें।',
    'alignment.selectAndAdd': 'लिंक करने के लिए सेगमेंट इसी का चयन करें।',
    'error.mustBeSignedToMakeChanges': 'किए जाने वाले परिवर्तन के लिए प्रवेश होना आवश्यक है।',
    'error.mustBeSignedToTranslateBible': 'आप बाइबल का अनुवाद करने में प्रवेश करना होगा।',
    checking: 'जाँच हो रही है',
    'verseEditor.verseSuggestions': 'सुझाव',
    verses: 'द्वारा सुराह',
    words: 'तक पद',
    'leftPanel.name': 'बाइबल की मूल पाठ',
    'rightPanel.name': 'बाइबल की अनुवाद',
    'bottomPanel.name': 'बाइबिल के लिए संसाधन',
    'syntax.adv': 'क्रिया विशेषण',
    'syntax.advp': 'क्रिया विशेषण वाक्यांश',
    'syntax.cl': 'धारा',
    'syntax.conj': 'संयोजन के रूप',
    'syntax.cjp': 'संयोजन के रूप',
    'syntax.np': 'संज्ञा वाक्यांश',
    'syntax.o': 'वस्तु',
    'syntax.io': 'अप्रत्यक्ष वस्तु',
    'syntax.p': 'विधेय',
    'syntax.pp': 'पूर्वसर्गीय वाक्यांश',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रिया',
    'syntax.vc': 'क्रिया खण्ड',
    'syntax.adv.description':
      'भाषण के दूसरे हिस्से संशोधित करता है। आमतौर पर ढंग, स्थान, समय, आवृत्ति, डिग्री, निश्चितता के स्तर, आदि को व्यक्त',
    'syntax.advp.description':
      'एक बहु-शब्द अभिव्यक्ति है कि संशोधित भाषण के दूसरे भाग। आमतौर पर ढंग, स्थान, समय, आवृत्ति, डिग्री, निश्चितता के स्तर, आदि को व्यक्त ...',
    'syntax.cl.description': 'एक वाक्य है कि एक क्रिया शामिल है का एक हिस्सा है।',
    'syntax.conj.description': 'शब्दों, वाक्यांशों, और खंड जोड़ता है।',
    'syntax.cjp.description': 'शब्दों, वाक्यांशों, और खंड जोड़ता है।',
    'syntax.np.description': 'एक बहु-शब्द अभिव्यक्ति है कि एक संज्ञा का कार्य।',
    'syntax.o.description': 'इकाई है कि इस विषय अपना काम कर रहा है।',
    'syntax.io.description': 'यह एक व्याख्या करने के लिए कठिन है।',
    'syntax.p.description': 'विषय संशोधित करता है। आम तौर पर एक क्रिया के साथ अभिनय किया है।',
    'syntax.pp.description': 'यह एक व्याख्या करने के लिए कठिन है।',
    'syntax.s.description': 'संस्था, जिसे खंड के क्रिया नियंत्रित करता है।',
    'syntax.v.description': 'एक कार्रवाई बता देते हैं।',
    'syntax.vc.description':
      'एक बहु-शब्द अभिव्यक्ति है कि प्रदर्शन एक क्रिया के समारोह (बता देते हैं एक कार्रवाई)।',
    'general.close': 'बंद करे',
    'general.action': 'कार्य',
    'general.select': 'चुनते हैं...',
    'general.back': 'वापस',
  },
  hr: {
    'languages.en': 'Engleski',
    'languages.am': 'amharski',
    'languages.bn': 'bengalski jezik',
    'languages.ceb': 'cebuano',
    'languages.de': 'Njemački jezik',
    'languages.es-419': 'Španjolski jezik',
    'languages.fr': 'Francuski jezik',
    'languages.gu': 'gujarati',
    'languages.hi': 'hindski',
    'languages.hr': 'Hrvatski jezik',
    'languages.hu': 'mađarski jezik',
    'languages.id': 'indonezijski jezik',
    'languages.km': 'Crvenih',
    'languages.kn': 'kannada',
    'languages.ko': 'Korejski jezik',
    'languages.lo': 'Laoski Jezik',
    'languages.ml': 'malajalamski',
    'languages.mr': 'marathi',
    'languages.ms': 'Malezijski',
    'languages.my': 'burmanski jezik',
    'languages.ne': 'nepalski jezik',
    'languages.plt': 'Madagaskar Jezik',
    'languages.pt-br': 'Portugalski (Brazil) Jezik',
    'languages.ru': 'Ruski jezik',
    'languages.sw': 'svahili',
    'languages.ta': 'Tamilski jezik',
    'languages.th': 'Thai jezik',
    'languages.tl': 'Tagalog',
    'languages.te': 'telegu jezik Jezik',
    'languages.vi': 'Vijetnamski jezik',
    'languages.zh-hans': 'Kineski jezik (jednostavan)',
    'languages.zh-hant': 'Kineski jezik (tradicionalni)',
    'header.home': 'Dom',
    'header.help': 'Pomozite',
    'header.project': 'Projekt',
    'help.contactSupport': 'Molimo pošaljite e-poštu na help@youtranslate.bible za pomoć.',
    'home.ytbProvides': 'YouTranslate.Bible slobodno omogućuje:',
    'home.easyToUseTool': 'Jednostavan za korištenje online alat za prevođenje',
    'home.originalTextHelp': 'Pomoć razumjeti tekst Biblije u svojim izvornim jezicima',
    'home.referencesAndResources': 'Referentni i trening resursa',
    'home.computerAssistance':
      'State-of-the-art računalne tehnologije kako bi se pomoglo prevoditelje',
    'profile.userProfile': 'Korisnički profil',
    'profile.language': 'Jezik',
    'profile.update': 'Ažuriraj',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Odjavite se',
    'usernav.logout.modalTitle': 'Odjava iz YouTranslate.Bible',
    'usernav.logout.modalText':
      'Jeste li sigurni da želite odjaviti? Sve što niste spremili bit će izgubljene.',
    cancel: 'Otkazati',
    delete: 'Izbrisati',
    create: 'Stvoriti',
    update: 'Ažuriraj',
    edit: 'Uredi',
    save: 'Uštedjeti',
    add: 'Dodati',
    'project.create.title': 'Biblija Translation Project',
    'project.create.list': 'popis projekata',
    'project.list.deleteProject': 'Izbriši projekt?',
    'project.list.areYouSure': 'Jeste li sigurni da želite izbrisati ovaj projekt?',
    'project.list.workWillBeDeleted': 'Projekt i bilo koji posao povezan bit će izbrisan.',
    'project.list.completion': 'Završetak:',
    'project.list.createdDate': 'Datum kreiranja:',
    'project.list.beginningDate': 'Početak Date:',
    'project.list.targetCompletionDate': 'Ciljna Datum završetka:',
    'project.list.description': 'Opis:',
    'project.edit': 'Uredi projekta',
    'project.list.translateBible': 'Prevedi Bibliju',
    'project.list.empty': 'Vaš popis prijevod projekt je prazna.',
    'project.list.myProjects': 'Bibliju Prevođenje Projekti',
    'project.list.newProject': 'Novi projekt',
    'project.create.createNew': 'Stvaranje novog projekta',
    'project.namePlaceholder': 'Unesite naziv projekta',
    'project.descriptionPlaceholder': 'Unesite opis projekta',
    'project.name': 'Naziv projekta',
    'project.beginningDate': 'počevši Datum',
    'project.targetCompletionDate': 'Ciljna Datum završetka',
    'project.description': 'Opis',
    'project.manage-users': 'Upravljanje Prevoditelji',
    'project.new-user': 'Novi prevoditelj',
    'project.add-user': 'Dodaj prevoditelj',
    'project.users': 'prevoditelji Projekt',
    'project.read-only': 'Samo za čitanje',
    'project.read&write': 'Čitati i pisati',
    'project.owner': 'Vlasnik',
    'project.translator': 'Prevoditelj',
    'project.message.no-such-user': 'Ne kao prevoditelj postoji',
    'project.message.user-added': 'Prevoditelj uspješno dodan',
    'project.message.user-deleted': 'Prevoditelj uklonjena.',
    'project.message.only-owner-can-manage-users':
      'Samo vlasnik projekt može upravljati korisnicima',
    'project.shared': 'kolaborativni projekt',
    'general.delete-user': 'brisanje prevoditelj',
    'general.edit-user': 'Uređivanje korisnika',
    'general.name': 'Ime',
    'general.email': 'E-mail',
    'general.permissions': 'dozvole',
    'general.role': 'Uloga',
    signin: 'Prijaviti se',
    'signin.emailPlaceholder': 'Email adresa',
    'signin.passwordPlaceholder': 'Zaporka',
    'signin.forgotPassword': 'Zaboravili ste zaporku?',
    'signin.rememberMe': 'Zapamti me',
    'signin.facebook': 'Prijavite se sa Facebook',
    'signin.google': 'Prijavite se s Googleom',
    'signin.dontHaveAccount': 'Nemate korisnički račun?',
    'signin.createOne': 'Stvori jedan',
    'signup.join': 'Pridružite YouTranslate.Bible',
    'signup.createAccountPrompt': 'Napravite svoj besplatni korisnički račun za početak.',
    'signup.createAccount': 'Izradi račun',
    'signup.emailPlaceholder': 'Email adresa',
    'signup.passwordPlaceholder': 'Zaporka',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Povratak na popis projekata',
    'translation.book': 'Knjiga:',
    'translation.chapter': 'Poglavlje:',
    'translation.prompt':
      'Jeste li sigurni da želite napustiti ovo poglavlje? Sve što niste spremili bit će izgubljene.',
    'translation.prev': 'Prethodna',
    'translation.next': 'Sljedeći',
    'translation.previousChapter': 'Prethodno poglavlje',
    'translation.nextChapter': 'Sljedeće poglavlje',
    'navigator.book': 'Knjiga:',
    'navigator.chapter': 'Poglavlje:',
    'navigator.oldTestament': 'Stari zavjet',
    'navigator.newTestament': 'Novi zavjet',
    'navigator.selectBook': 'Odaberite knjigu',
    'navigator.search': 'traži',
    'navigator.changeBook': 'promjena knjiga',
    'navigator.changeChapter': 'promjena poglavlje',
    Genesis: 'Geneza',
    Exodus: 'odlazak',
    Leviticus: 'Levitski zakonik',
    Numbers: 'brojevi',
    Deuteronomy: 'Ponovljeni zakon',
    Joshua: 'Joshua',
    Judges: 'suci',
    Ruth: 'tuga',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kr',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1. Ljetopisa',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemija',
    Esther: 'Estera',
    Job: 'Posao',
    Psalms: 'Psalmi',
    Proverbs: 'ljetopis',
    Ecclesiastes: 'Propovjednik',
    'Song of Songs': 'Pjesma nad pjesmama',
    Isaiah: 'Izaija',
    Jeremiah: 'Jeremija',
    Lamentations: 'Tužaljke',
    Ezekiel: 'Ezekiel',
    Daniel: 'Danijel',
    Hosea: 'Hošea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadija',
    Jonah: 'Jona',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Sefanija',
    Haggai: 'Hagaj',
    Zechariah: 'Zaharija',
    Malachi: 'Malahija',
    Matthew: 'Matthew',
    Mark: 'oznaka',
    Luke: 'Luka',
    John: 'Ivan',
    Acts: 'Djela',
    Romans: 'Rimljani',
    '1 Corinthians': '1 Kor',
    '2 Corinthians': '2 Kor',
    Galatians: 'Galaćanima',
    Ephesians: 'Efežanima',
    Philippians: 'Filipljanima',
    Colossians: 'Kološanima',
    '1 Thessalonians': '1 Solunjanima',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titu',
    Philemon: 'Philemon',
    Hebrews: 'Hebrejima',
    James: 'James',
    '1 Peter': '1. Petrova',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2. Ivanova',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Otkrovenje',
    'resources.prompt': 'Dodaj biblijske sredstva za dodatni uvid u prolazu prevodite.',
    'resources.add': 'Dodaj biblijski resurs',
    Manuscript: 'Rukopis',
    Morphology: 'Morfologija',
    Lexicon: 'Leksikon',
    'Syntactic Tree': 'sintaktička stablo',
    'Go to project list': 'Idi na popis projekata',
    'Project not found': 'Projekt nije pronađena ili je nedovoljno dozvole.',
    'error.referenceDoesNotExistForResource':
      'To prevođenja Biblije ne sadrži sadržaj za odabrani prolaz.',
    'references.addAnother': 'Dodaj još jednu prijevod',
    'references.referencePrompt': 'Dodajte ostale biblijske prijevode za dodatni uvid.',
    'references.add': 'Dodaj biblijski prijevod',
    'translation.complete': 'Prevođenje potpuna',
    Alignment: 'Poravnanje',
    Share: 'Udio',
    'Your Translation': 'vaš prijevod',
    'Enter your translation': 'Unesite svoj prijevod',
    OK: 'u redu',
    'error.verseTranslationEmpty': 'Ne možete ostaviti sadržaj prijevod prazna.',
    'translation.hide.completed': 'Sakrij završena',
    alignment: 'Poravnanje',
    'alignment.verify': 'provjeriti',
    'alignment.backToEditor': 'Povratak na Editor',
    'alignment.error':
      'Nešto je pošlo po zlu. Ako se problem nastavi, obratite se YouTranslate.Bible tim.',
    'alignment.add': 'Dodaj link',
    'alignment.delete': 'Ukloni vezu',
    'alignment.clear': 'Poništi odabir',
    'alignment.reverse': 'obrnuti prikaz',
    'alignment.removeOrClear': 'Ukloni odabrane vezu ili jasan izbor.',
    'alignment.addOrRemove': 'Izraditi novu vezu ili brisanje prethodnog.',
    'alignment.selectAndAdd': 'Odaberite odgovarajuće segmente na link.',
    'error.mustBeSignedToMakeChanges': 'Morate biti prijavljeni da biste promjene čine.',
    'error.mustBeSignedToTranslateBible': 'Morate biti prijavljeni da biste prevesti Bibliju.',
    checking: 'provjeravanje',
    'verseEditor.verseSuggestions': 'prijedlozi',
    verses: 'po stih',
    words: 'Do Word',
    'leftPanel.name': 'Izvorni tekst Biblije',
    'rightPanel.name': 'Prijevodi Biblije',
    'bottomPanel.name': 'Resursi za Bibliju',
    'syntax.adv': 'Prilog',
    'syntax.advp': 'prilog fraza',
    'syntax.cl': 'Klauzula',
    'syntax.conj': 'veznik',
    'syntax.cjp': 'veznik',
    'syntax.np': 'Imenični izraz',
    'syntax.o': 'Objekt',
    'syntax.io': 'Neizravni objekt',
    'syntax.p': 'Predikat',
    'syntax.pp': 'prijedložni izraz',
    'syntax.s': 'predmet',
    'syntax.v': 'Glagol',
    'syntax.vc': 'glagol Klauzula',
    'syntax.adv.description':
      'Modificira drugi dio govora. Tipično izražavaju način, mjesto, vrijeme, frekvencija, stupanj, stupanj sigurnosti, itd',
    'syntax.advp.description':
      'Izrazom multi-riječ koja mijenja jedan dio govora. Tipično izražavaju način, mjesto, vrijeme, frekvencija, stupanj, stupanj sigurnosti, itd ...',
    'syntax.cl.description': 'Dio kazne koji sadrži glagol.',
    'syntax.conj.description': 'Povezuje riječi, fraze i klauzule.',
    'syntax.cjp.description': 'Povezuje riječi, fraze i klauzule.',
    'syntax.np.description': 'Izrazom multi-riječ koja obavlja funkciju imenice.',
    'syntax.o.description': 'Subjekt koji je djelovao na po toj temi.',
    'syntax.io.description': 'Ovo je jedna teško objasniti.',
    'syntax.p.description': 'Mijenja temu. Obično zvijezde sa glagolom.',
    'syntax.pp.description': 'Ovo je jedna teško objasniti.',
    'syntax.s.description': 'Subjekt koji kontrolira glagol u rečenici.',
    'syntax.v.description': 'Prenosi radnju.',
    'syntax.vc.description':
      'Ekspresije više riječi koji obavlja funkciju (glagol preda Operacija).',
    'general.close': 'Zatvoriti',
    'general.action': 'Radnja',
    'general.select': 'Odaberi...',
    'general.back': 'leđa',
  },
  hu: {
    'languages.en': 'angol',
    'languages.am': 'amhara',
    'languages.bn': 'bengáli nyelv',
    'languages.ceb': 'Cebuano Nyelv',
    'languages.de': 'Német nyelv',
    'languages.es-419': 'Spanyol nyelv',
    'languages.fr': 'Francia nyelv',
    'languages.gu': 'gudzsaráti',
    'languages.hi': 'hindi',
    'languages.hr': 'horvát nyelv',
    'languages.hu': 'magyar nyelv',
    'languages.id': 'Indonéz nyelv',
    'languages.km': 'khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Koreai nyelv',
    'languages.lo': 'laoszi Nyelv',
    'languages.ml': 'Malayalam',
    'languages.mr': 'marathi',
    'languages.ms': 'malajziai',
    'languages.my': 'burmai nyelv',
    'languages.ne': 'nepáli nyelv',
    'languages.plt': 'madagaszkári nyelv',
    'languages.pt-br': 'Portugál (Brazília) Nyelv',
    'languages.ru': 'Orosz nyelv',
    'languages.sw': 'szuahéli',
    'languages.ta': 'tamil nyelv',
    'languages.th': 'Thai nyelv',
    'languages.tl': 'tagalog',
    'languages.te': 'Telegu Nyelv',
    'languages.vi': 'Vietnámi nyelv',
    'languages.zh-hans': 'Kínai nyelv (egyszerűsített)',
    'languages.zh-hant': 'Kínai nyelv (hagyományos)',
    'header.home': 'itthon',
    'header.help': 'Segítség',
    'header.project': 'program',
    'help.contactSupport': 'Kérjük, írjon nekünk help@youtranslate.bible segítségért.',
    'home.ytbProvides': 'YouTranslate.Bible szabadon biztosítja:',
    'home.easyToUseTool': 'Egy könnyen használható online fordítási eszköz',
    'home.originalTextHelp': 'Segíts megérteni a szöveg a Biblia eredeti nyelven',
    'home.referencesAndResources': 'Referencia és képzési erőforrások',
    'home.computerAssistance': 'State-of-the-art számítógépes technológia segíti fordítók',
    'profile.userProfile': 'Felhasználói profil',
    'profile.language': 'Nyelv',
    'profile.update': 'frissítés',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Kijelentkezés',
    'usernav.logout.modalTitle': 'Kilépés a YouTranslate.Bible',
    'usernav.logout.modalText':
      'Biztosan meg szeretné kijelentkezni? Minden nem mentett adatai elvesznek.',
    cancel: 'Megszünteti',
    delete: 'Töröl',
    create: 'teremt',
    update: 'frissítés',
    edit: 'szerkesztése',
    save: 'Megment',
    add: 'hozzáad',
    'project.create.title': 'Biblia fordítási projekt',
    'project.create.list': 'Projekt lista',
    'project.list.deleteProject': 'Projekt törlése?',
    'project.list.areYouSure': 'Biztos, hogy törölni kívánja ezt a projektet?',
    'project.list.workWillBeDeleted': 'A projekt és a kapcsolódó munka törlésre kerül.',
    'project.list.completion': 'Befejezés:',
    'project.list.createdDate': 'Létrehozás dátuma:',
    'project.list.beginningDate': 'Kezdet dátuma:',
    'project.list.targetCompletionDate': 'Terv teljesítés dátuma:',
    'project.list.description': 'Leírás:',
    'project.edit': 'Projekt szerkesztése',
    'project.list.translateBible': 'Fordítás Biblia',
    'project.list.empty': 'A fordítási projekt listája üres.',
    'project.list.myProjects': 'Saját fordítású Projects',
    'project.list.newProject': 'Új projekt',
    'project.create.createNew': 'Hozzon létre egy új projekt',
    'project.namePlaceholder': 'Adja meg a projekt neve',
    'project.descriptionPlaceholder': 'Adja meg a projekt leírása',
    'project.name': 'Projekt neve',
    'project.beginningDate': 'kezdetének',
    'project.targetCompletionDate': 'Terv teljesítés dátuma',
    'project.description': 'Leírás',
    'project.manage-users': 'kezelése fordítók',
    'project.new-user': 'új fordító',
    'project.add-user': 'Add fordító',
    'project.users': 'Project fordítók',
    'project.read-only': 'Csak olvasható',
    'project.read&write': 'Ír olvas',
    'project.owner': 'Tulajdonos',
    'project.translator': 'Fordító',
    'project.message.no-such-user': 'Nincs ilyen fordító létezik',
    'project.message.user-added': 'Fordító sikeresen hozzáadva',
    'project.message.user-deleted': 'Fordító távolítani.',
    'project.message.only-owner-can-manage-users':
      'Csak projekt tulajdonosa kezelheti a felhasználókat',
    'project.shared': 'együttműködési projekt',
    'general.delete-user': 'Törlés fordító',
    'general.edit-user': 'felhasználó szerkesztése',
    'general.name': 'Név',
    'general.email': 'Email',
    'general.permissions': 'Engedélyek',
    'general.role': 'Szerep',
    signin: 'Bejelentkezés',
    'signin.emailPlaceholder': 'Email cím',
    'signin.passwordPlaceholder': 'Jelszó',
    'signin.forgotPassword': 'Elfelejtette a jelszavát?',
    'signin.rememberMe': 'Emlékezz rám',
    'signin.facebook': 'Jelentkezzen be Facebook',
    'signin.google': 'Jelentkezzen be Google',
    'signin.dontHaveAccount': 'Ne regisztráltál?',
    'signin.createOne': 'Csinálni egyet',
    'signup.join': 'Csatlakozz YouTranslate.Bible',
    'signup.createAccountPrompt': 'Hozza létre ingyenes fiókot, és kezdődik.',
    'signup.createAccount': 'Fiók létrehozása',
    'signup.emailPlaceholder': 'Email cím',
    'signup.passwordPlaceholder': 'Jelszó',
    'translation.project': 'PROJEKT:',
    'translation.backToProjectList': 'Vissza projekt lista',
    'translation.book': 'Könyv:',
    'translation.chapter': 'Fejezet:',
    'translation.prompt':
      'Biztosan el szeretné hagyni ezt a fejezetet? Minden nem mentett adatai elvesznek.',
    'translation.prev': 'Előző',
    'translation.next': 'Következő',
    'translation.previousChapter': 'Előző fejezet',
    'translation.nextChapter': 'Következő fejezet',
    'navigator.book': 'Könyv:',
    'navigator.chapter': 'Fejezet:',
    'navigator.oldTestament': 'Ótestamentum',
    'navigator.newTestament': 'Újtestamentum',
    'navigator.selectBook': 'Válasszon egy könyvet',
    'navigator.search': 'Keresés',
    'navigator.changeBook': 'Változás könyv',
    'navigator.changeChapter': 'Változás fejezet',
    Genesis: 'Genezis',
    Exodus: 'Kivonulás',
    Leviticus: 'Mózes harmadik könyve',
    Numbers: 'számok',
    Deuteronomy: 'Mózes ötödik könyve',
    Joshua: 'Joshua',
    Judges: 'bírák könyve',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehémiás',
    Esther: 'Esther',
    Job: 'Munka',
    Psalms: 'Zsoltárok',
    Proverbs: 'Példabeszédek',
    Ecclesiastes: 'prédikátor könyve',
    'Song of Songs': 'Énekek éneke',
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'siralmai',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hóseás',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Abdiás',
    Jonah: 'Jónás',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Sofóniás',
    Haggai: 'Aggeus',
    Zechariah: 'Zakariás',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'János',
    Acts: 'ApCsel',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Ef',
    Philippians: 'Filippi',
    Colossians: 'Kol',
    '1 Thessalonians': '1Thessz',
    '2 Thessalonians': '2 Thesszalonika',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titusz',
    Philemon: 'Philemon',
    Hebrews: 'Zsid',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 János',
    '3 John': '3 János',
    Jude: 'Jude',
    Revelation: 'Kinyilatkoztatás',
    'resources.prompt': 'Add bibliai források további betekintést a folyosón fordítandó.',
    'resources.add': 'Add a bibliai forrás',
    Manuscript: 'Kézirat',
    Morphology: 'Morfológia',
    Lexicon: 'Szókincs',
    'Syntactic Tree': 'A szintaktikai fa',
    'Go to project list': 'Ide projekt lista',
    'Project not found': 'A projekt nem található vagy nem megfelelő jogosultságokat.',
    'error.referenceDoesNotExistForResource':
      'Ez bibliafordítás nem tartalmaz tartalom a kiválasztott szakasz.',
    'references.addAnother': 'Újabb fordítás',
    'references.referencePrompt': 'Add más bibliafordítások további betekintést.',
    'references.add': 'Add a bibliafordítás',
    'translation.complete': 'Translation teljes',
    Alignment: 'igazítás',
    Share: 'Részvény',
    'Your Translation': 'Az Translation',
    'Enter your translation': 'Adja meg a fordítás',
    OK: 'rendben',
    'error.verseTranslationEmpty': 'Nem hagyhatja a fordítás tartalmát kiüríteni.',
    'translation.hide.completed': 'Hide befejezett',
    alignment: 'igazítás',
    'alignment.verify': 'Ellenőrizze',
    'alignment.backToEditor': 'Vissza szerkesztő',
    'alignment.error':
      'Valami elromlott. Ha a probléma továbbra is fennáll, forduljon a YouTranslate.Bible csapat.',
    'alignment.add': 'link hozzáadása',
    'alignment.delete': 'link eltávolítása',
    'alignment.clear': 'Kijelölés törlése',
    'alignment.reverse': 'fordított megjelenítése',
    'alignment.removeOrClear': 'Távolítsuk el a kiválasztott link, vagy tiszta választást.',
    'alignment.addOrRemove': 'Hozzon létre egy új kapcsolatot, vagy törölhet egy előzőt.',
    'alignment.selectAndAdd': 'Jelölje megfelelő szegmensei a link.',
    'error.mustBeSignedToMakeChanges': 'Be kell jelentkeznie a módosításokat.',
    'error.mustBeSignedToTranslateBible': 'Be kell jelentkeznie a lefordítani a Bibliát.',
    checking: 'Ellenőrzés',
    'verseEditor.verseSuggestions': 'Javaslatok',
    verses: 'vers',
    words: 'a Word',
    'leftPanel.name': 'Az eredeti szöveg a Biblia',
    'rightPanel.name': 'Bibliafordítás',
    'bottomPanel.name': 'Források a Biblia',
    'syntax.adv': 'Határozószó',
    'syntax.advp': 'határozószó kifejezés',
    'syntax.cl': 'Kikötés',
    'syntax.conj': 'kötőszó',
    'syntax.cjp': 'kötőszó',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'Tárgy',
    'syntax.io': 'közvetett Object',
    'syntax.p': 'Állítmány',
    'syntax.pp': 'Elöljárós kifejezés',
    'syntax.s': 'Tantárgy',
    'syntax.v': 'Ige',
    'syntax.vc': 'ige szakasz',
    'syntax.adv.description':
      'Módosítja a másik része a beszéd. Jellemzően expressz módon, hely, idő, frekvencia fokozat, bizonyossággal, stb',
    'syntax.advp.description':
      'A több szóból álló kifejezés, amely módosítja a másik része a beszéd. Jellemzően expressz módon, hely, idő, frekvencia fokozat, bizonyossággal, stb ...',
    'syntax.cl.description': 'Egy része a mondat, amely tartalmaz egy ige.',
    'syntax.conj.description': 'Összeköti szavak, kifejezések és kikötések.',
    'syntax.cjp.description': 'Összeköti szavak, kifejezések és kikötések.',
    'syntax.np.description': 'A több szóból álló kifejezés, amely ellátja a funkcióját egy főnév.',
    'syntax.o.description': 'A gazdálkodó egység, amely hat rá a témára.',
    'syntax.io.description': 'Ez egy nehéz megmagyarázni.',
    'syntax.p.description': 'Módosítja a témát. Általában csillagok ige.',
    'syntax.pp.description': 'Ez egy nehéz megmagyarázni.',
    'syntax.s.description': 'A szervezet, amely szabályozza az ige a záradék.',
    'syntax.v.description': 'Közvetít egy műveletet.',
    'syntax.vc.description':
      'A több szóból álló kifejezés, amely funkciója van egy ige (közvetít akció).',
    'general.close': 'Bezárás',
    'general.action': 'Akció',
    'general.select': 'Kiválasztás ...',
    'general.back': 'Vissza',
  },
  id: {
    'languages.en': 'Inggris',
    'languages.am': 'Amharic',
    'languages.bn': 'Bahasa Bengali',
    'languages.ceb': 'Cebuano Bahasa',
    'languages.de': 'Bahasa Jerman',
    'languages.es-419': 'Bahasa Spanyol',
    'languages.fr': 'Bahasa Perancis',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroasia Bahasa',
    'languages.hu': 'Hungaria Bahasa',
    'languages.id': 'Bahasa Indonesia',
    'languages.km': 'khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Bahasa Korea',
    'languages.lo': 'Bahasa Laos',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Burma Bahasa',
    'languages.ne': 'Nepal Bahasa',
    'languages.plt': 'Malagasi Bahasa',
    'languages.pt-br': 'Portugis (Brasil) Bahasa',
    'languages.ru': 'Bahasa Rusia',
    'languages.sw': 'Swahili',
    'languages.ta': 'Bahasa Tamil',
    'languages.th': 'Bahasa Thailand',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Bahasa',
    'languages.vi': 'Bahasa Vietnam',
    'languages.zh-hans': 'Bahasa Cina (Sederhana)',
    'languages.zh-hant': 'Bahasa Cina (Tradisional)',
    'header.home': 'Rumah',
    'header.help': 'Tolong',
    'header.project': 'Proyek',
    'help.contactSupport': 'Silahkan email kami di help@youtranslate.bible untuk bantuan.',
    'home.ytbProvides': 'YouTranslate.Bible bebas memberikan:',
    'home.easyToUseTool': 'Alat terjemahan online mudah digunakan',
    'home.originalTextHelp': 'Bantuan untuk memahami teks Alkitab dalam bahasa aslinya',
    'home.referencesAndResources': 'sumber referensi dan pelatihan',
    'home.computerAssistance': 'State-of-the-art teknologi komputer untuk membantu penerjemah',
    'profile.userProfile': 'Profil pengguna',
    'profile.language': 'Bahasa',
    'profile.update': 'Memperbarui',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Keluar',
    'usernav.logout.modalTitle': 'Keluar dari YouTranslate.Bible',
    'usernav.logout.modalText':
      'Apakah Anda yakin Anda ingin logout? Semua pekerjaan yang belum disimpan akan hilang.',
    cancel: 'Membatalkan',
    delete: 'Menghapus',
    create: 'Membuat',
    update: 'Memperbarui',
    edit: 'mengedit',
    save: 'Menyimpan',
    add: 'Menambahkan',
    'project.create.title': 'Proyek Terjemahan Alkitab',
    'project.create.list': 'daftar proyek',
    'project.list.deleteProject': 'Hapus Project?',
    'project.list.areYouSure': 'Apakah Anda yakin ingin menghapus proyek ini?',
    'project.list.workWillBeDeleted': 'Proyek dan setiap pekerjaan yang terkait akan dihapus.',
    'project.list.completion': 'Penyelesaian:',
    'project.list.createdDate': 'Tanggal dibuat:',
    'project.list.beginningDate': 'Mulai Tanggal:',
    'project.list.targetCompletionDate': 'Target Penyelesaian Tanggal:',
    'project.list.description': 'Deskripsi:',
    'project.edit': 'mengedit Proyek',
    'project.list.translateBible': 'menerjemahkan Alkitab',
    'project.list.empty': 'daftar proyek terjemahan Anda kosong.',
    'project.list.myProjects': 'Saya Proyek Terjemahan Alkitab',
    'project.list.newProject': 'Proyek baru',
    'project.create.createNew': 'Buat Proyek baru',
    'project.namePlaceholder': 'Masukkan nama proyek',
    'project.descriptionPlaceholder': 'Masukkan deskripsi proyek',
    'project.name': 'Nama Proyek',
    'project.beginningDate': 'mulai Tanggal',
    'project.targetCompletionDate': 'Target Penyelesaian Tanggal',
    'project.description': 'Deskripsi',
    'project.manage-users': 'mengelola Penerjemah',
    'project.new-user': 'penerjemah baru',
    'project.add-user': 'add penerjemah',
    'project.users': 'penerjemah proyek',
    'project.read-only': 'Baca saja',
    'project.read&write': 'Baca tulis',
    'project.owner': 'Pemilik',
    'project.translator': 'Penerjemah',
    'project.message.no-such-user': 'Tidak ada penerjemah seperti ada',
    'project.message.user-added': 'Penerjemah berhasil ditambahkan',
    'project.message.user-deleted': 'Penerjemah dihapus.',
    'project.message.only-owner-can-manage-users': 'Hanya pemilik proyek dapat mengelola pengguna',
    'project.shared': 'proyek kolaborasi',
    'general.delete-user': 'Hapus penerjemah',
    'general.edit-user': 'mengedit pengguna',
    'general.name': 'Nama',
    'general.email': 'Surel',
    'general.permissions': 'perizinan',
    'general.role': 'Wewenang',
    signin: 'Masuk',
    'signin.emailPlaceholder': 'Alamat email',
    'signin.passwordPlaceholder': 'Kata sandi',
    'signin.forgotPassword': 'Lupa kata sandi Anda?',
    'signin.rememberMe': 'Ingat saya',
    'signin.facebook': 'Masuk melalui Facebook',
    'signin.google': 'Masuk dengan Google',
    'signin.dontHaveAccount': 'Tidak memiliki akun?',
    'signin.createOne': 'Buat satu',
    'signup.join': 'bergabung YouTranslate.Bible',
    'signup.createAccountPrompt': 'Buat akun gratis Anda untuk memulai.',
    'signup.createAccount': 'Buat Akun',
    'signup.emailPlaceholder': 'Alamat email',
    'signup.passwordPlaceholder': 'Kata sandi',
    'translation.project': 'PROYEK:',
    'translation.backToProjectList': 'Kembali ke daftar proyek',
    'translation.book': 'Buku:',
    'translation.chapter': 'Bab:',
    'translation.prompt':
      'Apakah Anda yakin ingin meninggalkan bab ini? Semua pekerjaan yang belum disimpan akan hilang.',
    'translation.prev': 'prev',
    'translation.next': 'Lanjut',
    'translation.previousChapter': 'bab sebelumnya',
    'translation.nextChapter': 'Bab selanjutnya',
    'navigator.book': 'Buku:',
    'navigator.chapter': 'Bab:',
    'navigator.oldTestament': 'Perjanjian Lama',
    'navigator.newTestament': 'Perjanjian Baru',
    'navigator.selectBook': 'Pilih buku',
    'navigator.search': 'Cari',
    'navigator.changeBook': 'perubahan buku',
    'navigator.changeChapter': 'perubahan bab',
    Genesis: 'Asal',
    Exodus: 'Keluaran',
    Leviticus: 'Imamat',
    Numbers: 'nomor',
    Deuteronomy: 'Ulangan',
    Joshua: 'joshua',
    Judges: 'hakim',
    Ruth: 'duka',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Tawarikh',
    '2 Chronicles': '2 Tawarikh',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemia',
    Esther: 'Esther',
    Job: 'Pekerjaan',
    Psalms: 'Mazmur',
    Proverbs: 'Peribahasa',
    Ecclesiastes: 'Pengkhotbah',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Yesaya',
    Jeremiah: 'Yeremia',
    Lamentations: 'Ratapan',
    Ezekiel: 'Yehezkiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'joel',
    Amos: 'Amos',
    Obadiah: 'Obaja',
    Jonah: 'Yunus',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakuk',
    Zephaniah: 'Zefanya',
    Haggai: 'Hagai',
    Zechariah: 'Zakharia',
    Malachi: 'Maleakhi',
    Matthew: 'Matthew',
    Mark: 'Menandai',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Tindakan',
    Romans: 'Roma',
    '1 Corinthians': '1 Korintus',
    '2 Corinthians': '2 Korintus',
    Galatians: 'Galatia',
    Ephesians: 'Efesus',
    Philippians: 'Filipi',
    Colossians: 'Kolose',
    '1 Thessalonians': '1 Tesalonika',
    '2 Thessalonians': '2 Tesalonika',
    '1 Timothy': '1 Timotius',
    '2 Timothy': '2 Timotius',
    Titus: 'Titus',
    Philemon: 'Filemon',
    Hebrews: 'Ibrani',
    James: 'James',
    '1 Peter': '1 Petrus',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Wahyu',
    'resources.prompt':
      'Menambahkan sumber Alkitab untuk wawasan tambahan bagian Anda menerjemahkan.',
    'resources.add': 'Tambahkan sumber daya Alkitab',
    Manuscript: 'Naskah',
    Morphology: 'Morfologi',
    Lexicon: 'Kamus',
    'Syntactic Tree': 'sintaksis Pohon',
    'Go to project list': 'Pergi ke daftar proyek',
    'Project not found': 'Proyek tidak ditemukan atau izin tidak memadai.',
    'error.referenceDoesNotExistForResource':
      'penerjemahan Alkitab ini tidak mengandung konten untuk bagian yang dipilih.',
    'references.addAnother': 'Tambah terjemahan lain',
    'references.referencePrompt': 'Menambahkan terjemahan Alkitab lainnya untuk wawasan tambahan.',
    'references.add': 'Tambah terjemahan Alkitab',
    'translation.complete': 'lengkap terjemahan',
    Alignment: 'Penjajaran',
    Share: 'Bagikan',
    'Your Translation': 'Terjemahan Anda',
    'Enter your translation': 'Masukkan terjemahan Anda',
    OK: 'baik',
    'error.verseTranslationEmpty': 'Anda tidak bisa meninggalkan konten terjemahan kosong.',
    'translation.hide.completed': 'Sembunyikan selesai',
    alignment: 'Penjajaran',
    'alignment.verify': 'Memeriksa',
    'alignment.backToEditor': 'Kembali ke Editor',
    'alignment.error':
      'Ada yang salah. Jika tetap terjadi masalah, hubungi tim YouTranslate.Bible.',
    'alignment.add': 'Tambah link',
    'alignment.delete': 'Hapus Tautan',
    'alignment.clear': 'jelas Seleksi',
    'alignment.reverse': 'Tampilan terbalik',
    'alignment.removeOrClear': 'Menghapus link yang dipilih atau seleksi yang jelas.',
    'alignment.addOrRemove': 'Buat link baru atau menghapus sebelumnya.',
    'alignment.selectAndAdd': 'Pilih yang sesuai segmen-link.',
    'error.mustBeSignedToMakeChanges': 'Anda harus masuk ke perubahan make.',
    'error.mustBeSignedToTranslateBible': 'Anda harus masuk untuk menerjemahkan Alkitab.',
    checking: 'memeriksa',
    'verseEditor.verseSuggestions': 'Saran',
    verses: 'dengan Ayat',
    words: 'dengan kata',
    'leftPanel.name': 'Teks asli Alkitab',
    'rightPanel.name': 'Terjemahan Alkitab',
    'bottomPanel.name': 'Sumber daya untuk Alkitab',
    'syntax.adv': 'Kata keterangan',
    'syntax.advp': 'Frase kata keterangan',
    'syntax.cl': 'Ayat',
    'syntax.conj': 'Konjungsi',
    'syntax.cjp': 'Konjungsi',
    'syntax.np': 'Frase nomina',
    'syntax.o': 'Obyek',
    'syntax.io': 'Objek tidak langsung',
    'syntax.p': 'Predikat',
    'syntax.pp': 'Frase preposisional',
    'syntax.s': 'Subyek',
    'syntax.v': 'Kata kerja',
    'syntax.vc': 'kata kerja Clause',
    'syntax.adv.description':
      'Memodifikasi bagian lain pidatonya. Biasanya mengungkapkan cara, tempat, waktu, frekuensi, tingkat, tingkat kepastian, dll',
    'syntax.advp.description':
      'Sebuah multi-kata ekspresi yang memodifikasi bagian lain pidatonya. Biasanya mengungkapkan cara, tempat, waktu, frekuensi, tingkat, tingkat kepastian, dll ...',
    'syntax.cl.description': 'Sebuah bagian dari kalimat yang berisi kata kerja.',
    'syntax.conj.description': 'Menghubungkan kata, frasa, dan klausa.',
    'syntax.cjp.description': 'Menghubungkan kata, frasa, dan klausa.',
    'syntax.np.description': 'Ekspresi multi-kata yang melakukan fungsi kata benda.',
    'syntax.o.description': 'Entitas yang ditindaklanjuti oleh subjek.',
    'syntax.io.description': 'Yang satu ini sulit untuk menjelaskan.',
    'syntax.p.description': 'Memodifikasi subjek. Biasanya dibintangi dengan kata kerja.',
    'syntax.pp.description': 'Yang satu ini sulit untuk menjelaskan.',
    'syntax.s.description': 'Entitas yang mengontrol kerja klausa.',
    'syntax.v.description': 'Menyampaikan suatu tindakan.',
    'syntax.vc.description':
      'Ekspresi multi-kata yang melakukan fungsi kata kerja (yang melekat tindakan).',
    'general.close': 'Menutup',
    'general.action': 'Tindakan',
    'general.select': 'Pilih...',
    'general.back': 'Kembali',
  },
  km: {
    'languages.en': 'ភាសាអង់គ្លេស',
    'languages.am': 'អំហារិក',
    'languages.bn': 'ភាសាបង់ក្លាដែភាសា',
    'languages.ceb': 'សឺប៊ូអាណូភាសា',
    'languages.de': 'ភាសាអាឡឺម៉ង់',
    'languages.es-419': 'ភាសា​អេស្ប៉ាញ',
    'languages.fr': 'ភាសា​បារាំង',
    'languages.gu': 'ហ្កុយ៉ារាទី',
    'languages.hi': 'ហិណ្ឌូ',
    'languages.hr': 'ក្រូអាតភាសា',
    'languages.hu': 'ភាសាហុងគ្រីភាសា',
    'languages.id': 'ភាសាឥណ្ឌូនេស៊ី',
    'languages.km': 'ភាសាខ្មែរ',
    'languages.kn': 'កាណាដា',
    'languages.ko': 'ភាសា​កូរ៉េ',
    'languages.lo': 'ភាសាឡាវ',
    'languages.ml': 'ម៉ាឡាយ៉ាឡា',
    'languages.mr': 'ភាសាមារាធិ',
    'languages.ms': 'ម៉ាឡេស៊ី',
    'languages.my': 'ភាសាភូមា',
    'languages.ne': 'ភាសានេប៉ាល់',
    'languages.plt': 'ម៉ាដាហ្កាភាសា',
    'languages.pt-br': 'ព័រទុយហ្គាល់ (ប្រេស៊ីល) ភាសា',
    'languages.ru': 'ភាសារុស្ស៊ី',
    'languages.sw': 'ភាសាស្វាហ៊ីលី',
    'languages.ta': 'ភាសាតាមីល',
    'languages.th': 'ភាសាថៃ',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu ភាសា',
    'languages.vi': 'ភាសាវៀតណាម',
    'languages.zh-hans': 'ភាសាចិន (អក្សរកាត់)',
    'languages.zh-hant': 'ភាសាចិន (អក្សរពេញ)',
    'header.home': 'ទំព័រដើម',
    'header.help': 'ជំនួយ',
    'header.project': 'គម្រោង',
    'help.contactSupport': 'សូមអ៊ីម៉ែលមកយើងនៅ help@youtranslate.bible សម្រាប់ជំនួយ។',
    'home.ytbProvides': 'YouTranslate.Bible ផ្ដល់នូវការដោយសេរី:',
    'home.easyToUseTool': 'ឧបករណ៍បកប្រែអនឡាញមានភាពងាយស្រួលប្រើ',
    'home.originalTextHelp': 'ជំនួយដើម្បីយល់អត្ថបទនៃព្រះគម្ពីរជាភាសាដើមរបស់ខ្លួន',
    'home.referencesAndResources': 'ធនធានឯកសារយោងនិងការបណ្តុះបណ្តាល',
    'home.computerAssistance': 'រដ្ឋនៃសិល្បៈនិងបច្ចេកវិទ្យាកុំព្យូទ័រដើម្បីជួយដល់អ្នកបកប្រែ',
    'profile.userProfile': 'ទម្រង់អ្នកប្រើ',
    'profile.language': 'ភាសា',
    'profile.update': 'ធ្វើឱ្យទាន់សម័យ',
    'usernav.profile': 'ទម្រង់',
    'usernav.logout': 'ចាកចេញ',
    'usernav.logout.modalTitle': 'ចេញពី YouTranslate.Bible',
    'usernav.logout.modalText':
      'តើអ្នកប្រាកដថាអ្នកចង់ចេញ? ការងារដែលមិនបានរក្សាទុកទាំងអស់នឹងត្រូវបានបាត់បង់។',
    cancel: 'បោះបង់',
    delete: 'លុប',
    create: 'បង្កើត',
    update: 'ធ្វើឱ្យទាន់សម័យ',
    edit: 'កែសម្រួល',
    save: 'រក្សាទុក',
    add: 'បន្ថែម',
    'project.create.title': 'គម្រោងបកប្រែព្រះគម្ពីរ',
    'project.create.list': 'បញ្ជីគម្រោង',
    'project.list.deleteProject': 'លុបគម្រោង?',
    'project.list.areYouSure': 'តើអ្នកប្រាកដជាចង់លុបគម្រោងនេះឬ?',
    'project.list.workWillBeDeleted': 'គម្រោងនេះនិងការងារណាមួយដែលបានភ្ជាប់នឹងត្រូវបានលុប។',
    'project.list.completion': 'បញ្ចប់:',
    'project.list.createdDate': 'កាលបរិច្ឆេទបង្កើត:',
    'project.list.beginningDate': 'ការចាប់ផ្តើមកាលបរិច្ឆេទ:',
    'project.list.targetCompletionDate': 'កាលបរិច្ឆេទបញ្ចប់គោលដៅ:',
    'project.list.description': 'សង្ខេប:',
    'project.edit': 'កែសម្រួលគម្រោង',
    'project.list.translateBible': 'បកប្រែព្រះគម្ពីរ',
    'project.list.empty': 'បញ្ជីគម្រោងការបកប្រែរបស់អ្នកគឺទទេ។',
    'project.list.myProjects': 'គម្រោងបកប្រែព្រះគម្ពីររបស់ខ្ញុំ',
    'project.list.newProject': 'គម្រោង​ថ្មី',
    'project.create.createNew': 'បង្កើតគម្រោងថ្មី',
    'project.namePlaceholder': 'បញ្ចូលឈ្មោះគម្រោង',
    'project.descriptionPlaceholder': 'បញ្ចូលការអធិប្បាយគម្រោង',
    'project.name': 'ឈ្មោះគម្រោង',
    'project.beginningDate': 'កាលបរិច្ឆេទការចាប់ផ្តើម',
    'project.targetCompletionDate': 'កាលបរិច្ឆេទបញ្ចប់គោលដៅ',
    'project.description': 'សង្ខេប',
    'project.manage-users': 'គ្រប់គ្រងអ្នកបកប្រែ',
    'project.new-user': 'អ្នកបកប្រែថ្មី',
    'project.add-user': 'បន្ថែមអ្នកបកប្រែ',
    'project.users': 'អ្នកបកប្រែគម្រោង',
    'project.read-only': 'អាច​បាន​តែ​អាន',
    'project.read&write': 'អាន​សរសេរ',
    'project.owner': 'ម្ចាស់',
    'project.translator': 'អ្នកបកប្រែ',
    'project.message.no-such-user': 'មិនមានការបកប្រែដូចមាន',
    'project.message.user-added': 'អ្នកប្រែបន្ថែមដោយជោគជ័យ',
    'project.message.user-deleted': 'អ្នកប្រែយកចេញ។',
    'project.message.only-owner-can-manage-users': 'ម្ចាស់គម្រោងអាចគ្រប់គ្រងអ្នកប្រើតែមួយគត់',
    'project.shared': 'គម្រោងសហការ',
    'general.delete-user': 'អ្នកបកប្រែលុប',
    'general.edit-user': 'កែសម្រួលអ្នកប្រើ',
    'general.name': 'ឈ្មោះ',
    'general.email': 'អ៊ីម៉ែល',
    'general.permissions': 'សិទ្ធិ',
    'general.role': 'តួនាទី',
    signin: 'ចុះឈ្មោះចូល',
    'signin.emailPlaceholder': 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
    'signin.passwordPlaceholder': 'ការពាក្យសម្ងាត់',
    'signin.forgotPassword': 'ភ្លេចពាក្យសម្ងាត់របស់អ្នក?',
    'signin.rememberMe': 'ចងចាំខ្ញុំ',
    'signin.facebook': 'ចុះឈ្មោះជាមួយហ្វេសប៊ុក',
    'signin.google': 'ចូលដោយប្រើ Google',
    'signin.dontHaveAccount': 'មិនមានគណនីទេឬ?',
    'signin.createOne': 'បង្កើតមួយ',
    'signup.join': 'ចូលរួមជាមួយ YouTranslate.Bible',
    'signup.createAccountPrompt': 'បង្កើតគណនីដែលឥតគិតថ្លៃរបស់អ្នកដើម្បីចាប់ផ្តើម។',
    'signup.createAccount': 'បង្កើតគណនី',
    'signup.emailPlaceholder': 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
    'signup.passwordPlaceholder': 'ការពាក្យសម្ងាត់',
    'translation.project': 'គម្រោង:',
    'translation.backToProjectList': 'ត្រឡប់ទៅក្នុងបញ្ជីគម្រោង',
    'translation.book': 'សៀវភៅ:',
    'translation.chapter': 'ជំពូក:',
    'translation.prompt':
      'តើអ្នកប្រាកដថាអ្នកចង់ចាកចេញពីជំពូកនេះ? ការងារដែលមិនបានរក្សាទុកទាំងអស់នឹងត្រូវបានបាត់បង់។',
    'translation.prev': 'មុន',
    'translation.next': 'បន្ទាប់',
    'translation.previousChapter': 'ជំពូកមុន',
    'translation.nextChapter': 'ជំពូកបន្ទាប់',
    'navigator.book': 'សៀវភៅ:',
    'navigator.chapter': 'ជំពូក:',
    'navigator.oldTestament': 'គម្ពីរសញ្ញាចាស់',
    'navigator.newTestament': 'គម្ពីរសញ្ញាថ្មី',
    'navigator.selectBook': 'ជ្រើសសៀវភៅមួយ',
    'navigator.search': 'ស្វែងរក',
    'navigator.changeBook': 'សៀវភៅការផ្លាស់ប្តូរ',
    'navigator.changeChapter': 'ការផ្លាស់ប្តូរជំពូក',
    Genesis: 'លោកុប្បត្តិ',
    Exodus: 'និក្ខមនំ',
    Leviticus: 'លេវីវិន័យ',
    Numbers: 'លេខ',
    Deuteronomy: 'ទុតិយកថា',
    Joshua: 'លោកយ៉ូស្វេ',
    Judges: 'ចៅក្រម',
    Ruth: 'នាងរស់',
    '1 Samuel': 'សាំយូអែលទី 1',
    '2 Samuel': 'សាំយូអែលទី 2',
    '1 Kings': 'ពង្សាវតារក្សត្រទី 1',
    '2 Kings': 'ពង្សាវតារក្សត្រទី 2',
    '1 Chronicles': 'របាក្សត្រទី 1',
    '2 Chronicles': 'របាក្សត្រទី 2',
    Ezra: 'អែសរ៉ា',
    Nehemiah: 'នេហេមា',
    Esther: 'អេសធើរ',
    Job: 'ការងារ',
    Psalms: 'ទំនុកតម្កើង',
    Proverbs: 'សុភាសិត',
    Ecclesiastes: 'សាស្ដា',
    'Song of Songs': 'បទចម្រៀង',
    Isaiah: 'អេសាយ',
    Jeremiah: 'លោកយេរេមា',
    Lamentations: 'បរិទេវ',
    Ezekiel: 'អេសេគាល',
    Daniel: 'ដានីយ៉ែល',
    Hosea: 'ហូសេ',
    Joel: 'លោក Joel',
    Amos: 'អេម៉ុស',
    Obadiah: 'អូបាឌា',
    Jonah: 'យ៉ូណាស',
    Micah: 'មីកា',
    Nahum: 'ណាហ៊ុម',
    Habakkuk: 'ហាបាគុក',
    Zephaniah: 'សេផានា',
    Haggai: 'ហាកាយ',
    Zechariah: 'សាការី',
    Malachi: 'ម៉ាឡាគី',
    Matthew: 'ម៉ាថាយ',
    Mark: 'លោក Mark',
    Luke: 'លូកា',
    John: 'យ៉ូហាន',
    Acts: 'កិច្ចការ',
    Romans: 'រ៉ូម',
    '1 Corinthians': 'កូរិនថូសទី 1',
    '2 Corinthians': 'កូរិនថូសទី 2',
    Galatians: 'កាឡាទី',
    Ephesians: 'អេភេសូរ',
    Philippians: 'ភីលីព',
    Colossians: 'កូឡុស',
    '1 Thessalonians': 'ថែស្សាឡូនីចទី 1',
    '2 Thessalonians': 'ថែស្សាឡូនីចទី 2',
    '1 Timothy': 'ធីម៉ូថេទី 1',
    '2 Timothy': 'ធីម៉ូថេទី 2',
    Titus: 'ទីតុស',
    Philemon: 'ភីលេម៉ូន',
    Hebrews: 'ហេប្រឺ',
    James: 'លោកយ៉ាកុប',
    '1 Peter': 'ពេត្រុសទី 1',
    '2 Peter': 'ពេត្រុសទី 2',
    '1 John': 'យ៉ូហានទី 1',
    '2 John': 'យ៉ូហានទី 2',
    '3 John': 'យ៉ូហានទី 3',
    Jude: 'យូដាស',
    Revelation: 'វិវរណៈ',
    'resources.prompt':
      'បន្ថែមធនធានព្រះគម្ពីរបន្ថែមការយល់ធ្លុះចូលទៅក្នុងការអនុម័តដែលអ្នកកំពុងបកប្រែ។',
    'resources.add': 'បន្ថែមធនធានព្រះគម្ពីរ',
    Manuscript: 'សាត្រាស្លឹករឹត',
    Morphology: 'morphology',
    Lexicon: 'សទ្ទានុក្រម',
    'Syntactic Tree': 'ជាវាក្យសម្ព័ន្ធមែកធាង',
    'Go to project list': 'ទៅកាន់បញ្ជីគម្រោង',
    'Project not found': 'រកមិនឃើញឬគម្រោងសិទ្ធិមិនគ្រប់គ្រាន់។',
    'error.referenceDoesNotExistForResource':
      'ការបកប្រែព្រះគម្ពីរនេះមិនមានមាតិកាសម្រាប់ការអនុម័តដែលបានជ្រើស។',
    'references.addAnother': 'បន្ថែមការបកប្រែផ្សេងទៀត',
    'references.referencePrompt':
      'បន្ថែមការបកប្រែព្រះគម្ពីរផ្សេងទៀតសម្រាប់ការយល់ដឹងទូលំទូលាយបន្ថែមទៀត។',
    'references.add': 'បន្ថែមបកប្រែព្រះគម្ពីរ',
    'translation.complete': 'បានបញ្ចប់ការបកប្រែ',
    Alignment: 'តម្រឹម',
    Share: 'ចែករំលែក',
    'Your Translation': 'ការបកប្រែរបស់អ្នក',
    'Enter your translation': 'បញ្ចូលការបកប្រែរបស់អ្នក',
    OK: 'យល់ព្រម',
    'error.verseTranslationEmpty': 'អ្នកមិនអាចចាកចេញពីមាតិកាបកប្រែលុប។',
    'translation.hide.completed': 'លាក់បានបញ្ចប់',
    alignment: 'តម្រឹម',
    'alignment.verify': 'ផ្ទៀង​ផ្ទាត់',
    'alignment.backToEditor': 'ត្រលប់ទៅកម្មវិធីនិពន្ធ',
    'alignment.error':
      'មាន​អ្វីមួយ​មិន​ប្រក្រតី។ ប្រសិនបើមានការតស៊ូបញ្ហានេះ, សូមទាក់ទងក្រុម YouTranslate.Bible នេះ។',
    'alignment.add': 'បន្ថែមតំណ',
    'alignment.delete': 'យកតំណ',
    'alignment.clear': 'ជម្រះការជ្រើសរើស',
    'alignment.reverse': 'បង្ហាញបញ្ច្រាស',
    'alignment.removeOrClear': 'យកតំណដែលបានជ្រើសឬជ្រើសច្បាស់លាស់។',
    'alignment.addOrRemove': 'បង្កើតតំណថ្មីឬលុបមួយមុន។',
    'alignment.selectAndAdd': 'ជ្រើសផ្នែកដែលត្រូវគ្នានឹងតំណ។',
    'error.mustBeSignedToMakeChanges': 'អ្នកត្រូវតែចូលទៅកាន់ការផ្លាស់ប្តូរធ្វើឱ្យនៅ។',
    'error.mustBeSignedToTranslateBible': 'អ្នកត្រូវតែចូលក្នុងដើម្បីបកប្រែគម្ពីរ។',
    checking: 'កំពុងពិនិត្យមើល',
    'verseEditor.verseSuggestions': 'ការផ្តល់យោបល់',
    verses: 'ដោយខ',
    words: 'ដោយពាក្យ',
    'leftPanel.name': 'អត្ថបទដើមនៃព្រះគម្ពីរ',
    'rightPanel.name': 'ការបកប្រែព្រះគម្ពីរ',
    'bottomPanel.name': 'ធនធានសម្រាប់ព្រះគម្ពីរ',
    'syntax.adv': 'គុណកិរិយា',
    'syntax.advp': 'ឃ្លាគុណកិរិយា',
    'syntax.cl': 'ប្រការ',
    'syntax.conj': 'ការ​ភ្ជាប់',
    'syntax.cjp': 'ការ​ភ្ជាប់',
    'syntax.np': 'ឃ្លានួន',
    'syntax.o': 'វត្ថុ',
    'syntax.io': 'វត្ថុប្រយោល',
    'syntax.p': 'predicate',
    'syntax.pp': 'ឃ្លា​ធ្នាក់',
    'syntax.s': 'ប្រធានបទ',
    'syntax.v': 'កិរិយា',
    'syntax.vc': 'ឃ្លាកិរិយាស័ព្ទ',
    'syntax.adv.description':
      'កែប្រែផ្នែកមួយទៀតនៃការបញ្ចេញមតិ។ ជាធម្មតាបង្ហាញពីលក្ខណៈ, កន្លែង, ពេល, ប្រេកង់, ដឺក្រេកម្រិតនៃភាពច្បាស់ល',
    'syntax.advp.description':
      'កន្សោមពាក្យដែលច្រើនផ្សេងទៀតនៃការកែប្រែផ្នែកបញ្ចេញមតិ។ ជាធម្មតាបង្ហាញពីលក្ខណៈ, កន្លែង, ពេល, ប្រេកង់, ដឺក្រេកម្រិតនៃភាពច្បាស់, ល ...',
    'syntax.cl.description': 'ជាផ្នែកមួយនៃការកាត់ទោសដែលមានកិរិយាស័ព្ទមួយ។',
    'syntax.conj.description': 'ភ្ជាប់ពាក្យ, ឃ្លា, និងខ។',
    'syntax.cjp.description': 'ភ្ជាប់ពាក្យ, ឃ្លា, និងខ។',
    'syntax.np.description': 'កន្សោមពហុពាក្យដែលដំណើរការមុខងារនៃនាមមួយនេះ។',
    'syntax.o.description': 'អង្គភាពដែលត្រូវបានធ្វើតាមដោយប្រធានបទនេះ។',
    'syntax.io.description': 'មួយនេះគឺជាការលំបាកក្នុងការពន្យល់។',
    'syntax.p.description': 'កែប្រែប្រធានបទនេះ។ ជាធម្មតាតារាជាមួយកិរិយាស័ព្ទ។',
    'syntax.pp.description': 'មួយនេះគឺជាការលំបាកក្នុងការពន្យល់។',
    'syntax.s.description': 'អង្គភាពនេះដែលគ្រប់គ្រងកិរិយាស័ព្ទនៃឃ្លានេះ។',
    'syntax.v.description': 'បង្ហាញសកម្មភាពមួយ។',
    'syntax.vc.description':
      'កន្សោមពាក្យដែលពហុមុខងារនៃកិរិយាស័ព្ទដំណើរការមួយ (conveys សកម្មភាពមួយ) ។',
    'general.close': 'បិទ',
    'general.action': 'សកម្មភាព',
    'general.select': 'ជ្រើស ...',
    'general.back': 'ត្រលប់ក្រោយ',
  },
  kn: {
    'languages.en': 'ಆಂಗ್ಲ',
    'languages.am': 'ಅಂಹರಿಕ್',
    'languages.bn': 'ಬಂಗಾಳಿ ಭಾಷಾ',
    'languages.ceb': 'ಸೆಬುಆನೋ ಭಾಷಾ',
    'languages.de': 'ಜರ್ಮನ್ ಭಾಷೆ',
    'languages.es-419': 'ಸ್ಪ್ಯಾನಿಷ್ ಭಾಷೆ',
    'languages.fr': 'ಫ್ರೆಂಚ್ ಭಾಷೆ',
    'languages.gu': 'ಗುಜರಾತಿ',
    'languages.hi': 'ಹಿಂದಿ',
    'languages.hr': 'ಕ್ರೊಯೇಶಿಯನ್ ಭಾಷಾ',
    'languages.hu': 'ಹಂಗೇರಿಯಾ ಭಾಷೆಯ',
    'languages.id': 'ಇಂಡೋನೇಶಿಯನ್ ಭಾಷಾ',
    'languages.km': 'ಖಮೇರ್',
    'languages.kn': 'ಕನ್ನಡ',
    'languages.ko': 'ಕೋರಿಯನ್ ಭಾಷೆ',
    'languages.lo': 'ಲಾವೋಷಿಯನ್ ಭಾಷಾ',
    'languages.ml': 'ಮಲಯಾಳಂ',
    'languages.mr': 'ಮರಾಠಿ',
    'languages.ms': 'ಮಲೇಷಿಯನ್',
    'languages.my': 'ಬರ್ಮೀಸ್ ಭಾಷಾ',
    'languages.ne': 'ನೇಪಾಳಿ ಭಾಷೆಯಲ್ಲಿ',
    'languages.plt': 'ಮಲಗಾಸಿ ಭಾಷಾ',
    'languages.pt-br': 'ಪೋರ್ಚುಗೀಸ್ (ಬ್ರೆಜಿಲ್) ಭಾಷಾ',
    'languages.ru': 'ರಷ್ಯಾದ ಭಾಷಾ',
    'languages.sw': 'ಸ್ವಹಿಲಿ',
    'languages.ta': 'ತಮಿಳು ಭಾಷಾ',
    'languages.th': 'ಥಾಯ್ ಭಾಷಾ',
    'languages.tl': 'ಟ್ಯಾಗಲೋಗ್',
    'languages.te': 'Telegu ಭಾಷಾ',
    'languages.vi': 'ವಿಯೇಟ್ನಾಮೀಸ್ ಭಾಷಾ',
    'languages.zh-hans': 'ಚೀನೀ ಭಾಷಾ (ಸರಳೀಕೃತ)',
    'languages.zh-hant': 'ಚೀನೀ ಭಾಷಾ (ಸಾಂಪ್ರದಾಯಿಕ)',
    'header.home': 'ಮುಖಪುಟ',
    'header.help': 'ಸಹಾಯ',
    'header.project': 'ಪ್ರಾಜೆಕ್ಟ್',
    'help.contactSupport': 'ಸಹಾಯಕ್ಕಾಗಿ help@youtranslate.bible ನಮಗೆ ಇಮೇಲ್ ಮಾಡಿ.',
    'home.ytbProvides': 'YouTranslate.Bible ಮುಕ್ತವಾಗಿ ಒದಗಿಸುತ್ತದೆ:',
    'home.easyToUseTool': 'ಸುಲಭ ಯಾ ಬಳಸಲು ಆನ್ಲೈನ್ ಅನುವಾದ ಉಪಕರಣವನ್ನು',
    'home.originalTextHelp': 'ಅದರ ಮೂಲ ಭಾಷೆಯಲ್ಲಿಯೇ ಬೈಬಲ್ ಮೂಲಗ್ರಂಥದ ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ಸಹಾಯ',
    'home.referencesAndResources': 'ರೆಫರೆನ್ಸ್ ಮತ್ತು ತರಬೇತಿ ಸಂಪನ್ಮೂಲಗಳನ್ನು',
    'home.computerAssistance': 'ರಾಜ್ಯ ಯಾ ಕಲೆ ಕಂಪ್ಯೂಟರ್ ತಂತ್ರಜ್ಞಾನ ಅನುವಾದಕರು ನೆರವಾಗಲು',
    'profile.userProfile': 'ಬಳಕೆದಾರ ಪ್ರೊಫೈಲ್',
    'profile.language': 'ಭಾಷಾ',
    'profile.update': 'ನವೀಕರಣ',
    'usernav.profile': 'ಪ್ರೊಫೈಲ್',
    'usernav.logout': 'ಲಾಗ್ ಔಟ್',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ಆಫ್ ಲಾಗ್ಔಟ್',
    'usernav.logout.modalText':
      'ನೀವು ನಿರ್ಗಮಿಸಬೇಕಾಗುತ್ತದೆ ಬಯಸಿದ್ದೀರಾ? ಎಲ್ಲಾ ಉಳಿಸದ ಕೆಲಸವನ್ನು ಕಳೆದುಹೋಗುತ್ತವೆ.',
    cancel: 'ರದ್ದು',
    delete: 'ಅಳಿಸು',
    create: 'ರಚಿಸಿ',
    update: 'ನವೀಕರಣ',
    edit: 'ತಿದ್ದು',
    save: 'ಉಳಿಸಿ',
    add: 'ಸೇರಿಸಿ',
    'project.create.title': 'ಬೈಬಲ್ ಅನುವಾದ ಪ್ರಾಜೆಕ್ಟ್',
    'project.create.list': 'ಪ್ರಾಜೆಕ್ಟ್ ಪಟ್ಟಿ',
    'project.list.deleteProject': 'ಪ್ರಾಜೆಕ್ಟ್ ಅಳಿಸು?',
    'project.list.areYouSure': 'ಈ ಪ್ರಾಜೆಕ್ಟ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?',
    'project.list.workWillBeDeleted': 'ಯೋಜನೆ ಮತ್ತು ಸಂಬಂಧಿಸಿದ ಯಾವುದೇ ಕೆಲಸ ಅಳಿಸಲ್ಪಡುತ್ತದೆ.',
    'project.list.completion': 'ಕಾಮಗಾರಿಯು:',
    'project.list.createdDate': 'ರಚಿಸಿದ ದಿನಾಂಕ:',
    'project.list.beginningDate': 'ಆರಂಭದಲ್ಲಿ ದಿನಾಂಕ:',
    'project.list.targetCompletionDate': 'ಟಾರ್ಗೆಟ್ ಕಾಮಗಾರಿಯು ದಿನಾಂಕ:',
    'project.list.description': 'ವಿವರಣೆ:',
    'project.edit': 'ಯೋಜನೆಯನ್ನು ಸಂಪಾದಿಸು',
    'project.list.translateBible': 'ಭಾಷಾಂತರಿಸಿ ಬೈಬಲ್',
    'project.list.empty': 'ನಿಮ್ಮ ಅನುವಾದ ಯೋಜನೆಯ ಪಟ್ಟಿ ಖಾಲಿಯಾಗಿದೆ.',
    'project.list.myProjects': 'ನನ್ನ ಬೈಬಲ್ ಅನುವಾದ ಯೋಜನೆಗಳು',
    'project.list.newProject': 'ಹೊಸ ಯೋಜನೆ',
    'project.create.createNew': 'ಹೊಸ ಪ್ರಾಜೆಕ್ಟ್ ರಚಿಸಿ',
    'project.namePlaceholder': 'ಯೋಜನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ',
    'project.descriptionPlaceholder': 'ಯೋಜನೆಯ ವಿವರಣೆ ನಮೂದಿಸಿ',
    'project.name': 'ಯೋಜನೆಯ ಹೆಸರು',
    'project.beginningDate': 'ಆರಂಭದಲ್ಲಿ ದಿನಾಂಕ',
    'project.targetCompletionDate': 'ಟಾರ್ಗೆಟ್ ಕಾಮಗಾರಿಯು ದಿನಾಂಕ',
    'project.description': 'ವಿವರಣೆ',
    'project.manage-users': 'ಅನುವಾದಕರು ನಿರ್ವಹಿಸಿ',
    'project.new-user': 'ಹೊಸ ಭಾಷಾಂತರಕಾರ',
    'project.add-user': 'ಭಾಷಾಂತರಕಾರ ಸೇರಿಸು',
    'project.users': 'ಪ್ರಾಜೆಕ್ಟ್ ಅನುವಾದಕರು',
    'project.read-only': 'ಓದಲು ಮಾತ್ರ',
    'project.read&write': 'ಓದು ಬರೆ',
    'project.owner': 'ಮಾಲೀಕ',
    'project.translator': 'ಅನುವಾದಕ',
    'project.message.no-such-user': 'ಅಂತಹ ಭಾಷಾಂತರಕಾರ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ',
    'project.message.user-added': 'ಅನುವಾದಕ ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ',
    'project.message.user-deleted': 'ಅನುವಾದಕ ತೆಗೆದುಹಾಕಲಾಗಿದೆ.',
    'project.message.only-owner-can-manage-users':
      'ಮಾತ್ರ ಯೋಜನೆಯ ಮಾಲೀಕರು ಬಳಕೆದಾರರನ್ನು ನಿರ್ವಹಿಸಬಹುದು',
    'project.shared': 'ಸಹಯೋಗದ ಯೋಜನೆಯಾಗಿದೆ',
    'general.delete-user': 'ಅಳಿಸು ಭಾಷಾಂತರಕಾರ',
    'general.edit-user': 'ಬಳಕೆದಾರರನ್ನು ಸಂಪಾದಿಸಿ',
    'general.name': 'ಹೆಸರು',
    'general.email': 'ಮೇಲ್',
    'general.permissions': 'ಅನುಮತಿಗಳು',
    'general.role': 'ಪಾತ್ರ',
    signin: 'ಸೈನ್ ಇನ್',
    'signin.emailPlaceholder': 'ಇಮೇಲ್ ವಿಳಾಸ',
    'signin.passwordPlaceholder': 'ಗುಪ್ತಪದ',
    'signin.forgotPassword': 'ನಿಮ್ಮ ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರುವಿರಾ?',
    'signin.rememberMe': 'ನನ್ನನ್ನು ನೆನಪಿನಲ್ಲಿಡು',
    'signin.facebook': 'ಫೇಸ್ಬುಕ್ ಜೊತೆ ಸೈನ್ ಇನ್',
    'signin.google': 'Google ನೊಂದಿಗೆ ಸೈನ್ ಇನ್',
    'signin.dontHaveAccount': 'ಒಂದು ಖಾತೆಯನ್ನು ಹೊಂದಿಲ್ಲ?',
    'signin.createOne': 'ಒಂದನ್ನು ತಯಾರಿಸು',
    'signup.join': 'YouTranslate.Bible ಸೇರಿ',
    'signup.createAccountPrompt': 'ಆರಂಭಿಸಲು ನಿಮ್ಮ ಉಚಿತ ಖಾತೆಯನ್ನು ರಚಿಸಿ.',
    'signup.createAccount': 'ಖಾತೆ ತೆರೆ',
    'signup.emailPlaceholder': 'ಇಮೇಲ್ ವಿಳಾಸ',
    'signup.passwordPlaceholder': 'ಗುಪ್ತಪದ',
    'translation.project': 'ಯೋಜನೆ:',
    'translation.backToProjectList': 'ಯೋಜನೆಯ ಪಟ್ಟಿಗೆ ಮತ್ತೆ',
    'translation.book': 'ಪುಸ್ತಕ:',
    'translation.chapter': 'ಅಧ್ಯಾಯ:',
    'translation.prompt':
      'ಈ ಅಧ್ಯಾಯದಲ್ಲಿ ಬಿಡಲು ನೀವು ಬಯಸುವಿರಾ? ಎಲ್ಲಾ ಉಳಿಸದ ಕೆಲಸವನ್ನು ಕಳೆದುಹೋಗುತ್ತವೆ.',
    'translation.prev': 'ಹಿಂದಿನದು',
    'translation.next': 'ಮುಂದಿನ',
    'translation.previousChapter': 'ಹಿಂದಿನ ಅಧ್ಯಾಯ',
    'translation.nextChapter': 'ಮುಂದಿನ ಅಧ್ಯಾಯದಲ್ಲಿ',
    'navigator.book': 'ಪುಸ್ತಕ:',
    'navigator.chapter': 'ಅಧ್ಯಾಯ:',
    'navigator.oldTestament': 'ಹಳೆಯ ಸಾಕ್ಷಿ',
    'navigator.newTestament': 'ಹೊಸ ಒಡಂಬಡಿಕೆಯಲ್ಲಿ',
    'navigator.selectBook': 'ಪುಸ್ತಕ ಆಯ್ಕೆ',
    'navigator.search': 'ಹುಡುಕಿ Kannada',
    'navigator.changeBook': 'ಚೇಂಜ್ ಪುಸ್ತಕದ',
    'navigator.changeChapter': 'ಚೇಂಜ್ ಅಧ್ಯಾಯ',
    Genesis: 'ಜೆನೆಸಿಸ್',
    Exodus: 'ಎಕ್ಸೋಡಸ್',
    Leviticus: 'ಲೆವಿಟಿಕಸ್',
    Numbers: 'ಸಂಖ್ಯೆಗಳು',
    Deuteronomy: 'ಧರ್ಮೋಪದೇಶಕಾಂಡ',
    Joshua: 'ಜೋಶುವಾ',
    Judges: 'ನ್ಯಾಯಾಧೀಶರು',
    Ruth: 'ರುತ್',
    '1 Samuel': '1 ಸ್ಯಾಮ್ಯುಯೆಲ್',
    '2 Samuel': '2 ಸ್ಯಾಮ್ಯುಯೆಲ್',
    '1 Kings': '1 ಅರಸುಗಳು',
    '2 Kings': '2 ಅರಸುಗಳು',
    '1 Chronicles': '1 ಕ್ರಾನಿಕಲ್ಸ್',
    '2 Chronicles': '2 ಕ್ರಾನಿಕಲ್ಸ್',
    Ezra: 'ಎಜ್ರಾ',
    Nehemiah: 'ನೆಹೆಮಿಯಾ',
    Esther: 'ಎಸ್ತರ್',
    Job: 'ಜಾಬ್',
    Psalms: 'ಪ್ಸಾಮ್ಸ್',
    Proverbs: 'ನಾಣ್ಣುಡಿ',
    Ecclesiastes: 'ಪ್ರಸಂಗಿ',
    'Song of Songs': 'ಸಾಂಗ್ಸ್ ಆಫ್ ಸಾಂಗ್',
    Isaiah: 'ಯೆಶಾಯ',
    Jeremiah: 'ಜೆರೇಮಿಃ',
    Lamentations: 'ಪ್ರಲಾಪಗಳು',
    Ezekiel: 'ಎಝೆಕಿಯೆಲ್',
    Daniel: 'ಡೇನಿಯಲ್',
    Hosea: 'ಹೊಸಿಯೊ',
    Joel: 'ಜೋಯಲ್',
    Amos: 'ಅಮೋಸ್',
    Obadiah: 'ಓಬದ್ಯನಿಗೆ',
    Jonah: 'ಜೋನ್ನಾ',
    Micah: 'ವಿಾಕನ',
    Nahum: 'ನಾಹುಂ',
    Habakkuk: 'ಹಬಾಕ್ಕುಕ್',
    Zephaniah: 'ಝೆಫಾನಿಯಾ',
    Haggai: 'ಹಗ್ಗಾಯನ',
    Zechariah: 'ಜೆಕರಾಯಾ',
    Malachi: 'ಮಲಾಚಿ',
    Matthew: 'ಮ್ಯಾಥ್ಯೂ',
    Mark: 'ಮಾರ್ಕ್',
    Luke: 'ಲ್ಯೂಕ್',
    John: 'ಜಾನ್',
    Acts: 'ಕಾಯಿದೆಗಳು',
    Romans: 'ರೋಮನ್ನರು',
    '1 Corinthians': '1 ಕೊರಿಂಥದವರಿಗೆ',
    '2 Corinthians': '2 ಕೊರಿಂಥದವರಿಗೆ',
    Galatians: 'ಗಲಾಷಿಯನ್ಸ್',
    Ephesians: 'ಎಫೆಸಿಯನ್ಸ್',
    Philippians: 'ಫಿಲಿಪ್ಪಿಯವರಿಗೆ',
    Colossians: 'ಕೊಲೊಸ್ಸೆಯವರಿಗೆ',
    '1 Thessalonians': '1 ಥೆಸಲೊನೀಕದವರಿಗೆ',
    '2 Thessalonians': '2 ಥೆಸಲೊನೀಕದವರಿಗೆ',
    '1 Timothy': '1 ತಿಮೋತಿ',
    '2 Timothy': '2 ತಿಮೋತಿ',
    Titus: 'ಟೈಟಸ್',
    Philemon: 'ಫಿಲೆಮನ್',
    Hebrews: 'ಹೀಬ್ರೂ',
    James: 'ಜೇಮ್ಸ್',
    '1 Peter': '1 ಪೇತ್ರ',
    '2 Peter': '2 ಪೀಟರ್',
    '1 John': '1 ಜಾನ್',
    '2 John': '2 ಜಾನ್',
    '3 John': '3 ಜಾನ್',
    Jude: 'ಜೂಡ್',
    Revelation: 'ರೆವೆಲೆಶನ್',
    'resources.prompt':
      'ನೀವು ಭಾಷಾಂತರಿಸುವ ಅಂಗೀಕಾರದ ಹೆಚ್ಚುವರಿ ಒಳನೋಟ ಬೈಬಲಿನ ಸಂಪನ್ಮೂಲಗಳನ್ನು ಸೇರಿಸಿ.',
    'resources.add': 'ಒಂದು ಬೈಬಲಿನ ಸಂಪನ್ಮೂಲ ಸೇರಿಸಿ',
    Manuscript: 'ಹಸ್ತಪ್ರತಿ',
    Morphology: 'ಮಾರ್ಫಾಲಜಿ',
    Lexicon: 'ಲೆಕ್ಸಿಕನ್',
    'Syntactic Tree': 'ವಾಕ್ಯರಚನೆ ಟ್ರೀ',
    'Go to project list': 'ಯೋಜನೆಯ ಪಟ್ಟಿಗೆ ಹೋಗು',
    'Project not found': 'ಪ್ರಾಜೆಕ್ಟ್ ಕಂಡುಬಂದಿಲ್ಲ ಅಥವಾ ಸಾಕಷ್ಟು ಅನುಮತಿಗಳನ್ನು.',
    'error.referenceDoesNotExistForResource':
      'ಈ ಬೈಬಲ್ ಅನುವಾದ ಆಯ್ಕೆ ಅಂಗೀಕಾರದ ವಿಷಯ ಹೊಂದಿರುವುದಿಲ್ಲ.',
    'references.addAnother': 'ಮತ್ತೊಂದು ಅನುವಾದ ಸೇರಿಸಿ',
    'references.referencePrompt': 'ಇತರ ಬೈಬಲ್ ಅನುವಾದಗಳನ್ನು ಸೇರಿಸಿ ಹೆಚ್ಚುವರಿ ಒಳನೋಟ.',
    'references.add': 'ಬೈಬಲ್ ಅನುವಾದ ಸೇರಿಸಿ',
    'translation.complete': 'ಅನುವಾದ ಸಂಪೂರ್ಣ',
    Alignment: 'ಸಾಲು',
    Share: 'ಹಂಚಿಕೊಳ್ಳಿ',
    'Your Translation': 'ನಿಮ್ಮ ಅನುವಾದ',
    'Enter your translation': 'ನಿಮ್ಮ ಅನುವಾದ ನಮೂದಿಸಿ',
    OK: 'ಸರಿ',
    'error.verseTranslationEmpty': 'ಈ ಅನುವಾದ ವಿಷಯ ಖಾಲಿ ಬಿಡುವಂತಿಲ್ಲ.',
    'translation.hide.completed': 'ಪೂರ್ಣಗೊಂಡಿತು ಮರೆಮಾಡಿ',
    alignment: 'ಸಾಲು',
    'alignment.verify': 'ಪರಿಶೀಲಿಸಿ',
    'alignment.backToEditor': 'ಸಂಪಾದಕರಿಗೆ ಬ್ಯಾಕ್',
    'alignment.error':
      'ಏನೋ ತಪ್ಪಾಗಿದೆ. ಸಮಸ್ಯೆ ಮುಂದುವರಿದರೆ, ದಯವಿಟ್ಟು YouTranslate.Bible ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ.',
    'alignment.add': 'ಲಿಂಕ್ ಸೇರಿಸಿ',
    'alignment.delete': 'ಲಿಂಕ್ ತೆಗೆದುಹಾಕಿ',
    'alignment.clear': 'ಆಯ್ಕೆಯನ್ನು ತೆರವುಗೊಳಿಸಿ',
    'alignment.reverse': 'ರಿವರ್ಸ್ ಪ್ರದರ್ಶನ',
    'alignment.removeOrClear': 'ಆಯ್ಕೆ ಲಿಂಕ್ ಅಥವಾ ಆಯ್ಕೆಯನ್ನು ತೆರವುಗೊಳಿಸಿ ತೆಗೆದುಹಾಕಿ.',
    'alignment.addOrRemove': 'ಹೊಸ ಲಿಂಕ್ ರಚಿಸಿ ಅಥವಾ ಹಿಂದಿನ ಅಳಿಸಿ.',
    'alignment.selectAndAdd': 'ಲಿಂಕ್ ಅನುಗುಣವಾದ ಭಾಗಗಳು ಆಯ್ಕೆಮಾಡಿ.',
    'error.mustBeSignedToMakeChanges': 'ನೀವು ಬದಲಾವಣೆಗಳನ್ನು ಮಾಡಲು ಸೈನ್ ಇನ್ ಮಾಡಬೇಕು.',
    'error.mustBeSignedToTranslateBible': 'ನೀವು ಬೈಬಲ್ ಭಾಷಾಂತರಿಸಲು ಸೈನ್ ಇನ್ ಮಾಡಬೇಕು.',
    checking: 'ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ',
    'verseEditor.verseSuggestions': 'ಸಲಹೆಗಳು',
    verses: 'ಶ್ಲೋಕ ಮೂಲಕ',
    words: 'ಪದಗಳ ಮೂಲಕ',
    'leftPanel.name': 'ಬೈಬಲ್ ಮೂಲ ಪಠ್ಯ',
    'rightPanel.name': 'ಬೈಬಲ್ ಅನುವಾದಗಳು',
    'bottomPanel.name': 'ಬೈಬಲ್ ಸಂಪನ್ಮೂಲಗಳು',
    'syntax.adv': 'ಕ್ರಿಯಾವಿಶೇಷಣ',
    'syntax.advp': 'ಕ್ರಿಯಾವಿಶೇಷಣ ಫ್ರೇಸ್',
    'syntax.cl': 'ಷರತ್ತು',
    'syntax.conj': 'ಯುತಿ',
    'syntax.cjp': 'ಯುತಿ',
    'syntax.np': 'ನಾಮಪದ ಫ್ರೇಸ್',
    'syntax.o': 'ವಸ್ತು',
    'syntax.io': 'ಪರೋಕ್ಷ ವಸ್ತು',
    'syntax.p': 'ಆಖ್ಯಾತ',
    'syntax.pp': 'ಉಪಸರ್ಗ ಫ್ರೇಸ್',
    'syntax.s': 'ವಿಷಯ',
    'syntax.v': 'ಕ್ರಿಯಾಪದ',
    'syntax.vc': 'ಕ್ರಿಯಾಪದ ಷರತ್ತು',
    'syntax.adv.description':
      'ಮಾರ್ಪಡಿಸುತ್ತದೆ ಭಾಷಣ ಮತ್ತೊಂದು ಭಾಗ. ಸಾಮಾನ್ಯವಾಗಿ ನಡವಳಿಕೆ, ಸ್ಥಳ, ಸಮಯ, ಆವರ್ತನ, ಪದವಿ, ನಿಶ್ಚಿತತೆಯ ಮಟ್ಟದ, ಇತ್ಯಾದಿ ವ್ಯಕ್ತಪಡಿಸಲು',
    'syntax.advp.description':
      'ಒಂದು ಬಹು ಪದ ಅಭಿವ್ಯಕ್ತಿ ಮಾರ್ಪಡಿಸಿಕೊಳ್ಳುವ ಭಾಷಣ ಮತ್ತೊಂದು ಭಾಗ. ಸಾಮಾನ್ಯವಾಗಿ ನಡವಳಿಕೆ, ಸ್ಥಳ, ಸಮಯ, ಆವರ್ತನ, ಪದವಿ, ನಿಶ್ಚಿತತೆಯ ಮಟ್ಟದ, ಇತ್ಯಾದಿ ವ್ಯಕ್ತಪಡಿಸಲು ...',
    'syntax.cl.description': 'ಒಂದು ಭಾಗವು ಕ್ರಿಯಾಪದವಾಗಿ ಒಳಗೊಂಡಿರುವ ಒಂದು ವಾಕ್ಯದ.',
    'syntax.conj.description': 'ಪದಗಳು, ನುಡಿಗಟ್ಟುಗಳು, ಮತ್ತು ವಿಧಿಗಳು ಸಂಪರ್ಕಿಸುತ್ತದೆ.',
    'syntax.cjp.description': 'ಪದಗಳು, ನುಡಿಗಟ್ಟುಗಳು, ಮತ್ತು ವಿಧಿಗಳು ಸಂಪರ್ಕಿಸುತ್ತದೆ.',
    'syntax.np.description': 'ನಾಮಪದವೊಂದರ ಕಾರ್ಯ ನಿರ್ವಹಿಸುತ್ತದೆ ಬಹು ಪದ ಅಭಿವ್ಯಕ್ತಿ.',
    'syntax.o.description': 'ವಿಷಯದ ಮೇಲೆ ಕ್ರಮ ಎಂದು ಘಟಕದ.',
    'syntax.io.description': 'ಈ ಒಂದು ವಿವರಿಸಲು ಕಷ್ಟ.',
    'syntax.p.description': 'ವಿಷಯದ ಮಾರ್ಪಡಿಸುತ್ತದೆ. ಸಾಮಾನ್ಯವಾಗಿ ಕ್ರಿಯಾಪದದೊಂದಿಗೆ ನಟರಾಗಿದ್ದಾರೆ.',
    'syntax.pp.description': 'ಈ ಒಂದು ವಿವರಿಸಲು ಕಷ್ಟ.',
    'syntax.s.description': 'ಕಲಂ ಕ್ರಿಯಾಪದ ನಿಯಂತ್ರಿಸುವ ಘಟಕದ.',
    'syntax.v.description': 'ಕ್ರಿಯಾಶೀಲ ರವಾನಿಸುವ.',
    'syntax.vc.description':
      'ಒಂದು ಬಹು ಪದ ಅಭಿವ್ಯಕ್ತಿ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಾರೆ ಕ್ರಿಯಾಪದದ ಕಾರ್ಯ (ರವಾನಿಸುವ ಕ್ರಿಯಾಶೀಲ).',
    'general.close': 'ಮುಚ್ಚು',
    'general.action': 'ಕ್ರಿಯೆ',
    'general.select': 'ಆಯ್ಕೆ ಮಾಡಿ...',
    'general.back': 'ಬ್ಯಾಕ್',
  },
  ko: {
    'languages.en': '영어',
    'languages.am': '암하라어',
    'languages.bn': '벵골어',
    'languages.ceb': '세부 아노 언어',
    'languages.de': '독일어',
    'languages.es-419': '스페인어',
    'languages.fr': '프랑스어',
    'languages.gu': '구자라트어',
    'languages.hi': '힌디 어',
    'languages.hr': '크로아티아어 언어',
    'languages.hu': '헝가리어',
    'languages.id': '인도네시아어',
    'languages.km': '크메르어',
    'languages.kn': '칸나다어',
    'languages.ko': '한국어',
    'languages.lo': '라오스 언어',
    'languages.ml': '말라얄람어',
    'languages.mr': '마라타어',
    'languages.ms': '말레이시아',
    'languages.my': '버마어 언어',
    'languages.ne': '네팔어',
    'languages.plt': '말라가 시어',
    'languages.pt-br': '포르투갈어 (브라질) 언어',
    'languages.ru': '러시아어',
    'languages.sw': '스와힐리어',
    'languages.ta': '타밀어',
    'languages.th': '태국어',
    'languages.tl': '타갈로그어',
    'languages.te': '텔루구 어 언어',
    'languages.vi': '베트남어',
    'languages.zh-hans': '중국어 (간체)',
    'languages.zh-hant': '중국어 (번체)',
    'header.home': '집',
    'header.help': '도움',
    'header.project': '계획',
    'help.contactSupport': '지원 help@youtranslate.bible에 이메일을 보내 주시기 바랍니다.',
    'home.ytbProvides': 'YouTranslate.Bible 자유롭게 제공',
    'home.easyToUseTool': '사용하기 쉬운 온라인 번역 도구',
    'home.originalTextHelp': '도움말은 원래 언어로 된 성경의 텍스트를 이해하는',
    'home.referencesAndResources': '참조 및 교육 자원',
    'home.computerAssistance': '최첨단 컴퓨터 기술 번역을 지원하기 위해',
    'profile.userProfile': '유저 프로필',
    'profile.language': '언어',
    'profile.update': '최신 정보',
    'usernav.profile': '프로필',
    'usernav.logout': '로그 아웃',
    'usernav.logout.modalTitle': 'YouTranslate.Bible의 로그 아웃',
    'usernav.logout.modalText':
      '당신은 당신이 로그 아웃 하시겠습니까? 저장되지 않은 모든 작업이 손실됩니다.',
    cancel: '취소',
    delete: '지우다',
    create: '창조하다',
    update: '최신 정보',
    edit: '편집하다',
    save: '저장',
    add: '더하다',
    'project.create.title': '성경 번역 프로젝트',
    'project.create.list': '프로젝트 목록',
    'project.list.deleteProject': '프로젝트를 삭제 하시겠습니까?',
    'project.list.areYouSure': '이 프로젝트를 삭제 하시겠습니까?',
    'project.list.workWillBeDeleted': '이 프로젝트와 관련된 모든 작업이 삭제됩니다.',
    'project.list.completion': '완성:',
    'project.list.createdDate': '만든 날짜 :',
    'project.list.beginningDate': '날짜부터 :',
    'project.list.targetCompletionDate': '목표 완료 날짜:',
    'project.list.description': '기술:',
    'project.edit': '프로젝트 편집',
    'project.list.translateBible': '성경을 번역',
    'project.list.empty': '번역 프로젝트 목록이 비어 있습니다.',
    'project.list.myProjects': '내 성경 번역 프로젝트',
    'project.list.newProject': '새로운 프로젝트',
    'project.create.createNew': '새로운 프로젝트 만들기',
    'project.namePlaceholder': '프로젝트 이름을 입력합니다',
    'project.descriptionPlaceholder': '프로젝트에 대한 설명을 입력합니다',
    'project.name': '프로젝트 이름',
    'project.beginningDate': '시작 날짜',
    'project.targetCompletionDate': '목표 완료 날짜',
    'project.description': '기술',
    'project.manage-users': '번역 관리',
    'project.new-user': '새로운 번역기',
    'project.add-user': '추가 번역기',
    'project.users': '프로젝트 번역',
    'project.read-only': '읽기 전용',
    'project.read&write': '읽기 및 쓰기',
    'project.owner': '소유자',
    'project.translator': '역자',
    'project.message.no-such-user': '그런 번역기는 존재하지 않는다',
    'project.message.user-added': '번역기가 성공적으로 추가',
    'project.message.user-deleted': '번역기를 제거합니다.',
    'project.message.only-owner-can-manage-users':
      '사용자를 관리 할 수 ​​있습니다 만 프로젝트 소유자',
    'project.shared': '공동 프로젝트',
    'general.delete-user': '삭제 번역기',
    'general.edit-user': '사용자 편집',
    'general.name': '이름',
    'general.email': '이메일',
    'general.permissions': '권한',
    'general.role': '역할',
    signin: '로그인',
    'signin.emailPlaceholder': '이메일 주소',
    'signin.passwordPlaceholder': '암호',
    'signin.forgotPassword': '비밀번호를 잊어 버렸습니까?',
    'signin.rememberMe': '날 기억해',
    'signin.facebook': '페이스 북으로 로그인',
    'signin.google': '구글에 로그인',
    'signin.dontHaveAccount': '계정이 없으십니까?',
    'signin.createOne': '하나를 만들기',
    'signup.join': 'YouTranslate.Bible 가입',
    'signup.createAccountPrompt': '시작하는 무료 계정을 만듭니다.',
    'signup.createAccount': '계정 만들기',
    'signup.emailPlaceholder': '이메일 주소',
    'signup.passwordPlaceholder': '암호',
    'translation.project': '계획:',
    'translation.backToProjectList': '프로젝트 목록으로 돌아 가기',
    'translation.book': '책:',
    'translation.chapter': '장:',
    'translation.prompt': '이 장을 떠나 하시겠습니까? 저장되지 않은 모든 작업이 손실됩니다.',
    'translation.prev': '이전',
    'translation.next': '다음',
    'translation.previousChapter': '이전 장',
    'translation.nextChapter': '다음 장',
    'navigator.book': '책:',
    'navigator.chapter': '장:',
    'navigator.oldTestament': '구약 성서',
    'navigator.newTestament': '신약 성서',
    'navigator.selectBook': '책을 선택',
    'navigator.search': '검색',
    'navigator.changeBook': '변경의 책',
    'navigator.changeChapter': '변경 장',
    Genesis: '창세기',
    Exodus: '이동',
    Leviticus: '레위기',
    Numbers: '번호',
    Deuteronomy: '신명기',
    Joshua: '여호수아',
    Judges: '심사 위원',
    Ruth: '룻',
    '1 Samuel': '사무엘',
    '2 Samuel': '사무엘',
    '1 Kings': '왕상',
    '2 Kings': '열왕기하',
    '1 Chronicles': '역 대상',
    '2 Chronicles': '역대',
    Ezra: '에즈라',
    Nehemiah: '느헤미야',
    Esther: '에스더',
    Job: '일',
    Psalms: '시편',
    Proverbs: '잠언',
    Ecclesiastes: '전도서',
    'Song of Songs': '노래의 노래',
    Isaiah: '이사야',
    Jeremiah: '예레미야',
    Lamentations: '예레미야 애가',
    Ezekiel: '에스겔',
    Daniel: '다니엘',
    Hosea: '호세아',
    Joel: '조엘',
    Amos: '아모스',
    Obadiah: '오바댜',
    Jonah: '요나',
    Micah: '미가',
    Nahum: '나훔',
    Habakkuk: '하박국',
    Zephaniah: '스바 니야',
    Haggai: '학개',
    Zechariah: '스가랴',
    Malachi: '말라기',
    Matthew: '마태 복음',
    Mark: '표',
    Luke: '누가 복음',
    John: '남자',
    Acts: '사도 행전',
    Romans: '로마서',
    '1 Corinthians': '고린도 전서',
    '2 Corinthians': '고린도 후서',
    Galatians: '갈라디아서',
    Ephesians: '에베소서',
    Philippians: '빌립보서',
    Colossians: '골',
    '1 Thessalonians': '데살로니가 전서',
    '2 Thessalonians': '데살로니가 후서',
    '1 Timothy': '디모데 전서',
    '2 Timothy': '디모데 후서',
    Titus: '디도',
    Philemon: '빌레몬서',
    Hebrews: '히브리서',
    James: '제임스',
    '1 Peter': '베드로 전서',
    '2 Peter': '2 피터',
    '1 John': '요한 1 서',
    '2 John': '2 존',
    '3 John': '3 존',
    Jude: '주드',
    Revelation: '시현',
    'resources.prompt': '당신이 번역하는 통로에 추가 통찰력 성경 자원을 추가합니다.',
    'resources.add': '성경 자원 추가',
    Manuscript: '원고',
    Morphology: '형태',
    Lexicon: '사전',
    'Syntactic Tree': '구문 트리',
    'Go to project list': '프로젝트 목록으로 이동',
    'Project not found': '프로젝트를 찾을 수 없거나 권한이 부족하지.',
    'error.referenceDoesNotExistForResource':
      '이 성경 번역은 선택한 경로에 대한 내용이 포함되어 있지 않습니다.',
    'references.addAnother': '다른 번역을 추가',
    'references.referencePrompt': '추가 통찰력 다른 성경 번역을 추가합니다.',
    'references.add': '성경 번역을 추가',
    'translation.complete': '번역 완료',
    Alignment: '조정',
    Share: '공유',
    'Your Translation': '번역',
    'Enter your translation': '번역을 입력',
    OK: '확인',
    'error.verseTranslationEmpty': '당신은 번역 내용이 비어 떠날 수 없다.',
    'translation.hide.completed': '숨기기 완료',
    alignment: '조정',
    'alignment.verify': '확인',
    'alignment.backToEditor': '편집기로 돌아 가기',
    'alignment.error':
      '문제가 발생했습니다. 문제가 지속되면 YouTranslate.Bible 팀에 문의하시기 바랍니다.',
    'alignment.add': '링크 추가',
    'alignment.delete': '링크 제거',
    'alignment.clear': '명확한 선택',
    'alignment.reverse': '역 표시',
    'alignment.removeOrClear': '선택한 링크 나 명확한 선택을 제거합니다.',
    'alignment.addOrRemove': '새 링크를 만들거나 이전을 삭제합니다.',
    'alignment.selectAndAdd': '링크 세그먼트를 해당 선택합니다.',
    'error.mustBeSignedToMakeChanges': '당신은 메이크업의 변화에 ​​로그인해야합니다.',
    'error.mustBeSignedToTranslateBible': '당신은 성경을 번역하려면 로그인해야합니다.',
    checking: '확인 중',
    'verseEditor.verseSuggestions': '제안',
    verses: '운문으로',
    words: '단어',
    'leftPanel.name': '성경의 원문',
    'rightPanel.name': '성경의 번역',
    'bottomPanel.name': '성경에 대한 자료',
    'syntax.adv': '부사',
    'syntax.advp': '부사 구문',
    'syntax.cl': '절',
    'syntax.conj': '접속사',
    'syntax.cjp': '접속사',
    'syntax.np': '명사구',
    'syntax.o': '목적',
    'syntax.io': '간접 목적어',
    'syntax.p': '술부',
    'syntax.pp': '전치사구',
    'syntax.s': '제목',
    'syntax.v': '동사',
    'syntax.vc': '동사의 절',
    'syntax.adv.description':
      '수정 연설의 다른 부분. 일반적 방법 등, 장소, 시간, 주파수, 정도의 확실성 레벨을 표현할',
    'syntax.advp.description':
      '다중 단어 표현 표현의 수정이 다른 부분. 일반적으로 등의 방식, 장소, 시간, 빈도, 정도, 확실성의 수준을 표현 ...',
    'syntax.cl.description': '동사가 포함 된 문장의 일부입니다.',
    'syntax.conj.description': '단어, 구 및 절을 연결합니다.',
    'syntax.cjp.description': '단어, 구 및 절을 연결합니다.',
    'syntax.np.description': '명사의 기능을 수행하는 다중 워드 표현.',
    'syntax.o.description': '주제에 의해 작용하는 엔티티.',
    'syntax.io.description': '이 사람은 설명하기 어렵다.',
    'syntax.p.description': '제목을 수정합니다. 일반적으로 동사와 별.',
    'syntax.pp.description': '이 사람은 설명하기 어렵다.',
    'syntax.s.description': '절의 동사를 제어하는 ​​엔티티.',
    'syntax.v.description': '작업을 전달한다.',
    'syntax.vc.description': '다중 워드 표현하는 동사 수행하는 기능 (동작 전달한다).',
    'general.close': '닫기',
    'general.action': '동작',
    'general.select': '고르다...',
    'general.back': '뒤',
  },
  lo: {
    'languages.en': 'ພາສາອັງກິດ',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali ພາສາ',
    'languages.ceb': 'Cebuano ພາສາ',
    'languages.de': 'ພາສາເຢຍລະມັນ',
    'languages.es-419': 'ພາສາແອສປາໂຍນ',
    'languages.fr': 'ພາສາຝຣັ່ງ',
    'languages.gu': 'Gujarati',
    'languages.hi': 'ຮິນດູ',
    'languages.hr': 'Croatian ພາສາ',
    'languages.hu': 'ຮັງກາຣີພາສາ',
    'languages.id': 'ພາສາອິນໂດເນເຊຍ',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'ພາສາເກົາຫຼີ',
    'languages.lo': 'ພາສາລາວ',
    'languages.ml': 'ມາລາຢາລໍາ',
    'languages.mr': 'Marathi',
    'languages.ms': 'ມາເລເຊຍ',
    'languages.my': 'ພະມ່າພາສາ',
    'languages.ne': 'ເນປານພາສາ',
    'languages.plt': 'Malagasy ພາສາ',
    'languages.pt-br': 'ປອກຕຸຍການ (Brazil) ພາສາ',
    'languages.ru': 'ພາສາລັດເຊຍ',
    'languages.sw': 'Swahili',
    'languages.ta': 'ທະມິນພາສາ',
    'languages.th': 'ພາສາໄທ',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu ພາສາ',
    'languages.vi': 'ພາສາຫວຽດນາມ',
    'languages.zh-hans': 'ພາສາຈີນ (ແບບງ່າຍ)',
    'languages.zh-hant': 'ພາສາຈີນ (ດັ້ງເດີມ)',
    'header.home': 'ຫນ້າທໍາອິດ',
    'header.help': 'ຊ່ວຍເຫຼືອ',
    'header.project': 'ໂຄງການ',
    'help.contactSupport':
      'ກະລຸນາສົ່ງອີເມວຫາພວກເຮົາທີ່ help@youtranslate.bible ສໍາລັບການຊ່ວຍເຫຼືອ.',
    'home.ytbProvides': 'YouTranslate.Bible freely ໃຫ້:',
    'home.easyToUseTool': 'ການງ່າຍທີ່ຈະນໍາໃຊ້ເຄື່ອງມືການແປພາສາອອນໄລນ໌',
    'home.originalTextHelp': 'ການຊ່ວຍເຫຼືອທີ່ຈະເຂົ້າໃຈຂໍ້ຄວາມຂອງພະຄໍາພີໄດ້ໃນພາສາຕົ້ນສະບັບຂອງຕົນ',
    'home.referencesAndResources': 'ຊັບພະຍາກອນເອກະສານແລະການຝຶກອົບຮົມ',
    'home.computerAssistance': 'ລັດຂອງການສິນລະປະເຕັກໂນໂລຊີຄອມພິວເຕີເພື່ອຊ່ວຍແປພາສາ',
    'profile.userProfile': 'Profile User',
    'profile.language': 'ພາສາ',
    'profile.update': 'ການປັບປຸງ',
    'usernav.profile': 'Profile',
    'usernav.logout': 'ອອກ​ຈາກ​ລະ​ບົບ',
    'usernav.logout.modalTitle': 'ອອກຈາກລະບົບຂອງ YouTranslate.Bible',
    'usernav.logout.modalText':
      'ທ່ານແນ່ໃຈບໍວ່າທ່ານຕ້ອງການທີ່ຈະອອກຈາກລະບົບ? ການເຮັດວຽກທີ່ບໍ່ເຊື່ອທັງຫມົດຈະໄດ້ຮັບການສູນເສຍ.',
    cancel: 'ຍົກເລີກການ',
    delete: 'Delete',
    create: 'ສ້າງ',
    update: 'ການປັບປຸງ',
    edit: 'ດັດແກ້',
    save: 'ບັນທຶກ',
    add: 'ຕື່ມ',
    'project.create.title': 'ໂຄງການການແປພາສາພະຄໍາພີ',
    'project.create.list': 'ບັນຊີລາຍຊື່ໂຄງການ',
    'project.list.deleteProject': 'ລຶບ Project?',
    'project.list.areYouSure': 'ທ່ານທີ່ທ່ານກໍາລັງໃຫ້ແນ່ໃຈວ່າຕ້ອງການທີ່ຈະລົບໂຄງການນີ້?',
    'project.list.workWillBeDeleted': 'ໂຄງການແລະການເຮັດວຽກທີ່ກ່ຽວຂ້ອງຈະໄດ້ຮັບການລຶບ.',
    'project.list.completion': 'ສໍາເລັດ:',
    'project.list.createdDate': 'ວັນທີ່ສ້າງ:',
    'project.list.beginningDate': 'ເລີ່ມຕົ້ນວັນທີ່:',
    'project.list.targetCompletionDate': 'ເປົ້າຫມາຍສໍາເລັດທີ່ສະຫມັກ:',
    'project.list.description': 'ອະທິບາຍ:',
    'project.edit': 'ແກ້ໄຂໂຄງການ',
    'project.list.translateBible': 'Translate ຄໍາພີໄບເບິນ',
    'project.list.empty': 'ບັນຊີລາຍຊື່ໂຄງການການແປພາສາຂອງທ່ານແມ່ນເປົ່າ.',
    'project.list.myProjects': 'Projects ຂອງຂ້າພະເຈົ້າຄໍາພີໄບເບິນການແປພາສາ',
    'project.list.newProject': 'ໂຄງການໃຫມ່',
    'project.create.createNew': 'ສ້າງໂຄງການໃຫມ່',
    'project.namePlaceholder': 'ກະລຸນາໃສ່ຊື່ໂຄງການ',
    'project.descriptionPlaceholder': 'ກະລຸນາໃສ່ຄໍາອະທິບາຍໂຄງການ',
    'project.name': 'ຊື່ໂຄງການ',
    'project.beginningDate': 'ເລີ່ມຕົ້ນວັນທີ່',
    'project.targetCompletionDate': 'ເປົ້າຫມາຍສໍາເລັດວັນທີ່',
    'project.description': 'ຄໍາອະທິບາຍ',
    'project.manage-users': 'ການຄຸ້ມຄອງການແປພາສາ',
    'project.new-user': 'ການແປພາສາໃຫມ່',
    'project.add-user': 'Add ແປພາສາ',
    'project.users': 'ແປໂຄງການ',
    'project.read-only': 'ອ່ານ​ຢ່າງ​ດຽວ',
    'project.read&write': 'ອ່ານແລະຂຽນ',
    'project.owner': 'ເຈົ້າຂອງ',
    'project.translator': 'ການແປພາສາ',
    'project.message.no-such-user': 'ແປດັ່ງກ່າວຢູ່',
    'project.message.user-added': 'ການແປພາສາເພີ່ມສົບຜົນສໍາເລັດ',
    'project.message.user-deleted': 'ການແປພາສາອອກ.',
    'project.message.only-owner-can-manage-users': 'ເຈົ້າຂອງໂຄງການພຽງແຕ່ສາມາດມີການຄຸ້ມຄອງຜູ້ຊົມໃຊ້',
    'project.shared': 'ໂຄງການຮ່ວມມື',
    'general.delete-user': 'ແປພາສາ Delete',
    'general.edit-user': 'ຜູ້ແກ້ໄຂ',
    'general.name': 'ຊື່',
    'general.email': 'ອີເມລ໌',
    'general.permissions': 'ການອະນຸຍາດ',
    'general.role': 'ພາລະບົດບາດ',
    signin: 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
    'signin.emailPlaceholder': 'ທີ່​ຢູ່​ອີ​ເມວ',
    'signin.passwordPlaceholder': 'ລະຫັດຜ່ານ',
    'signin.forgotPassword': 'ລືມລະຫັດຜ່ານຂອງທ່ານ?',
    'signin.rememberMe': 'ຈື່​ຂ້ອຍ',
    'signin.facebook': 'ເຂົ້າສູ່ລະບົບກັບເຟສບຸກ',
    'signin.google': 'ເຂົ້າສູ່ລະບົບກັບກູໂກ',
    'signin.dontHaveAccount': 'ຍັງບໍ່ມີບັນຊີຢູ່ແລ້ວ?',
    'signin.createOne': 'ລົງທະບຽນໃຫມ່',
    'signup.join': 'ເຂົ້າຮ່ວມ YouTranslate.Bible',
    'signup.createAccountPrompt': 'ສ້າງບັນຊີຂອງທ່ານເພື່ອເລີ່ມຕົ້ນການ.',
    'signup.createAccount': 'ສ້າງ​ບັນ​ຊີ',
    'signup.emailPlaceholder': 'ທີ່​ຢູ່​ອີ​ເມວ',
    'signup.passwordPlaceholder': 'ລະຫັດຜ່ານ',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'ກັບຄືນໄປບ່ອນບັນຊີລາຍຊື່ໂຄງການ',
    'translation.book': 'ປື້ມບັນ:',
    'translation.chapter': 'ບົດທີ:',
    'translation.prompt':
      'ທ່ານທີ່ທ່ານກໍາລັງໃຫ້ແນ່ໃຈວ່າຕ້ອງການທີ່ຈະອອກຈາກບົດນີ້? ການເຮັດວຽກທີ່ບໍ່ເຊື່ອທັງຫມົດຈະໄດ້ຮັບການສູນເສຍ.',
    'translation.prev': 'prev',
    'translation.next': 'ຖັດໄປ',
    'translation.previousChapter': 'ບົດທີ່ຜ່ານມາ',
    'translation.nextChapter': 'ບົດຕໍ່ໄປ',
    'navigator.book': 'ປື້ມບັນ:',
    'navigator.chapter': 'ບົດທີ:',
    'navigator.oldTestament': 'ພຣະສັນຍາເດີມ',
    'navigator.newTestament': 'ພຣະສັນຍາໃຫມ່',
    'navigator.selectBook': 'ເລືອກຫນັງສື',
    'navigator.search': 'ຄົ້ນຫາ',
    'navigator.changeBook': 'book ການປ່ຽນແປງ',
    'navigator.changeChapter': 'ປ່ຽນແປງບົດ',
    Genesis: 'ປະຖົມການ',
    Exodus: 'ອົບພະຍົບ',
    Leviticus: 'ລະບຽບພວກເລວີ',
    Numbers: 'ຈໍານວນ',
    Deuteronomy: 'ພຣະບັນຍັດສອງ',
    Joshua: 'Joshua',
    Judges: 'ຜູ້ພິພາກສາ',
    Ruth: 'Ruth',
    '1 Samuel': '1 ຊາມູເອນ',
    '2 Samuel': '2 ຊາມູເອນ',
    '1 Kings': '1 ກະສັດ',
    '2 Kings': '2 ກະສັດ',
    '1 Chronicles': '1 ຕສຸ້ນຕ່ອຍໂຕ້ວ',
    '2 Chronicles': '2 ພົງສາວະດານ',
    Ezra: 'Ezra',
    Nehemiah: 'ເນເຫມີຢາໄດ້',
    Esther: 'Esther',
    Job: 'ວຽກເຮັດງານທໍາ',
    Psalms: 'ເພງສະດຸດີ',
    Proverbs: 'ສຸພາສິດ',
    Ecclesiastes: 'ປັນຍາຈານ',
    'Song of Songs': 'ສຸພາສິດ',
    Isaiah: 'ເອຊາຢາ',
    Jeremiah: 'Jeremiah',
    Lamentations: 'ຄ້ໍາຄວນ',
    Ezekiel: 'ເອເຊກຽນ',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'ໂອບາດີຢາ',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'ຮາບາກຸກ',
    Zephaniah: 'Zephaniah',
    Haggai: 'ອາໂມສ',
    Zechariah: 'ຊາຂາຣີຢາ',
    Malachi: 'ມາລາກີ',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'ລູກາ',
    John: 'John',
    Acts: 'ການກະທໍາ',
    Romans: 'Romans',
    '1 Corinthians': '1 ໂກຣິນໂທ',
    '2 Corinthians': '2 ໂກຣິນໂທ',
    Galatians: 'ຄາລາເຕຍ',
    Ephesians: 'ເອເຟໂຊ',
    Philippians: 'Philippians',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 ເທຊະໂລນິກ',
    '2 Thessalonians': '2 ເທຊະໂລນິກ',
    '1 Timothy': '1 ຕີໂມທຽວ',
    '2 Timothy': '2 ຕີໂມທຽວ',
    Titus: 'Titus',
    Philemon: 'ຟີເລໂມນ',
    Hebrews: 'ເຮັບເຣີ',
    James: 'James',
    '1 Peter': '1 ເປໂຕ',
    '2 Peter': '2 ເປໂຕ',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'ການເປີດເຜີຍ',
    'resources.prompt':
      'ຕື່ມການຊັບພະຍາກອນໃນຄໍາພີໄບສໍາລັບຄວາມເຂົ້າໃຈເພີ່ມເຕີມເຂົ້າໄປໃນ passage ທີ່ທ່ານກໍາລັງແປ.',
    'resources.add': 'ເພີ່ມຊັບພະຍາກອນໃນຄໍາພີໄບ',
    Manuscript: 'ຫນັງສືໃບລານ',
    Morphology: 'ສັນຖານວິທະ',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'syntactic ເປັນໄມ້ຢືນຕົ້ນ',
    'Go to project list': 'ໄປທີ່ບັນຊີລາຍຊື່ໂຄງການ',
    'Project not found': 'ໂຄງການບໍ່ພົບຫຼືການອະນຸຍາດບໍ່ພຽງພໍ.',
    'error.referenceDoesNotExistForResource':
      'ການແປພາສາຄໍາພີໄບເບິນບໍ່ໄດ້ປະກອບດ້ວຍເນື້ອໃນສໍາລັບ passage ທີ່ເລືອກ.',
    'references.addAnother': 'ຕື່ມການແປພາສາອື່ນ',
    'references.referencePrompt': 'ຕື່ມການແປພາສາຄໍາພີໄບເບິນສໍາລັບຄວາມເຂົ້າໃຈເພີ່ມເຕີມ.',
    'references.add': 'ຕື່ມການແປພາສາພະຄໍາພີ',
    'translation.complete': 'ສໍາເລັດການແປພາສາ',
    Alignment: 'ການຈັດຕໍາແຫນ່ງ',
    Share: 'Share',
    'Your Translation': 'ການແປພາສາຂອງທ່ານ',
    'Enter your translation': 'ກະລຸນາໃສ່ການແປພາສາຂອງທ່ານ',
    OK: 'ຕົກ​ລົງ',
    'error.verseTranslationEmpty': 'ທ່ານບໍ່ສາມາດປ່ອຍໃຫ້ເນື້ອໃນການແປພາສາລົບ.',
    'translation.hide.completed': 'ເຊື່ອງສໍາເລັດ',
    alignment: 'ການຈັດຕໍາແຫນ່ງ',
    'alignment.verify': 'ຢືນຢັນ',
    'alignment.backToEditor': 'ກັບຄືນໄປບ່ອນບັນນາທິການ',
    'alignment.error':
      'ບາງສິ່ງບາງຢ່າງເອງເຮັດຜິດພາດ. ຖ້າຫາກວ່າຍັງຄົງຄ້າງບັນຫາ, ກະລຸນາຕິດຕໍ່ທີມງານ YouTranslate.Bible.',
    'alignment.add': 'ເພີ່ມການເຊື່ອມຕໍ່',
    'alignment.delete': 'ລົບລິ້ງ',
    'alignment.clear': 'ເລືອກທີ່ຈະແຈ້ງ',
    'alignment.reverse': 'ສະແດງ Reverse',
    'alignment.removeOrClear': 'ເອົາລິ້ງທີ່ເລືອກຫຼືການຄັດເລືອກທີ່ຈະແຈ້ງ.',
    'alignment.addOrRemove': 'ສ້າງການເຊື່ອມຕໍ່ໃຫມ່ຫລືລົບຫນຶ່ງທີ່ຜ່ານມາ.',
    'alignment.selectAndAdd': 'ເລືອກສ່ວນທີ່ສອດຄ້ອງກັນກັບການເຊື່ອມຕໍ່.',
    'error.mustBeSignedToMakeChanges': 'ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບເພື່ອການປ່ຽນແປງເຮັດ.',
    'error.mustBeSignedToTranslateBible': 'ທ່ານຕ້ອງເຂົ້າສູ່ລະບົບການແປພາສາພະຄໍາພີໄດ້.',
    checking: 'ກໍາລັງກວດສອບ',
    'verseEditor.verseSuggestions': 'ຄໍາແນະນໍາ',
    verses: 'by Verse',
    words: 'by Word',
    'leftPanel.name': 'ຂໍ້ຄວາມຕົ້ນສະບັບຂອງຄໍາພີໄບເບິນ',
    'rightPanel.name': 'ການແປພາສາຂອງພະຄໍາພີໄດ້',
    'bottomPanel.name': 'ຊັບພະຍາກອນຄໍາພີໄບເບິນ',
    'syntax.adv': 'ລິຍາພິເສດ',
    'syntax.advp': 'ປະໂຫຍກລິຍາພິເສດ',
    'syntax.cl': 'ອານຸປະໂຫຍດ',
    'syntax.conj': 'ຮ່ວມ',
    'syntax.cjp': 'ຮ່ວມ',
    'syntax.np': 'ພາສາປະໂຫຍກ',
    'syntax.o': 'ວັດຖຸ',
    'syntax.io': 'ວັດຖຸທາງອ້ອມ',
    'syntax.p': 'ຢາ',
    'syntax.pp': 'ປະໂຫຍກບຸພະບົດ',
    'syntax.s': 'Subject',
    'syntax.v': 'ພາສາ',
    'syntax.vc': 'ພາສາອານຸປະໂຫຍດ',
    'syntax.adv.description':
      'ປັບປຸງແກ້ໄຂສ່ວນຂອງການປາກເວົ້າອີກ. ໂດຍປົກກະຕິສະແດງລັກສະນະ, ສະຖານທີ່, ທີ່ໃຊ້ເວລາ, ຄວາມຖີ່, ອົງສາໃນລະດັບຂອງຄວາມແນ່ນອນ, ແລະອື່ນໆ',
    'syntax.advp.description':
      'A ການສະແດງອອກຫຼາຍຄໍາທີ່ປັບປຸງແກ້ໄຂອີກສ່ວນຫນຶ່ງຂອງການປາກເວົ້າ. ໂດຍປົກກະຕິສະແດງລັກສະນະ, ສະຖານທີ່, ທີ່ໃຊ້ເວລາ, ຄວາມຖີ່, ອົງສາໃນລະດັບຂອງຄວາມແນ່ນອນ, ແລະອື່ນໆ ...',
    'syntax.cl.description': 'A ສ່ວນຫນຶ່ງຂອງປະໂຫຍກທີ່ມີພາຕົນເອງໄດ້.',
    'syntax.conj.description': 'ເຊື່ອມຕໍ່ຄໍາສັບຕ່າງໆ, ປະໂຫຍກທີ່, ແລະອານຸປະໂຫຍດ.',
    'syntax.cjp.description': 'ເຊື່ອມຕໍ່ຄໍາສັບຕ່າງໆ, ປະໂຫຍກທີ່, ແລະອານຸປະໂຫຍດ.',
    'syntax.np.description': 'A ການສະແດງອອກຫຼາຍຄໍາທີ່ດໍາເນີນຫນ້າທີ່ຂອງພາສາໄດ້.',
    'syntax.o.description': 'ການນິຕິບຸກຄົນທີ່ຖືກກະທໍາຕໍ່ໂດຍວິຊາດັ່ງກ່າວ.',
    'syntax.io.description': 'ນີ້ເປັນການຍາກທີ່ຈະອະທິບາຍ.',
    'syntax.p.description': 'ປຽນວິຊາດັ່ງກ່າວ. ປົກກະຕິແລ້ວດາວກັບພາເປັນ.',
    'syntax.pp.description': 'ນີ້ເປັນການຍາກທີ່ຈະອະທິບາຍ.',
    'syntax.s.description': 'ການນິຕິບຸກຄົນທີ່ຄວບຄຸມພາຂອງອານຸປະໂຫຍດໄດ້.',
    'syntax.v.description': 'ຖ່າຍທອດປະຕິບັດເປັນ.',
    'syntax.vc.description':
      'A ການສະແດງອອກຫຼາຍຄໍາທີ່ performs ຫນ້າທີ່ຂອງຄໍາໄດ້ (conveys ເປັນການປະຕິບັດ).',
    'general.close': 'ປິດ',
    'general.action': 'ປະຕິບັດ',
    'general.select': 'ເລືອກ ...',
    'general.back': 'ກັບຄືນໄປບ່ອນ',
  },
  ml: {
    'languages.en': 'ഇംഗ്ലീഷ്',
    'languages.am': 'അംഹറിക്',
    'languages.bn': 'ബംഗാളി ഭാഷ',
    'languages.ceb': 'സെബുവാനോ ഭാഷ',
    'languages.de': 'ജർമൻ ഭാഷ',
    'languages.es-419': 'സ്പാനിഷ് ഭാഷ',
    'languages.fr': 'ഫ്രഞ്ച് ഭാഷ',
    'languages.gu': 'ഗുജറാത്തി',
    'languages.hi': 'ഹിന്ദി',
    'languages.hr': 'ക്രൊയേഷ്യൻ ഭാഷ',
    'languages.hu': 'ഹംഗേറിയൻ ഭാഷ',
    'languages.id': 'ഇന്തോനേഷ്യൻ ഭാഷ',
    'languages.km': 'ഖെമർ',
    'languages.kn': 'കന്നഡ',
    'languages.ko': 'കൊറിയന് ഭാഷ',
    'languages.lo': 'ലാവോഷിയൻ ഭാഷ',
    'languages.ml': 'മലയാളം',
    'languages.mr': 'മറാത്തി',
    'languages.ms': 'മലേഷ്യൻ',
    'languages.my': 'ബർമീസ് ഭാഷ',
    'languages.ne': 'നേപ്പാളി ഭാഷ',
    'languages.plt': 'പ്രവേശിക്കുക ഭാഷ',
    'languages.pt-br': 'പോർച്ചുഗീസ് (ബ്രസീൽ) ഭാഷ',
    'languages.ru': 'റഷ്യന് ഭാഷ',
    'languages.sw': 'സ്വാഹിലി',
    'languages.ta': 'തമിഴ് ഭാഷ',
    'languages.th': 'തായ് ഭാഷ',
    'languages.tl': 'തഗാലോഗ്',
    'languages.te': 'തെലുങ്ക് ഭാഷ',
    'languages.vi': 'വിയറ്റ്നാമീസ് ഭാഷ',
    'languages.zh-hans': 'ചൈനീസ് ഭാഷ (സിമ്പ്ലിഫീദ്)',
    'languages.zh-hant': 'ചൈനീസ് ഭാഷ (പരമ്പരാഗതം)',
    'header.home': 'വീട്',
    'header.help': 'സഹായിക്കൂ',
    'header.project': 'പദ്ധതി',
    'help.contactSupport': 'സഹായത്തിനായി help@youtranslate.bible എന്നതിൽ ഞങ്ങൾക്ക് ഇമെയിൽ ചെയ്യുക.',
    'home.ytbProvides': 'YouTranslate.Bible സ്വതന്ത്രമായി നൽകുന്നു:',
    'home.easyToUseTool': 'എളുപ്പത്തിൽ ഉപയോഗിക്കാവുന്ന ഓൺലൈൻ വിവർത്തനം ഉപകരണം',
    'home.originalTextHelp': 'അതിന്റെ യഥാർത്ഥ ഭാഷകളിൽ ബൈബിൾ വാചകം മനസ്സിലാക്കാൻ സഹായം',
    'home.referencesAndResources': 'റഫറൻസ് പരിശീലനവും ഉറവിടങ്ങൾ',
    'home.computerAssistance': 'പരിഭാഷകരുടെ സഹായിക്കാൻ സംസ്ഥാന-അതിവിശിഷ്ട കമ്പ്യൂട്ടർ സാങ്കേതിക',
    'profile.userProfile': 'ഉപയോക്തൃ പ്രൊഫൈൽ',
    'profile.language': 'ഭാഷ',
    'profile.update': 'അപ്ഡേറ്റ് ചെയ്യുക',
    'usernav.profile': 'പ്രൊഫൈൽ',
    'usernav.logout': 'ലോഗൗട്ട്',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ഓഫ് ലോഗൗട്ട്',
    'usernav.logout.modalText':
      'നിങ്ങൾക്ക് ഉറപ്പാണോ ലോഗൌട്ട് ചെയ്യണമെന്ന് ഉറപ്പാണോ? എല്ലാ സംരക്ഷിക്കാത്ത വർക്ക് നഷ്ടപ്പെടും.',
    cancel: 'റദ്ദാക്കുക',
    delete: 'ഇല്ലാതാക്കുക',
    create: 'സൃഷ്ടിക്കാൻ',
    update: 'അപ്ഡേറ്റ് ചെയ്യുക',
    edit: 'തിരുത്തുക',
    save: 'രക്ഷിക്കും',
    add: 'ചേർക്കുക',
    'project.create.title': 'ബൈബിൾ പരിഭാഷ പദ്ധതി',
    'project.create.list': 'പദ്ധതി ലിസ്റ്റ്',
    'project.list.deleteProject': 'പ്രോജക്റ്റ് ഇല്ലാതാക്കുക?',
    'project.list.areYouSure': 'നിങ്ങൾക്ക് ഈ പ്രോജക്റ്റ് ഇല്ലാതാക്കണമെന്ന് ഉറപ്പാണോ?',
    'project.list.workWillBeDeleted': 'പദ്ധതി ബന്ധപ്പെട്ട വല്ല പണിക്കും ഇല്ലാതാക്കും.',
    'project.list.completion': 'പൂർത്തിയാക്കൽ:',
    'project.list.createdDate': 'സൃഷ്ടിച്ച തീയതി:',
    'project.list.beginningDate': 'ആരംഭിക്കുന്നു തീയതി:',
    'project.list.targetCompletionDate': 'പൂർത്തിയാക്കുകയായിരുന്നു:',
    'project.list.description': 'വിവരണം:',
    'project.edit': 'എഡിറ്റ് പദ്ധതി',
    'project.list.translateBible': 'ബൈബിൾ വിവർത്തനം',
    'project.list.empty': 'നിങ്ങളുടെ ട്രാൻസ്ലേഷൻ പരിപാടിയുടെ ലിസ്റ്റ് ശൂന്യമാണ്.',
    'project.list.myProjects': 'എന്റെ ബൈബിൾ പരിഭാഷ പദ്ധതികൾ',
    'project.list.newProject': 'പുതിയ പദ്ധതി',
    'project.create.createNew': 'ഒരു പുതിയ പ്രോജക്റ്റ് സൃഷ്ടിക്കുക',
    'project.namePlaceholder': 'പ്രോജക്റ്റ് നാമം നൽകുക',
    'project.descriptionPlaceholder': 'പദ്ധതി വിവരണം നൽകുക',
    'project.name': 'പദ്ധതിയുടെ പേര്',
    'project.beginningDate': 'ആരംഭിക്കുന്നു തീയതി',
    'project.targetCompletionDate': 'പൂർത്തിയാക്കുകയായിരുന്നു',
    'project.description': 'വിവരണം',
    'project.manage-users': 'പരിഭാഷകർ നിയന്ത്രിക്കുക',
    'project.new-user': 'പുതിയ വിവർത്തകൻ',
    'project.add-user': 'വിവർത്തകൻ ചേർക്കുക',
    'project.users': 'പദ്ധതി പരിഭാഷകർക്ക്',
    'project.read-only': 'വായിക്കാൻ മാത്രം',
    'project.read&write': 'വായിക്കുക & എഴുതുക',
    'project.owner': 'ഉടമ',
    'project.translator': 'വിവര്ത്തകന്',
    'project.message.no-such-user': 'അത്തരം വിവർത്തകൻ നിലവിലുണ്ട്',
    'project.message.user-added': 'പരിഭാഷകൻ വിജയകരമായി ചേർത്തു',
    'project.message.user-deleted': 'പരിഭാഷകൻ നീക്കം.',
    'project.message.only-owner-can-manage-users':
      'മാത്രം പ്രോജക്റ്റ് ഉടമ ഉപയോക്താക്കളെ നിയന്ത്രിക്കാനാകൂ',
    'project.shared': 'സഹകരണ പദ്ധതിയാണിത്',
    'general.delete-user': 'ഇല്ലാതാക്കുക വിവർത്തകൻ',
    'general.edit-user': 'ഉപയോക്താവിനെ എഡിറ്റുചെയ്യുക',
    'general.name': 'പേര്',
    'general.email': 'ഇ-മെയിൽ',
    'general.permissions': 'അനുമതികൾ',
    'general.role': 'പങ്ക്',
    signin: 'സൈൻ ഇൻ',
    'signin.emailPlaceholder': 'ഈ - മെയില് വിലാസം',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'നിങ്ങളുടെ രഹസ്യ വാക്ക് മറന്നോ?',
    'signin.rememberMe': 'എന്നെ ഓർക്കണം',
    'signin.facebook': 'Facebook ഉപയോഗിച്ച് സൈൻ ഇൻ',
    'signin.google': 'Google ഉപയോഗിച്ച് സൈൻ ഇൻ',
    'signin.dontHaveAccount': 'ഒരു അക്കൗണ്ട് ഇല്ലേ?',
    'signin.createOne': 'ഒരെണ്ണം ഉണ്ടാക്കുക',
    'signup.join': 'YouTranslate.Bible ചേരുക',
    'signup.createAccountPrompt': 'ആരംഭിക്കുന്നതിന് നിങ്ങളുടെ സൗജന്യ അക്കൗണ്ട് സൃഷ്ടിക്കുക.',
    'signup.createAccount': 'അക്കൗണ്ട് സൃഷ്ടിക്കുക',
    'signup.emailPlaceholder': 'ഈ - മെയില് വിലാസം',
    'signup.passwordPlaceholder': 'Password',
    'translation.project': 'പദ്ധതി:',
    'translation.backToProjectList': 'പദ്ധതി പട്ടികയിലേക്ക് തിരികെ',
    'translation.book': 'പുസ്തകം:',
    'translation.chapter': 'അദ്ധ്യായം:',
    'translation.prompt':
      'നിങ്ങൾക്ക് ഈ അധ്യായം വിടണമെന്ന് തീർച്ചയാണോ? എല്ലാ സംരക്ഷിക്കാത്ത വർക്ക് നഷ്ടപ്പെടും.',
    'translation.prev': 'മുമ്പത്തെ',
    'translation.next': 'അടുത്തത്',
    'translation.previousChapter': 'മുമ്പത്തെ അധ്യായം',
    'translation.nextChapter': 'അടുത്ത അധ്യായം',
    'navigator.book': 'പുസ്തകം:',
    'navigator.chapter': 'അദ്ധ്യായം:',
    'navigator.oldTestament': 'പഴയ നിയമം',
    'navigator.newTestament': 'പുതിയ നിയമം',
    'navigator.selectBook': 'ഒരു പുസ്തകം തിരഞ്ഞെടുക്കുക',
    'navigator.search': 'തിരയൽ',
    'navigator.changeBook': 'പുസ്തകം മാറ്റുക',
    'navigator.changeChapter': 'മാറ്റുക അധ്യായം',
    Genesis: 'ഉല്പത്തി',
    Exodus: 'പുറപ്പാട്',
    Leviticus: 'ലേവ്യപുസ്തകം',
    Numbers: 'നമ്പറുകൾ',
    Deuteronomy: 'ആവർത്തനം',
    Joshua: 'യോശുവ',
    Judges: 'ന്യായാധിപന്മാർ',
    Ruth: 'ruth',
    '1 Samuel': '1 ശമൂവേൽ',
    '2 Samuel': '2 ശമൂവേൽ',
    '1 Kings': '1 രാജാക്കന്മാർ',
    '2 Kings': '2 രാജാക്കന്മാർ',
    '1 Chronicles': '1 ദിനവൃത്താന്തം',
    '2 Chronicles': '2 ദിനവൃത്താന്തം',
    Ezra: 'എസ്രാ',
    Nehemiah: 'നെഹെമ്യാവു',
    Esther: 'എസ്ഥേർ',
    Job: 'ജോലി',
    Psalms: 'സങ്കീർത്തനങ്ങൾ',
    Proverbs: 'സദൃശ്യവാക്യങ്ങൾ',
    Ecclesiastes: 'സഭാപ്രസംഗി',
    'Song of Songs': 'ഉത്തമഗീതം',
    Isaiah: 'യെശയ്യാവു',
    Jeremiah: 'യിരെമ്യാവു',
    Lamentations: 'വിലാപങ്ങൾ',
    Ezekiel: 'യെഹെസ്കേൽ',
    Daniel: 'ദാനിയേൽ',
    Hosea: 'ഹോശേയ',
    Joel: 'യോവേൽ',
    Amos: 'ആമോസ്',
    Obadiah: 'ഓബദ്യാവു',
    Jonah: 'യൂനുസ്',
    Micah: 'മീഖാവു',
    Nahum: 'നഹൂം',
    Habakkuk: 'ഹബക്കൂക്',
    Zephaniah: 'സെഫന്യാവു',
    Haggai: 'ഹഗ്ഗായി',
    Zechariah: 'സെഖർയ്യാവു',
    Malachi: 'മലാഖി',
    Matthew: 'മത്തായി',
    Mark: 'അടയാളം',
    Luke: 'ലൂക്കോസ്',
    John: 'ജോൺ',
    Acts: 'പ്രവൃത്തികൾ',
    Romans: 'റോമർ',
    '1 Corinthians': '1 കൊരിന്ത്യർ',
    '2 Corinthians': '2 കൊരിന്ത്യർ',
    Galatians: 'ഗലാത്യർ',
    Ephesians: 'എഫെസ്യർ',
    Philippians: 'ഫിലി',
    Colossians: 'കൊലൊസ്സ്യർ',
    '1 Thessalonians': '1 തെസ്സലൊനീക്യർ',
    '2 Thessalonians': '2 തെസ്സലൊനീക്യർ',
    '1 Timothy': '1 തിമൊഥെയൊസ്',
    '2 Timothy': '2 തിമൊഥെയൊസ്',
    Titus: 'ടൈറ്റസ്',
    Philemon: 'ഫിലേമോൻ',
    Hebrews: 'എബ്രായർ',
    James: 'ജെയിംസ്',
    '1 Peter': '1 പത്രോസ്',
    '2 Peter': '2 പത്രോസ്',
    '1 John': '1 യോഹന്നാൻ',
    '2 John': '2 യോഹന്നാൻ',
    '3 John': '3 യോഹന്നാൻ',
    Jude: 'യൂദാ',
    Revelation: 'വെളിപ്പെടുന്ന',
    'resources.prompt': 'നിങ്ങൾ വിവർത്തനം ചുരം അധിക ഉൾക്കാഴ്ച എഴുതുന്നത് വിഭവങ്ങൾ ചേർക്കുക.',
    'resources.add': 'ഒരു ബൈബിൾ റിസോഴ്സ് ചേർക്കുക',
    Manuscript: 'കയ്യെഴുത്തുപ്രതി',
    Morphology: 'രൂപഘടന',
    Lexicon: 'ലെക്സിക്കൺ',
    'Syntactic Tree': 'വായനാനുഭവം ട്രീ',
    'Go to project list': 'പദ്ധതി ലിസ്റ്റിലേക്ക് പോകുക',
    'Project not found': 'പദ്ധതി കണ്ടെത്തി അല്ലെങ്കിൽ അപര്യാപ്തമായ അനുമതികൾ.',
    'error.referenceDoesNotExistForResource':
      'ഈ ബൈബിൾ പരിഭാഷ തിരഞ്ഞെടുത്ത പോകാനായി ഉള്ളടക്കം അടങ്ങിയിട്ടില്ല.',
    'references.addAnother': 'മറ്റൊരു വിവർത്തനം ചേർക്കുക',
    'references.referencePrompt': 'അധിക ഉൾക്കാഴ്ച മറ്റ് ബൈബിൾ വിവർത്തനങ്ങൾ ചേർക്കുക.',
    'references.add': 'ഒരു ബൈബിൾ വിവർത്തനം ചേർക്കുക',
    'translation.complete': 'പരിഭാഷ പൂർത്തിയായി',
    Alignment: 'വിന്യാസം',
    Share: 'പങ്കിടുക',
    'Your Translation': 'നിങ്ങളുടെ പരിഭാഷ',
    'Enter your translation': 'നിങ്ങളുടെ വിവർത്തന നൽകുക',
    OK: 'ശരി',
    'error.verseTranslationEmpty': 'നിങ്ങൾ ശൂന്യമായ വിവർത്തന ഉള്ളടക്കം വിട്ടു കഴിയില്ല.',
    'translation.hide.completed': 'മറയ്ക്കുക പൂർത്തിയായി',
    alignment: 'വിന്യാസം',
    'alignment.verify': 'പരിശോധിക്കുക',
    'alignment.backToEditor': 'എഡിറ്റർ മടങ്ങുക',
    'alignment.error':
      'എന്തോ കുഴപ്പം സംഭവിച്ചു. പ്രശ്നം നിലനിൽക്കുകയാണെങ്കിൽ, YouTranslate.Bible ടീം ബന്ധപ്പെടുക.',
    'alignment.add': 'ലിങ്ക് ചേർക്കുക',
    'alignment.delete': 'ലിങ്ക് നീക്കം',
    'alignment.clear': 'തിരഞ്ഞെടുത്തത് മായ്ക്കുക',
    'alignment.reverse': 'റിവേഴ്സ് പ്രദർശിപ്പിക്കുക',
    'alignment.removeOrClear': 'തിരഞ്ഞെടുത്ത ലിങ്ക് അല്ലെങ്കിൽ വ്യക്തമായ തിരഞ്ഞെടുപ്പ് മാറ്റുക.',
    'alignment.addOrRemove': 'ഒരു പുതിയ ലിങ്ക് സൃഷ്ടിക്കുക അല്ലെങ്കിൽ മുൻ ഒന്ന് ഇല്ലാതാക്കണം.',
    'alignment.selectAndAdd': 'ലിങ്ക് സെഗ്മെൻറ് സദൃശ്യമായ തിരഞ്ഞെടുക്കുക.',
    'error.mustBeSignedToMakeChanges': 'നിങ്ങൾ വരുത്തുന്ന മാറ്റങ്ങൾ പ്രവേശിച്ചിരിക്കണം.',
    'error.mustBeSignedToTranslateBible': 'ബൈബിൾ വിവർത്തനം ചെയ്യാൻ ഇൻ ചെയ്തിരിക്കണം.',
    checking: 'പരിശോധന',
    'verseEditor.verseSuggestions': 'നിർദ്ദേശങ്ങൾ',
    verses: 'Verse വഴി',
    words: 'പദം പ്രകാരം',
    'leftPanel.name': 'ബൈബിൾ യഥാർത്ഥ ഉള്ളടക്കം',
    'rightPanel.name': 'ബൈബിൾ വിവർത്തനങ്ങൾ',
    'bottomPanel.name': 'ബൈബിൾ ഉറവിടങ്ങൾ',
    'syntax.adv': 'കിയാവിശേഷണം',
    'syntax.advp': 'ക്രിയാവിശേഷണം വാചകം',
    'syntax.cl': 'ഉടന്വടി',
    'syntax.conj': 'സംയോജിച്ച്',
    'syntax.cjp': 'സംയോജിച്ച്',
    'syntax.np': 'നാമം വാചകം',
    'syntax.o': 'വസ്തു',
    'syntax.io': 'പരോക്ഷ ഒബ്ജക്റ്റ്',
    'syntax.p': 'ആഖ്യാത',
    'syntax.pp': 'പ്രെപൊസിതിഒനല് വാചകം',
    'syntax.s': 'വിഷയം',
    'syntax.v': 'കിയാപദം',
    'syntax.vc': 'ക്രിയ ഖണ്ഡം',
    'syntax.adv.description':
      'പ്രസംഗം മറ്റൊരു ഭാഗം മാറ്റം വരുത്തുന്നു. സാധാരണഗതിയിൽ വിധത്തിൽ, സ്ഥലം, സമയം, ആവൃത്തി, ഡിഗ്രി, ദൃഢമായ നില തുടങ്ങിയവ പ്രകടിപ്പിക്കുന്ന',
    'syntax.advp.description':
      'പ്രസംഗം മറ്റൊരു മാറ്റം വരുത്തുന്ന ഒരു മൾട്ടി-വചനം പദപ്രയോഗം. സാധാരണഗതിയിൽ വിധത്തിൽ, സ്ഥലം, സമയം, ആവൃത്തി, ഡിഗ്രി, ദൃഢമായ നില തുടങ്ങിയവ പ്രകടിപ്പിക്കുന്ന ...',
    'syntax.cl.description': 'ഒരു ക്രിയ ഉൾക്കൊള്ളുന്ന ഒരു വാക്യത്തിന്റെ ഒരു ഭാഗം.',
    'syntax.conj.description': 'വാക്കുകൾ ശൈലികളും ക്ലോസുകളെ ബന്ധിപ്പിക്കുന്നു.',
    'syntax.cjp.description': 'വാക്കുകൾ ശൈലികളും ക്ലോസുകളെ ബന്ധിപ്പിക്കുന്നു.',
    'syntax.np.description': 'ഒരു ക്രിയ എന്ന പ്രവർത്തിക്കുന്ന ഒരു മൾട്ടി-വചനം പദപ്രയോഗം.',
    'syntax.o.description': 'വിഷയം പ്രവർത്തിച്ച എന്ന് എന്റിറ്റി.',
    'syntax.io.description': 'ഈ ഒരു വരെ എട്ട്.',
    'syntax.p.description': 'വിഷയം പരിഷ്കരിക്കുന്നു. സാധാരണയായി ഒരു ക്രിയ നക്ഷത്രങ്ങൾക്കൊപ്പം.',
    'syntax.pp.description': 'ഈ ഒരു വരെ എട്ട്.',
    'syntax.s.description': 'വ്യവസ്ഥ ക്രിയ നിയന്ത്രിക്കുന്ന എന്റിറ്റി.',
    'syntax.v.description': 'ഒരു പ്രവർത്തനം നൽകുന്നു.',
    'syntax.vc.description':
      'നിർവഹിക്കുന്ന ഒരു ക്രിയ പ്രവർത്തനം (ഒരു പ്രവർത്തനം നൽകുന്നു) ഒരു മൾട്ടി-വചനം പദപ്രയോഗം.',
    'general.close': 'അടയ്ക്കുക',
    'general.action': 'ആക്ഷൻ',
    'general.select': 'തിരഞ്ഞെടുക്കുക ...',
    'general.back': 'തിരികെ',
  },
  mr: {
    'languages.en': 'इंग्रजी',
    'languages.am': 'अम्हारिक',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सिबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पॅनिश भाषा',
    'languages.fr': 'फ्रेंच भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिंदी',
    'languages.hr': 'क्रोएशियन भाषा',
    'languages.hu': 'हंगेरियन भाषा',
    'languages.id': 'इंडोनेशियन भाषा',
    'languages.km': 'ख्मेर',
    'languages.kn': 'कन्नड',
    'languages.ko': 'कोरियन भाषा',
    'languages.lo': 'लाओतिअन भाषा',
    'languages.ml': 'मल्याळम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियन',
    'languages.my': 'बर्मी भाषा',
    'languages.ne': 'नेपाळी भाषा',
    'languages.plt': 'मलागसी भाषा',
    'languages.pt-br': 'पोर्तुगीज (ब्राझिल) भाषा',
    'languages.ru': 'रशियन भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तमिळ भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'टागालोग',
    'languages.te': 'Telegu भाषा',
    'languages.vi': 'व्हिएतनामी भाषा',
    'languages.zh-hans': 'चीनी भाषा (सरलीकृत)',
    'languages.zh-hant': 'चीनी भाषा (पारंपारिक)',
    'header.home': 'मुख्यपृष्ठ',
    'header.help': 'मदत',
    'header.project': 'प्रकल्प',
    'help.contactSupport': 'मदत help@youtranslate.bible येथे आम्हाला ईमेल करा.',
    'home.ytbProvides': 'YouTranslate.Bible मुक्तपणे उपलब्ध:',
    'home.easyToUseTool': 'एक-सुलभ वापर ऑनलाइन अनुवाद साधन',
    'home.originalTextHelp': 'त्याच्या मूळ भाषांमध्ये बायबल मजकूर समजून मदत',
    'home.referencesAndResources': 'संदर्भ आणि प्रशिक्षण संसाधने',
    'home.computerAssistance': 'राज्य-ऑफ-द-आर्ट संगणक तंत्रज्ञान अनुवादकांची मदत करण्यासाठी',
    'profile.userProfile': 'वापरकर्ता प्रोफाइल',
    'profile.language': 'इंग्रजी',
    'profile.update': 'अद्यतनित करा',
    'usernav.profile': 'प्रोफाइल',
    'usernav.logout': 'बाहेर पडणे',
    'usernav.logout.modalTitle': 'YouTranslate.Bible च्या लॉग आऊट',
    'usernav.logout.modalText':
      'आपल्याला खात्री आहे आपण लॉग आऊट करू इच्छिता? सर्व जतन न केलेले काम गमावला जाईल.',
    cancel: 'रद्द करा',
    delete: 'हटवा',
    create: 'तयार करा',
    update: 'अद्यतनित करा',
    edit: 'सुधारणे',
    save: 'जतन करा',
    add: 'जोडा',
    'project.create.title': 'बायबल भाषांतर प्रकल्प',
    'project.create.list': 'प्रकल्प यादी',
    'project.list.deleteProject': 'प्रकल्प हटवा?',
    'project.list.areYouSure': 'आपण हा प्रकल्प हटवू इच्छिता आपल्याला खात्री आहे?',
    'project.list.workWillBeDeleted': 'प्रकल्प आणि संबंधित कोणतेही काम काढून टाकले जातील.',
    'project.list.completion': 'पूर्ण:',
    'project.list.createdDate': 'तयार केल्याची तारीख:',
    'project.list.beginningDate': 'सुरुवात दिनांक:',
    'project.list.targetCompletionDate': 'लक्ष्य पूर्ण झाल्याची तारीख:',
    'project.list.description': 'वर्णन:',
    'project.edit': 'योजना संपादित करा',
    'project.list.translateBible': 'भाषांतर बायबल',
    'project.list.empty': 'आपली भाषांतर प्रकल्प सूची रिक्त आहे.',
    'project.list.myProjects': 'माझे बायबल भाषांतर प्रकल्प',
    'project.list.newProject': 'नवीन प्रकल्प',
    'project.create.createNew': 'एक नवीन प्रकल्प तयार',
    'project.namePlaceholder': 'प्रकल्प नाव प्रविष्ट करा',
    'project.descriptionPlaceholder': 'प्रकल्प वर्णन प्रविष्ट करा',
    'project.name': 'प्रकल्पाचे नाव',
    'project.beginningDate': 'सुरुवात तारीख',
    'project.targetCompletionDate': 'लक्ष्य पूर्ण झाल्याची तारीख',
    'project.description': 'वर्णन',
    'project.manage-users': 'Marathi: व्यवस्थापित करा',
    'project.new-user': 'नवीन भाषांतर',
    'project.add-user': 'अनुवादक जोडा',
    'project.users': 'प्रकल्प अनुवादकांची',
    'project.read-only': 'फक्त वाचा',
    'project.read&write': 'वाचा लिहा',
    'project.owner': 'मालक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'असा कोणताही भाषांतर अस्तित्वात',
    'project.message.user-added': 'अनुवादक यशस्वीरित्या जोडले',
    'project.message.user-deleted': 'अनुवादक काढले.',
    'project.message.only-owner-can-manage-users':
      'केवळ प्रकल्प मालक वापरकर्त्यांना व्यवस्थापित करू शकता',
    'project.shared': 'सहयोगी प्रकल्प',
    'general.delete-user': 'भाषांतर हटवा',
    'general.edit-user': 'वापरकर्ता संपादन करा',
    'general.name': 'नाव',
    'general.email': 'ई-मेल',
    'general.permissions': 'परवानग्या',
    'general.role': 'भूमिका',
    signin: 'साइन इन करा',
    'signin.emailPlaceholder': 'ई-मेल पत्ता',
    'signin.passwordPlaceholder': 'पासवर्ड',
    'signin.forgotPassword': 'आपला संकेतशब्द विसरलात?',
    'signin.rememberMe': 'माझी आठवण ठेवा',
    'signin.facebook': 'फेसबुक साइन इन करा',
    'signin.google': 'Google सह साइन इन',
    'signin.dontHaveAccount': 'खाते नाही?',
    'signin.createOne': 'एक बनव',
    'signup.join': 'YouTranslate.Bible सामील व्हा',
    'signup.createAccountPrompt': 'सुरुवात करण्यासाठी आपल्या विनामूल्य खाते तयार करा.',
    'signup.createAccount': 'खाते तयार करा',
    'signup.emailPlaceholder': 'ई-मेल पत्ता',
    'signup.passwordPlaceholder': 'पासवर्ड',
    'translation.project': 'प्रकल्प:',
    'translation.backToProjectList': 'प्रकल्प यादी परत',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'आपण या धडा सोडू इच्छिता आपल्याला खात्री आहे? सर्व जतन न केलेले काम गमावला जाईल.',
    'translation.prev': 'मागील',
    'translation.next': 'पुढे',
    'translation.previousChapter': 'मागील अध्यायात',
    'translation.nextChapter': 'पुढील अध्यायात',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'जुना करार',
    'navigator.newTestament': 'नवा करार',
    'navigator.selectBook': 'एक पुस्तक निवडा',
    'navigator.search': 'शोधा',
    'navigator.changeBook': 'पुस्तक बदला',
    'navigator.changeChapter': 'बदला धडा',
    Genesis: 'उत्पत्ति',
    Exodus: 'Exodus',
    Leviticus: 'लेवीय',
    Numbers: 'संख्या',
    Deuteronomy: 'अनुवाद',
    Joshua: 'यहोशवा',
    Judges: 'न्यायाधीश',
    Ruth: 'रूथ',
    '1 Samuel': '1 शमुवेल',
    '2 Samuel': '2 शमुवेल',
    '1 Kings': '1 राजे',
    '2 Kings': '2 राजे',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एज्रा',
    Nehemiah: 'नहेम्या',
    Esther: 'एस्तेर',
    Job: 'नोकरी',
    Psalms: 'Psalms',
    Proverbs: 'नीतिसूत्रे',
    Ecclesiastes: 'उपदेशक',
    'Song of Songs': 'गाणी गीत',
    Isaiah: 'यशया',
    Jeremiah: 'यिर्मया',
    Lamentations: 'विलापगीत',
    Ezekiel: 'यहेज्केल',
    Daniel: 'डॅनियल',
    Hosea: 'होशेय',
    Joel: 'जोएल',
    Amos: 'आमोस',
    Obadiah: 'ओबद्या',
    Jonah: 'योना',
    Micah: 'मीखा',
    Nahum: 'निनवे',
    Habakkuk: 'हबक्कूक',
    Zephaniah: 'सफन्या',
    Haggai: 'हाग्गय',
    Zechariah: 'जखऱ्या',
    Malachi: 'मलाखी',
    Matthew: 'मॅथ्यू',
    Mark: 'मार्क',
    Luke: 'लूक',
    John: 'जॉन',
    Acts: 'कायदे',
    Romans: 'रोम',
    '1 Corinthians': '1 करिंथकर',
    '2 Corinthians': '2 करिंथकर',
    Galatians: 'गलती',
    Ephesians: 'इफिस',
    Philippians: 'फिलिप्पैकर',
    Colossians: 'कलस्सैकर',
    '1 Thessalonians': '1 थेस्सलनीका येथील',
    '2 Thessalonians': '2 थेस्सलनीकाकरांस',
    '1 Timothy': '1 तीमथ्य',
    '2 Timothy': '2 तीमथ्य',
    Titus: 'तीत',
    Philemon: 'फिलेमोन',
    Hebrews: 'इब्री लोकांस',
    James: 'जेम्स',
    '1 Peter': '1 पेत्र',
    '2 Peter': '2 पेत्र',
    '1 John': '1 योहान',
    '2 John': '2 योहान',
    '3 John': '3 योहान',
    Jude: 'यहूदा',
    Revelation: 'प्रकटीकरण',
    'resources.prompt': 'आपण अनुवाद रस्ता अतिरिक्त माहिती साठी बायबलातील संसाधने जोडा.',
    'resources.add': 'एक बायबलातील संसाधन जोडा',
    Manuscript: 'हस्तलिखित',
    Morphology: 'शब्द कसे बनतात त्याचे शास्त्र',
    Lexicon: 'शब्दकोश',
    'Syntactic Tree': 'वितरकांची वृक्ष',
    'Go to project list': 'प्रकल्प यादी जा',
    'Project not found': 'प्रकल्प आढळले नाही किंवा अपूर्ण परवानग्या नाही.',
    'error.referenceDoesNotExistForResource': 'या बायबल अनुवाद निवडले रस्ता सामग्री असू शकत नाही.',
    'references.addAnother': 'आणखी एक अनुवाद जोडा',
    'references.referencePrompt': 'अतिरिक्त माहिती इतर बायबल भाषांतरे जोडा.',
    'references.add': 'बायबल अनुवाद जोडा',
    'translation.complete': 'भाषांतर पूर्ण',
    Alignment: 'संरेखन',
    Share: 'सामायिक करा',
    'Your Translation': 'आपले भाषांतर',
    'Enter your translation': 'आपली भाषांतर प्रविष्ट करा',
    OK: 'ठीक आहे',
    'error.verseTranslationEmpty': 'आपण अनुवाद सामग्री रिक्त सोडू शकत नाही.',
    'translation.hide.completed': 'लपवा पूर्ण',
    alignment: 'संरेखन',
    'alignment.verify': 'सत्यापित करा',
    'alignment.backToEditor': 'संपादक परत',
    'alignment.error':
      'काहीतरी चूक झाली. समस्या कायम राहिल्यास, YouTranslate.Bible टीमशी संपर्क साधा.',
    'alignment.add': 'दुवा जोडा',
    'alignment.delete': 'दुवा काढा',
    'alignment.clear': 'निवड साफ करा',
    'alignment.reverse': 'उलट प्रदर्शन',
    'alignment.removeOrClear': 'निवडलेले दुवा किंवा स्पष्ट निवड काढा.',
    'alignment.addOrRemove': 'एक नवीन दुवा तयार करा किंवा मागील हटवा.',
    'alignment.selectAndAdd': 'दुवा विभागांची संबंधित निवडा.',
    'error.mustBeSignedToMakeChanges': 'आपण केलेले बदल साइन इन करणे आवश्यक आहे.',
    'error.mustBeSignedToTranslateBible': 'आपण बायबल अनुवाद साइन इन करणे आवश्यक आहे.',
    checking: 'तपासत',
    'verseEditor.verseSuggestions': 'सूचना',
    verses: 'काव्य करून',
    words: 'शब्दानुसार',
    'leftPanel.name': 'बायबलच्या मूळ मजकूर',
    'rightPanel.name': 'बायबल भाषांतरे',
    'bottomPanel.name': 'बायबल संसाधने',
    'syntax.adv': 'क्रियाविशेषण',
    'syntax.advp': 'क्रियाविशेषण लिहिलंय',
    'syntax.cl': 'कलम',
    'syntax.conj': 'उभयान्वयी अव्यय',
    'syntax.cjp': 'उभयान्वयी अव्यय',
    'syntax.np': 'नाम लिहिलंय',
    'syntax.o': 'ऑब्जेक्ट',
    'syntax.io': 'अप्रत्यक्ष ऑब्जेक्ट',
    'syntax.p': 'सत्य विधान',
    'syntax.pp': 'Prepositional लिहिलंय',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रियापद',
    'syntax.vc': 'क्रियापद कलम',
    'syntax.adv.description':
      'फेरफार भाषण दुसरा भाग. थोडक्यात रीतीने, स्थान, वेळ, वारंवारता, पदवी, निश्चितता पातळी, इ व्यक्त',
    'syntax.advp.description':
      'एक मल्टि-शब्द अभिव्यक्ती भाषण की फेरफार दुसरा भाग. थोडक्यात रीतीने, स्थान, वेळ, वारंवारता, पदवी, निश्चितता पातळी, इ व्यक्त ...',
    'syntax.cl.description': 'एक क्रियापद आहे की एक वाक्य एक भाग.',
    'syntax.conj.description': 'शब्द, वाक्ये, कलम जोडते.',
    'syntax.cjp.description': 'शब्द, वाक्ये, कलम जोडते.',
    'syntax.np.description': 'एक नाम कार्य करते की एक मल्टि-शब्द अभिव्यक्ती.',
    'syntax.o.description': 'विषयानुसार कार्य आहे की अस्तित्व नाही.',
    'syntax.io.description': 'हा एक स्पष्ट करणे कठीण आहे.',
    'syntax.p.description': 'विषय बदलतो. सहसा एक क्रियापद चिन्ह.',
    'syntax.pp.description': 'हा एक स्पष्ट करणे कठीण आहे.',
    'syntax.s.description': 'खंड क्रियापद नियंत्रित करते अस्तित्व नाही.',
    'syntax.v.description': 'एक क्रिया खूप वेगळा आहे.',
    'syntax.vc.description':
      'एक मल्टि-शब्द अभिव्यक्ती की कामगिरी एक क्रियापद कार्य (प्रकट एक क्रिया).',
    'general.close': 'बंद',
    'general.action': 'कृती',
    'general.select': 'निवडा ...',
    'general.back': 'मागे',
  },
  ms: {
    'languages.en': 'bahasa inggeris',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Bahasa',
    'languages.ceb': 'Cebuano Bahasa',
    'languages.de': 'Bahasa Jerman',
    'languages.es-419': 'Bahasa Sepanyol',
    'languages.fr': 'Bahasa Perancis',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Bahasa',
    'languages.hu': 'Hungary Bahasa',
    'languages.id': 'Bahasa Indonesia',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Bahasa Korea',
    'languages.lo': 'Laos Bahasa',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Bahasa Burma',
    'languages.ne': 'Nepal Bahasa',
    'languages.plt': 'Malagasy Bahasa',
    'languages.pt-br': 'Portugis (Brazil) Bahasa',
    'languages.ru': 'Bahasa Rusia',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Bahasa',
    'languages.th': 'Bahasa Thai',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Bahasa',
    'languages.vi': 'Bahasa Vietnam',
    'languages.zh-hans': 'Bahasa Cina (Mudah)',
    'languages.zh-hant': 'Bahasa Cina (Tradisional)',
    'header.home': 'rumah',
    'header.help': 'bantuan',
    'header.project': 'projek',
    'help.contactSupport': 'Sila e-mel kami di help@youtranslate.bible untuk mendapatkan bantuan.',
    'home.ytbProvides': 'YouTranslate.Bible bebas menyediakan:',
    'home.easyToUseTool': 'Alat penterjemahan dalam talian yang mudah untuk digunakan',
    'home.originalTextHelp': 'Bantuan untuk memahami teks Alkitab dalam bahasa-bahasa asal',
    'home.referencesAndResources': 'sumber rujukan dan latihan',
    'home.computerAssistance': 'State-of-the-art teknologi komputer untuk membantu penterjemah',
    'profile.userProfile': 'Profil pengguna',
    'profile.language': 'bahasa',
    'profile.update': 'Kemas kini',
    'usernav.profile': 'Profil',
    'usernav.logout': 'Log keluar',
    'usernav.logout.modalTitle': 'Log keluar dari YouTranslate.Bible',
    'usernav.logout.modalText':
      'Adakah anda pasti anda mahu log keluar? Semua kerja yang tidak disimpan akan hilang.',
    cancel: 'Batal',
    delete: 'Padam',
    create: 'Buat',
    update: 'Kemas kini',
    edit: 'Edit',
    save: 'jimat',
    add: 'Tambah',
    'project.create.title': 'Alkitab Projek Terjemahan',
    'project.create.list': 'senarai projek',
    'project.list.deleteProject': 'Memadam Project?',
    'project.list.areYouSure': 'Adakah anda pasti anda mahu memadamkan projek ini?',
    'project.list.workWillBeDeleted': 'Projek ini dan apa-apa kerja yang berkaitan akan dipadam.',
    'project.list.completion': 'Selesai:',
    'project.list.createdDate': 'Dicipta Date:',
    'project.list.beginningDate': 'Bermula Date:',
    'project.list.targetCompletionDate': 'Sasaran Siap Date:',
    'project.list.description': 'Penerangan:',
    'project.edit': 'Edit Projek',
    'project.list.translateBible': 'menterjemahkan Bible',
    'project.list.empty': 'senarai projek terjemahan anda kosong.',
    'project.list.myProjects': 'Alkitab Projek Saya Terjemahan',
    'project.list.newProject': 'Projek baru',
    'project.create.createNew': 'Buat Projek baru',
    'project.namePlaceholder': 'Masukkan nama projek',
    'project.descriptionPlaceholder': 'Masukkan penerangan projek',
    'project.name': 'Nama Projek',
    'project.beginningDate': 'bermula Tarikh',
    'project.targetCompletionDate': 'Sasaran Tarikh Siap',
    'project.description': 'Penerangan',
    'project.manage-users': 'Urus Translators',
    'project.new-user': 'penterjemah baru',
    'project.add-user': 'penterjemah add',
    'project.users': 'penterjemah projek',
    'project.read-only': 'Baca sahaja',
    'project.read&write': 'Baca tulis',
    'project.owner': 'Owner',
    'project.translator': 'penterjemah',
    'project.message.no-such-user': 'No penterjemah tersebut wujud',
    'project.message.user-added': 'Penterjemah berjaya diletakkan',
    'project.message.user-deleted': 'Penterjemah dikeluarkan.',
    'project.message.only-owner-can-manage-users':
      'pemilik projek hanya boleh menguruskan pengguna',
    'project.shared': 'projek bersama',
    'general.delete-user': 'penterjemah padam',
    'general.edit-user': 'Edit pengguna',
    'general.name': 'nama',
    'general.email': 'E-mel',
    'general.permissions': 'kebenaran',
    'general.role': 'peranan',
    signin: 'Daftar masuk',
    'signin.emailPlaceholder': 'Alamat emel',
    'signin.passwordPlaceholder': 'kata laluan',
    'signin.forgotPassword': 'Lupa kata laluan anda?',
    'signin.rememberMe': 'Ingat saya',
    'signin.facebook': 'Daftar masuk dengan Facebook',
    'signin.google': 'Log masuk dengan Google',
    'signin.dontHaveAccount': 'Tiada akaun?',
    'signin.createOne': 'Buat satu',
    'signup.join': 'Sertai YouTranslate.Bible',
    'signup.createAccountPrompt': 'Buat akaun percuma anda bermula.',
    'signup.createAccount': 'Buat akaun',
    'signup.emailPlaceholder': 'Alamat emel',
    'signup.passwordPlaceholder': 'kata laluan',
    'translation.project': 'PROJEK:',
    'translation.backToProjectList': 'Kembali ke senarai projek',
    'translation.book': 'book:',
    'translation.chapter': 'bab:',
    'translation.prompt':
      'Adakah anda pasti anda mahu meninggalkan bab ini? Semua kerja yang tidak disimpan akan hilang.',
    'translation.prev': 'terdahulu',
    'translation.next': 'Seterusnya',
    'translation.previousChapter': 'bab sebelum',
    'translation.nextChapter': 'bab seterusnya',
    'navigator.book': 'book:',
    'navigator.chapter': 'bab:',
    'navigator.oldTestament': 'Perjanjian lama',
    'navigator.newTestament': 'Perjanjian baru',
    'navigator.selectBook': 'Pilih buku',
    'navigator.search': 'cari',
    'navigator.changeBook': 'Tukar buku',
    'navigator.changeChapter': 'Tukar bab',
    Genesis: 'Kejadian',
    Exodus: 'Keluaran',
    Leviticus: 'Imamat',
    Numbers: 'nombor',
    Deuteronomy: 'Ulangan',
    Joshua: 'joshua',
    Judges: 'hakim',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Raja-raja',
    '2 Kings': '2 Raja-raja',
    '1 Chronicles': '1 Tawarikh',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'pekerjaan',
    Psalms: 'mazmur',
    Proverbs: 'peribahasa',
    Ecclesiastes: 'Ecclesiastes',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Yesaya',
    Jeremiah: 'Yeremia',
    Lamentations: 'Lamentations',
    Ezekiel: 'Yehezkiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obaja',
    Jonah: 'Jonah',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habakkuk',
    Zephaniah: 'Zefanya',
    Haggai: 'Haggai',
    Zechariah: 'Zakharia',
    Malachi: 'Maleakhi',
    Matthew: 'Matthew',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'perbuatan',
    Romans: 'Romans',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Galatians',
    Ephesians: 'Efesus',
    Philippians: 'Filipi',
    Colossians: 'Kolose',
    '1 Thessalonians': '1 Tesalonika',
    '2 Thessalonians': '2 Tesalonika',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Ibrani',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Wahyu',
    'resources.prompt':
      'Menambah sumber Alkitab untuk wawasan tambahan ke dalam laluan yang anda sedang terjemahkan.',
    'resources.add': 'Tambah sumber Alkitab',
    Manuscript: 'manuskrip',
    Morphology: 'morfologi',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'sintaktik Tree',
    'Go to project list': 'Pergi ke senarai projek',
    'Project not found': 'Projek tidak dijumpai atau kebenaran yang tidak mencukupi.',
    'error.referenceDoesNotExistForResource':
      'Ini terjemahan Bible tidak mengandungi kandungan untuk laluan yang dipilih.',
    'references.addAnother': 'Menambah terjemahan lain',
    'references.referencePrompt': 'Menambah terjemahan Bible lain untuk wawasan tambahan.',
    'references.add': 'Menambah penterjemahan Bible',
    'translation.complete': 'lengkap terjemahan',
    Alignment: 'penjajaran',
    Share: 'Kongsi',
    'Your Translation': 'Terjemahan anda',
    'Enter your translation': 'Masukkan terjemahan',
    OK: 'okey',
    'error.verseTranslationEmpty':
      'Anda tidak boleh meninggalkan kandungan penterjemahan mengosongkan.',
    'translation.hide.completed': 'Menyembunyikan selesai',
    alignment: 'penjajaran',
    'alignment.verify': 'mengesahkan',
    'alignment.backToEditor': 'Kembali Kepada Pengarang',
    'alignment.error':
      'Ada sesuatu yang tidak salah. Jika masalah berterusan, sila hubungi pasukan YouTranslate.Bible.',
    'alignment.add': 'Tambah Pautan',
    'alignment.delete': 'Buang Link',
    'alignment.clear': 'Kosongkan Pilihan',
    'alignment.reverse': 'Paparan terbalik',
    'alignment.removeOrClear': 'Alih keluar pautan yang dipilih atau pemilihan yang jelas.',
    'alignment.addOrRemove': 'Membuat pautan baharu atau memadamkan apa yang sebelumnya.',
    'alignment.selectAndAdd': 'Pilih sama segmen untuk link.',
    'error.mustBeSignedToMakeChanges': 'Anda mesti log masuk untuk membuat perubahan.',
    'error.mustBeSignedToTranslateBible': 'Anda mesti log masuk untuk menterjemahkan Alkitab.',
    checking: 'Semakan',
    'verseEditor.verseSuggestions': 'cadangan',
    verses: 'oleh Verse',
    words: 'by Word',
    'leftPanel.name': 'Teks Asal Alkitab',
    'rightPanel.name': 'Terjemahan Alkitab',
    'bottomPanel.name': 'Sumber untuk Alkitab',
    'syntax.adv': 'kata keterangan',
    'syntax.advp': 'frasa kata keterangan',
    'syntax.cl': 'Fasal',
    'syntax.conj': 'sempena',
    'syntax.cjp': 'sempena',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'objek',
    'syntax.io': 'Objek tidak langsung',
    'syntax.p': 'predikat',
    'syntax.pp': 'Frasa sendi nama',
    'syntax.s': 'tertakluk',
    'syntax.v': 'kata kerja',
    'syntax.vc': 'kata kerja Fasal',
    'syntax.adv.description':
      'Suai bahagian lain bersuara. Biasanya menyatakan cara, tempat, masa, kekerapan, tahap, tahap kepastian, dan lain-lain',
    'syntax.advp.description':
      'A beberapa kata ungkapan itu suai bahagian lain bersuara. Biasanya menyatakan cara, tempat, masa, kekerapan, tahap, tahap kepastian, dan lain-lain ...',
    'syntax.cl.description': 'Sebahagian daripada ayat yang mengandungi kata kerja.',
    'syntax.conj.description': 'Menyambung kata-kata, frasa dan klausa.',
    'syntax.cjp.description': 'Menyambung kata-kata, frasa dan klausa.',
    'syntax.np.description': 'Ungkapan beberapa kata yang melaksanakan fungsi kata nama.',
    'syntax.o.description': 'Entiti yang diambil tindakan oleh subjek.',
    'syntax.io.description': 'Yang ini adalah sukar untuk menjelaskan.',
    'syntax.p.description': 'Mengubah subjek. Biasanya dibintangi dengan kata kerja.',
    'syntax.pp.description': 'Yang ini adalah sukar untuk menjelaskan.',
    'syntax.s.description': 'Entiti yang mengawal kata kerja fasal.',
    'syntax.v.description': 'Menyampaikan tindakan.',
    'syntax.vc.description':
      'Ungkapan beberapa kata yang melaksanakan fungsi kata kerja (ini menyampaikan suatu tindakan).',
    'general.close': 'Tutup',
    'general.action': 'Tindakan',
    'general.select': 'Pilih ...',
    'general.back': 'Kembali',
  },
  my: {
    'languages.en': 'အင်္ဂလိပ်',
    'languages.am': 'ခြ',
    'languages.bn': 'ဘင်္ဂါလီဘာသာစကားကို',
    'languages.ceb': 'Cebuano အဘိဓါန်ဘာသာစကားများ',
    'languages.de': 'ဂျာမန်ဘာသာစကား',
    'languages.es-419': 'စပိန်ဘာသာစကား',
    'languages.fr': 'ပြင်သစ်ဘာသာစကားများ',
    'languages.gu': 'ဂူ',
    'languages.hi': 'ဟိန်ဒီ',
    'languages.hr': 'ခရိုအရှေဘာသာစကားများ',
    'languages.hu': 'ဟနျဂရေီဘာသာစကားများ',
    'languages.id': 'အင်ဒိုနီးရှားဘာသာစကားများ',
    'languages.km': 'ခမာ',
    'languages.kn': 'ကန်နာဒါ',
    'languages.ko': 'ကိုရီးယားဘာသာစကား',
    'languages.lo': 'လာအိုပြည်သူ့ဒီမိုကရက်တစ်ဘာသာစကားများ',
    'languages.ml': 'မလေးရာလ',
    'languages.mr': 'မာရသီ',
    'languages.ms': 'မလေးရှား',
    'languages.my': 'မြန်မာဘာသာစကားကို',
    'languages.ne': 'နီပေါဘာသာစကားများ',
    'languages.plt': 'အာလာဂါစီဘာသာစကားများ',
    'languages.pt-br': 'ပေါ်တူဂီ (ဘရာဇီး) ဘာသာစကားများ',
    'languages.ru': 'ရုရှားဘာသာစကားများ',
    'languages.sw': 'ဆွာဟီလီ',
    'languages.ta': 'တမီးဘာသာစကားများ',
    'languages.th': 'ထိုင်းဘာသာစကား',
    'languages.tl': 'အဘိဓါန် Tagalog-',
    'languages.te': 'Telegu ဘာသာစကားများ',
    'languages.vi': 'ဗီယက်နမ်ဘာသာစကားများ',
    'languages.zh-hans': 'တရုတ်ဘာသာစကားကို (မျက်မှောက်ရေးရာ)',
    'languages.zh-hant': 'တရုတ်ဘာသာစကားကို (ရိုးရာ)',
    'header.home': 'နေအိမ်',
    'header.help': 'ကူညီပါ',
    'header.project': 'စီမံကိန်း',
    'help.contactSupport': 'အကူအညီအတွက် help@youtranslate.bible မှာကျွန်တော်တို့ကိုအီးမေးလ်ပို့ပါ။',
    'home.ytbProvides': 'YouTranslate.Bible လွတ်လပ်စွာထောက်ပံ့:',
    'home.easyToUseTool': 'လွယ်ကူသော-to-အသုံးပြုမှုအွန်လိုင်းဘာသာပြန် tool ကို',
    'home.originalTextHelp': 'မူရင်းဘာသာစကားများကမျြးစာ၏စာသားကိုနားလည်ရန်အကူအညီ',
    'home.referencesAndResources': 'ကိုးကားစရာများနှင့်လေ့ကျင့်ရေးအရင်းအမြစ်များကို',
    'home.computerAssistance': 'ဘာသာပြန်များကူညီပေးဖို့ပြည်နယ်-Of-The-Art ကွန်ပျူတာနည်းပညာ',
    'profile.userProfile': 'အသုံးပြုသူပရိုဖိုင်',
    'profile.language': 'ဘာသာစကား',
    'profile.update': 'Update ကို',
    'usernav.profile': 'ပရိုဖိုင်းကို',
    'usernav.logout': 'logout',
    'usernav.logout.modalTitle': 'YouTranslate.Bible ၏ logout',
    'usernav.logout.modalText':
      'သင်လော့ဂ်အောက်ချင်သေချာပါသလား? အားလုံးမသိမ်းရသေးသောအလုပ်ဆုံးရှုံးခဲ့ရပါလိမ့်မည်။',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'ဖန်တီး',
    update: 'Update ကို',
    edit: 'Edit ကို',
    save: 'ကြော်ငြာကို Save လုပ်ရန်',
    add: 'add',
    'project.create.title': 'ကမျြးစာကိုဘာသာပြန်စာပေစီမံကိန်း',
    'project.create.list': 'Project မှစာရင်း',
    'project.list.deleteProject': 'စီမံကိန်းကိုဖျက်မလား?',
    'project.list.areYouSure': 'သငျသညျဤစီမံကိန်းကိုဖျက်ပစ်ချင်သင်သေချာပါသလား?',
    'project.list.workWillBeDeleted': 'အဆိုပါစီမံကိန်းနှင့်ဆက်စပ်မည်သည့်အလုပ်ကိုမှဖျက်ပစ်လိမ့်မည်။',
    'project.list.completion': 'ပြီးစီး:',
    'project.list.createdDate': 'Created နေ့စွဲ:',
    'project.list.beginningDate': 'နေ့စွဲကစပြီး:',
    'project.list.targetCompletionDate': 'ပစ်မှတ်ပြီးစီးနေ့စွဲ:',
    'project.list.description': 'ဖော်ပြချက်:',
    'project.edit': 'Edit ကိုစီမံကိန်း',
    'project.list.translateBible': 'ကမျြးစာကို Translate',
    'project.list.empty': 'သင့်ရဲ့ဘာသာပြန်စီမံကိန်းအတွက်စာရင်းဗလာဖြစ်နေသည်။',
    'project.list.myProjects': 'အကြှနျုပျ၏ကမျြးစာကိုဘာသာပြန်စာပေစီမံကိန်းများ',
    'project.list.newProject': 'နယူးစီမံကိန်း',
    'project.create.createNew': 'အသစ်တခုစီမံကိန်း Create',
    'project.namePlaceholder': 'စီမံကိန်း၏အမည်ထည့်ပါ',
    'project.descriptionPlaceholder': 'စီမံကိန်းကိုဖော်ပြချက် Enter',
    'project.name': 'စီမံကိန်းအမည်',
    'project.beginningDate': 'စတင်နေ့စွဲ',
    'project.targetCompletionDate': 'ပစ်မှတ်ပြီးစီးနေ့စွဲ',
    'project.description': 'ဖေါ်ပြချက်',
    'project.manage-users': 'ဘာသာစီမံခန့်ခွဲရန်',
    'project.new-user': 'နယူးဘာသာပြန်ဆိုသူ',
    'project.add-user': 'ဘာသာပြန်ဆိုသူ Add',
    'project.users': 'Project မှဘာသာပြန်များ',
    'project.read-only': 'သာ Read',
    'project.read&write': 'ဖတ်ရေး',
    'project.owner': 'ပိုင်ဆိုင်သူ',
    'project.translator': 'ဘာသာပြန်သူ',
    'project.message.no-such-user': 'အဘယ်သူမျှမထိုကဲ့သို့သောဘာသာပြန်ဆိုသူတည်ရှိ',
    'project.message.user-added': 'ဘာသာပြန်ဆိုသူအောင်မြင်စွာကဆက်ပြောသည်',
    'project.message.user-deleted': 'ဘာသာပြန်ဆိုသူဖယ်ရှားခဲ့သည်။',
    'project.message.only-owner-can-manage-users':
      'သာစီမံကိန်းကိုပိုင်ရှင်အသုံးပြုသူများကိုစီမံခန့်ခွဲနိုင်တယ်',
    'project.shared': 'ပူးပေါင်းစီမံကိန်းအား',
    'general.delete-user': 'delete ဘာသာပြန်ဆိုသူ',
    'general.edit-user': 'Edit ကိုအသုံးပြုသူ',
    'general.name': 'အမည်',
    'general.email': 'E-mail ကို',
    'general.permissions': 'permissions',
    'general.role': 'အခန်းက္ပ',
    signin: 'ဆိုင်းအင်လုပ်ခြင်း',
    'signin.emailPlaceholder': 'အီးမေးလိပ်စာ',
    'signin.passwordPlaceholder': 'Password ကို',
    'signin.forgotPassword': 'သင့်ရဲ့စကားဝှက်ကိုမေ့နေပါသလား?',
    'signin.rememberMe': 'ငါ့ကိုသတိရပါ',
    'signin.facebook': 'Facebook ကနဲ့ Sign',
    'signin.google': 'Google ဖြင့်မှတ်ပုံတင်ရန်',
    'signin.dontHaveAccount': 'အကောင့်တစ်ခုရှိသည်မဟုတ်လော',
    'signin.createOne': 'တဦးတည်း Create',
    'signup.join': 'YouTranslate.Bible join',
    'signup.createAccountPrompt': 'စတင်သင့်ရဲ့အခမဲ့အကောင့်ကိုဖန်တီးပါ။',
    'signup.createAccount': 'အကောင့်ပြုလုပ်ပါ',
    'signup.emailPlaceholder': 'အီးမေးလိပ်စာ',
    'signup.passwordPlaceholder': 'Password ကို',
    'translation.project': 'စီမံကိန်း:',
    'translation.backToProjectList': 'စီမံကိန်းကိုစာရင်းကိုပြန်သွားရန်',
    'translation.book': 'စာအုပ်:',
    'translation.chapter': 'အခန်းကြီး:',
    'translation.prompt':
      'သငျသညျဤအခနျးစွန့်ခွာချင်သင်သေချာပါသလား? အားလုံးမသိမ်းရသေးသောအလုပ်ဆုံးရှုံးခဲ့ရပါလိမ့်မည်။',
    'translation.prev': 'prev',
    'translation.next': 'နောက်တစ်ခု',
    'translation.previousChapter': 'ယခင်မျြးအခနျးကွီး',
    'translation.nextChapter': 'နောကျအခနျး',
    'navigator.book': 'စာအုပ်:',
    'navigator.chapter': 'အခန်းကြီး:',
    'navigator.oldTestament': 'ဓမ်မဟောငျးကမျြး',
    'navigator.newTestament': 'ဓမ္မသစ်ကျမ်း',
    'navigator.selectBook': 'စာအုပ်တစ်အုပ်ကိုရွေးချယ်ပါ',
    'navigator.search': 'ရှာဖှေ',
    'navigator.changeBook': 'ပြောင်းလဲမှုစာအုပ်',
    'navigator.changeChapter': 'ပြောင်းလဲမှုမျြးအခနျးကွီး',
    Genesis: 'ကမ်ဘာဦး',
    Exodus: 'ထှကျမွောကျရာ',
    Leviticus: 'ဝတ်ပြုရာကျမ်း',
    Numbers: 'တောလည်ရာ',
    Deuteronomy: 'တရားဟောရာ',
    Joshua: 'ယောရှုသည်',
    Judges: 'တရားသူကြီးမှတ်စာ',
    Ruth: 'ရုသသည်',
    '1 Samuel': '1 ရှမွေလသည်',
    '2 Samuel': '2 ရှမွေလက',
    '1 Kings': '1 ဓမ္မရာဇဝင်',
    '2 Kings': '2 ရာဇဝင်',
    '1 Chronicles': '1 ရာဇဝင်ချုပ်',
    '2 Chronicles': '2 ရာဇဝင်',
    Ezra: 'ဧဇရ',
    Nehemiah: 'နေဟမိ',
    Esther: 'ဧသတာ',
    Job: 'အလုပ်',
    Psalms: 'ဆာလံကမျြး',
    Proverbs: 'သုတ္တံ',
    Ecclesiastes: 'ဒေသနာ',
    'Song of Songs': 'Songs ရဲ့သီချင်း',
    Isaiah: 'ကျဟရှောယ',
    Jeremiah: 'ယေရမိက',
    Lamentations: 'မြည်တမ်းစကား',
    Ezekiel: 'ယေဇကျေလ',
    Daniel: 'ဒံယလေက',
    Hosea: 'ဟောရှေ',
    Joel: 'ယောလ',
    Amos: 'အာမုတ်',
    Obadiah: 'ဩဗဒိ',
    Jonah: 'ယောန',
    Micah: 'မိက္ခာ',
    Nahum: 'နာဟုံ',
    Habakkuk: 'ဟဗက်ကုတျ',
    Zephaniah: 'ဇေဖနိ',
    Haggai: 'ဟဂ်ဂဲ',
    Zechariah: 'ဇာခရိ',
    Malachi: 'မာလခိ',
    Matthew: 'မဿဲ',
    Mark: 'အမှတ်',
    Luke: 'လုကာ',
    John: 'ယောဟနျသ',
    Acts: 'တမန်တော်ဝတ္ထု',
    Romans: 'ရောမ',
    '1 Corinthians': '1 ကောရိန်သု',
    '2 Corinthians': '2 ကောရိန္သု',
    Galatians: 'ဂလာတိ',
    Ephesians: 'ဧဖက်',
    Philippians: 'ဖိလိပ္ပိ',
    Colossians: 'ကောလောသဲ',
    '1 Thessalonians': '1 သက်သာလောနိတ်',
    '2 Thessalonians': '2 သက်သာလောနိတ်',
    '1 Timothy': '1 တိမောသေ',
    '2 Timothy': '2 တိမောသေ',
    Titus: 'တိတု',
    Philemon: 'ဖိလမေုနျ',
    Hebrews: 'ဟေဗြဲ',
    James: 'ဂျိမ်း',
    '1 Peter': '1 ပေတရု',
    '2 Peter': '2 ပေတရု',
    '1 John': '1 ယော',
    '2 John': '2 ယော',
    '3 John': '3 ယောဟနျ',
    Jude: 'ယုဒ',
    Revelation: 'ဖွင့်ပြချက်',
    'resources.prompt':
      'သငျသညျဘာသာပြန်ဆိုထားပါသည်ကျမ်းပိုဒ်သို့အပိုဆောင်းထိုးထွင်းသိမြင်ဘို့ကျမ်းစာအရင်းအမြစ်တွေကိုထည့်ပါ။',
    'resources.add': 'တစ်ဦးကမျြးစာအရင်းအမြစ် Add',
    Manuscript: 'လက်ရေးမူများမှာတွေ့နိုင်ပါတယ်',
    Morphology: 'shape သုက်ပိုးပုံသဏ္ဌာန်',
    Lexicon: 'အဘိဓာန်',
    'Syntactic Tree': 'Syntactic သစ်ပင်',
    'Go to project list': 'စီမံကိန်းအတွက်စာရင်းသို့သွားရန်',
    'Project not found': 'Project မှတွေ့ရှိခဲ့သို့မဟုတ်မလုံလောက်ခွင့်ပြုချက်မရ။',
    'error.referenceDoesNotExistForResource':
      'ဤသည်ကမျြးစာဘာသာရွေးချယ်ထားကျမ်းပိုဒ်များအတွက်အကြောင်းအရာဆံ့မခံပါဘူး။',
    'references.addAnother': 'အခြားဘာသာပြန်ချက်ထည့်ပါ',
    'references.referencePrompt': 'အပိုဆောင်းထိုးထွင်းသိမြင်အခြားဘာသာပြန်ကျမ်းချက်ထည့်ပါ။',
    'references.add': 'ကမျြးစာဘာသာပြန်ချက်ထည့်ပါ',
    'translation.complete': 'ဘာသာပြန်ချက်ပြည့်စုံ',
    Alignment: 'alignment',
    Share: 'ဝေစု',
    'Your Translation': 'သင့်ရဲ့ဘာသာပြန်စာပေ',
    'Enter your translation': 'သင်၏ဘာသာပြန် Enter',
    OK: 'ရလား',
    'error.verseTranslationEmpty': 'သငျသညျအချည်းနှီးဘာသာပြန်အကြောင်းအရာမချန်မထားနိုင်ပါ။',
    'translation.hide.completed': 'ဖျောက်ပြီးစီး',
    alignment: 'alignment',
    'alignment.verify': 'Verify',
    'alignment.backToEditor': 'Editor ကိုပြန်သွားရန်',
    'alignment.error':
      'တစ်စုံတစ်ခုမှားယွင်းခဲ့သည်။ ထိုပြဿနာကို အကယ်. လျှင်, YouTranslate.Bible အဖွဲ့ဆက်သွယ်နိုင်ပါသည်။',
    'alignment.add': 'Link ကို Add',
    'alignment.delete': 'Link ကို Remove',
    'alignment.clear': 'Clear ကိုရွေးချယ်ရေး',
    'alignment.reverse': 'ပြောင်းပြန်ပြရန်',
    'alignment.removeOrClear':
      'ရွေးချယ်ထားသော link ကိုသို့မဟုတ်ရှင်းရှင်းလင်းလင်းရွေးချယ်ရေးဖယ်ရှားပါ။',
    'alignment.addOrRemove': 'သစ်တစ်ခု link ကို Create တစ်ခုသို့မဟုတ်ယခင်ဖျက်ပါ။',
    'alignment.selectAndAdd': 'လင့်ခ်မှအစိတ်အပိုင်းများနဲ့သက်ဆိုင်တဲ့ရွေးချယ်ပါ။',
    'error.mustBeSignedToMakeChanges': 'သငျသညျလုပ်အပြောင်းအလဲများအတွက်လက်မှတ်ရေးထိုးရမည်ဖြစ်သည်။',
    'error.mustBeSignedToTranslateBible':
      'သငျသညျကမျြးစာကိုဘာသာပြန်ဆိုအတွက်လက်မှတ်ရေးထိုးရမည်ဖြစ်သည်။',
    checking: 'စစ်ဆေးခြင်းကို',
    'verseEditor.verseSuggestions': 'အကြံပြုချက်များ',
    verses: 'အခနျးငယျအားဖြင့်',
    words: 'သမ်မာကမျြးခွငျးအားဖွငျ့',
    'leftPanel.name': 'သမ္မာကျမ်းစာကိုမူရင်းစာသား',
    'rightPanel.name': 'သမ္မာကျမ်းစာကိုဘာသာပြန်ချက်များ',
    'bottomPanel.name': 'ကမျြးစာကိုများအတွက်အရင်းအမြစ်များ',
    'syntax.adv': 'ကြိယာဝိသေသန',
    'syntax.advp': 'adverb Phrase',
    'syntax.cl': 'အချက်',
    'syntax.conj': 'တွဲဖက်',
    'syntax.cjp': 'တွဲဖက်',
    'syntax.np': 'Noun Phrase',
    'syntax.o': 'အရာဝတ္တု',
    'syntax.io': 'သွယ်ဝိုက်အရာဝတ္ထု',
    'syntax.p': 'Predicate',
    'syntax.pp': 'preposition Phrase',
    'syntax.s': 'ဘာသာရပ်',
    'syntax.v': 'ကြိယာ',
    'syntax.vc': 'ကြိယာအပိုဒ်',
    'syntax.adv.description':
      'မိန့်ခွန်း၏အခြားအစိတ်အပိုင်းတစ်ရပ်ပြုပြင်မှု။ ပုံမှန်အားဖြင့်စသည်တို့ကိုထုံးစံ, နေရာ, အချိန်, အကြိမ်ရေ, ဒီဂရီ, ဧကန်အမှန်ဖြစ်၏အဆင့်ဖော်ပြ',
    'syntax.advp.description':
      'မိန့်ခွန်း၏အခြားအစိတ်အပိုင်းတစ်ရပ်ကိုအထူးပြုတဲ့ Multi-စကားလုံးစကားရပ်။ ပုံမှန်အားဖြင့်ထုံးစံ, နေရာ, အချိန်, အကြိမ်ရေ, ဒီဂရီ, ဧကန်အမှန်ဖြစ်၏အဆငျ့, စသည်တို့ကိုဖော်ပြ ...',
    'syntax.cl.description': 'တစ်ဦးကြိယာပါရှိသည်တဲ့ဝါကျတစ်အစိတ်အပိုင်း။',
    'syntax.conj.description': 'စကားလုံးများ, စာပိုဒ်တိုများ, နှင့်ဝါကျကိုဆက်သွယ်။',
    'syntax.cjp.description': 'စကားလုံးများ, စာပိုဒ်တိုများ, နှင့်ဝါကျကိုဆက်သွယ်။',
    'syntax.np.description': 'နာမ်၏ function ကိုလုပ်ဆောင်သော Multi-စကားလုံးစကားရပ်။',
    'syntax.o.description': 'အကြောင်းအရာအားဖြင့်အပေါ်သို့ပြုမူသော entity ။',
    'syntax.io.description': 'ဒီတစ်ခုရှင်းပြဖို့ခဲယဉ်းသည်။',
    'syntax.p.description': 'ဘာသာရပ်အထူးပြု။ အများအားဖြင့်တစ်ဦးကြိယာနှင့်အတူသရုပ်ဆောင်။',
    'syntax.pp.description': 'ဒီတစ်ခုရှင်းပြဖို့ခဲယဉ်းသည်။',
    'syntax.s.description': 'အဆိုပါအပိုဒ်၏ကြိယာကိုထိန်းချုပ်ထားသည့် entity ။',
    'syntax.v.description': 'လုပ်ဆောင်ချက်တစ်ခုကိုဖော်ပြတယ်။',
    'syntax.vc.description':
      'တစ်ဦးကြိယာ၏စွမ်းဆောင်ရည်ကို function ကို (လုပ်ဆောင်ချက်တစ်ခုသဘောဆောငျ) ထိုတစ်ဦးက Multi-စကားလုံးစကားရပ်။',
    'general.close': 'ပိတ်',
    'general.action': 'လှုပ်ရှားမှု',
    'general.select': 'ရွေးရန် ...',
    'general.back': 'ပြန်.',
  },
  ne: {
    'languages.en': 'अंग्रेजी',
    'languages.am': 'अम्हारिक',
    'languages.bn': 'बंगाली भाषा',
    'languages.ceb': 'सेबुआनो भाषा',
    'languages.de': 'जर्मन भाषा',
    'languages.es-419': 'स्पनिश भाषा',
    'languages.fr': 'फ्रेन्च भाषा',
    'languages.gu': 'गुजराती',
    'languages.hi': 'हिन्दी',
    'languages.hr': 'क्रोएसियाली भाषा',
    'languages.hu': 'हंगेरी भाषा',
    'languages.id': 'इन्डोनेसियाली भाषा',
    'languages.km': 'खमेर',
    'languages.kn': 'कन्नड',
    'languages.ko': 'कोरियन भाषा',
    'languages.lo': 'लाओसियन भाषा',
    'languages.ml': 'मलयालम',
    'languages.mr': 'मराठी',
    'languages.ms': 'मलेशियन',
    'languages.my': 'बर्मेली भाषा',
    'languages.ne': 'नेपाली भाषा',
    'languages.plt': 'मालागासी भाषा',
    'languages.pt-br': 'पोर्चुगिज (ब्राजिल) भाषा',
    'languages.ru': 'रूसी भाषा',
    'languages.sw': 'स्वाहिली',
    'languages.ta': 'तामिल भाषा',
    'languages.th': 'थाई भाषा',
    'languages.tl': 'टागालोग',
    'languages.te': 'Telegu भाषा',
    'languages.vi': 'भियतनामी भाषा',
    'languages.zh-hans': 'चिनियाँ भाषा (सरलीकृत)',
    'languages.zh-hant': 'चिनियाँ भाषा (परम्परागत)',
    'header.home': 'घर',
    'header.help': 'सहायता',
    'header.project': 'परियोजना',
    'help.contactSupport': 'सहायताको लागि help@youtranslate.bible हामीलाई इमेल गर्नुहोस्।',
    'home.ytbProvides': 'YouTranslate.Bible खुलेर प्रदान गर्दछ:',
    'home.easyToUseTool': 'एक-गर्न-सजिलो प्रयोग अनलाइन अनुवाद उपकरण',
    'home.originalTextHelp': 'यसको मूल भाषामा बाइबल पाठ बुझ्न मद्दत',
    'home.referencesAndResources': 'सन्दर्भ र प्रशिक्षण स्रोतहरू',
    'home.computerAssistance': 'राज्य-को-को-कला कम्प्युटर प्रविधिको अनुवादकहरू सहयोग गर्न',
    'profile.userProfile': 'प्रयोगकर्ता प्रोफाइल',
    'profile.language': 'भाषा',
    'profile.update': 'अद्यावधिक',
    'usernav.profile': 'प्रोफाइल',
    'usernav.logout': 'बाहिर निस्कनु',
    'usernav.logout.modalTitle': 'YouTranslate.Bible को लगआउट',
    'usernav.logout.modalText':
      'तपाईं लग आउट गर्न चाहनुहुन्छ? सबै सुरक्षित नगरिएका काम नष्ट हुनेछन्।',
    cancel: 'रद्द',
    delete: 'मेटाउन',
    create: 'सिर्जना',
    update: 'अद्यावधिक',
    edit: 'सम्पादन',
    save: 'सेव',
    add: 'थप',
    'project.create.title': 'बाइबल अनुवाद परियोजना',
    'project.create.list': 'परियोजना सूची',
    'project.list.deleteProject': 'परियोजना मेटाउने?',
    'project.list.areYouSure': 'तपाईं यस परियोजना मेट्न निश्चित हुनुहुन्छ?',
    'project.list.workWillBeDeleted': 'परियोजना र सम्बन्धित कुनै पनि काम मेटिनेछ।',
    'project.list.completion': 'पूरा:',
    'project.list.createdDate': 'सिर्जना तिथि:',
    'project.list.beginningDate': 'सुरु मिति:',
    'project.list.targetCompletionDate': 'लक्ष्य सकिने मिति:',
    'project.list.description': 'विवरण:',
    'project.edit': 'परियोजना सम्पादन',
    'project.list.translateBible': 'बाइबल अनुवाद',
    'project.list.empty': 'तपाईंको अनुवाद परियोजना सूची खाली छ।',
    'project.list.myProjects': 'मेरो बाइबल अनुवाद परियोजनाहरु',
    'project.list.newProject': 'नयाँ परियोजना',
    'project.create.createNew': 'नयाँ परियोजना सिर्जना',
    'project.namePlaceholder': 'परियोजना नाम प्रविष्ट गर्नुहोस्',
    'project.descriptionPlaceholder': 'परियोजना विवरण प्रविष्ट गर्नुहोस्',
    'project.name': 'परियोजनाको नाम',
    'project.beginningDate': 'सुरु तिथि',
    'project.targetCompletionDate': 'लक्ष्य सकिने मिति',
    'project.description': 'विवरण',
    'project.manage-users': 'अनुवादक व्यवस्थापन',
    'project.new-user': 'नयाँ अनुवादक',
    'project.add-user': 'add अनुवादक',
    'project.users': 'परियोजना अनुवादकहरू',
    'project.read-only': 'पढ्न मात्र मिल्ने',
    'project.read&write': 'पढ्ने र लेख्ने',
    'project.owner': 'मालिक',
    'project.translator': 'अनुवादक',
    'project.message.no-such-user': 'यस्तो कुनै अनुवादक अवस्थित',
    'project.message.user-added': 'अनुवादक सफलतापूर्वक थपियो',
    'project.message.user-deleted': 'अनुवादक हटाइयो।',
    'project.message.only-owner-can-manage-users':
      'परियोजना को मालिक मात्र प्रयोगकर्ता व्यवस्थापन गर्न सक्छ',
    'project.shared': 'सहयोगी परियोजना',
    'general.delete-user': 'मेटाउने अनुवादक',
    'general.edit-user': 'प्रयोगकर्ता सम्पादन',
    'general.name': 'नाम',
    'general.email': 'ई-मेल',
    'general.permissions': 'अनुमति',
    'general.role': 'भूमिका',
    signin: 'साइन इन',
    'signin.emailPlaceholder': 'इ - मेल ठेगाना',
    'signin.passwordPlaceholder': 'पासवर्ड',
    'signin.forgotPassword': 'तपाईंको पासवर्ड बिर्सनु भयो?',
    'signin.rememberMe': 'मलाई सम्झना',
    'signin.facebook': 'फेसबुक साइन इन',
    'signin.google': 'Google संग साइन',
    'signin.dontHaveAccount': 'खाता छैन?',
    'signin.createOne': 'सिर्जना',
    'signup.join': 'YouTranslate.Bible सामेल',
    'signup.createAccountPrompt': 'सुरु गर्न तपाईँको निःशुल्क खाता सिर्जना गर्नुहोस्।',
    'signup.createAccount': 'खाता सिर्जना गर्नुहोस्',
    'signup.emailPlaceholder': 'इ - मेल ठेगाना',
    'signup.passwordPlaceholder': 'पासवर्ड',
    'translation.project': 'परियोजना:',
    'translation.backToProjectList': 'सूची परियोजना फिर्ता',
    'translation.book': 'पुस्तक:',
    'translation.chapter': 'अध्याय:',
    'translation.prompt':
      'तपाईं यस अध्यायमा छोड्न चाहनुहुन्छ निश्चित हुनुहुन्छ? सबै सुरक्षित नगरिएका काम नष्ट हुनेछन्।',
    'translation.prev': 'पछिल्लो',
    'translation.next': 'अर्को',
    'translation.previousChapter': 'अघिल्लो अध्याय',
    'translation.nextChapter': 'अर्को अध्याय',
    'navigator.book': 'पुस्तक:',
    'navigator.chapter': 'अध्याय:',
    'navigator.oldTestament': 'पुरानो नियमका',
    'navigator.newTestament': 'नयाँ नियमको',
    'navigator.selectBook': 'पुस्तक चयन',
    'navigator.search': 'खोज',
    'navigator.changeBook': 'पुस्तक परिवर्तन',
    'navigator.changeChapter': 'अध्याय परिवर्तन',
    Genesis: 'उत्पत्ति',
    Exodus: 'प्रस्थान',
    Leviticus: 'लेवी',
    Numbers: 'संख्या',
    Deuteronomy: 'व्यवस्था',
    Joshua: 'यहोशू',
    Judges: 'न्यायाधीशहरूले',
    Ruth: 'रूथ',
    '1 Samuel': '1 शमूएल',
    '2 Samuel': '2 शमूएल',
    '1 Kings': '1 राजा',
    '2 Kings': '2 राजा',
    '1 Chronicles': '1 इतिहास',
    '2 Chronicles': '2 इतिहास',
    Ezra: 'एज्रा',
    Nehemiah: 'नहेम्याह',
    Esther: 'एस्तर',
    Job: 'काम',
    Psalms: 'भजनमा',
    Proverbs: 'हितोपदेश',
    Ecclesiastes: 'उपदेशक',
    'Song of Songs': 'गीतहरूको गीत',
    Isaiah: 'यशैया',
    Jeremiah: 'यर्मिया',
    Lamentations: 'विलाप',
    Ezekiel: 'इजकिएल',
    Daniel: 'दानियल',
    Hosea: 'होशे',
    Joel: 'योएल',
    Amos: 'आमोस',
    Obadiah: 'ओबद्याले',
    Jonah: 'योना',
    Micah: 'मीका',
    Nahum: 'नहूम',
    Habakkuk: 'हबकूक',
    Zephaniah: 'सपन्याह',
    Haggai: 'हाग्गै',
    Zechariah: 'जकरिया',
    Malachi: 'मलाकी',
    Matthew: 'मत्ती',
    Mark: 'मार्क',
    Luke: 'लूका',
    John: 'जन',
    Acts: 'प्रेरित',
    Romans: 'रोमी',
    '1 Corinthians': '1 कोरिन्थी',
    '2 Corinthians': '2 कोरिन्थी',
    Galatians: 'गलाती',
    Ephesians: 'एफिसी',
    Philippians: 'फिलिप्पी',
    Colossians: 'कलस्सी',
    '1 Thessalonians': '1 थिस्सलोनिकी',
    '2 Thessalonians': '2 थिस्सलोनिकी',
    '1 Timothy': '1 तिमोथी',
    '2 Timothy': '2 तिमोथी',
    Titus: 'तीतस',
    Philemon: 'फिलेमोन',
    Hebrews: 'हिब्रू',
    James: 'जेम्स',
    '1 Peter': '1 पत्रुस',
    '2 Peter': '2 पत्रुस',
    '1 John': '1 यूहन्ना',
    '2 John': '2 यूहन्ना',
    '3 John': '3 यूहन्ना',
    Jude: 'यहूदा',
    Revelation: 'प्रकाश',
    'resources.prompt':
      'तपाईं अनुवाद गर्दै खण्डमा मा थप अन्तरदृष्टि लागि बाइबलीय स्रोतहरू थप्नुहोस्।',
    'resources.add': 'बाइबलीय स्रोत थप्न',
    Manuscript: 'पांडुलिपि',
    Morphology: 'आकृति',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic ट्री',
    'Go to project list': 'सूची परियोजना जाने',
    'Project not found': 'परियोजना फेला परेन वा अपर्याप्त अनुमतिहरू।',
    'error.referenceDoesNotExistForResource':
      'यस बाइबल अनुवाद चयन खण्डमा लागि सामग्री समावेश गर्दैन।',
    'references.addAnother': 'अर्को अनुवाद थप्न',
    'references.referencePrompt': 'थप अन्तरदृष्टि लागि बाइबल अन्य अनुवाद थप्नुहोस्।',
    'references.add': 'बाइबल अनुवाद थप्न',
    'translation.complete': 'अनुवाद पूरा',
    Alignment: 'पङ्क्तिबद्ध',
    Share: 'शेयर',
    'Your Translation': 'तपाईंको अनुवाद',
    'Enter your translation': 'तपाईंको अनुवाद प्रविष्ट',
    OK: 'ठिक छ',
    'error.verseTranslationEmpty': 'तपाईं अनुवाद सामग्री खाली छोड्न सक्नुहुन्छ।',
    'translation.hide.completed': 'पूरा लुकाउनुहोस्',
    alignment: 'पङ्क्तिबद्ध',
    'alignment.verify': 'प्रमाणित',
    'alignment.backToEditor': 'सम्पादक फिर्ता',
    'alignment.error':
      'केहि गलत भयो। समस्या यथावत् छ भने, YouTranslate.Bible टोली सम्पर्क गर्नुहोस्।',
    'alignment.add': 'लिंक थप्न',
    'alignment.delete': 'लिंक हटाउन',
    'alignment.clear': 'चयन स्पष्ट',
    'alignment.reverse': 'उल्टो प्रदर्शन',
    'alignment.removeOrClear': 'चयन गरिएको लिङ्क वा स्पष्ट चयन हटाउनुहोस्।',
    'alignment.addOrRemove': 'नयाँ लिङ्क सिर्जना गर्न वा अघिल्लो एक मेट्नुहोस्।',
    'alignment.selectAndAdd': 'लिंक गर्न खण्डहरूमा अनुरूप चयन गर्नुहोस्।',
    'error.mustBeSignedToMakeChanges': 'तपाईंले परिवर्तनहरू मा हस्ताक्षर हुनुपर्छ।',
    'error.mustBeSignedToTranslateBible': 'के तपाईं बाइबल अनुवाद मा हस्ताक्षर हुनुपर्छ।',
    checking: 'जाँच',
    'verseEditor.verseSuggestions': 'सुझाव',
    verses: 'पद द्वारा',
    words: 'शब्द द्वारा',
    'leftPanel.name': 'बाइबल को मूल पाठ',
    'rightPanel.name': 'बाइबल अनुवाद',
    'bottomPanel.name': 'बाइबल लागि संसाधन',
    'syntax.adv': 'adverb',
    'syntax.advp': 'adverb पदावली',
    'syntax.cl': 'खण्ड',
    'syntax.conj': 'संयोजनमा',
    'syntax.cjp': 'संयोजनमा',
    'syntax.np': 'संज्ञा वाक्यांश',
    'syntax.o': 'वस्तु',
    'syntax.io': 'अप्रत्यक्ष वस्तु',
    'syntax.p': 'Predicate',
    'syntax.pp': 'Prepositional पदावली',
    'syntax.s': 'विषय',
    'syntax.v': 'क्रिया',
    'syntax.vc': 'क्रिया खण्ड',
    'syntax.adv.description':
      'Modifies बोली अर्को भाग। सामान्यतया तरिका, स्थान, समय, आवृत्ति, डिग्री, निश्चय को स्तर, आदि व्यक्त',
    'syntax.advp.description':
      'बहु-शब्द अभिव्यक्ति बोलीको कि modifies अर्को भाग। सामान्यतया तरिका, स्थान, समय, आवृत्ति, डिग्री, निश्चय को स्तर, आदि व्यक्त ...',
    'syntax.cl.description': 'एक क्रिया समावेश गर्ने एक वाक्यको एक भाग।',
    'syntax.conj.description': 'शब्द, वाक्यांश र क्लाज जडान।',
    'syntax.cjp.description': 'शब्द, वाक्यांश र क्लाज जडान।',
    'syntax.np.description': 'बहु-शब्द अभिव्यक्ति एक संज्ञा को समारोह कार्य छ।',
    'syntax.o.description': 'विषय द्वारा मा काम छ कि संस्थासँग।',
    'syntax.io.description': 'यो एक व्याख्या गर्न कठिन छ।',
    'syntax.p.description': 'विषय Modifies। सामान्यतया क्रिया संग ताराहरु।',
    'syntax.pp.description': 'यो एक व्याख्या गर्न कठिन छ।',
    'syntax.s.description': 'संस्थासँग को दफा को क्रिया नियन्त्रण जो।',
    'syntax.v.description': 'कार्य Conveys।',
    'syntax.vc.description': 'बहु-शब्द अभिव्यक्ति भनेर कार्य एक क्रिया को समारोह (conveys कार्य)।',
    'general.close': 'बन्द',
    'general.action': 'कार्य',
    'general.select': 'चयन गर्नुहोस् ...',
    'general.back': 'फिर्ता',
  },
  plt: {
    'languages.en': 'anglisy',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Language',
    'languages.ceb': 'Cebuano Language',
    'languages.de': 'Anarana Language',
    'languages.es-419': 'Espaniola Language',
    'languages.fr': 'French Language',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Kroaty Language',
    'languages.hu': 'Fiteny hongariana',
    'languages.id': 'Fiteny indonezianina',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Koreana Language',
    'languages.lo': 'Laosianina Language',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Maleziana',
    'languages.my': 'Birmana Language',
    'languages.ne': 'Nepali Language',
    'languages.plt': 'Malagasy Fiteny',
    'languages.pt-br': 'Anarana (Brazil) Language',
    'languages.ru': 'Rosiana Language',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Language',
    'languages.th': 'Thai Language',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Language',
    'languages.vi': 'vietnamiana Language',
    'languages.zh-hans': 'Fiteny sinoa (Simplified)',
    'languages.zh-hant': 'Fiteny sinoa (Nentim-paharazana)',
    'header.home': 'Home',
    'header.help': 'Vonjeo',
    'header.project': 'Project',
    'help.contactSupport': 'Mba mailaka antsika help@youtranslate.bible fanampiana.',
    'home.ytbProvides': 'Manome malalaka YouTranslate.Bible:',
    'home.easyToUseTool': "Mora-to-fampiasana fitaovana fandikan-teny amin'ny aterineto",
    'home.originalTextHelp':
      "Ampio hahatakatra ny soratra ao amin'ny Baiboly amin'ny fiteny tany am-boalohany",
    'home.referencesAndResources': 'Reference sy ny fampiofanana loharanon-karena',
    'home.computerAssistance':
      'State-of-the-kanto solosaina teknolojia mba hanampiana ny mpandika teny',
    'profile.userProfile': 'User Profile',
    'profile.language': 'fiteny',
    'profile.update': 'Update',
    'usernav.profile': 'Profile',
    'usernav.logout': 'Miala',
    'usernav.logout.modalTitle': 'Logout ny YouTranslate.Bible',
    'usernav.logout.modalText':
      'Azonao antoka tianao Logout? Tsy voavonjy rehetra dia ho very asa.',
    cancel: 'hanafoana',
    delete: 'Mamafa',
    create: 'mamorona',
    update: 'Update',
    edit: 'Edit',
    save: 'Save',
    add: 'Add',
    'project.create.title': 'Malagasy Bible Project',
    'project.create.list': 'Project list',
    'project.list.deleteProject': 'Fafao Project?',
    'project.list.areYouSure': 'Siora ianao fa te-hamafa ity tetikasa ity?',
    'project.list.workWillBeDeleted': 'Ny tetikasa sy ny asa rehetra mifandray dia ho voafafa.',
    'project.list.completion': 'vita:',
    'project.list.createdDate': 'Noforonina Date:',
    'project.list.beginningDate': 'Nanomboka Date:',
    'project.list.targetCompletionDate': 'Target Mahafeno Date:',
    'project.list.description': 'Description:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Zavatra Bible',
    'project.list.empty': 'Ny fandikan-teny tetikasa lisitra dia foana tsy mitondra fanatitra.',
    'project.list.myProjects': 'Ny Bible Malagasy Projects',
    'project.list.newProject': 'New Project',
    'project.create.createNew': 'Mamorona vaovao Project',
    'project.namePlaceholder': 'Tetikasa Ampidiro anarana',
    'project.descriptionPlaceholder': 'Tetikasa Ampidiro Famaritana',
    'project.name': 'Project anarana',
    'project.beginningDate': 'Nanomboka Date',
    'project.targetCompletionDate': 'Target Mahafeno Date',
    'project.description': 'Description',
    'project.manage-users': 'mitantana Translators',
    'project.new-user': 'New mpandika teny',
    'project.add-user': 'Add mpandika teny',
    'project.users': 'Project mpandika teny',
    'project.read-only': 'Vakio ihany',
    'project.read&write': 'Vakio & Soraty',
    'project.owner': 'Owner',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'Tsy misy toy izany misy mpandika teny',
    'project.message.user-added': 'Translator nanampy soa aman-tsara',
    'project.message.user-deleted': 'Translator nesorina.',
    'project.message.only-owner-can-manage-users':
      "Ihany no tompon'ny tetikasa afaka mitantana mpampiasa",
    'project.shared': 'fiaraha tetikasa',
    'general.delete-user': 'Mamafa mpandika teny',
    'general.edit-user': 'Hanova ny mpampiasa',
    'general.name': 'Anarana',
    'general.email': 'E-mail',
    'general.permissions': 'Permissions',
    'general.role': 'anjara asa',
    signin: 'Hiditra',
    'signin.emailPlaceholder': 'Adiresy mailaka',
    'signin.passwordPlaceholder': 'Password',
    'signin.forgotPassword': 'Hadinonao ny tenimiafinao?',
    'signin.rememberMe': 'Tsarovy aho',
    'signin.facebook': "Midira amin'ny Facebook",
    'signin.google': "Midira amin'ny Google",
    'signin.dontHaveAccount': 'Aza manana kaonty?',
    'signin.createOne': 'hamorona iray',
    'signup.join': 'Join YouTranslate.Bible',
    'signup.createAccountPrompt': 'Mamorona kaonty hanombohana maimaim-poana.',
    'signup.createAccount': 'Hamorona kaonty',
    'signup.emailPlaceholder': 'Adiresy mailaka',
    'signup.passwordPlaceholder': 'Password',
    'translation.project': 'TETIKASA:',
    'translation.backToProjectList': "Miverina amin'ny tetikasa lisitra",
    'translation.book': 'Book:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Marina ve ianao te-hiala ity toko ity? Tsy voavonjy rehetra dia ho very asa.',
    'translation.prev': 'Prev',
    'translation.next': 'Manaraka',
    'translation.previousChapter': 'Previous toko',
    'translation.nextChapter': 'Next toko',
    'navigator.book': 'Book:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Old Testament',
    'navigator.newTestament': 'New Testament',
    'navigator.selectBook': 'Select boky',
    'navigator.search': 'Search',
    'navigator.changeBook': 'Change boky',
    'navigator.changeChapter': 'Change toko',
    Genesis: 'Genesis',
    Exodus: 'fifindra',
    Leviticus: 'Levitikosy',
    Numbers: 'Numbers',
    Deuteronomy: 'Deuteronomy',
    Joshua: 'Joshua',
    Judges: 'Mpitsara',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Mpanjaka',
    '2 Kings': '2 Mpanjaka',
    '1 Chronicles': '1 Tantara',
    '2 Chronicles': '2 Tantara',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Psalms',
    Proverbs: 'Ohabolana',
    Ecclesiastes: 'Mpitoriteny',
    'Song of Songs': "Tonon-kiran'i Solomona",
    Isaiah: 'Isaiah',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Fitomaniana',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadia',
    Jonah: 'Jona',
    Micah: 'Mika',
    Nahum: 'Nahum',
    Habakkuk: 'Habakoka',
    Zephaniah: 'Zefania',
    Haggai: 'Hagay',
    Zechariah: 'Zakaria',
    Malachi: 'Malakia',
    Matthew: 'Matthew',
    Mark: 'marika',
    Luke: 'Luke',
    John: 'John',
    Acts: "Asan'ny Apostoly",
    Romans: 'Romana',
    '1 Corinthians': '1 Korintiana',
    '2 Corinthians': '2 Korintiana',
    Galatians: 'Galatianina',
    Ephesians: 'Efesianina',
    Philippians: 'Filipianina',
    Colossians: 'Kolosiana',
    '1 Thessalonians': '1 Tesalonianina',
    '2 Thessalonians': '2 Tesalonianina',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebrews',
    James: 'Malagasy',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 Jôhany',
    Jude: 'Joda',
    Revelation: 'Revelation',
    'resources.prompt':
      'Add Baiboly loharanon-karena fanampiny hahatakatra ilay andalana ianao mandika.',
    'resources.add': 'Hanampy ara-Baiboly loharano',
    Manuscript: 'Sora-tanana',
    Morphology: 'Morphologie',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Syntactic Tree',
    'Go to project list': "Mandehana any amin'ny tetikasa lisitra",
    'Project not found': "Project tsy hita na tsy fahampian'ny alalana.",
    'error.referenceDoesNotExistForResource':
      "Fandikan-teny ao amin'ny Baiboly dia tsy ahitana afa-po ny andalana voafantina.",
    'references.addAnother': 'Add hafa fandikan-teny',
    'references.referencePrompt':
      "Add fandikan-tenin'ny Baiboly hafa ho an'ny lalin-tsaina fanampiny.",
    'references.add': 'Add fandikan-teny ara-baiboly',
    'translation.complete': 'Translation tanteraka',
    Alignment: 'Fanitsiana',
    Share: 'Share',
    'Your Translation': 'ny Translation',
    'Enter your translation': 'Ampidiro ny fandikan-teny',
    OK: 'ok',
    'error.verseTranslationEmpty': 'Tsy afaka mandao ny fandikan-teny afa-po foana.',
    'translation.hide.completed': 'afeno vita',
    alignment: 'Fanitsiana',
    'alignment.verify': 'manamarina',
    'alignment.backToEditor': 'Back to Editor',
    'alignment.error':
      'Nisy zavatra tsy nety. Raha mbola mitohy ny olana, dia mifandraisa ny YouTranslate.Bible ekipa.',
    'alignment.add': 'Add Link',
    'alignment.delete': 'Esory Link',
    'alignment.clear': 'Clear Selection',
    'alignment.reverse': 'mifanohitra Display',
    'alignment.removeOrClear': 'Esory ny rohy na mazava voafantina fifantenana.',
    'alignment.addOrRemove': 'Mamorona vaovao mamafa rohy na ny teo aloha indray.',
    'alignment.selectAndAdd': 'Mifidiana mifanaraka fizarana ny rohy.',
    'error.mustBeSignedToMakeChanges': 'Tsy maintsy ho nanao sonia hanao fanovana.',
    'error.mustBeSignedToTranslateBible': 'Tsy maintsy ho nanao sonia tao mba handika ny Baiboly.',
    checking: 'fanamarinana',
    'verseEditor.verseSuggestions': 'Soso-kevitra',
    verses: 'By Verse',
    words: 'By Teny',
    'leftPanel.name': 'Original lahatsoratry ny Baiboly',
    'rightPanel.name': "Zavatra ao Amin'ny Baiboly",
    'bottomPanel.name': 'Resources ny Baiboly',
    'syntax.adv': 'Anarana iombonana',
    'syntax.advp': 'Anarana Phrase',
    'syntax.cl': 'fepetra',
    'syntax.conj': 'Anarana iombonana',
    'syntax.cjp': 'Anarana iombonana',
    'syntax.np': 'Anarana iombonana Phrase',
    'syntax.o': 'zavatra',
    'syntax.io': 'mivantana Object',
    'syntax.p': 'enti-',
    'syntax.pp': 'Litera Phrase',
    'syntax.s': 'Subject',
    'syntax.v': "Endriky ny matoanteny amin'ny",
    'syntax.vc': "Endriky ny matoanteny amin'ny fepetra",
    'syntax.adv.description':
      'Manova faritra hafa ny teny. Matetika maneho izany koa, toerana, ny fotoana, matetika, mari-pahaizana, fari-marina, sns',
    'syntax.advp.description':
      'Ny multi-teny teny fa Manova faritra hafa ny teny. Matetika maneho izany koa, toerana, ny fotoana, matetika, mari-pahaizana, fari-marina, sns ...',
    'syntax.cl.description': "Ny ampahany amin'ny fehezanteny iray izay ahitana ny matoanteny.",
    'syntax.conj.description': 'Mampifandray teny, andian-teny, sy ny fepetra.',
    'syntax.cjp.description': 'Mampifandray teny, andian-teny, sy ny fepetra.',
    'syntax.np.description': 'Ny multi-teny teny izay manao ny asa ny anarana.',
    'syntax.o.description': "Ny fikambanana izay nanao 'ny foto-kevitra.",
    'syntax.io.description': 'Dia izy no sarotra ny manazava.',
    'syntax.p.description':
      "Manova ny foto-kevitra. Matetika kintana eny amin'ny Endriky ny matoanteny amin'ny.",
    'syntax.pp.description': 'Dia izy no sarotra ny manazava.',
    'syntax.s.description': "Ny fikambanana izay mifehy ny matoanteny amin'ny ny fepetra.",
    'syntax.v.description': 'Dia midika ho asa.',
    'syntax.vc.description':
      "Ny multi-teny teny fa manao ny asa ny ny matoanteny (satria ahitan'ilay ny asa).",
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Select ...',
    'general.back': 'miverina',
  },
  'pt-br': {
    'languages.en': 'Inglês',
    'languages.am': 'amárico',
    'languages.bn': 'Bengali Idioma',
    'languages.ceb': 'Cebuano Língua',
    'languages.de': 'Língua alemã',
    'languages.es-419': 'Língua espanhola',
    'languages.fr': 'Lingua francesa',
    'languages.gu': 'Gujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'croata Idioma',
    'languages.hu': 'Língua húngara',
    'languages.id': 'Língua Indonésia',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Língua coreana',
    'languages.lo': 'Idioma do Laos',
    'languages.ml': 'malayalam',
    'languages.mr': 'marata',
    'languages.ms': 'Malaysian',
    'languages.my': 'birmanês Idioma',
    'languages.ne': 'Nepali Língua',
    'languages.plt': 'Malagasy Idioma',
    'languages.pt-br': 'Português (Brasil) Idioma',
    'languages.ru': 'Língua russa',
    'languages.sw': 'swahili',
    'languages.ta': 'Tamil Idioma',
    'languages.th': 'língua tailandesa',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Idioma',
    'languages.vi': 'Idioma vietnamita',
    'languages.zh-hans': 'Idioma Chinês (simplificado)',
    'languages.zh-hant': 'Idioma Chinês (Tradicional)',
    'header.home': 'Casa',
    'header.help': 'Socorro',
    'header.project': 'Projeto',
    'help.contactSupport': 'Envie um email para help@youtranslate.bible para obter assistência.',
    'home.ytbProvides': 'YouTranslate.Bible fornece livremente:',
    'home.easyToUseTool': 'Uma ferramenta de tradução online fácil de usar',
    'home.originalTextHelp': 'Ajuda a compreender o texto da Bíblia em suas línguas originais',
    'home.referencesAndResources': 'Materiais de referência e de treinamento',
    'home.computerAssistance':
      'State-of-the-art tecnologia de computador para auxiliar os tradutores',
    'profile.userProfile': 'Perfil de usuário',
    'profile.language': 'Língua',
    'profile.update': 'Atualizar',
    'usernav.profile': 'Perfil',
    'usernav.logout': 'Sair',
    'usernav.logout.modalTitle': 'Sair de YouTranslate.Bible',
    'usernav.logout.modalText':
      'Tem certeza que deseja sair? Todo o trabalho não salvo será perdido.',
    cancel: 'Cancelar',
    delete: 'Excluir',
    create: 'Crio',
    update: 'Atualizar',
    edit: 'Editar',
    save: 'Salve ',
    add: 'Adicionar',
    'project.create.title': 'Bíblia Projecto de Tradução',
    'project.create.list': 'lista de projetos',
    'project.list.deleteProject': 'Excluir projeto?',
    'project.list.areYouSure': 'Tem certeza de que deseja excluir este projeto?',
    'project.list.workWillBeDeleted': 'O projeto e qualquer trabalho associado será excluído.',
    'project.list.completion': 'conclusão:',
    'project.list.createdDate': 'Data de criação:',
    'project.list.beginningDate': 'Data de início:',
    'project.list.targetCompletionDate': 'Data de Conclusão do Objetivo:',
    'project.list.description': 'Descrição:',
    'project.edit': 'Editar Projeto',
    'project.list.translateBible': 'traduzir Bíblia',
    'project.list.empty': 'Sua lista projecto de tradução está vazio.',
    'project.list.myProjects': 'Minha Bíblia Projectos de Tradução',
    'project.list.newProject': 'Novo projeto',
    'project.create.createNew': 'Criar um novo projeto',
    'project.namePlaceholder': 'Digite o nome do projeto',
    'project.descriptionPlaceholder': 'Digite descrição do projeto',
    'project.name': 'Nome do Projeto',
    'project.beginningDate': 'Data de início',
    'project.targetCompletionDate': 'Data de Conclusão do Objetivo',
    'project.description': 'Descrição',
    'project.manage-users': 'Gerenciar Tradutores',
    'project.new-user': 'New tradutor',
    'project.add-user': 'add tradutor',
    'project.users': 'tradutores do projeto',
    'project.read-only': 'Somente leitura',
    'project.read&write': 'Ler escrever',
    'project.owner': 'Proprietário',
    'project.translator': 'Tradutor',
    'project.message.no-such-user': 'não existe tal Tradutor',
    'project.message.user-added': 'Tradutor adicionado com sucesso',
    'project.message.user-deleted': 'Tradutor removido.',
    'project.message.only-owner-can-manage-users':
      'proprietário do projeto só pode gerenciar usuários',
    'project.shared': 'projeto colaborativo',
    'general.delete-user': 'tradutor de exclusão',
    'general.edit-user': 'Editar usuário',
    'general.name': 'Nome',
    'general.email': 'O email',
    'general.permissions': 'permissões',
    'general.role': 'Função',
    signin: 'assinar em',
    'signin.emailPlaceholder': 'Endereço de e-mail',
    'signin.passwordPlaceholder': 'Senha',
    'signin.forgotPassword': 'Esqueceu sua senha?',
    'signin.rememberMe': 'Lembre de mim',
    'signin.facebook': 'Faça login no Facebook',
    'signin.google': 'Faça login no Google',
    'signin.dontHaveAccount': 'Não tem uma conta?',
    'signin.createOne': 'Crie um',
    'signup.join': 'Junte YouTranslate.Bible',
    'signup.createAccountPrompt': 'Criar a sua conta gratuita para começar.',
    'signup.createAccount': 'Criar Conta',
    'signup.emailPlaceholder': 'Endereço de e-mail',
    'signup.passwordPlaceholder': 'Senha',
    'translation.project': 'PROJETO:',
    'translation.backToProjectList': 'Voltar à lista de projeto',
    'translation.book': 'Livro:',
    'translation.chapter': 'Capítulo:',
    'translation.prompt':
      'Tem certeza de que deseja sair desse capítulo? Todo o trabalho não salvo será perdido.',
    'translation.prev': 'prev',
    'translation.next': 'Próximo',
    'translation.previousChapter': 'capítulo anterior',
    'translation.nextChapter': 'Próximo Capítulo',
    'navigator.book': 'Livro:',
    'navigator.chapter': 'Capítulo:',
    'navigator.oldTestament': 'Antigo Testamento',
    'navigator.newTestament': 'Novo Testamento',
    'navigator.selectBook': 'Escolha um livro',
    'navigator.search': 'Procurar',
    'navigator.changeBook': 'Alterar livro',
    'navigator.changeChapter': 'mudança capítulo',
    Genesis: 'Gênese',
    Exodus: 'Êxodo',
    Leviticus: 'Levítico',
    Numbers: 'Números',
    Deuteronomy: 'Deuteronômio',
    Joshua: 'Joshua',
    Judges: 'Juízes',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Reis',
    '2 Kings': '2 Reis',
    '1 Chronicles': '1 Crônicas',
    '2 Chronicles': '2 Crônicas',
    Ezra: 'Ezra',
    Nehemiah: 'Neemias',
    Esther: 'Esther',
    Job: 'Trabalho',
    Psalms: 'Salmos',
    Proverbs: 'Provérbios',
    Ecclesiastes: 'eclesiastes',
    'Song of Songs': 'Cântico dos Cânticos',
    Isaiah: 'Isaías',
    Jeremiah: 'Jeremiah',
    Lamentations: 'Lamentações',
    Ezekiel: 'Ezequiel',
    Daniel: 'Daniel',
    Hosea: 'Oséias',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadias',
    Jonah: 'Jonah',
    Micah: 'Miquéias',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuque',
    Zephaniah: 'Sofonias',
    Haggai: 'Ageu',
    Zechariah: 'Zacarias',
    Malachi: 'Malaquias',
    Matthew: 'Mateus',
    Mark: 'Marca',
    Luke: 'Lucas',
    John: 'John',
    Acts: 'Atos',
    Romans: 'Romans',
    '1 Corinthians': '1 Coríntios',
    '2 Corinthians': '2 Coríntios',
    Galatians: 'Gálatas',
    Ephesians: 'Efésios',
    Philippians: 'Filipenses',
    Colossians: 'Colossenses',
    '1 Thessalonians': '1 Tessalonicenses',
    '2 Thessalonians': '2 Tessalonicenses',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Hebreus',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Revelação',
    'resources.prompt':
      'Adicionar recursos bíblicos para esclarecimentos adicionais sobre a passagem que você está traduzindo.',
    'resources.add': 'Adicionar um recurso bíblico',
    Manuscript: 'Manuscrito',
    Morphology: 'Morfologia',
    Lexicon: 'Léxico',
    'Syntactic Tree': 'Árvore sintática',
    'Go to project list': 'Ir para a lista de projetos',
    'Project not found': 'Projeto não encontrado ou permissões insuficientes.',
    'error.referenceDoesNotExistForResource':
      'Esta tradução da Bíblia não contém conteúdo para a passagem selecionada.',
    'references.addAnother': 'Adicionar outra tradução',
    'references.referencePrompt':
      'Adicionar outras traduções da Bíblia para esclarecimentos adicionais.',
    'references.add': 'Adicionar uma tradução da Bíblia',
    'translation.complete': 'completa tradução',
    Alignment: 'Alinhamento',
    Share: 'Compartilhar',
    'Your Translation': 'seu Tradução',
    'Enter your translation': 'Digite sua tradução',
    OK: 'Está bem',
    'error.verseTranslationEmpty': 'Você não pode deixar o conteúdo de tradução esvaziar.',
    'translation.hide.completed': 'Esconder concluída',
    alignment: 'Alinhamento',
    'alignment.verify': 'Verificar',
    'alignment.backToEditor': 'Voltar para o Editor',
    'alignment.error':
      'Algo deu errado. Se as persistir problemas, entre em contato com a equipe YouTranslate.Bible.',
    'alignment.add': 'Adicionar link',
    'alignment.delete': 'Remover link',
    'alignment.clear': 'Seleção clara',
    'alignment.reverse': 'Exibição inversa',
    'alignment.removeOrClear': 'Remover o link selecionado ou seleção claro.',
    'alignment.addOrRemove': 'Criar uma nova ligação ou excluir uma anterior.',
    'alignment.selectAndAdd': 'Selecione segmentos correspondente ao link.',
    'error.mustBeSignedToMakeChanges': 'Você deve fazer o login para mudanças fazer.',
    'error.mustBeSignedToTranslateBible': 'Você deve fazer o login para traduzir a Bíblia.',
    checking: 'checagem',
    'verseEditor.verseSuggestions': 'sugestões',
    verses: 'por Verso',
    words: 'por Palavra',
    'leftPanel.name': 'Texto original da Bíblia',
    'rightPanel.name': 'Traduções da Bíblia',
    'bottomPanel.name': 'Recursos para a Bíblia',
    'syntax.adv': 'Advérbio',
    'syntax.advp': 'Frase advérbio',
    'syntax.cl': 'Cláusula',
    'syntax.conj': 'Conjunção',
    'syntax.cjp': 'Conjunção',
    'syntax.np': 'substantivo Frase',
    'syntax.o': 'Objeto',
    'syntax.io': 'Objeto indireto',
    'syntax.p': 'Predicado',
    'syntax.pp': 'Locução prepositiva',
    'syntax.s': 'Sujeito',
    'syntax.v': 'Verbo',
    'syntax.vc': 'verbo Cláusula',
    'syntax.adv.description':
      'Modifica outra parte do discurso. Tipicamente expressar modo, local, tempo, frequência, grau, grau de segurança, etc.',
    'syntax.advp.description':
      'A multi-palavra expressão que modifica outra parte do discurso. Normalmente expressar forma, lugar, tempo, frequência, grau, grau de certeza, etc ...',
    'syntax.cl.description': 'Uma parte de uma frase que contém um verbo.',
    'syntax.conj.description': 'Conecta as palavras, frases e cláusulas.',
    'syntax.cjp.description': 'Conecta as palavras, frases e cláusulas.',
    'syntax.np.description':
      'Uma expressão de múltiplas palavras que desempenha a função de um substantivo.',
    'syntax.o.description': 'A entidade que é posta em prática pelo sujeito.',
    'syntax.io.description': 'Este é difícil de explicar.',
    'syntax.p.description': 'Modifica o assunto. Normalmente, as estrelas com um verbo.',
    'syntax.pp.description': 'Este é difícil de explicar.',
    'syntax.s.description': 'A entidade que controla o verbo da cláusula.',
    'syntax.v.description': 'Transmite uma ação.',
    'syntax.vc.description':
      'Uma expressão de múltiplas palavras que desempenha a função de um verbo (transmite uma acção).',
    'general.close': 'Fechar',
    'general.action': 'Açao',
    'general.select': 'Escolha ...',
    'general.back': 'Costas',
  },
  ru: {
    'languages.en': 'английский',
    'languages.am': 'амхарский',
    'languages.bn': 'Бенгальский язык',
    'languages.ceb': 'Cebuano Язык',
    'languages.de': 'Немецкий язык',
    'languages.es-419': 'Испанский язык',
    'languages.fr': 'Французский язык',
    'languages.gu': 'гуджарати',
    'languages.hi': 'хинди',
    'languages.hr': 'Хорватский язык',
    'languages.hu': 'Венгерский язык',
    'languages.id': 'Индонезийский язык',
    'languages.km': 'кхмерский',
    'languages.kn': 'каннада',
    'languages.ko': 'Корейский язык',
    'languages.lo': 'лаосской Язык',
    'languages.ml': 'Malayalam',
    'languages.mr': 'маратхи',
    'languages.ms': 'малазийский',
    'languages.my': 'Бирманский язык',
    'languages.ne': 'Непальский Язык',
    'languages.plt': 'Малагасийский язык',
    'languages.pt-br': 'Португальский (Бразилия) Язык',
    'languages.ru': 'Русский язык',
    'languages.sw': 'суахили',
    'languages.ta': 'тамильский язык',
    'languages.th': 'Тайский язык',
    'languages.tl': 'тагальского',
    'languages.te': 'телугу Язык',
    'languages.vi': 'Вьетнамский язык',
    'languages.zh-hans': 'Китайский язык (упрощенный)',
    'languages.zh-hant': 'Китайский язык (традиционный)',
    'header.home': 'Дом',
    'header.help': 'Помогите',
    'header.project': 'проект',
    'help.contactSupport': 'Пожалуйста, напишите нам по адресу help@youtranslate.bible для помощи.',
    'home.ytbProvides': 'YouTranslate.Bible свободно обеспечивает:',
    'home.easyToUseTool': 'Простой в использовании интерактивный инструмент для перевода',
    'home.originalTextHelp': 'Помогите понять текст Библии на языке оригинала',
    'home.referencesAndResources': 'Справочные и учебные ресурсы',
    'home.computerAssistance':
      'Состояние дел современных компьютерных технологий для оказания помощи переводчиков',
    'profile.userProfile': 'Профиль пользователя',
    'profile.language': 'язык',
    'profile.update': 'Обновить',
    'usernav.profile': 'Профиль',
    'usernav.logout': 'Выйти',
    'usernav.logout.modalTitle': 'Выход из YouTranslate.Bible',
    'usernav.logout.modalText':
      'Вы действительно хотите выйти? Все несохраненные данные будут потеряны.',
    cancel: 'Отмена',
    delete: 'удалять',
    create: 'Создайте',
    update: 'Обновить',
    edit: 'редактировать',
    save: 'Сохранить',
    add: 'добавлять',
    'project.create.title': 'Проект перевода Библии',
    'project.create.list': 'список проектов',
    'project.list.deleteProject': 'Удалить проект?',
    'project.list.areYouSure': 'Вы уверены, что хотите удалить этот проект?',
    'project.list.workWillBeDeleted': 'Проект и любая работа, связанная будут удалены.',
    'project.list.completion': 'Завершение:',
    'project.list.createdDate': 'Дата создания:',
    'project.list.beginningDate': 'Начиная с даты:',
    'project.list.targetCompletionDate': 'Дата завершения выполнения задания:',
    'project.list.description': 'Описание:',
    'project.edit': 'Редактирование проекта',
    'project.list.translateBible': 'Перевести Библию',
    'project.list.empty': 'Ваш список перевода проекта пуст.',
    'project.list.myProjects': 'Мои Библии Проекты перевода',
    'project.list.newProject': 'Новый проект',
    'project.create.createNew': 'Создание нового проекта',
    'project.namePlaceholder': 'Введите имя проекта',
    'project.descriptionPlaceholder': 'Введите описание проекта',
    'project.name': 'Название проекта',
    'project.beginningDate': 'Начиная с даты',
    'project.targetCompletionDate': 'Дата завершения выполнения задания',
    'project.description': 'Описание',
    'project.manage-users': 'Управление переводчиков',
    'project.new-user': 'Новый переводчик',
    'project.add-user': 'Добавить переводчик',
    'project.users': 'переводчики проекта',
    'project.read-only': 'только для чтения',
    'project.read&write': 'Читай пиши',
    'project.owner': 'владелец',
    'project.translator': 'Переводчик',
    'project.message.no-such-user': 'Нет такой переводчик не существует',
    'project.message.user-added': 'Переводчик успешно добавлен',
    'project.message.user-deleted': 'Переводчик удален.',
    'project.message.only-owner-can-manage-users':
      'Только владелец проекта может управлять пользователями',
    'project.shared': 'Совместный проект',
    'general.delete-user': 'Удалить переводчик',
    'general.edit-user': 'Изменить пользователя',
    'general.name': 'имя',
    'general.email': 'Эл. почта',
    'general.permissions': 'права доступа',
    'general.role': 'Роль',
    signin: 'войти в систему',
    'signin.emailPlaceholder': 'Адрес электронной почты',
    'signin.passwordPlaceholder': 'пароль',
    'signin.forgotPassword': 'Забыли свой пароль?',
    'signin.rememberMe': 'Запомните меня',
    'signin.facebook': 'Авторизоваться с помощью Фэйсбука',
    'signin.google': 'Войти с помощью Google',
    'signin.dontHaveAccount': 'Не зарегистрированы?',
    'signin.createOne': 'Создай',
    'signup.join': 'Регистрация YouTranslate.Bible',
    'signup.createAccountPrompt': 'Создайте свой бесплатный аккаунт, чтобы начать.',
    'signup.createAccount': 'Регистрация',
    'signup.emailPlaceholder': 'Адрес электронной почты',
    'signup.passwordPlaceholder': 'пароль',
    'translation.project': 'ПРОЕКТ:',
    'translation.backToProjectList': 'Вернуться к списку проектов',
    'translation.book': 'Книга:',
    'translation.chapter': 'Глава:',
    'translation.prompt':
      'Вы уверены, что хотите покинуть эту главу? Все несохраненные данные будут потеряны.',
    'translation.prev': 'Предыдущая',
    'translation.next': 'следующий',
    'translation.previousChapter': 'Предыдущая глава',
    'translation.nextChapter': 'Следующая глава',
    'navigator.book': 'Книга:',
    'navigator.chapter': 'Глава:',
    'navigator.oldTestament': 'Ветхий Завет',
    'navigator.newTestament': 'Новый Завет',
    'navigator.selectBook': 'Выберите книгу',
    'navigator.search': 'Поиск',
    'navigator.changeBook': 'Изменение книги',
    'navigator.changeChapter': 'Изменить раздел',
    Genesis: 'генезис',
    Exodus: 'исход',
    Leviticus: 'Левит',
    Numbers: 'чисел',
    Deuteronomy: 'Второзаконие',
    Joshua: 'Джошуа',
    Judges: 'Книга судей',
    Ruth: 'Рут',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1-я Паралипоменон',
    '2 Chronicles': '2-я Паралипоменон',
    Ezra: 'Ezra',
    Nehemiah: 'Неемия',
    Esther: 'Эстер',
    Job: 'работа',
    Psalms: 'Псалмы',
    Proverbs: 'пословицы',
    Ecclesiastes: 'Экклезиаст',
    'Song of Songs': 'Песнь Песней',
    Isaiah: 'Исайя',
    Jeremiah: 'Иеремия',
    Lamentations: 'плач Иеремии',
    Ezekiel: 'Иезекииль',
    Daniel: 'Даниил',
    Hosea: 'Осия',
    Joel: 'Joel',
    Amos: 'амосс',
    Obadiah: 'Овадия',
    Jonah: 'Ион',
    Micah: 'Михей',
    Nahum: 'Наум',
    Habakkuk: 'Аввакум',
    Zephaniah: 'Софония',
    Haggai: 'Аггей',
    Zechariah: 'Захария',
    Malachi: 'Малахия',
    Matthew: 'Мэтью',
    Mark: 'отметка',
    Luke: 'Люк',
    John: 'Джон',
    Acts: 'акты',
    Romans: 'римляне',
    '1 Corinthians': '1-е Коринфянам',
    '2 Corinthians': '2-е Коринфянам',
    Galatians: 'Галатам',
    Ephesians: 'Ефесянам',
    Philippians: 'Филиппийцам',
    Colossians: 'Колоссянам',
    '1 Thessalonians': '1 Фессалоникийцам',
    '2 Thessalonians': '2 Фессалоникийцам',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Филимон',
    Hebrews: 'Евреям',
    James: 'Джеймс',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'Открытие',
    'resources.prompt':
      'Добавить Библейские ресурсы для дополнительного проникновения в проход вы переводите.',
    'resources.add': 'Добавить библейский ресурс',
    Manuscript: 'Рукопись',
    Morphology: 'Морфология',
    Lexicon: 'Лексикон',
    'Syntactic Tree': 'Синтаксическое Дерево',
    'Go to project list': 'Перейти к списку проектов',
    'Project not found': 'Проект не найден или недостаточно прав.',
    'error.referenceDoesNotExistForResource':
      'Этот перевод Библии не содержит контент для выбранного отрывка.',
    'references.addAnother': 'Добавить другой перевод',
    'references.referencePrompt': 'Добавить другие переводы Библии для дополнительного понимания.',
    'references.add': 'Добавить перевод Библии',
    'translation.complete': 'полный перевод',
    Alignment: 'центровка',
    Share: 'Поделиться',
    'Your Translation': 'Ваш перевод',
    'Enter your translation': 'Введите свой перевод',
    OK: 'Ok',
    'error.verseTranslationEmpty': 'Вы не можете оставить содержание перевода опорожнить.',
    'translation.hide.completed': 'Скрыть завершения',
    alignment: 'центровка',
    'alignment.verify': 'проверить',
    'alignment.backToEditor': 'Назад к редактору',
    'alignment.error':
      'Что-то пошло не так. Если проблема не устраняется, обратитесь в YouTranslate.Bible команды.',
    'alignment.add': 'Добавить ссылку',
    'alignment.delete': 'Удалить ссылку',
    'alignment.clear': 'Понятный выбор',
    'alignment.reverse': 'Обратный дисплей',
    'alignment.removeOrClear': 'Удалить выбранную ссылку или четкий выбор.',
    'alignment.addOrRemove': 'Создать новую ссылку или удалить предыдущую.',
    'alignment.selectAndAdd': 'Выберите соответствующие сегменты для связи.',
    'error.mustBeSignedToMakeChanges': 'Вы должны войти в систему, чтобы изменения делают.',
    'error.mustBeSignedToTranslateBible': 'Вы должны войти в систему, чтобы перевести Библию.',
    checking: 'проверка',
    'verseEditor.verseSuggestions': 'Предложения',
    verses: 'стих',
    words: 'По Слову',
    'leftPanel.name': 'Оригинальный текст Библии',
    'rightPanel.name': 'Переводы Библии',
    'bottomPanel.name': 'Ресурсы для Библии',
    'syntax.adv': 'Наречие',
    'syntax.advp': 'Наречие разговорник',
    'syntax.cl': 'пункт',
    'syntax.conj': 'конъюнкция',
    'syntax.cjp': 'конъюнкция',
    'syntax.np': 'Словосочетание',
    'syntax.o': 'объект',
    'syntax.io': 'Косвенное дополнение',
    'syntax.p': 'сказуемое',
    'syntax.pp': 'предложной',
    'syntax.s': 'Предмет',
    'syntax.v': 'глагол',
    'syntax.vc': 'Глагол Статья',
    'syntax.adv.description':
      'Модифицирует другая часть речи. Как правило, выражают способ, место, время, частота, степень, уровень достоверности и т.п.',
    'syntax.advp.description':
      'Выражение из нескольких слов, которые модифицируют другую часть речи. Как правило, выражают способ, место, время, частота, степень, уровень достоверности, и т.д. ...',
    'syntax.cl.description': 'Часть предложения, которая содержит глагол.',
    'syntax.conj.description': 'Подключается слова, фразы и предложения.',
    'syntax.cjp.description': 'Подключается слова, фразы и предложения.',
    'syntax.np.description': 'Выражение мульти-слово, которое выполняет функцию существительного.',
    'syntax.o.description': 'Объект, который действует по этому вопросу.',
    'syntax.io.description': 'Это один трудно объяснить.',
    'syntax.p.description': 'Изменяет тему. Обычно звезды с глаголом.',
    'syntax.pp.description': 'Это один трудно объяснить.',
    'syntax.s.description': 'Лицо, которое управляет глагол в предложении.',
    'syntax.v.description': 'Передает действие.',
    'syntax.vc.description':
      'Выражение мульти-слово, которое выполняет функцию глагола (транспортирует действие).',
    'general.close': 'близко',
    'general.action': 'действие',
    'general.select': 'Выбрать...',
    'general.back': 'назад',
  },
  sw: {
    'languages.en': 'english',
    'languages.am': 'Kiamhari',
    'languages.bn': 'Kibengali lugha',
    'languages.ceb': 'Cebuano lugha',
    'languages.de': 'Lugha ya Kijerumani',
    'languages.es-419': 'lugha ya Kihispania',
    'languages.fr': 'Lugha ya Kifaransa',
    'languages.gu': 'Kigujarati',
    'languages.hi': 'hindi',
    'languages.hr': 'Kikorasia lugha',
    'languages.hu': 'hungarian lugha',
    'languages.id': 'Kiindonesia lugha',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Korea lugha',
    'languages.lo': 'Laotian lugha',
    'languages.ml': 'Kimalayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysia',
    'languages.my': 'Kiburma lugha',
    'languages.ne': 'Kinepali lugha',
    'languages.plt': 'Malagasy lugha',
    'languages.pt-br': 'Kireno (Brazil) lugha',
    'languages.ru': 'russian lugha',
    'languages.sw': 'Kiswahili',
    'languages.ta': 'Tamil lugha',
    'languages.th': 'thai lugha',
    'languages.tl': 'Kitagalogi',
    'languages.te': 'Telegu lugha',
    'languages.vi': 'Kivietinamu lugha',
    'languages.zh-hans': 'Lugha ya Kichina (Kilichorahisishwa)',
    'languages.zh-hant': 'Lugha ya Kichina (cha Jadi)',
    'header.home': 'Home',
    'header.help': 'Msaada',
    'header.project': 'Mradi',
    'help.contactSupport': 'Tafadhali email yetu katika help@youtranslate.bible kwa msaada.',
    'home.ytbProvides': 'YouTranslate.Bible uhuru hutoa:',
    'home.easyToUseTool': 'rahisi kutumia zana ya tafsiri mtandaoni',
    'home.originalTextHelp': 'Msaada kuelewa maandiko ya Biblia katika lugha yake ya asili',
    'home.referencesAndResources': 'Kumbukumbu na mafunzo rasilimali',
    'home.computerAssistance': 'Hali ya-ya sanaa teknolojia ya kompyuta ili kusaidia watafsiri',
    'profile.userProfile': 'profile user',
    'profile.language': 'lugha',
    'profile.update': 'Mwisho',
    'usernav.profile': 'Profile',
    'usernav.logout': 'Toka',
    'usernav.logout.modalTitle': 'Toka wa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Una uhakika unataka ili kuondoka? All kazi wasiookoka itakuwa waliopotea.',
    cancel: 'kufuta',
    delete: 'Futa',
    create: 'kujenga',
    update: 'Mwisho',
    edit: 'Edit',
    save: 'Save',
    add: 'Kuongeza',
    'project.create.title': 'Biblia tafsiri Project',
    'project.create.list': 'orodha Project',
    'project.list.deleteProject': 'Futa Project?',
    'project.list.areYouSure': 'Una uhakika unataka kufuta mradi huu?',
    'project.list.workWillBeDeleted': 'mradi na kazi yoyote yanayohusiana yatafutwa.',
    'project.list.completion': 'Mwisho:',
    'project.list.createdDate': 'Imeundwa Tarehe:',
    'project.list.beginningDate': 'Kuanzia Tarehe:',
    'project.list.targetCompletionDate': 'Target Kukamilisha Tarehe:',
    'project.list.description': 'Maelezo:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Tafsiri ya Biblia',
    'project.list.empty': 'Orodha yako ya tafsiri mradi ni tupu.',
    'project.list.myProjects': 'My Biblia tafsiri Miradi',
    'project.list.newProject': 'Project New',
    'project.create.createNew': 'Kujenga mpya Project',
    'project.namePlaceholder': 'Ingiza jina mradi',
    'project.descriptionPlaceholder': 'Weka maelezo ya mradi',
    'project.name': 'Jina la mradi',
    'project.beginningDate': 'kuanzia tarehe',
    'project.targetCompletionDate': 'Target Kukamilisha Tarehe',
    'project.description': 'Maelezo',
    'project.manage-users': 'kusimamia Watafsiri',
    'project.new-user': 'translator New',
    'project.add-user': 'kuongeza translator',
    'project.users': 'Watafsiri Project',
    'project.read-only': 'kusoma tu',
    'project.read&write': 'Read & Write',
    'project.owner': 'mmiliki',
    'project.translator': 'Translator',
    'project.message.no-such-user': 'Hakuna translator hiyo uliopo',
    'project.message.user-added': 'Translator imeongezwa',
    'project.message.user-deleted': 'Translator kuondolewa.',
    'project.message.only-owner-can-manage-users':
      'mmiliki wa mradi tu unaweza kusimamia watumiaji',
    'project.shared': 'mradi shirikishi',
    'general.delete-user': 'translator Futa',
    'general.edit-user': 'Edit user',
    'general.name': 'jina',
    'general.email': 'E-mail',
    'general.permissions': 'Idhini',
    'general.role': 'Wajibu',
    signin: 'Weka sahihi',
    'signin.emailPlaceholder': 'Barua pepe',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Umesahau nenosiri yako?',
    'signin.rememberMe': 'Nikumbuke',
    'signin.facebook': 'Ingia kwa kutumia Facebook',
    'signin.google': 'Ingia kwa kutumia Google',
    'signin.dontHaveAccount': 'Huna akaunti ya?',
    'signin.createOne': 'kujenga moja',
    'signup.join': 'Kujiunga YouTranslate.Bible',
    'signup.createAccountPrompt': 'Fungua akaunti yako ya bure ili kuanza.',
    'signup.createAccount': 'Tengeneza akaunti',
    'signup.emailPlaceholder': 'Barua pepe',
    'signup.passwordPlaceholder': 'password',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Rudi kwenye orodha ya mradi',
    'translation.book': 'kitabu:',
    'translation.chapter': 'Sura:',
    'translation.prompt':
      'Una uhakika unataka kuondoka sura hii? All kazi wasiookoka itakuwa waliopotea.',
    'translation.prev': 'Awali',
    'translation.next': 'next',
    'translation.previousChapter': 'sura ya awali',
    'translation.nextChapter': 'sura inayofuata',
    'navigator.book': 'kitabu:',
    'navigator.chapter': 'Sura:',
    'navigator.oldTestament': 'Agano la Kale',
    'navigator.newTestament': 'Agano jipya',
    'navigator.selectBook': 'chagua kitabu',
    'navigator.search': 'Tafuta',
    'navigator.changeBook': 'Badilisha kitabu',
    'navigator.changeChapter': 'Badilisha sura',
    Genesis: 'Mwanzo',
    Exodus: 'Kutoka',
    Leviticus: 'Walawi',
    Numbers: 'Hesabu',
    Deuteronomy: 'Kumbukumbu',
    Joshua: 'Joshua',
    Judges: 'Waamuzi',
    Ruth: 'Ruth',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Wafalme',
    '2 Kings': '2 Wafalme',
    '1 Chronicles': '1 Mambo ya Nyakati',
    '2 Chronicles': '2 Mambo ya Nyakati',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemia',
    Esther: 'Esther',
    Job: 'Job',
    Psalms: 'Zaburi',
    Proverbs: 'methali',
    Ecclesiastes: 'Mhubiri',
    'Song of Songs': 'Wimbo',
    Isaiah: 'Isaya',
    Jeremiah: 'Jeremiah',
    Lamentations: 'maombolezo',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadia',
    Jonah: 'Yona',
    Micah: 'Mika',
    Nahum: 'Nahumu',
    Habakkuk: 'Habakuki',
    Zephaniah: 'Sefania',
    Haggai: 'Hagai',
    Zechariah: 'Zakaria',
    Malachi: 'Malaki',
    Matthew: 'Mathayo',
    Mark: 'Mark',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Matendo',
    Romans: 'Warumi',
    '1 Corinthians': '1 Wakorintho',
    '2 Corinthians': '2 Wakorintho',
    Galatians: 'Wagalatia',
    Ephesians: 'Waefeso',
    Philippians: 'Wafilipi',
    Colossians: 'Wakolosai',
    '1 Thessalonians': '1 Wathesalonike',
    '2 Thessalonians': '2 Wathesalonike',
    '1 Timothy': 'Timothy 1',
    '2 Timothy': '2 Timothy',
    Titus: 'Titus',
    Philemon: 'Philemon',
    Hebrews: 'Waebrania',
    James: 'James',
    '1 Peter': '1 Petro',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 Yohana',
    Jude: 'Jude',
    Revelation: 'Ufunuo',
    'resources.prompt':
      'Kuongeza rasilimali Biblia kwa ufahamu wa ziada kwenye kifungu unayotafsiri.',
    'resources.add': 'Kuongeza rasilimali Biblia',
    Manuscript: 'Muswada',
    Morphology: 'Mofolojia',
    Lexicon: 'Lexicon',
    'Syntactic Tree': 'Kiwango cha kisintaksia Tree',
    'Go to project list': 'Nenda kwenye orodha ya mradi',
    'Project not found': 'Mradi haipatikani au ruhusa halitoshi.',
    'error.referenceDoesNotExistForResource':
      'tafsiri hii Biblia haina maudhui ya kifungu kuchaguliwa.',
    'references.addAnother': 'Kuongeza tafsiri nyingine',
    'references.referencePrompt': 'Kuongeza Biblia nyingine kwa ufahamu zaidi.',
    'references.add': 'Kuongeza tafsiri Biblia',
    'translation.complete': 'tafsiri kamili',
    Alignment: 'Mpangilio',
    Share: 'Kushiriki',
    'Your Translation': 'tafsiri yako',
    'Enter your translation': 'Weka tafsiri yako',
    OK: 'sawa',
    'error.verseTranslationEmpty': 'Huwezi kuacha tafsiri maudhui tupu.',
    'translation.hide.completed': 'Ficha kukamilika',
    alignment: 'Mpangilio',
    'alignment.verify': 'Thibitisha',
    'alignment.backToEditor': 'Nyuma kwa Mhariri',
    'alignment.error':
      'Kulikuwa na tatizo. Kama likiendelea tatizo, tafadhali wasiliana na timu YouTranslate.Bible.',
    'alignment.add': 'Ongeza Kiungo',
    'alignment.delete': 'Ondoa Kiungo',
    'alignment.clear': 'Futa Uchaguzi',
    'alignment.reverse': 'kinyume Display',
    'alignment.removeOrClear': 'Kuondoa kiungo au uteuzi wa wazi.',
    'alignment.addOrRemove': 'Kiungo kipya au futa moja uliopita.',
    'alignment.selectAndAdd': 'Kuchagua sambamba makundi ya kiungo.',
    'error.mustBeSignedToMakeChanges': 'Lazima uwe umeingia ili kufanya mabadiliko.',
    'error.mustBeSignedToTranslateBible': 'Lazima uwe umeingia ili kutafsiri Biblia.',
    checking: 'kuangalia',
    'verseEditor.verseSuggestions': 'mapendekezo',
    verses: 'By Verse',
    words: 'kwa Neno',
    'leftPanel.name': 'Original Nakala ya Biblia',
    'rightPanel.name': 'Tafsiri za Biblia',
    'bottomPanel.name': 'Rasilimali kwa ajili ya Biblia',
    'syntax.adv': 'kielezi',
    'syntax.advp': 'kielezi Fungu la maneno',
    'syntax.cl': 'kifungu',
    'syntax.conj': 'kushirikiana',
    'syntax.cjp': 'kushirikiana',
    'syntax.np': 'nomino Fungu la maneno',
    'syntax.o': 'kitu',
    'syntax.io': 'moja kwa moja kitu',
    'syntax.p': 'prediketo',
    'syntax.pp': 'Prepositional Fungu la maneno',
    'syntax.s': 'Subject',
    'syntax.v': 'kitendo',
    'syntax.vc': 'kitenzi Kifungu',
    'syntax.adv.description':
      'Modifies sehemu nyingine ya hotuba. Kwa kawaida kueleza namna, mahali, wakati, marudio, shahada, kiwango cha uhakika, nk',
    'syntax.advp.description':
      'mbalimbali neno onyesho ambalo modifies sehemu nyingine ya hotuba. Kwa kawaida kueleza namna, mahali, wakati, marudio, shahada, kiwango cha uhakika, nk ...',
    'syntax.cl.description': 'moja kati ya hukumu ambayo ina kitenzi.',
    'syntax.conj.description': 'Huunganisha maneno, maneno, na vipengele.',
    'syntax.cjp.description': 'Huunganisha maneno, maneno, na vipengele.',
    'syntax.np.description': 'mbalimbali neno onyesho ambalo hufanya kazi ya jina.',
    'syntax.o.description': 'chombo ambacho kutendwa na somo.',
    'syntax.io.description': 'Hii ni moja ya vigumu kueleza.',
    'syntax.p.description': 'Hugeuza somo. Kwa kawaida nyota na kitenzi.',
    'syntax.pp.description': 'Hii ni moja ya vigumu kueleza.',
    'syntax.s.description': 'chombo ambayo udhibiti kitendo cha kifungu.',
    'syntax.v.description': 'Huwasilisha action.',
    'syntax.vc.description':
      'mbalimbali neno onyesho ambalo hufanya kazi ya kitenzi (zinaonyesha hatua).',
    'general.close': 'Close',
    'general.action': 'Action',
    'general.select': 'Chagua ...',
    'general.back': 'Back',
  },
  ta: {
    'languages.en': 'ஆங்கிலம்',
    'languages.am': 'அம்ஹரிக்',
    'languages.bn': 'பெங்காலி மொழி',
    'languages.ceb': 'செபுவானோ மொழி',
    'languages.de': 'ஜெர்மன் மொழி',
    'languages.es-419': 'ஸ்பானிஷ் மொழி',
    'languages.fr': 'பிரஞ்சு மொழி',
    'languages.gu': 'குஜராத்தி',
    'languages.hi': 'இந்தி',
    'languages.hr': 'குரோஷியன் மொழி',
    'languages.hu': 'ஹங்கேரியன் மொழி',
    'languages.id': 'இந்தோனேஷியன் மொழி',
    'languages.km': 'கெமெர்',
    'languages.kn': 'கன்னடம்',
    'languages.ko': 'கொரிய மொழி',
    'languages.lo': 'லாவோடியன் மொழி',
    'languages.ml': 'மலையாளம்',
    'languages.mr': 'மராத்தி',
    'languages.ms': 'மலேசிய',
    'languages.my': 'பர்மிஸ் மொழி',
    'languages.ne': 'நேபாளி மொழி',
    'languages.plt': 'மலகாஸி மொழி',
    'languages.pt-br': 'போர்த்துகீசியம் (பிரேசில்) மொழி',
    'languages.ru': 'ரஷ்ய மொழி',
    'languages.sw': 'சுவாஹிலி',
    'languages.ta': 'தமிழ் மொழி',
    'languages.th': 'தாய்லாந்து மொழி',
    'languages.tl': 'டாகாலோக்',
    'languages.te': 'தெலுங்கு மொழி',
    'languages.vi': 'வியட்நாமிய மொழி',
    'languages.zh-hans': 'சீன மொழி (எளிதாக்கப்பட்டது)',
    'languages.zh-hant': 'சீன மொழி (பாரம்பரிய)',
    'header.home': 'வீடு',
    'header.help': 'உதவி',
    'header.project': 'திட்ட',
    'help.contactSupport': 'உதவி help@youtranslate.bible எங்களை தொடர்பு கொள்ளவும்.',
    'home.ytbProvides': 'YouTranslate.Bible சுதந்திரமாக வழங்குகிறது:',
    'home.easyToUseTool': 'ஒரு சுலபமான பயன்படுத்தக்கூடிய ஆன்லைன் மொழிபெயர்ப்பு கருவி',
    'home.originalTextHelp': 'உதவி அதன் அசல் மொழிகளில் பைபிள் உரை புரிந்து கொள்ள',
    'home.referencesAndResources': 'குறிப்பு மற்றும் பயிற்சி வளங்கள்',
    'home.computerAssistance': 'ஸ்டேட்-ஆஃப்-த-ஆர்ட் கணினி தொழில்நுட்பம் மொழிபெயர்ப்பாளர்கள் உதவ',
    'profile.userProfile': 'பயனர் விவரம்',
    'profile.language': 'மொழி',
    'profile.update': 'புதுப்பிக்கப்பட்டது',
    'usernav.profile': 'பதிவு செய்தது',
    'usernav.logout': 'வெளியேறு',
    'usernav.logout.modalTitle': 'YouTranslate.Bible இன் வெளியேறு',
    'usernav.logout.modalText':
      'உறுதியாக உள்ளீர்களா வெளியேறி வேண்டும்? சேமிக்கப்படாத அனைத்து வேலை இழக்கப்படும்.',
    cancel: 'ரத்து',
    delete: 'அழி',
    create: 'உருவாக்கவும்',
    update: 'புதுப்பிக்கப்பட்டது',
    edit: 'தொகு',
    save: 'சேமி',
    add: 'கூட்டு',
    'project.create.title': 'பைபிள் மொழிபெயர்ப்பு திட்ட',
    'project.create.list': 'திட்ட பட்டியலில்',
    'project.list.deleteProject': 'திட்டப்பணியை நீக்கு?',
    'project.list.areYouSure': 'இந்தத் திட்டப்பணியை நீக்க வேண்டும் உறுதியாக உள்ளீர்களா?',
    'project.list.workWillBeDeleted': 'திட்டம் மற்றும் தொடர்புடைய எந்த வேலை நீக்கப்படும்.',
    'project.list.completion': 'நிறைவு:',
    'project.list.createdDate': 'உருவாக்கிய தேதி:',
    'project.list.beginningDate': 'தேதி தொடங்கி:',
    'project.list.targetCompletionDate': 'இலக்கு நிறைவு நாள்:',
    'project.list.description': 'விளக்கம்:',
    'project.edit': 'புராஜெக்ட்டைத் திருத்து',
    'project.list.translateBible': 'பைபிள் மொழிபெயர்',
    'project.list.empty': 'உங்கள் மொழிபெயர்ப்பு திட்டம் பட்டியல் காலியாக உள்ளது.',
    'project.list.myProjects': 'என் பைபிள் மொழிபெயர்ப்பு திட்டங்கள்',
    'project.list.newProject': 'புதிய திட்டம்',
    'project.create.createNew': 'ஒரு புதிய திட்ட உருவாக்கவும்',
    'project.namePlaceholder': 'திட்டம் பெயரை உள்ளிடவும்',
    'project.descriptionPlaceholder': 'திட்டம் விளக்கத்தை உள்ளிடவும்',
    'project.name': 'திட்டப்பணி பெயர்',
    'project.beginningDate': 'தொடங்கி தேதி',
    'project.targetCompletionDate': 'இலக்கு நிறைவு தேதி',
    'project.description': 'விளக்கம்',
    'project.manage-users': 'மொழிபெயர்ப்பாளர் நிர்வகிக்கவும்',
    'project.new-user': 'புதிய மொழிபெயர்ப்பாளர்',
    'project.add-user': 'மொழிபெயர்ப்பாளர் சேர்',
    'project.users': 'திட்ட மொழிபெயர்ப்பாளர்கள்',
    'project.read-only': 'படிக்க மட்டும்',
    'project.read&write': 'படிக்க & எழுத',
    'project.owner': 'உரிமையாளர்',
    'project.translator': 'மொழிபெயர்ப்பாளர்',
    'project.message.no-such-user': 'அதுபோன்ற மொழிபெயர்ப்பாளர் உள்ளது',
    'project.message.user-added': 'மொழிபெயர்ப்பாளர் வெற்றிகரமாக சேர்க்கப்பட்டது',
    'project.message.user-deleted': 'மொழிபெயர்ப்பாளர் நீக்கப்பட்டது.',
    'project.message.only-owner-can-manage-users':
      'ஒரே திட்டம் உரிமையாளர் மட்டுமே பயனர்களை நிர்வகிக்க முடியும்',
    'project.shared': 'கூட்டுமுயற்சித் திட்டம்',
    'general.delete-user': 'நீக்கு மொழிபெயர்ப்பாளர்',
    'general.edit-user': 'பயனர் திருத்த',
    'general.name': 'பெயர்',
    'general.email': 'மின்னஞ்சல்',
    'general.permissions': 'அனுமதிகள்',
    'general.role': 'பங்கு',
    signin: 'உள்நுழையவும்',
    'signin.emailPlaceholder': 'மின்னஞ்சல் முகவரி',
    'signin.passwordPlaceholder': 'கடவுச்சொல்',
    'signin.forgotPassword': 'உங்கள் கடவுச்சொல்லை மறந்து விட்டீர்களா?',
    'signin.rememberMe': 'என்னை நினைவில்',
    'signin.facebook': 'பேஸ்பு கொண்டு உள்நுழையவும்',
    'signin.google': 'கூகிள் கொண்டு உள்நுழையவும்',
    'signin.dontHaveAccount': 'ஒரு கணக்கு இல்லையா?',
    'signin.createOne': 'ஒன்றை உருவாக்கவும்',
    'signup.join': 'YouTranslate.Bible சேர',
    'signup.createAccountPrompt': 'தொடங்க உங்கள் இலவச கணக்கை உருவாக்கவும்.',
    'signup.createAccount': 'உங்கள் கணக்கை துவங்குங்கள்',
    'signup.emailPlaceholder': 'மின்னஞ்சல் முகவரி',
    'signup.passwordPlaceholder': 'கடவுச்சொல்',
    'translation.project': 'கருத்திட்டம்:',
    'translation.backToProjectList': 'திட்டம் பட்டியலில் மீண்டும்',
    'translation.book': 'நூல்:',
    'translation.chapter': 'அத்தியாயம்:',
    'translation.prompt':
      'நீங்கள் இந்த அத்தியாயம் விட்டு வேண்டும் உறுதியாக உள்ளீர்களா? சேமிக்கப்படாத அனைத்து வேலை இழக்கப்படும்.',
    'translation.prev': 'முன்',
    'translation.next': 'அடுத்தது',
    'translation.previousChapter': 'முந்தைய அத்தியாயம்',
    'translation.nextChapter': 'அடுத்த அத்தியாயம்',
    'navigator.book': 'நூல்:',
    'navigator.chapter': 'அத்தியாயம்:',
    'navigator.oldTestament': 'பழைய ஏற்பாடு',
    'navigator.newTestament': 'புதிய ஏற்பாடு',
    'navigator.selectBook': 'ஒரு புத்தகம் தேர்ந்தெடுக்கவும்',
    'navigator.search': 'தேடல்',
    'navigator.changeBook': 'மாற்றம் புத்தகம்',
    'navigator.changeChapter': 'மாற்றம் அத்தியாயம்',
    Genesis: 'ஆதியாகமம்',
    Exodus: 'யாத்திராகமம்',
    Leviticus: 'லேவிடிகிஸ்',
    Numbers: 'எண்கள்',
    Deuteronomy: 'உபாகமம்',
    Joshua: 'யோசுவா',
    Judges: 'நீதிபதிகள்',
    Ruth: 'ரூத்',
    '1 Samuel': '1 சாமுவேல்',
    '2 Samuel': '2 சாமுவேல்',
    '1 Kings': '1 கிங்ஸ்',
    '2 Kings': '2 கிங்ஸ்',
    '1 Chronicles': '1 நாளாகமம்',
    '2 Chronicles': '2 நாளாகமம்',
    Ezra: 'எஸ்ரா',
    Nehemiah: 'நெகேமியா',
    Esther: 'எஸ்தர்',
    Job: 'வேலை',
    Psalms: 'சங்கீதம்',
    Proverbs: 'பழமொழிகள்',
    Ecclesiastes: 'பிரசங்கி',
    'Song of Songs': 'பாடல்கள் பாடல்',
    Isaiah: 'ஏசாயா',
    Jeremiah: 'ஜெரிமியா',
    Lamentations: 'புலம்பல்',
    Ezekiel: 'எசேக்கியேல்',
    Daniel: 'டேனியல்',
    Hosea: 'ஓசியா',
    Joel: 'ஜோயல்',
    Amos: 'அமோஸ்',
    Obadiah: 'ஒபதியா',
    Jonah: 'ஜோனா',
    Micah: 'மீகா',
    Nahum: 'நாகூம்',
    Habakkuk: 'ஆபகூக்',
    Zephaniah: 'செப்பனியா',
    Haggai: 'ஆகாய்',
    Zechariah: 'சகரியா',
    Malachi: 'மல்கியா',
    Matthew: 'மத்தேயு',
    Mark: 'மார்க்',
    Luke: 'லூக்கா',
    John: 'ஜான்',
    Acts: 'செயல்கள்',
    Romans: 'ரோமர்',
    '1 Corinthians': '1 கொரிந்தியர்',
    '2 Corinthians': '2 கொரிந்தியர்',
    Galatians: 'கலாத்தியர்',
    Ephesians: 'எபேசியர்',
    Philippians: 'பிலிப்பியர்',
    Colossians: 'கொலோசெயர்',
    '1 Thessalonians': '1 தெசலோனிக்கேயர்',
    '2 Thessalonians': '2 தெசலோனிக்கேயர்',
    '1 Timothy': '1 தீமோத்தேயு',
    '2 Timothy': '2 டிமோதி',
    Titus: 'டைட்டஸ்',
    Philemon: 'ஃபிலோமின்',
    Hebrews: 'எபிரேயர்',
    James: 'ஜேம்ஸ்',
    '1 Peter': '1 பீட்டர்',
    '2 Peter': '2 பீட்டர்',
    '1 John': '1 யோவான்',
    '2 John': '2 ஜான்',
    '3 John': '3 ஜான்',
    Jude: 'ஜூட்',
    Revelation: 'வெளிப்பாடு',
    'resources.prompt':
      'நீங்கள் மொழிபெயர்க்கும் பத்தியில் கூடுதல் பார்வையை விவிலிய வளங்கள் சேர்க்கவும்.',
    'resources.add': 'ஒரு விவிலிய வள சேர்',
    Manuscript: 'கையெழுத்துப்படி',
    Morphology: 'உருவியலில்',
    Lexicon: 'லெக்சிகன்',
    'Syntactic Tree': 'தொடரியல் மரம்',
    'Go to project list': 'திட்டம் பட்டியலிற்குச் செல்லலாம்',
    'Project not found': 'திட்ட கண்டுபிடிக்கப்பட்டதா அல்லது போதிய அனுமதி இல்லை.',
    'error.referenceDoesNotExistForResource':
      'இந்த பைபிள் மொழிபெயர்ப்பு தேர்ந்தெடுக்கப்பட்டுள்ளன வழிப்பாதையை உள்ளடக்கம் இல்லை.',
    'references.addAnother': 'மற்றொரு மொழிபெயர்ப்புக்கும் சேர்',
    'references.referencePrompt': 'கூடுதல் பார்வையை மற்ற பைபிள் மொழிபெயர்ப்பு சேர்க்க.',
    'references.add': 'பைபிள் மொழிபெயர்ப்பு சேர்',
    'translation.complete': 'மொழிபெயர்ப்பு முழுமையான',
    Alignment: 'சீரமைப்பு',
    Share: 'பகிர்',
    'Your Translation': 'உங்கள் மொழிபெயர்ப்பு',
    'Enter your translation': 'உங்கள் மொழிபெயர்ப்பு சேர்க்கவும்',
    OK: 'சரி',
    'error.verseTranslationEmpty': 'நீங்கள் மொழிபெயர்ப்பு உள்ளடக்கத்தை காலியாக விட முடியாது.',
    'translation.hide.completed': 'நிறைவு மறை',
    alignment: 'சீரமைப்பு',
    'alignment.verify': 'சரிபார்க்கவும்',
    'alignment.backToEditor': 'ஆசிரியர் மீண்டும்',
    'alignment.error':
      'ஏதோ தவறு நடைபெற்றிருக்கிறது. சிக்கல் தொடர்ந்தால், YouTranslate.Bible அணி தொடர்பு கொள்ளவும்.',
    'alignment.add': 'இணைப்பு சேர்க்க',
    'alignment.delete': 'இணைப்பு நீக்க',
    'alignment.clear': 'தெளிவு தேர்வு',
    'alignment.reverse': 'தலைகீழ் காட்சி',
    'alignment.removeOrClear':
      'தேர்ந்தெடுக்கப்பட்டுள்ளன இணைப்பு அல்லது தேர்ந்தெடுத்ததை அழி நீக்கவும்.',
    'alignment.addOrRemove': 'ஒரு புதிய இணைப்பு உருவாக்கவும் அல்லது முந்தைய உள்ளதை நீக்கவும்.',
    'alignment.selectAndAdd': 'இணைப்பை நீக்க பகுதியில் தொடர்புடைய தேர்ந்தெடுக்கவும்.',
    'error.mustBeSignedToMakeChanges': 'நீங்கள் செய்யும் மாற்றங்கள் உள்நுழையப்படுவீர்கள் வேண்டும்.',
    'error.mustBeSignedToTranslateBible': 'நீங்கள் பைபிள் மொழிபெயர்க்க உள்நுழைந்திருக்க வேண்டும்.',
    checking: 'சரிபார்க்கிறது',
    'verseEditor.verseSuggestions': 'ஆலோசனைகள்',
    verses: 'வசனம் மூலம்',
    words: 'வார்த்தை மூலம்',
    'leftPanel.name': 'பைபிள் மூல உரை',
    'rightPanel.name': 'பைபிள் மொழிபெயர்ப்புகள்',
    'bottomPanel.name': 'பைபிள் வளங்கள்',
    'syntax.adv': 'வினையுரிச்சொல்',
    'syntax.advp': 'வினையுரிச்சொல் சொற்றொடர்',
    'syntax.cl': 'உட்கூறு',
    'syntax.conj': 'இணைந்து',
    'syntax.cjp': 'இணைந்து',
    'syntax.np': 'பெயர்ச்சொல் சொற்றொடர்',
    'syntax.o': 'பொருள்',
    'syntax.io': 'மறைமுக பொருள்',
    'syntax.p': 'பயனிலை',
    'syntax.pp': 'முன்னிடை சொற்றொடர்',
    'syntax.s': 'பொருள்',
    'syntax.v': 'வினை',
    'syntax.vc': 'வினை உட்பிரிவு',
    'syntax.adv.description':
      'மாற்றங்களும் பேச்சு மற்றொரு பகுதி. பொதுவாக முறையில், இடம், நேரம், அதிர்வெண், பட்டம், நிச்சயமாக நிலை, முதலியன வெளிப்படுத்த',
    'syntax.advp.description':
      'ஒரு பல வார்த்தை வெளிப்பாடு மாற்றங்களும் என்று பேச்சு மற்றொரு பகுதி. பொதுவாக முறையில், இடம், நேரம், அதிர்வெண், பட்டம், நிச்சயமாக நிலை போன்றவை வெளிப்படுத்த ...',
    'syntax.cl.description': 'ஒரு வினை கொண்டிருக்கும் ஒரு வாக்கியத்தில் ஒரு பகுதி.',
    'syntax.conj.description': 'வார்த்தைகள், சொற்றொடர்கள், மற்றும் பிரிவுகளைக் இணைக்கிறது.',
    'syntax.cjp.description': 'வார்த்தைகள், சொற்றொடர்கள், மற்றும் பிரிவுகளைக் இணைக்கிறது.',
    'syntax.np.description': 'ஒரு பெயர்ச்சொல்லாக செயல்பாடு செயல்படுத்தும் பல சொல் வெளிப்பாடு.',
    'syntax.o.description': 'பொருள் செயற்படுத்தப்படும் என்று நிறுவனம்.',
    'syntax.io.description': 'இந்த ஒரு விளக்க கடினமாக உள்ளது.',
    'syntax.p.description':
      'பொருள் திருத்துகிறது. பொதுவாக ஒரு வினைச் சொல்லைக் கொண்டு நடித்துள்ளனர்.',
    'syntax.pp.description': 'இந்த ஒரு விளக்க கடினமாக உள்ளது.',
    'syntax.s.description': 'உட்கூறு வினை கட்டுப்படுத்தும் நிறுவனம்.',
    'syntax.v.description': 'ஒரு நடவடிக்கை தெரிவிக்கும்.',
    'syntax.vc.description':
      'ஒரு பல வார்த்தை வெளிப்பாடு என்று செய்கிறது வினைச்சொல்லில் செயல்பாடு (தெரிவிக்கும் ஒரு நடவடிக்கை).',
    'general.close': 'நெருக்கமான',
    'general.action': 'அதிரடி',
    'general.select': 'தேர்வு ...',
    'general.back': 'மீண்டும்',
  },
  th: {
    'languages.en': 'อังกฤษ',
    'languages.am': 'อัมฮาริค',
    'languages.bn': 'บังคลาเทศภาษา',
    'languages.ceb': 'Cebuano ภาษา',
    'languages.de': 'ภาษาเยอรมัน',
    'languages.es-419': 'ภาษาสเปน',
    'languages.fr': 'ภาษาฝรั่งเศส',
    'languages.gu': 'คุชราต',
    'languages.hi': 'ภาษาฮินดี',
    'languages.hr': 'โครเอเชียภาษา',
    'languages.hu': 'ฮังการีภาษา',
    'languages.id': 'ภาษาอินโดนีเซีย',
    'languages.km': 'ขอม',
    'languages.kn': 'ดา',
    'languages.ko': 'ภาษาเกาหลี',
    'languages.lo': 'ภาษาลาว',
    'languages.ml': 'มาลายาลัม',
    'languages.mr': 'ฐี',
    'languages.ms': 'ชาวมาเลเซีย',
    'languages.my': 'ภาษาพม่า',
    'languages.ne': 'เนปาลภาษา',
    'languages.plt': 'มาดากัสการ์ภาษา',
    'languages.pt-br': 'โปรตุเกส (บราซิล) ภาษา',
    'languages.ru': 'ภาษารัสเซีย',
    'languages.sw': 'ภาษาสวาฮิลี',
    'languages.ta': 'ภาษาทมิฬ',
    'languages.th': 'ภาษาไทย',
    'languages.tl': 'ตากาล็อก',
    'languages.te': 'Telegu ภาษา',
    'languages.vi': 'ภาษาเวียดนาม',
    'languages.zh-hans': 'ภาษาจีน (ดั้งเดิม)',
    'languages.zh-hant': 'ภาษาจีน (ดั้งเดิม)',
    'header.home': 'บ้าน',
    'header.help': 'ช่วยด้วย',
    'header.project': 'โครงการ',
    'help.contactSupport': 'กรุณาส่ง email มาที่ help@youtranslate.bible เพื่อขอความช่วยเหลือ',
    'home.ytbProvides': 'YouTranslate.Bible ได้อย่างอิสระให้:',
    'home.easyToUseTool': 'ที่ง่ายต่อการใช้เครื่องมือการแปลออนไลน์',
    'home.originalTextHelp': 'ช่วยให้เข้าใจข้อความของพระคัมภีร์ในภาษาเดิม',
    'home.referencesAndResources': 'ทรัพยากรการอ้างอิงและการฝึกอบรม',
    'home.computerAssistance': 'state-of-the-Art เทคโนโลยีคอมพิวเตอร์เพื่อช่วยแปล',
    'profile.userProfile': 'ประวัติผู้ใช้',
    'profile.language': 'ภาษา',
    'profile.update': 'ปรับปรุง',
    'usernav.profile': 'ข้อมูลส่วนตัว',
    'usernav.logout': 'ออกจากระบบ',
    'usernav.logout.modalTitle': 'ออกจากระบบของ YouTranslate.Bible',
    'usernav.logout.modalText':
      'คุณแน่ใจว่าคุณต้องการที่จะออกจากระบบ? ทำงานไม่ได้บันทึกทั้งหมดจะหายไป',
    cancel: 'ยกเลิก',
    delete: 'ลบ',
    create: 'สร้าง',
    update: 'ปรับปรุง',
    edit: 'แก้ไข',
    save: 'บันทึก',
    add: 'เพิ่ม',
    'project.create.title': 'โครงการแปลพระคัมภีร์',
    'project.create.list': 'รายชื่อโครงการ',
    'project.list.deleteProject': 'ลบโครงการ?',
    'project.list.areYouSure': 'คุณแน่ใจหรือไม่ว่าต้องการลบโครงการนี้หรือไม่',
    'project.list.workWillBeDeleted': 'โครงการและการทำงานใด ๆ ที่เกี่ยวข้องจะถูกลบ',
    'project.list.completion': 'แล้วเสร็จ:',
    'project.list.createdDate': 'วันที่สร้าง:',
    'project.list.beginningDate': 'เริ่มต้นวันที่:',
    'project.list.targetCompletionDate': 'เป้าหมายแล้วเสร็จวันที่:',
    'project.list.description': 'รายละเอียด:',
    'project.edit': 'แก้ไขโครงการ',
    'project.list.translateBible': 'แปลพระคัมภีร์',
    'project.list.empty': 'รายชื่อโครงการการแปลของคุณว่างเปล่า',
    'project.list.myProjects': 'โครงการของพระคัมภีร์แปล',
    'project.list.newProject': 'โครงการใหม่',
    'project.create.createNew': 'สร้างโครงการใหม่',
    'project.namePlaceholder': 'ใส่ชื่อโครงการ',
    'project.descriptionPlaceholder': 'ใส่รายละเอียดโครงการ',
    'project.name': 'ชื่อโครงการ',
    'project.beginningDate': 'เริ่มต้นวันที่',
    'project.targetCompletionDate': 'เป้าหมายวันที่เสร็จสมบูรณ์',
    'project.description': 'ลักษณะ',
    'project.manage-users': 'จัดการแปล',
    'project.new-user': 'แปลใหม่',
    'project.add-user': 'เพิ่มแปล',
    'project.users': 'แปลโครงการ',
    'project.read-only': 'อ่านเท่านั้น',
    'project.read&write': 'อ่านเขียน',
    'project.owner': 'เจ้าของ',
    'project.translator': 'นักแปล',
    'project.message.no-such-user': 'ไม่มีแปลดังกล่าวอยู่',
    'project.message.user-added': 'นักแปลเพิ่มเรียบร้อยแล้ว',
    'project.message.user-deleted': 'นักแปลลบออก',
    'project.message.only-owner-can-manage-users': 'เจ้าของโครงการเท่านั้นที่สามารถจัดการผู้ใช้',
    'project.shared': 'โครงการความร่วมมือ',
    'general.delete-user': 'แปลลบ',
    'general.edit-user': 'แก้ไขผู้ใช้',
    'general.name': 'ชื่อ',
    'general.email': 'E-mail',
    'general.permissions': 'สิทธิ์',
    'general.role': 'บทบาท',
    signin: 'เข้าสู่ระบบ',
    'signin.emailPlaceholder': 'ที่อยู่อีเมล',
    'signin.passwordPlaceholder': 'รหัสผ่าน',
    'signin.forgotPassword': 'ลืมรหัสผ่านหรือไม่?',
    'signin.rememberMe': 'จดจำฉัน',
    'signin.facebook': 'เข้าสู่ระบบด้วย Facebook',
    'signin.google': 'เข้าสู่ระบบกับ Google',
    'signin.dontHaveAccount': 'ไม่ได้มีบัญชีอยู่แล้ว?',
    'signin.createOne': 'สร้างหนึ่ง',
    'signup.join': 'เข้าร่วม YouTranslate.Bible',
    'signup.createAccountPrompt': 'สร้างบัญชีฟรีของคุณจะเริ่มต้น',
    'signup.createAccount': 'สร้างบัญชี',
    'signup.emailPlaceholder': 'ที่อยู่อีเมล',
    'signup.passwordPlaceholder': 'รหัสผ่าน',
    'translation.project': 'โครงการ:',
    'translation.backToProjectList': 'กลับไปยังรายการโครงการ',
    'translation.book': 'หนังสือ:',
    'translation.chapter': 'บท:',
    'translation.prompt': 'คุณแน่ใจหรือไม่ว่าต้องการออกจากบทนี้? ทำงานไม่ได้บันทึกทั้งหมดจะหายไป',
    'translation.prev': 'ก่อนหน้า',
    'translation.next': 'ต่อไป',
    'translation.previousChapter': 'บทก่อนหน้า',
    'translation.nextChapter': 'บทต่อไป',
    'navigator.book': 'หนังสือ:',
    'navigator.chapter': 'บท:',
    'navigator.oldTestament': 'พันธสัญญาเดิม',
    'navigator.newTestament': 'พันธสัญญาใหม่',
    'navigator.selectBook': 'เลือกหนังสือ',
    'navigator.search': 'ค้นหา',
    'navigator.changeBook': 'หนังสือเปลี่ยน',
    'navigator.changeChapter': 'เปลี่ยนบท',
    Genesis: 'แหล่งกำเนิด',
    Exodus: 'พระธรรม',
    Leviticus: 'เลวีนิติ',
    Numbers: 'เบอร์',
    Deuteronomy: 'เฉลยธรรมบัญญัติ',
    Joshua: 'โจชัว',
    Judges: 'ผู้พิพากษา',
    Ruth: 'ความสงสาร',
    '1 Samuel': '1 ซามูเอล',
    '2 Samuel': '2 ซามูเอล',
    '1 Kings': '1 พงศ์กษัตริย์',
    '2 Kings': '2 พงศ์กษัตริย์',
    '1 Chronicles': '1 พงศาวดาร',
    '2 Chronicles': '2 พงศาวดาร',
    Ezra: 'เอสรา',
    Nehemiah: 'เนหะมีย์',
    Esther: 'เอสเธอร์',
    Job: 'งาน',
    Psalms: 'เพลงสดุดี',
    Proverbs: 'สุภาษิต',
    Ecclesiastes: 'ปัญญาจารย์',
    'Song of Songs': 'เพลงเพลง',
    Isaiah: 'อิสยาห์',
    Jeremiah: 'เยเรมีย์',
    Lamentations: 'คร่ำครวญ',
    Ezekiel: 'เอเสเคียล',
    Daniel: 'แดเนียล',
    Hosea: 'โฮเชยา',
    Joel: 'โจเอล',
    Amos: 'เอมัส',
    Obadiah: 'โอบาดีห์',
    Jonah: 'โยนาห์',
    Micah: 'มีคาห์',
    Nahum: 'นาฮูม',
    Habakkuk: 'ฮาบากุก',
    Zephaniah: 'เศฟันยาห์',
    Haggai: 'ฮักกัย',
    Zechariah: 'เศคาริยา',
    Malachi: 'มาลาคี',
    Matthew: 'แมทธิว',
    Mark: 'เครื่องหมาย',
    Luke: 'ลุค',
    John: 'จอห์น',
    Acts: 'การกระทำ',
    Romans: 'ชาวโรมัน',
    '1 Corinthians': '1 โครินธ์',
    '2 Corinthians': '2 โครินธ์',
    Galatians: 'กาลาเทีย',
    Ephesians: 'เอเฟซัส',
    Philippians: 'ฟิลิป',
    Colossians: 'โคโลสี',
    '1 Thessalonians': '1 เธสะโลนิ',
    '2 Thessalonians': '2 สะโลนิกา',
    '1 Timothy': '1 ทิโมธี',
    '2 Timothy': '2 ทิโมธี',
    Titus: 'ติตัส',
    Philemon: 'ฟิเล',
    Hebrews: 'ฮีบรู',
    James: 'เจมส์',
    '1 Peter': '1 เปโตร',
    '2 Peter': '2 เปโตร',
    '1 John': '1 ยอห์',
    '2 John': '2 จอห์น',
    '3 John': '3 จอห์น',
    Jude: 'จูด',
    Revelation: 'การเปิดเผย',
    'resources.prompt':
      'เพิ่มทรัพยากรในพระคัมภีร์ไบเบิลสำหรับความเข้าใจเพิ่มเติมลงในทางที่คุณกำลังจะแปล',
    'resources.add': 'เพิ่มทรัพยากรในพระคัมภีร์ไบเบิล',
    Manuscript: 'ต้นฉบับ',
    Morphology: 'สัณฐานวิทยา',
    Lexicon: 'พจนานุกรม',
    'Syntactic Tree': 'วากยสัมพันธ์ต้นไม้',
    'Go to project list': 'ไปที่รายการโครงการ',
    'Project not found': 'โครงการไม่พบหรือสิทธิ์ไม่เพียงพอ',
    'error.referenceDoesNotExistForResource': 'นี้แปลพระคัมภีร์ไม่ได้มีเนื้อหาสำหรับทางเลือก',
    'references.addAnother': 'เพิ่มการแปลอีก',
    'references.referencePrompt': 'เพิ่มการแปลพระคัมภีร์อื่น ๆ สำหรับข้อมูลเชิงลึกเพิ่มเติม',
    'references.add': 'เพิ่มการแปลพระคัมภีร์',
    'translation.complete': 'สมบูรณ์แปล',
    Alignment: 'การวางแนว',
    Share: 'หุ้น',
    'Your Translation': 'การแปลของคุณ',
    'Enter your translation': 'ใส่การแปลของคุณ',
    OK: 'ตกลง',
    'error.verseTranslationEmpty': 'คุณไม่สามารถออกจากเนื้อหาการแปลที่ว่างเปล่า',
    'translation.hide.completed': 'ซ่อนเสร็จสมบูรณ์',
    alignment: 'การวางแนว',
    'alignment.verify': 'ตรวจสอบ',
    'alignment.backToEditor': 'กลับไปแก้ไข',
    'alignment.error': 'บางอย่างผิดพลาด. หากยังคงมีปัญหาโปรดติดต่อทีม YouTranslate.Bible',
    'alignment.add': 'เพิ่มลิ้งค์',
    'alignment.delete': 'ลบลิงก์',
    'alignment.clear': 'เลือกที่ชัดเจน',
    'alignment.reverse': 'จอแสดงผลแบบย้อนกลับ',
    'alignment.removeOrClear': 'นำลิงก์ที่เลือกหรือตัวเลือกที่ชัดเจน',
    'alignment.addOrRemove': 'สร้างการเชื่อมโยงใหม่หรือลบหนึ่งก่อนหน้านี้',
    'alignment.selectAndAdd': 'เลือกกลุ่มที่สอดคล้องกับการเชื่อมโยง',
    'error.mustBeSignedToMakeChanges': 'คุณต้องลงนามในการเปลี่ยนแปลงให้',
    'error.mustBeSignedToTranslateBible': 'คุณต้องลงนามในการแปลพระคัมภีร์',
    checking: 'การตรวจสอบ',
    'verseEditor.verseSuggestions': 'ข้อเสนอแนะ',
    verses: 'โดย Verse',
    words: 'ด้วยคำพูด',
    'leftPanel.name': 'ข้อความเดิมของพระคัมภีร์',
    'rightPanel.name': 'คำของพระคัมภีร์',
    'bottomPanel.name': 'ทรัพยากรสำหรับพระคัมภีร์',
    'syntax.adv': 'คำวิเศษณ์',
    'syntax.advp': 'วลีคำวิเศษณ์',
    'syntax.cl': 'ประโยค',
    'syntax.conj': 'การเชื่อม',
    'syntax.cjp': 'การเชื่อม',
    'syntax.np': 'นามวลี',
    'syntax.o': 'วัตถุ',
    'syntax.io': 'วัตถุทางอ้อม',
    'syntax.p': 'คำกริยา',
    'syntax.pp': 'บุพบทวลี',
    'syntax.s': 'เรื่อง',
    'syntax.v': 'คำกริยา',
    'syntax.vc': 'กริยาข้อ',
    'syntax.adv.description':
      'ปรับเปลี่ยนส่วนหนึ่งของคำพูดอีก โดยปกติแสดงลักษณะที่, สถานที่, เวลาความถี่ระดับระดับของความเชื่อมั่น ฯลฯ',
    'syntax.advp.description':
      'นิพจน์หลายคำที่ปรับเปลี่ยนส่วนหนึ่งของคำพูดอีก โดยปกติแสดงลักษณะที่, สถานที่, เวลาความถี่ระดับระดับของความเชื่อมั่น ฯลฯ ...',
    'syntax.cl.description': 'เป็นส่วนหนึ่งของประโยคที่มีคำกริยา',
    'syntax.conj.description': 'เชื่อมต่อคำวลีและคำสั่ง',
    'syntax.cjp.description': 'เชื่อมต่อคำวลีและคำสั่ง',
    'syntax.np.description': 'นิพจน์หลายคำที่มีประสิทธิภาพการทำงานของคำนามที่',
    'syntax.o.description': 'กิจการที่มีการดำเนินการใด ๆ โดยเรื่อง',
    'syntax.io.description': 'หนึ่งในนี้เป็นเรื่องยากที่จะอธิบาย',
    'syntax.p.description': 'ปรับเปลี่ยนเรื่อง มักจะแสดงร่วมกับคำกริยา',
    'syntax.pp.description': 'หนึ่งในนี้เป็นเรื่องยากที่จะอธิบาย',
    'syntax.s.description': 'กิจการที่ควบคุมกริยาของประโยคที่',
    'syntax.v.description': 'บ่งบอกถึงการกระทำ',
    'syntax.vc.description':
      'นิพจน์หลายคำที่มีประสิทธิภาพการทำงานของคำกริยา (บ่งบอกถึงการดำเนินการ)',
    'general.close': 'ปิด',
    'general.action': 'หนังบู๊',
    'general.select': 'เลือก...',
    'general.back': 'กลับ',
  },
  tl: {
    'languages.en': 'Ingles',
    'languages.am': 'Amharic',
    'languages.bn': 'Bengali Wika',
    'languages.ceb': 'Cebuano Wika',
    'languages.de': 'German Wika',
    'languages.es-419': 'Spanish Wika',
    'languages.fr': 'Pranses Wika',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Hindi',
    'languages.hr': 'Croatian Wika',
    'languages.hu': 'Hungarian Wika',
    'languages.id': 'Indonesian Wika',
    'languages.km': 'Khmer',
    'languages.kn': 'Kannada',
    'languages.ko': 'Wikang Koreano',
    'languages.lo': 'Laotian Wika',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'Malaysian',
    'languages.my': 'Burmese Wika',
    'languages.ne': 'Nepali Wika',
    'languages.plt': 'Malagasy Wika',
    'languages.pt-br': 'Portuges (Brazil) Wika',
    'languages.ru': 'Russian Wika',
    'languages.sw': 'Swahili',
    'languages.ta': 'Tamil Wika',
    'languages.th': 'Thai Wika',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Wika',
    'languages.vi': 'Wikang Vietnamese',
    'languages.zh-hans': 'Chinese Language (Pinapayak)',
    'languages.zh-hant': 'Chinese Language (Tradisyunal)',
    'header.home': 'bahay',
    'header.help': 'Tulong',
    'header.project': 'proyekto',
    'help.contactSupport':
      'Mangyaring mag-email sa amin sa help@youtranslate.bible para sa tulong.',
    'home.ytbProvides': 'YouTranslate.Bible malayang nagbibigay ng:',
    'home.easyToUseTool': 'Ang isang madaling-gamitin na online na kasangkapan translation',
    'home.originalTextHelp': 'Tulong upang maunawaan ang teksto ng Bibliya sa orihinal na mga wika',
    'home.referencesAndResources': 'Reference at pagsasanay mapagkukunan',
    'home.computerAssistance':
      'State-of-the-art na computer na teknolohiya upang tulungan ang mga tagapagsalin',
    'profile.userProfile': 'Profile ng gumagamit',
    'profile.language': 'wika',
    'profile.update': 'I-update',
    'usernav.profile': 'profile',
    'usernav.logout': 'Mag-logout',
    'usernav.logout.modalTitle': 'Mag-logout sa YouTranslate.Bible',
    'usernav.logout.modalText':
      'Sigurado ka bang gusto mong mag-logout? Ang lahat ng hindi na-save na trabaho ay mawawala.',
    cancel: 'kanselahin',
    delete: 'Delete',
    create: 'lumikha',
    update: 'I-update',
    edit: 'I-edit',
    save: 'I-save ang',
    add: 'magdagdag',
    'project.create.title': 'Bible Translation Project',
    'project.create.list': 'listahan Project',
    'project.list.deleteProject': 'Burahin Project?',
    'project.list.areYouSure': 'Sigurado ka bang gusto mong tanggalin ang proyektong ito?',
    'project.list.workWillBeDeleted': 'Ang proyekto at anumang trabaho na nauugnay ay tatanggalin.',
    'project.list.completion': 'pagkumpleto:',
    'project.list.createdDate': 'Nilikha Petsa:',
    'project.list.beginningDate': 'Simula ng petsa:',
    'project.list.targetCompletionDate': 'Takdang pasahan:',
    'project.list.description': 'Paglalarawan:',
    'project.edit': 'Edit Project',
    'project.list.translateBible': 'Isalin sa Bibliya',
    'project.list.empty': 'Ang iyong listahan translation project ay walang laman.',
    'project.list.myProjects': 'My Bible Translation Proyekto',
    'project.list.newProject': 'Bagong proyekto',
    'project.create.createNew': 'Lumikha ng isang bagong Project',
    'project.namePlaceholder': 'Ipasok ang pangalan ng proyekto',
    'project.descriptionPlaceholder': 'Ipasok ang paglalarawan ng proyekto',
    'project.name': 'Pangalan ng proyekto',
    'project.beginningDate': 'simula ng Petsa',
    'project.targetCompletionDate': 'takdang pasahan',
    'project.description': 'paglalarawan',
    'project.manage-users': 'pamahalaan ang Mga Tagapagsalin',
    'project.new-user': 'New tagasalin',
    'project.add-user': 'add tagasalin',
    'project.users': 'tagasalin Project',
    'project.read-only': 'Basahin lamang',
    'project.read&write': 'Basa sulat',
    'project.owner': 'may-ari',
    'project.translator': 'tagasalin',
    'project.message.no-such-user': 'Walang ganitong tagasalin umiiral',
    'project.message.user-added': 'Tagasalin matagumpay na naidagdag',
    'project.message.user-deleted': 'dahil Translator.',
    'project.message.only-owner-can-manage-users':
      'Tanging may-ari ng proyekto ay maaaring pamahalaan ang mga user',
    'project.shared': 'collaborative proyekto',
    'general.delete-user': 'Delete tagasalin',
    'general.edit-user': 'edit ang user',
    'general.name': 'pangalan',
    'general.email': 'E-mail',
    'general.permissions': 'Pahintulot',
    'general.role': 'papel',
    signin: 'Mag-sign in',
    'signin.emailPlaceholder': 'email address',
    'signin.passwordPlaceholder': 'password',
    'signin.forgotPassword': 'Nakalimutan ang Iyong Password?',
    'signin.rememberMe': 'Tandaan mo ako',
    'signin.facebook': 'Mag-sign in gamit ang Facebook',
    'signin.google': 'Mag-sign in sa Google',
    'signin.dontHaveAccount': 'Huwag magkaroon ng isang account?',
    'signin.createOne': 'Gumawa ng isa',
    'signup.join': 'Sumali YouTranslate.Bible',
    'signup.createAccountPrompt': 'Lumikha ng iyong libreng account upang magsimula.',
    'signup.createAccount': 'Gumawa ng Account',
    'signup.emailPlaceholder': 'email address',
    'signup.passwordPlaceholder': 'password',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'Bumalik sa listahan ng mga proyekto',
    'translation.book': 'Aklat:',
    'translation.chapter': 'Chapter:',
    'translation.prompt':
      'Sigurado ka bang gusto mong umalis sa kabanatang ito? Ang lahat ng hindi na-save na trabaho ay mawawala.',
    'translation.prev': 'Prev',
    'translation.next': 'susunod',
    'translation.previousChapter': 'Previous chapter',
    'translation.nextChapter': 'Sunod na kabanata',
    'navigator.book': 'Aklat:',
    'navigator.chapter': 'Chapter:',
    'navigator.oldTestament': 'Lumang Tipan',
    'navigator.newTestament': 'Bagong Tipan',
    'navigator.selectBook': 'Pumili ng isang aklat',
    'navigator.search': 'Hanapan',
    'navigator.changeBook': 'Baguhin aklat',
    'navigator.changeChapter': 'Baguhin ang kabanatang',
    Genesis: 'simula',
    Exodus: 'pag-aalisan',
    Leviticus: 'Levitico',
    Numbers: 'Numero',
    Deuteronomy: 'Dyuteronomi',
    Joshua: 'Joshua',
    Judges: 'Mga hukom',
    Ruth: 'awa',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Mga Hari',
    '2 Kings': '2 Mga Hari',
    '1 Chronicles': '1 Mga Cronica',
    '2 Chronicles': '2 Mga Cronica',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemias',
    Esther: 'Esther',
    Job: 'trabaho',
    Psalms: 'Mga Awit',
    Proverbs: 'Kawikaan',
    Ecclesiastes: 'Ang Mangangaral',
    'Song of Songs': 'Awit ng mga Awit',
    Isaiah: 'Isaya',
    Jeremiah: 'Jeremiah',
    Lamentations: 'pagpipighati',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'Jonas',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacuc',
    Zephaniah: 'Zephaniah',
    Haggai: 'Haggai',
    Zechariah: 'Zacarias',
    Malachi: 'Malakias',
    Matthew: 'Matthew',
    Mark: 'marka',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Mga Gawa',
    Romans: 'Roma',
    '1 Corinthians': '1 Mga Taga-Corinto',
    '2 Corinthians': '2 Mga Taga-Corinto',
    Galatians: 'Mga Taga-Galacia',
    Ephesians: 'Mga Taga-Efeso',
    Philippians: 'Filipos',
    Colossians: 'Mga Taga-Colosas',
    '1 Thessalonians': '1 Tesalonica',
    '2 Thessalonians': '2 Mga Taga-Tesalonica',
    '1 Timothy': '1 Timoteo',
    '2 Timothy': '2 Timoteo',
    Titus: 'Tito',
    Philemon: 'Philemon',
    Hebrews: 'Hebreo',
    James: 'James',
    '1 Peter': '1 Pedro',
    '2 Peter': '2 Pedro',
    '1 John': '1 Juan',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'paghahayag',
    'resources.prompt':
      'Magdagdag ng mga mapagkukunan Bibliya para sa karagdagang pananaw sa mga talata na iyong isinasalin.',
    'resources.add': 'Magdagdag ng isang Bibliya na mapagkukunan',
    Manuscript: 'manuskrito',
    Morphology: 'morpolohiya',
    Lexicon: 'leksikon',
    'Syntactic Tree': 'sintaktik Tree',
    'Go to project list': 'Pumunta sa listahan ng mga proyekto',
    'Project not found': 'Project ay hindi nahanap o hindi sapat na mga pahintulot.',
    'error.referenceDoesNotExistForResource':
      'Ito translation Biblia ay hindi naglalaman ng nilalaman para sa mga napiling mga talata.',
    'references.addAnother': 'Magdagdag ng isa pang translation',
    'references.referencePrompt':
      'Magdagdag ng iba pang mga pagsasalin ng Bibliya para sa karagdagang pananaw.',
    'references.add': 'Magdagdag ng isang salin ng Bibliya',
    'translation.complete': 'translation kumpleto',
    Alignment: 'pagkakahanay',
    Share: 'magbahagi',
    'Your Translation': 'Ang iyong Translation',
    'Enter your translation': 'Ipasok ang iyong pagsasalin',
    OK: 'OK',
    'error.verseTranslationEmpty':
      'Hindi ka maaaring umalis ang nilalaman translation alisan ng laman.',
    'translation.hide.completed': 'Itago ang nakumpleto',
    alignment: 'pagkakahanay',
    'alignment.verify': 'mapatunayan',
    'alignment.backToEditor': 'Bumalik sa Editor',
    'alignment.error':
      'May nangyaring mali. Kung ang nagpatuloy problema, mangyaring makipag-ugnay sa YouTranslate.Bible team.',
    'alignment.add': 'Magdagdag ng Link',
    'alignment.delete': 'alisin ang Link',
    'alignment.clear': 'Limasin ang Pagpili',
    'alignment.reverse': 'reverse Display',
    'alignment.removeOrClear': 'Alisin ang napiling link o malinaw na pagpili.',
    'alignment.addOrRemove': 'Lumikha ng isang bagong link o magtanggal ng isang nakaraan.',
    'alignment.selectAndAdd': 'Piliin kaukulang mga segment sa link na ito.',
    'error.mustBeSignedToMakeChanges': 'Kailangang naka-sign in ka sa make pagbabago.',
    'error.mustBeSignedToTranslateBible': 'Kailangang naka-sign in upang isalin ang Bibliya.',
    checking: 'Sinusuri',
    'verseEditor.verseSuggestions': 'mungkahi',
    verses: 'Sa pamamagitan ng Verse',
    words: 'Sa pamamagitan ng Salita',
    'leftPanel.name': 'Orihinal na Teksto ng Bibliya',
    'rightPanel.name': 'Pagsasalin ng Bibliya',
    'bottomPanel.name': 'Mga mapagkukunan para sa Bibliya',
    'syntax.adv': 'pang-abay',
    'syntax.advp': 'pang-abay Parirala',
    'syntax.cl': 'sugnay',
    'syntax.conj': 'kasabay',
    'syntax.cjp': 'kasabay',
    'syntax.np': 'pangngalan Parirala',
    'syntax.o': 'bagay',
    'syntax.io': 'Hindi direktang bagay',
    'syntax.p': 'panaguri',
    'syntax.pp': 'preposisyonal Parirala',
    'syntax.s': 'paksa',
    'syntax.v': 'pandiwa',
    'syntax.vc': 'pandiwa Clause',
    'syntax.adv.description':
      'Binabago isa pang bahagi ng pananalita. Kadalasan ipahayag ang paraan, lugar, oras, kadalasan, degree, antas ng katiyakan, at iba pa',
    'syntax.advp.description':
      'Ang isang multi-word expression na modifies isa pang bahagi ng pananalita. Kadalasan ipahayag ang paraan, lugar, oras, kadalasan, degree, antas ng katiyakan, atbp ...',
    'syntax.cl.description': 'Ang isang bahagi ng isang pangungusap na naglalaman ng isang verb.',
    'syntax.conj.description': 'Nag-uugnay sa mga salita, parirala, at mga clauses.',
    'syntax.cjp.description': 'Nag-uugnay sa mga salita, parirala, at mga clauses.',
    'syntax.np.description':
      'Ang isang multi-word expression na gumaganap ang pag-andar ng isang pangngalan.',
    'syntax.o.description': 'Ang entity na kumilos sa pamamagitan ng paksa.',
    'syntax.io.description': 'Isa na ito ay mahirap na ipaliwanag.',
    'syntax.p.description': 'Binabago ang paksa. Karaniwan star sa pandiwa.',
    'syntax.pp.description': 'Isa na ito ay mahirap na ipaliwanag.',
    'syntax.s.description': 'Ang entity na kumokontrol sa pandiwa ng sugnay.',
    'syntax.v.description': 'Binabatid ang isang aksyon.',
    'syntax.vc.description':
      'Ang isang multi-word expression na gumaganap ang pag-andar ng isang verb (conveys isang aksyon).',
    'general.close': 'malapit',
    'general.action': 'aksyon',
    'general.select': 'Piliin ang ...',
    'general.back': 'likod',
  },
  te: {
    'languages.en': 'ఆంగ్ల',
    'languages.am': 'Amharic',
    'languages.bn': 'బెంగాలీ భాషా',
    'languages.ceb': 'సేబుఆనో భాషా',
    'languages.de': 'జర్మన్ భాష',
    'languages.es-419': 'స్పానిష్ భాష',
    'languages.fr': 'ఫ్రెంచ్ భాష',
    'languages.gu': 'gujarati',
    'languages.hi': 'హిందీ',
    'languages.hr': 'Croatian భాషా',
    'languages.hu': 'హంగేరియన్ భాషా',
    'languages.id': 'ఇండోనేషియన్ భాష',
    'languages.km': 'ఖ్మేర్',
    'languages.kn': 'కన్నడ',
    'languages.ko': 'కొరియన్ బాష',
    'languages.lo': 'లావోయేషన్ భాషా',
    'languages.ml': 'మలయాళం',
    'languages.mr': 'మరాఠీ',
    'languages.ms': 'మలేషియన్',
    'languages.my': 'బర్మీస్ భాషా',
    'languages.ne': 'నేపాలీ భాష',
    'languages.plt': 'మాలాగసి భాషా',
    'languages.pt-br': 'పోర్చుగీస్ (బ్రెజిల్) భాషా',
    'languages.ru': 'రష్యన్ భాష',
    'languages.sw': 'Swahili',
    'languages.ta': 'తమిళ భాష',
    'languages.th': 'థాయ్ భాష',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu భాషా',
    'languages.vi': 'vietnamese భాషా',
    'languages.zh-hans': 'చైనీస్ భాష (సరళీకృత)',
    'languages.zh-hant': 'చైనీస్ భాష (సాంప్రదాయ)',
    'header.home': 'హోమ్',
    'header.help': 'సహాయం',
    'header.project': 'ప్రాజెక్ట్',
    'help.contactSupport': 'సహాయం కోసం help@youtranslate.bible వద్ద మాకు ఇమెయిల్ దయచేసి.',
    'home.ytbProvides': 'YouTranslate.Bible ఉచితంగా అందిస్తుంది:',
    'home.easyToUseTool': 'ఒక సులభమైన వాడేందుకు ఆన్లైన్ అనువాదం సాధనం',
    'home.originalTextHelp': 'దాని అసలు భాషలలో బైబిల్ టెక్స్ట్ అర్థం సహాయం',
    'home.referencesAndResources': 'సూచన మరియు శిక్షణ వనరులు',
    'home.computerAssistance': 'రాష్ట్ర ఆఫ్ ఆర్ట్ అనువాదకుల సహాయంగా కంప్యూటర్ సాంకేతిక',
    'profile.userProfile': 'వినియోగదారు వివరాలు',
    'profile.language': 'భాషా',
    'profile.update': 'నవీకరణ',
    'usernav.profile': 'ప్రొఫైల్',
    'usernav.logout': 'లాగౌట్',
    'usernav.logout.modalTitle': 'YouTranslate.Bible యొక్క లాగ్అవుట్',
    'usernav.logout.modalText':
      'మీరు ఖచ్చితంగా ఉన్నాయి లాగ్అవుట్ అనుకుంటున్నారా? అన్ని సేవ్ చేయని పనిని పోతాయి.',
    cancel: 'రద్దు చేయండి',
    delete: 'తొలగించు',
    create: 'సృష్టించు',
    update: 'నవీకరణ',
    edit: 'మార్చు',
    save: 'సేవ్',
    add: 'చేర్చు',
    'project.create.title': 'బైబిల్ అనువాద ప్రాజెక్ట్',
    'project.create.list': 'ప్రాజెక్ట్ జాబితా',
    'project.list.deleteProject': 'ప్రాజెక్ట్ను తొలగించు?',
    'project.list.areYouSure': 'మీరు ఈ ప్రాజెక్ట్ తొలగించడానికి ఉండాలనుకుంటున్నారా?',
    'project.list.workWillBeDeleted': 'ప్రాజెక్ట్ మరియు సంబంధం ఏ పని తొలగించబడుతుంది.',
    'project.list.completion': 'పూర్తి:',
    'project.list.createdDate': 'రూపొందించబడింది తేదీ:',
    'project.list.beginningDate': 'ప్రారంభమై తేదీ:',
    'project.list.targetCompletionDate': 'టార్గెట్ పూర్తిచేసే తేదీ:',
    'project.list.description': 'వివరణ:',
    'project.edit': 'ప్రాజెక్ట్ని సవరించు',
    'project.list.translateBible': 'బైబిల్ అనువదించు',
    'project.list.empty': 'మీ అనువాదం ప్రాజెక్టు జాబితా ఖాళీగా ఉంది.',
    'project.list.myProjects': 'నా బైబిల్ అనువాద ప్రాజెక్ట్స్',
    'project.list.newProject': 'కొత్త ప్రాజెక్ట్',
    'project.create.createNew': 'ఒక కొత్త ప్రాజెక్ట్ సృష్టించు',
    'project.namePlaceholder': 'ప్రాజెక్ట్ పేరును నమోదు',
    'project.descriptionPlaceholder': 'ప్రాజెక్ట్ వివరణ ఎంటర్',
    'project.name': 'ప్రాజెక్ట్ పేరు',
    'project.beginningDate': 'ప్రారంభమై తేదీ',
    'project.targetCompletionDate': 'టార్గెట్ పూర్తిచేసే తేదీ',
    'project.description': 'వివరణ',
    'project.manage-users': 'అనువాదకుల నిర్వహించండి',
    'project.new-user': 'న్యూ అనువాదకుడు',
    'project.add-user': 'యాడ్ అనువాదకుడు',
    'project.users': 'ప్రాజెక్టు అనువాదకుల',
    'project.read-only': 'చదవడానికి మాత్రమే',
    'project.read&write': 'చదువు రాయి',
    'project.owner': 'యజమాని',
    'project.translator': 'అనువాదకుడు',
    'project.message.no-such-user': 'అటువంటి అనువాదకుడు ఉంది',
    'project.message.user-added': 'అనువాదకుడు విజయవంతంగా జోడించారు',
    'project.message.user-deleted': 'అనువాదకుడు తొలగించబడింది.',
    'project.message.only-owner-can-manage-users':
      'మాత్రమే ప్రాజెక్ట్ యజమాని వినియోగదారులు నిర్వహించవచ్చు',
    'project.shared': 'సహకార ప్రాజెక్ట్',
    'general.delete-user': 'తొలగించు అనువాదకుడు',
    'general.edit-user': 'యూజర్ మార్చు',
    'general.name': 'పేరు',
    'general.email': 'ఇ-మెయిల్',
    'general.permissions': 'అనుమతులు',
    'general.role': 'పాత్ర',
    signin: 'సైన్ ఇన్',
    'signin.emailPlaceholder': 'ఇమెయిల్ చిరునామా',
    'signin.passwordPlaceholder': 'పాస్వర్డ్',
    'signin.forgotPassword': 'మీ పాస్వర్డ్ మర్చిపోయారా?',
    'signin.rememberMe': 'నన్ను గుర్తు పెట్టుకో',
    'signin.facebook': 'ఫేస్బుక్ తో సైన్ ఇన్',
    'signin.google': 'Google తో సైన్ ఇన్',
    'signin.dontHaveAccount': 'ఒక ఖాతా లేదా?',
    'signin.createOne': 'ఒకటి సృష్టించు',
    'signup.join': 'YouTranslate.Bible చేరండి',
    'signup.createAccountPrompt': 'ప్రారంభించడానికి మీ ఉచిత ఖాతా సృష్టించు.',
    'signup.createAccount': 'ఖాతా సృష్టించు',
    'signup.emailPlaceholder': 'ఇమెయిల్ చిరునామా',
    'signup.passwordPlaceholder': 'పాస్వర్డ్',
    'translation.project': 'PROJECT:',
    'translation.backToProjectList': 'ప్రాజెక్ట్ జాబితాకు తిరిగి',
    'translation.book': 'పుస్తకం:',
    'translation.chapter': 'అధ్యాయము:',
    'translation.prompt': 'మీరు ఈ అధ్యాయం వదిలి ఉండాలనుకుంటున్నారా? అన్ని సేవ్ చేయని పనిని పోతాయి.',
    'translation.prev': 'మునుపటి',
    'translation.next': 'తరువాత',
    'translation.previousChapter': 'మునుపటి అధ్యాయంలో',
    'translation.nextChapter': 'తదుపరి అధ్యాయం',
    'navigator.book': 'పుస్తకం:',
    'navigator.chapter': 'అధ్యాయము:',
    'navigator.oldTestament': 'పాత నిబంధన',
    'navigator.newTestament': 'కొత్త నిబంధన',
    'navigator.selectBook': 'ఒక పుస్తకం ఎంచుకోవడానికి',
    'navigator.search': 'వెతకండి',
    'navigator.changeBook': 'మార్చు పుస్తకం',
    'navigator.changeChapter': 'మార్చు అధ్యాయం',
    Genesis: 'ఆదికాండము',
    Exodus: 'ఎక్సోడస్',
    Leviticus: 'లెవిటికస్',
    Numbers: 'సంఖ్యలు',
    Deuteronomy: 'ద్వితీయోపదేశకాండము',
    Joshua: 'జాషువా',
    Judges: 'న్యాయమూర్తులు',
    Ruth: 'రూత్',
    '1 Samuel': '1 సమూయేలు',
    '2 Samuel': '2 సమూయేలు',
    '1 Kings': '1 రాజులు',
    '2 Kings': '2 రాజులు',
    '1 Chronicles': '1 దినవృత్తాంతములు',
    '2 Chronicles': '2 క్రానికల్స్',
    Ezra: 'ఎజ్రా',
    Nehemiah: 'నెహెమ్యా',
    Esther: 'ఎస్తేర్',
    Job: 'ఉద్యోగం',
    Psalms: 'కీర్తనలు',
    Proverbs: 'సామెతలు',
    Ecclesiastes: 'ప్రసంగి',
    'Song of Songs': 'సాంగ్ అఫ్ సాంగ్స్',
    Isaiah: 'యెషయా',
    Jeremiah: 'యిర్మీయా',
    Lamentations: 'విలాపవాక్యములు',
    Ezekiel: 'యెహెజ్కేలు',
    Daniel: 'డేనియల్',
    Hosea: 'హోషేయా',
    Joel: 'జోయెల్',
    Amos: 'అమోస్',
    Obadiah: 'ఓబద్యా',
    Jonah: 'జోనా',
    Micah: 'మీకా',
    Nahum: 'నహుం',
    Habakkuk: 'హబక్కూకు',
    Zephaniah: 'జెఫన్యా',
    Haggai: 'హగ్గయి',
    Zechariah: 'జెకర్యా',
    Malachi: 'మలాకీ',
    Matthew: 'మాథ్యూ',
    Mark: 'మార్క్',
    Luke: 'ల్యూక్',
    John: 'జాన్',
    Acts: 'చట్టాలు',
    Romans: 'రోమన్లు',
    '1 Corinthians': '1 కొరింథీయులకు',
    '2 Corinthians': '2 కొరింథీయులకు',
    Galatians: 'గలతీయులకు',
    Ephesians: 'ఎఫెసీయులకు',
    Philippians: 'ఫిలిప్పీయులకు',
    Colossians: 'Colossians',
    '1 Thessalonians': '1 Thessalonians',
    '2 Thessalonians': '2 Thessalonians',
    '1 Timothy': '1 తిమోతి',
    '2 Timothy': '2 తిమోతి',
    Titus: 'తీతుకు',
    Philemon: 'Philemon',
    Hebrews: 'హెబ్రీయులు',
    James: 'జేమ్స్',
    '1 Peter': '1 పేతురు',
    '2 Peter': '2 పేతురు',
    '1 John': '1 జాన్',
    '2 John': '2 యోహాను',
    '3 John': '3 యోహాను',
    Jude: 'జూడ్',
    Revelation: 'ప్రకటన',
    'resources.prompt':
      'మీరు అనువదిస్తున్న మార్గంలోకి అదనపు అంతర్దృష్టి కోసం బైబిల్ వనరులను జోడించవచ్చు.',
    'resources.add': 'గ్రంధముల వనరు జోడించు',
    Manuscript: 'మాన్యుస్క్రిప్ట్',
    Morphology: 'స్వరూప శాస్త్రం',
    Lexicon: 'లెక్సికాన్',
    'Syntactic Tree': 'వాక్యనిర్మాణ ట్రీ',
    'Go to project list': 'ప్రాజెక్ట్ జాబితాకు వెళ్ళండి',
    'Project not found': 'ప్రాజెక్టు దొరకలేదు లేదా సరిపోని అనుమతుల కాదు.',
    'error.referenceDoesNotExistForResource':
      'ఈ బైబిల్ అనువాద ఎంపిక ఆమోదానికి కంటెంట్ కలిగి లేదు.',
    'references.addAnother': 'మరో అనువాద జోడించండి',
    'references.referencePrompt': 'అదనపు అంతర్దృష్టి కోసం ఇతర బైబిలు అనువాదాలు జోడించవచ్చు.',
    'references.add': 'ఒక బైబిల్ అనువాద జోడించండి',
    'translation.complete': 'అనువాద పూర్తి',
    Alignment: 'అమరిక',
    Share: 'Share',
    'Your Translation': 'మీ అనువాదం',
    'Enter your translation': 'మీ అనువాదం ఎంటర్',
    OK: 'అలాగే',
    'error.verseTranslationEmpty': 'మీకు అనువాదం కంటెంట్ ఖాళీగా ఉంచకూడదు.',
    'translation.hide.completed': 'పూర్తి దాచు',
    alignment: 'అమరిక',
    'alignment.verify': 'నిర్ధారించండి',
    'alignment.backToEditor': 'కు ఎడిటర్ తిరిగి',
    'alignment.error':
      'ఎక్కడో తేడ జరిగింది. సమస్య కొనసాగితే, దయచేసి YouTranslate.Bible జట్టు సంప్రదించండి.',
    'alignment.add': 'లింక్ ను జోడించు',
    'alignment.delete': 'లింక్ తొలగించు',
    'alignment.clear': 'క్లియర్ ఎన్నిక',
    'alignment.reverse': 'రివర్స్ డిస్ప్లే',
    'alignment.removeOrClear': 'ఎంపిక లింక్ లేదా స్పష్టమైన ఎంపిక తొలగించండి.',
    'alignment.addOrRemove': 'ఒక కొత్త లింక్ సృష్టించడానికి లేదా ఒక మునుపటి తొలగించండి.',
    'alignment.selectAndAdd': 'లింక్ సంబంధిత విభాగాలు ఎంచుకోండి.',
    'error.mustBeSignedToMakeChanges': 'మీరు చేసిన మార్పులు సైన్ ఇన్ చేయాలి.',
    'error.mustBeSignedToTranslateBible': 'మీరు బైబిల్ అనువదించడానికి సైన్ ఇన్ చెయ్యాలి.',
    checking: 'తనిఖీ చేస్తోంది',
    'verseEditor.verseSuggestions': 'సలహాలు',
    verses: 'వెర్స్ ద్వారా',
    words: 'పద ద్వారా',
    'leftPanel.name': 'బైబిల్ యొక్క వాస్తవ వచనం',
    'rightPanel.name': 'బైబిల్ అనువాదాలు',
    'bottomPanel.name': 'బైబిల్ వనరుల',
    'syntax.adv': 'క్రియా విశేషణం',
    'syntax.advp': 'క్రియా విశేషణం ఫ్రేజ్',
    'syntax.cl': 'ఉపవాక్య',
    'syntax.conj': 'సముచ్చయం',
    'syntax.cjp': 'సముచ్చయం',
    'syntax.np': 'నామవాచక పదము',
    'syntax.o': 'ఆబ్జెక్ట్',
    'syntax.io': 'పరోక్ష వస్తువు',
    'syntax.p': 'ఆధారం అనేది',
    'syntax.pp': 'విభక్తి ఫ్రేజ్',
    'syntax.s': 'Subject',
    'syntax.v': 'క్రియ',
    'syntax.vc': 'క్రియా నిబంధన',
    'syntax.adv.description':
      'మార్పు ప్రసంగం మరొక భాగం. సాధారణంగా పద్ధతిలో, స్థలం, సమయం, ఫ్రీక్వెన్సీ, డిగ్రీ, నిశ్చితమైన స్థాయి, మొదలైనవి వ్యక్తం',
    'syntax.advp.description':
      'అనేక పదాల వ్యక్తీకరణ సవరిస్తుంది ఆ ప్రసంగం యొక్క మరొక భాగం. సాధారణంగా పద్ధతిలో, స్థలం, సమయం, ఫ్రీక్వెన్సీ, డిగ్రీ, నిశ్చితమైన స్థాయి, etc ఎక్స్ప్రెస్ ...',
    'syntax.cl.description': 'ఒక క్రియ కలిగి వాక్యం యొక్క ఒక భాగం.',
    'syntax.conj.description': 'పదాలు, పదబంధాలు మరియు ఉపవాక్యాలు కలుపుతుంది.',
    'syntax.cjp.description': 'పదాలు, పదబంధాలు మరియు ఉపవాక్యాలు కలుపుతుంది.',
    'syntax.np.description': 'ఒక నామవాచకం యొక్క పనిని అనేక పదాల వ్యక్తీకరణ.',
    'syntax.o.description': 'విషయం తరపున అని పరిధి.',
    'syntax.io.description': 'ఈ ఒక వివరించడానికి కష్టం.',
    'syntax.p.description': 'విషయం మార్చుతోంది. సాధారణంగా ఒక క్రియతో నటించారు.',
    'syntax.pp.description': 'ఈ ఒక వివరించడానికి కష్టం.',
    'syntax.s.description': 'నిబంధన యొక్క క్రియా నియంత్రించే పరిధి.',
    'syntax.v.description': 'ఒక చర్య తెలియచేస్తుంది.',
    'syntax.vc.description':
      'అనేక పదాల వ్యక్తీకరణ అమలు ఒక క్రియ యొక్క ఫంక్షన్ (తెలియచేస్తుంది ఒక చర్య).',
    'general.close': 'దగ్గరగా',
    'general.action': 'యాక్షన్',
    'general.select': 'ఎంచుకోండి...',
    'general.back': 'తిరిగి',
  },
  vi: {
    'languages.en': 'Tiếng Anh',
    'languages.am': 'Amharic',
    'languages.bn': 'Tiếng Bengal',
    'languages.ceb': 'Cebuano Ngôn ngữ',
    'languages.de': 'Tiếng Đức',
    'languages.es-419': 'Tiếng Tây Ban Nha',
    'languages.fr': 'Tiếng Pháp',
    'languages.gu': 'Gujarati',
    'languages.hi': 'Tiếng Hin-ddi',
    'languages.hr': 'Croatia Ngôn ngữ',
    'languages.hu': 'Ngôn ngữ hungarian',
    'languages.id': 'Ngôn ngữ Indonesia',
    'languages.km': 'Khmer',
    'languages.kn': 'kannada',
    'languages.ko': 'Ngôn ngữ Hàn Quốc',
    'languages.lo': 'Ngôn ngữ Lào',
    'languages.ml': 'Malayalam',
    'languages.mr': 'Marathi',
    'languages.ms': 'người Malaysia',
    'languages.my': 'Miến Điện Ngôn ngữ',
    'languages.ne': 'Nepali Ngôn ngữ',
    'languages.plt': 'Malagasy Ngôn ngữ',
    'languages.pt-br': 'Bồ Đào Nha (Brazil) Ngôn ngữ',
    'languages.ru': 'Ngôn ngữ Nga',
    'languages.sw': 'swahili',
    'languages.ta': 'Ngôn ngữ Tamil',
    'languages.th': 'Tiếng Thái Lan',
    'languages.tl': 'Tagalog',
    'languages.te': 'Telegu Ngôn ngữ',
    'languages.vi': 'Ngôn ngữ tiếng Việt',
    'languages.zh-hans': 'Trung Quốc Ngôn ngữ (Giản thể)',
    'languages.zh-hant': 'Trung Quốc Ngôn ngữ (Phồn thể)',
    'header.home': 'Trang Chủ',
    'header.help': 'Cứu giúp',
    'header.project': 'Dự án',
    'help.contactSupport':
      'Vui lòng gửi email cho chúng tôi tại help@youtranslate.bible để được giúp đỡ.',
    'home.ytbProvides': 'YouTranslate.Bible tự do cung cấp:',
    'home.easyToUseTool': 'Một công cụ dịch thuật trực tuyến dễ sử dụng',
    'home.originalTextHelp':
      'Trợ giúp để hiểu được văn bản của Kinh Thánh trong ngôn ngữ ban đầu của nó',
    'home.referencesAndResources': 'nguồn tài liệu tham khảo và đào tạo',
    'home.computerAssistance': 'Nhà nước-of-the-art công nghệ máy tính để hỗ trợ dịch',
    'profile.userProfile': 'Thông tin người dùng',
    'profile.language': 'ngôn ngữ',
    'profile.update': 'cập nhật',
    'usernav.profile': 'Hồ sơ',
    'usernav.logout': 'Đăng xuất',
    'usernav.logout.modalTitle': 'Thoát của YouTranslate.Bible',
    'usernav.logout.modalText':
      'Bạn có chắc chắn bạn muốn thoát? Tất cả công việc chưa được lưu sẽ bị mất.',
    cancel: 'hủy bỏ',
    delete: 'Xóa bỏ',
    create: 'Tạo nên',
    update: 'cập nhật',
    edit: 'Biên tập',
    save: 'Tiết kiệm',
    add: 'Thêm vào',
    'project.create.title': 'Dự án dịch Kinh Thánh',
    'project.create.list': 'danh mục dự án',
    'project.list.deleteProject': 'Xóa dự án?',
    'project.list.areYouSure': 'Bạn có chắc chắn muốn xóa dự án này?',
    'project.list.workWillBeDeleted': 'dự án và bất kỳ công việc liên quan sẽ bị xóa.',
    'project.list.completion': 'Hoàn thành:',
    'project.list.createdDate': 'Ngày tạo ra:',
    'project.list.beginningDate': 'Ngày bắt đầu:',
    'project.list.targetCompletionDate': 'Ngày Target hoàn thành:',
    'project.list.description': 'Sự miêu tả:',
    'project.edit': 'chỉnh sửa dự án',
    'project.list.translateBible': 'Dịch Kinh Thánh',
    'project.list.empty': 'Danh mục dự án dịch của bạn đang trống.',
    'project.list.myProjects': 'My Kinh Thánh Các dự án dịch',
    'project.list.newProject': 'Dự án mới',
    'project.create.createNew': 'Tạo một dự án mới',
    'project.namePlaceholder': 'Nhập tên dự án',
    'project.descriptionPlaceholder': 'Nhập mô tả dự án',
    'project.name': 'Tên dự án',
    'project.beginningDate': 'Ngày bắt đầu',
    'project.targetCompletionDate': 'Mục tiêu hoàn thành ngày',
    'project.description': 'Sự miêu tả',
    'project.manage-users': 'quản lý dịch',
    'project.new-user': 'dịch mới',
    'project.add-user': 'Thêm phiên dịch',
    'project.users': 'dịch dự án',
    'project.read-only': 'Chỉ đọc',
    'project.read&write': 'Đọc viết',
    'project.owner': 'Chủ nhân',
    'project.translator': 'thông dịch viên',
    'project.message.no-such-user': 'Không dịch như vậy tồn tại',
    'project.message.user-added': 'Translator thêm thành công',
    'project.message.user-deleted': 'Translator gỡ bỏ.',
    'project.message.only-owner-can-manage-users': 'Chủ dự án chỉ có thể quản lý người dùng',
    'project.shared': 'dự án hợp tác',
    'general.delete-user': 'dịch Xóa',
    'general.edit-user': 'Người dùng biên tập',
    'general.name': 'Tên',
    'general.email': 'E-mail',
    'general.permissions': 'Quyền',
    'general.role': 'Vai trò',
    signin: 'Đăng nhập',
    'signin.emailPlaceholder': 'Địa chỉ email',
    'signin.passwordPlaceholder': 'Mật khẩu',
    'signin.forgotPassword': 'Quên mật khẩu?',
    'signin.rememberMe': 'Ghi nhớ tôi',
    'signin.facebook': 'Đăng nhập bằng facebook',
    'signin.google': 'Đăng nhập bằng Google',
    'signin.dontHaveAccount': 'Không có tài khoản rồi?',
    'signin.createOne': 'Tạo một cái',
    'signup.join': 'Tham gia YouTranslate.Bible',
    'signup.createAccountPrompt': 'Tạo tài khoản miễn phí của bạn để bắt đầu.',
    'signup.createAccount': 'Tạo tài khoản',
    'signup.emailPlaceholder': 'Địa chỉ email',
    'signup.passwordPlaceholder': 'Mật khẩu',
    'translation.project': 'DỰ ÁN:',
    'translation.backToProjectList': 'Quay lại danh sách dự án',
    'translation.book': 'Sách:',
    'translation.chapter': 'Chương:',
    'translation.prompt':
      'Bạn có chắc chắn muốn rời khỏi chương này? Tất cả công việc chưa được lưu sẽ bị mất.',
    'translation.prev': 'Trước',
    'translation.next': 'Kế tiếp',
    'translation.previousChapter': 'Chương trước',
    'translation.nextChapter': 'Chương tiếp theo',
    'navigator.book': 'Sách:',
    'navigator.chapter': 'Chương:',
    'navigator.oldTestament': 'Di chúc cũ',
    'navigator.newTestament': 'Di chúc mới',
    'navigator.selectBook': 'Chọn một cuốn sách',
    'navigator.search': 'Tìm kiếm',
    'navigator.changeBook': 'thay đổi cuốn sách',
    'navigator.changeChapter': 'thay đổi chương',
    Genesis: 'sự bắt đầu',
    Exodus: 'Cuộc di cư',
    Leviticus: 'Leviticus',
    Numbers: 'số',
    Deuteronomy: 'Đệ nhị luật',
    Joshua: 'Joshua',
    Judges: 'Ban giám khảo',
    Ruth: 'lòng trắc ẩn',
    '1 Samuel': '1 Samuel',
    '2 Samuel': '2 Samuel',
    '1 Kings': '1 Kings',
    '2 Kings': '2 Kings',
    '1 Chronicles': '1 Chronicles',
    '2 Chronicles': '2 Chronicles',
    Ezra: 'Ezra',
    Nehemiah: 'Nehemiah',
    Esther: 'Esther',
    Job: 'Việc làm',
    Psalms: 'Thánh Vịnh',
    Proverbs: 'Châm ngôn',
    Ecclesiastes: 'Giảng Viên',
    'Song of Songs': 'Song of Songs',
    Isaiah: 'Isaiah',
    Jeremiah: 'nhà tiên đoán yếm thế',
    Lamentations: 'sự phàn nàn',
    Ezekiel: 'Ezekiel',
    Daniel: 'Daniel',
    Hosea: 'Hosea',
    Joel: 'Joel',
    Amos: 'Amos',
    Obadiah: 'Obadiah',
    Jonah: 'anh chàng số đen',
    Micah: 'Micah',
    Nahum: 'Nahum',
    Habakkuk: 'Habacúc',
    Zephaniah: 'Zephaniah',
    Haggai: 'A-ghê',
    Zechariah: 'Zechariah',
    Malachi: 'Malachi',
    Matthew: 'Matthew',
    Mark: 'dấu',
    Luke: 'Luke',
    John: 'John',
    Acts: 'Hành vi',
    Romans: 'Người La Mã',
    '1 Corinthians': '1 Corinthians',
    '2 Corinthians': '2 Corinthians',
    Galatians: 'Gal',
    Ephesians: 'Êphêsô',
    Philippians: 'Phi-líp',
    Colossians: 'Côlôxê',
    '1 Thessalonians': '1 Têsalônica',
    '2 Thessalonians': '2 Têsalônica',
    '1 Timothy': '1 Timothy',
    '2 Timothy': '2 Timothy',
    Titus: 'Tít',
    Philemon: 'Philêmôn',
    Hebrews: 'Do Thái',
    James: 'James',
    '1 Peter': '1 Peter',
    '2 Peter': '2 Peter',
    '1 John': '1 John',
    '2 John': '2 John',
    '3 John': '3 John',
    Jude: 'Jude',
    Revelation: 'sự tiết lộ',
    'resources.prompt':
      'Thêm tài nguyên Kinh Thánh cho cái nhìn sâu sắc bổ sung vào những đoạn văn bạn muốn dịch.',
    'resources.add': 'Thêm một nguồn lực Kinh Thánh',
    Manuscript: 'bản viết tay',
    Morphology: 'hình thái học',
    Lexicon: 'từ điển',
    'Syntactic Tree': 'cú pháp Tree',
    'Go to project list': 'Tới danh sách dự án',
    'Project not found': 'Dự án không tìm thấy hoặc không đủ quyền.',
    'error.referenceDoesNotExistForResource':
      'dịch Kinh Thánh này không chứa nội dung cho việc thông qua lựa chọn.',
    'references.addAnother': 'Thêm dịch khác',
    'references.referencePrompt': 'Thêm bản dịch Kinh Thánh khác cho cái nhìn sâu sắc thêm.',
    'references.add': 'Thêm một bản dịch Kinh Thánh',
    'translation.complete': 'dịch thuật hoàn thành',
    Alignment: 'sự liên kết',
    Share: 'Chia sẻ',
    'Your Translation': 'dịch của bạn',
    'Enter your translation': 'Nhập dịch của bạn',
    OK: 'đồng ý',
    'error.verseTranslationEmpty': 'Bạn không thể rời khỏi nội dung dịch rỗng.',
    'translation.hide.completed': 'Ẩn hoàn thành',
    alignment: 'sự liên kết',
    'alignment.verify': 'Kiểm chứng',
    'alignment.backToEditor': 'Về biên tập viên',
    'alignment.error':
      'Một cái gì đó đã đi sai. Nếu vẫn còn tồn tại vấn đề, xin vui lòng liên hệ với nhóm YouTranslate.Bible.',
    'alignment.add': 'Thêm liên kết',
    'alignment.delete': 'Remove Link',
    'alignment.clear': 'Xoá Lựa chọn',
    'alignment.reverse': 'Hiển thị ngược',
    'alignment.removeOrClear': 'Hủy bỏ các liên kết được lựa chọn hoặc lựa chọn rõ ràng.',
    'alignment.addOrRemove': 'Tạo một liên kết mới hoặc xóa một trước.',
    'alignment.selectAndAdd': 'Chọn phân khúc tương ứng với liên kết.',
    'error.mustBeSignedToMakeChanges': 'Bạn phải đăng nhập để thay đổi.',
    'error.mustBeSignedToTranslateBible': 'Bạn phải đăng nhập để dịch Kinh Thánh.',
    checking: 'kiểm tra',
    'verseEditor.verseSuggestions': 'gợi ý',
    verses: 'bởi Verse',
    words: 'bằng Lời',
    'leftPanel.name': 'Văn bản Gốc của Kinh Thánh',
    'rightPanel.name': 'Dịch của Kinh Thánh',
    'bottomPanel.name': 'Tài nguyên cho Kinh Thánh',
    'syntax.adv': 'trạng từ',
    'syntax.advp': 'cụm từ trạng từ',
    'syntax.cl': 'điều khoản',
    'syntax.conj': 'Kết hợp',
    'syntax.cjp': 'Kết hợp',
    'syntax.np': 'Cụm danh từ',
    'syntax.o': 'Vật',
    'syntax.io': 'Object gián tiếp',
    'syntax.p': 'Thuộc tính',
    'syntax.pp': 'cụm giới từ',
    'syntax.s': 'Môn học',
    'syntax.v': 'động từ',
    'syntax.vc': 'Động từ khoản',
    'syntax.adv.description':
      'Sửa một phần khác của bài phát biểu. Thông thường bày tỏ cách, địa điểm, thời gian, tần số, mức độ, mức độ chắc chắn, vv',
    'syntax.advp.description':
      'Một biểu hiện nhiều từ mà sửa đổi một phần khác của bài phát biểu. Thông thường bày tỏ cách, địa điểm, thời gian, tần số, mức độ, mức độ chắc chắn, vv ...',
    'syntax.cl.description': 'Một phần của một câu có chứa một động từ.',
    'syntax.conj.description': 'Kết nối từ, cụm từ, và mệnh đề.',
    'syntax.cjp.description': 'Kết nối từ, cụm từ, và mệnh đề.',
    'syntax.np.description': 'Một biểu thức đa từ đó thực hiện chức năng của một danh từ.',
    'syntax.o.description': 'Các thực thể đó là hành động thuận của đề tài này.',
    'syntax.io.description': 'Đây là một khó khăn để giải thích.',
    'syntax.p.description': 'Sửa đổi đề tài này. Thông thường các ngôi sao với một động từ.',
    'syntax.pp.description': 'Đây là một khó khăn để giải thích.',
    'syntax.s.description': 'Thực thể kiểm soát động từ của mệnh đề.',
    'syntax.v.description': 'Truyền đạt một hành động.',
    'syntax.vc.description':
      'Một biểu thức đa từ đó thực hiện chức năng của một động từ (chuyển tải một hành động).',
    'general.close': 'Đóng',
    'general.action': 'Hoạt động',
    'general.select': 'Lựa chọn...',
    'general.back': 'Trở lại',
  },
  'zh-hans': {
    'languages.en': '英语',
    'languages.am': '阿姆哈拉语',
    'languages.bn': '孟加拉语',
    'languages.ceb': '宿务语',
    'languages.de': '德语',
    'languages.es-419': '西班牙语',
    'languages.fr': '法语',
    'languages.gu': '古吉拉特语',
    'languages.hi': '印地语',
    'languages.hr': '克罗地亚语',
    'languages.hu': '匈牙利语',
    'languages.id': '印度尼西亚语',
    'languages.km': '高棉语',
    'languages.kn': '卡纳达语',
    'languages.ko': '朝鲜语',
    'languages.lo': '老挝语',
    'languages.ml': '马拉雅拉姆语',
    'languages.mr': '马拉',
    'languages.ms': '马来西亚',
    'languages.my': '缅甸语',
    'languages.ne': '尼泊尔语',
    'languages.plt': '马达加斯加语',
    'languages.pt-br': '葡萄牙语（巴西）语言',
    'languages.ru': '俄语',
    'languages.sw': '斯瓦希里',
    'languages.ta': '泰米尔语',
    'languages.th': '泰语',
    'languages.tl': '他加禄语',
    'languages.te': '泰卢固语',
    'languages.vi': '越南语',
    'languages.zh-hans': '简体中文',
    'languages.zh-hant': '繁体中文',
    'header.home': '主页',
    'header.help': '帮助',
    'header.project': '项目',
    'help.contactSupport': '请help@youtranslate.bible援助给我们发电子邮件。',
    'home.ytbProvides': 'YouTranslate.Bible自由提供：',
    'home.easyToUseTool': '一个易于使用的在线翻译工具',
    'home.originalTextHelp': '有助于理解圣经的文字在其原来的语言',
    'home.referencesAndResources': '参考和培训资源',
    'home.computerAssistance': '国家的最先进的计算机技术，以协助翻译',
    'profile.userProfile': '用户资料',
    'profile.language': '语言',
    'profile.update': '更新',
    'usernav.profile': '用户信息',
    'usernav.logout': '登出',
    'usernav.logout.modalTitle': '登出 YouTranslate.Bible',
    'usernav.logout.modalText': "确定要deng'ch？所有未保存的工作都将丢失。\n",
    cancel: '取消',
    delete: '删除',
    create: '创建',
    update: '更新',
    edit: '编辑',
    save: '保存',
    add: '加',
    'project.create.title': '圣经翻译项目',
    'project.create.list': '项目列表',
    'project.list.deleteProject': '删除项目？',
    'project.list.areYouSure': '确定要删除这个项目？',
    'project.list.workWillBeDeleted': '项目和相关的任何工作都将被删除。',
    'project.list.completion': '完成：',
    'project.list.createdDate': '创建日期：',
    'project.list.beginningDate': '开始日期：',
    'project.list.targetCompletionDate': '目标完成日期：',
    'project.list.description': '说明：',
    'project.edit': '编辑项目',
    'project.list.translateBible': '翻译圣经',
    'project.list.empty': '你的翻译项目列表为空。',
    'project.list.myProjects': '我的圣经翻译项目',
    'project.list.newProject': '新项目',
    'project.create.createNew': '创建一个新项目',
    'project.namePlaceholder': '输入项目名称',
    'project.descriptionPlaceholder': '输入项目的描述',
    'project.name': '项目名',
    'project.beginningDate': '开始日期',
    'project.targetCompletionDate': '目标完成日期',
    'project.description': '描述',
    'project.manage-users': '翻译管理',
    'project.new-user': '新翻译',
    'project.add-user': '添加翻译',
    'project.users': '项目翻译',
    'project.read-only': '只读',
    'project.read&write': '读写',
    'project.owner': '所有者',
    'project.translator': '翻译者',
    'project.message.no-such-user': '没有这样的翻译存在',
    'project.message.user-added': '翻译成功添加',
    'project.message.user-deleted': '译者删除。',
    'project.message.only-owner-can-manage-users': '只有项目所有者可以管理用户',
    'project.shared': '合作项目',
    'general.delete-user': '删除翻译',
    'general.edit-user': '编辑用户',
    'general.name': '名称',
    'general.email': '电子邮件',
    'general.permissions': '权限',
    'general.role': '角色',
    signin: '登入',
    'signin.emailPlaceholder': '电子邮件地址',
    'signin.passwordPlaceholder': '密码',
    'signin.forgotPassword': '忘记密码了吗？',
    'signin.rememberMe': '记住账号',
    'signin.facebook': '请用facebook',
    'signin.google': '用谷歌登录',
    'signin.dontHaveAccount': '已经有帐号了吗？',
    'signin.createOne': '创建账号',
    'signup.join': '加入YouTranslate.Bible',
    'signup.createAccountPrompt': '建立免费账户',
    'signup.createAccount': '創建帳號\n',
    'signup.emailPlaceholder': '电子邮件地址',
    'signup.passwordPlaceholder': '密码',
    'translation.project': '项目：',
    'translation.backToProjectList': '返回项目列表',
    'translation.book': '书：',
    'translation.chapter': '章节：',
    'translation.prompt': '你确定要离开本章？所有未保存的工作都将丢失',
    'translation.prev': '上一页',
    'translation.next': '下一个',
    'translation.previousChapter': '上一章',
    'translation.nextChapter': '下一章',
    'navigator.book': '书：',
    'navigator.chapter': '章节：',
    'navigator.oldTestament': '旧约全书',
    'navigator.newTestament': '新约全书',
    'navigator.selectBook': '选择一本书',
    'navigator.search': '搜索',
    'navigator.changeBook': '换一本书',
    'navigator.changeChapter': '换一章',
    Genesis: '创世纪',
    Exodus: '出埃及记',
    Leviticus: '利未记',
    Numbers: '民数记',
    Deuteronomy: '申命记',
    Joshua: ' \n                 \n\n\n约书亚记',
    Judges: '士师记',
    Ruth: '路得记',
    '1 Samuel': ' \n                 \n\n\n撒母耳记上',
    '2 Samuel': '撒母耳记下',
    '1 Kings': '列王纪上',
    '2 Kings': '列王纪下',
    '1 Chronicles': '历代志上',
    '2 Chronicles': '历代志下',
    Ezra: '以斯拉记',
    Nehemiah: '尼希米记',
    Esther: '以斯帖记',
    Job: '约伯记',
    Psalms: '诗篇',
    Proverbs: '箴言',
    Ecclesiastes: '传道书',
    'Song of Songs': '雅歌',
    Isaiah: '以赛亚书',
    Jeremiah: '耶利米书',
    Lamentations: '耶利米哀歌',
    Ezekiel: '以西结书',
    Daniel: '但以理书',
    Hosea: '何西阿书',
    Joel: '约珥书',
    Amos: '阿摩司书',
    Obadiah: '俄巴底亚书',
    Jonah: '约拿书',
    Micah: '弥迦书',
    Nahum: '那鸿书',
    Habakkuk: '哈巴谷书',
    Zephaniah: '西番雅书',
    Haggai: '哈该书',
    Zechariah: '撒迦利亚书',
    Malachi: '玛拉基书',
    Matthew: '马太福音',
    Mark: '马可福音',
    Luke: '路加福音',
    John: '约翰福音',
    Acts: '使徒行传',
    Romans: '罗马书',
    '1 Corinthians': '哥林多前书',
    '2 Corinthians': '哥林多后书',
    Galatians: '加拉太书',
    Ephesians: '以弗所书',
    Philippians: '腓立比书',
    Colossians: '歌罗西书',
    '1 Thessalonians': '帖撒罗尼迦前书',
    '2 Thessalonians': '帖撒罗尼迦后书',
    '1 Timothy': '提摩太前书',
    '2 Timothy': '提摩太后书',
    Titus: '提多书',
    Philemon: '腓利门书\n',
    Hebrews: '希伯来书',
    James: '雅各书',
    '1 Peter': '彼得前书',
    '2 Peter': '彼得后书',
    '1 John': '约翰一书',
    '2 John': '约翰二书',
    '3 John': '约翰三书',
    Jude: '犹大书',
    Revelation: '启示录',
    'resources.prompt': '添加圣经资源，更深入地了解您所翻译的段落',
    'resources.add': '添加圣经资源',
    Manuscript: '原稿',
    Morphology: '词型',
    Lexicon: '词汇',
    'Syntactic Tree': '句法',
    'Go to project list': '转到项目列表',
    'Project not found': '项目未找到或权限不足。',
    'error.referenceDoesNotExistForResource': '这圣经翻译不包含所选段落的内容',
    'references.addAnother': '添加另一个翻译',
    'references.referencePrompt': '添加其他圣经翻译作参考',
    'references.add': '添加圣经翻译',
    'translation.complete': '翻译完成',
    Alignment: '对齐',
    Share: '分享',
    'Your Translation': '您的翻译',
    'Enter your translation': '输入您的翻译',
    OK: 'OK',
    'error.verseTranslationEmpty': '翻译内容不可为空白',
    'translation.hide.completed': '已隐藏',
    alignment: '对齐',
    'alignment.verify': '校对',
    'alignment.backToEditor': '校对',
    'alignment.error': '有问题。如果问题仍然存在，请与YouTranslate团队联系',
    'alignment.add': '添加链接',
    'alignment.delete': '删除链接',
    'alignment.clear': '清空选项',
    'alignment.reverse': '反转显示',
    'alignment.removeOrClear': '删除选定的链接或删除所有链接',
    'alignment.addOrRemove': '创建一个新的链接或删除之前的一个',
    'alignment.selectAndAdd': '选定所有需链接的词，然后单击添加按钮\n',
    'error.mustBeSignedToMakeChanges': '登录后才能修改',
    'error.mustBeSignedToTranslateBible': '登录后才能翻译圣经',
    checking: '检查',
    'verseEditor.verseSuggestions': '建议',
    verses: '按节',
    words: '按词',
    'leftPanel.name': '圣经原文',
    'rightPanel.name': '圣经译本',
    'bottomPanel.name': '圣经资源',
    'syntax.adv': '副词',
    'syntax.advp': '副词短语',
    'syntax.cl': '条款',
    'syntax.conj': '连词',
    'syntax.cjp': '连词',
    'syntax.np': '名词短语',
    'syntax.o': '宾语',
    'syntax.io': '间接宾语',
    'syntax.p': '谓语',
    'syntax.pp': '介词短语',
    'syntax.s': '学科',
    'syntax.v': '动词',
    'syntax.vc': '动词条款',
    'syntax.adv.description':
      '修改演讲的另一部分。通常表达方式，地点，时间，频率，程度，确定性的水平，等等。',
    'syntax.advp.description':
      '多词表达语音的修饰另一部分。典型的表达方式，地点，时间，频率，程度，确定性的水平，等等。',
    'syntax.cl.description': '它包含一个动词的句子的一部分。',
    'syntax.conj.description': '连接单词，短语和从句。',
    'syntax.cjp.description': '连接单词，短语和从句。',
    'syntax.np.description': '多词表达执行一个名词的功能。',
    'syntax.o.description': '即在通过主题行动的实体。',
    'syntax.io.description': '这是一个很难解释。',
    'syntax.p.description': '修改主题。通常以动词明星。',
    'syntax.pp.description': '这是一个很难解释。',
    'syntax.s.description': '其控制条款的动词的实体。',
    'syntax.v.description': '传达的动作。',
    'syntax.vc.description': '多词表达执行动词的功能（一个传达动作）。',
    'general.close': '关',
    'general.action': '行动',
    'general.select': '选择...',
    'general.back': '背部',
  },
  'zh-hant': {
    'languages.en': '英語',
    'languages.am': '阿姆哈拉語',
    'languages.bn': '孟加拉語',
    'languages.ceb': '宿務語',
    'languages.de': '德語',
    'languages.es-419': '西班牙語',
    'languages.fr': '法語',
    'languages.gu': '古吉拉特語',
    'languages.hi': '印地語',
    'languages.hr': '克羅地亞語',
    'languages.hu': '匈牙利語',
    'languages.id': '印度尼西亞語',
    'languages.km': '高棉文',
    'languages.kn': '卡納達語',
    'languages.ko': '朝鮮語',
    'languages.lo': '老撾語',
    'languages.ml': '馬拉雅拉姆語',
    'languages.mr': '馬拉',
    'languages.ms': '馬來西亞',
    'languages.my': '緬甸語',
    'languages.ne': '尼泊爾語',
    'languages.plt': '馬達加斯加語',
    'languages.pt-br': '葡萄牙語（巴西）語言',
    'languages.ru': '俄語',
    'languages.sw': '斯瓦希裡',
    'languages.ta': '泰米爾語',
    'languages.th': '泰語',
    'languages.tl': '他加祿語',
    'languages.te': '泰盧固語',
    'languages.vi': '越南語',
    'languages.zh-hans': '簡體中文',
    'languages.zh-hant': '繁體中文',
    'header.home': '主頁\n',
    'header.help': '説明',
    'header.project': '項目',
    'help.contactSupport': '請help@youtranslate.bible援助給我們發電子郵件。',
    'home.ytbProvides': 'YouTranslate.Bible自由提供：',
    'home.easyToUseTool': '一個易於使用的在線翻譯工具',
    'home.originalTextHelp': '有助於理解聖經的文字在其原來的語言',
    'home.referencesAndResources': '參考和培訓資源',
    'home.computerAssistance': '國家的最先進的計算機技術，以協助翻譯',
    'profile.userProfile': '用戶資料',
    'profile.language': '語言',
    'profile.update': '更新',
    'usernav.profile': '使用者資訊\n',
    'usernav.logout': '登出',
    'usernav.logout.modalTitle': '登出 YouTranslate.Bible\n',
    'usernav.logout.modalText': "確定要deng'ch？ 所有未保存的工作都將丟失。\n",
    cancel: '取消',
    delete: '刪除',
    create: '創建',
    update: '更新',
    edit: '編輯',
    save: '保存',
    add: '加',
    'project.create.title': '聖經翻譯項目',
    'project.create.list': '項目列表',
    'project.list.deleteProject': '刪除項目？',
    'project.list.areYouSure': '確定要刪除這個項目？',
    'project.list.workWillBeDeleted': '項目和相關的任何工作都將被刪除。',
    'project.list.completion': '完成：',
    'project.list.createdDate': '創建日期：',
    'project.list.beginningDate': '開始日期：',
    'project.list.targetCompletionDate': '目標完成日期：',
    'project.list.description': '說明：\n',
    'project.edit': '編輯項目',
    'project.list.translateBible': '翻譯聖經',
    'project.list.empty': '你的翻譯項目列表為空。',
    'project.list.myProjects': '我的聖經翻譯項目',
    'project.list.newProject': '新項目',
    'project.create.createNew': '創建一個新項目',
    'project.namePlaceholder': '輸入項目名稱',
    'project.descriptionPlaceholder': '輸入項目的描述',
    'project.name': '項目名',
    'project.beginningDate': '開始日期',
    'project.targetCompletionDate': '目標完成日期',
    'project.description': '描述',
    'project.manage-users': '翻譯管理',
    'project.new-user': '新翻譯',
    'project.add-user': '添加翻譯',
    'project.users': '項目翻譯',
    'project.read-only': '只讀',
    'project.read&write': '讀寫',
    'project.owner': '所有者',
    'project.translator': '翻譯者',
    'project.message.no-such-user': '沒有這樣的翻譯存在',
    'project.message.user-added': '翻譯成功添加',
    'project.message.user-deleted': '譯者刪除。',
    'project.message.only-owner-can-manage-users': '只有項目所有者可以管理用戶',
    'project.shared': '合作項目',
    'general.delete-user': '刪除翻譯',
    'general.edit-user': '編輯用戶',
    'general.name': '名稱',
    'general.email': '電子郵件',
    'general.permissions': '權限',
    'general.role': '角色',
    signin: '登入',
    'signin.emailPlaceholder': '電子郵件地址',
    'signin.passwordPlaceholder': '密碼',
    'signin.forgotPassword': '忘記密碼了嗎？',
    'signin.rememberMe': '記住賬號',
    'signin.facebook': '請用facebook',
    'signin.google': '用谷歌登錄\n',
    'signin.dontHaveAccount': '已經有帳號了嗎？\n',
    'signin.createOne': '創建帳號\n\n',
    'signup.join': '加入YouTranslate.Bible',
    'signup.createAccountPrompt': '建立免費帳戶\n',
    'signup.createAccount': '創建帳號\n\n',
    'signup.emailPlaceholder': '電子郵件地址',
    'signup.passwordPlaceholder': '密碼',
    'translation.project': '項目：',
    'translation.backToProjectList': '返回項目列表',
    'translation.book': '書：',
    'translation.chapter': '章節：',
    'translation.prompt': '你確定要離開本章？ 所有未保存的工作都將丟失\n',
    'translation.prev': '上一頁',
    'translation.next': '下一個',
    'translation.previousChapter': '上一章',
    'translation.nextChapter': '下一章',
    'navigator.book': '書：',
    'navigator.chapter': '章節：',
    'navigator.oldTestament': '舊約全書',
    'navigator.newTestament': '新約全書',
    'navigator.selectBook': '選擇一本書',
    'navigator.search': '搜索',
    'navigator.changeBook': '換一本書\n',
    'navigator.changeChapter': '換一章\n',
    Genesis: '創世紀',
    Exodus: '出埃及記',
    Leviticus: '利未記',
    Numbers: '民數記\n',
    Deuteronomy: '申命記',
    Joshua: '約書亞記\n',
    Judges: '士師記',
    Ruth: '路得記\n',
    '1 Samuel': '撒母耳記上',
    '2 Samuel': '撒母耳記下',
    '1 Kings': '列王紀上',
    '2 Kings': '列王紀下',
    '1 Chronicles': '歷代志上',
    '2 Chronicles': '歷代志下',
    Ezra: '以斯拉記',
    Nehemiah: '尼希米記',
    Esther: '以斯帖記',
    Job: '約伯記',
    Psalms: '詩篇',
    Proverbs: '箴言',
    Ecclesiastes: '傳道書',
    'Song of Songs': '雅歌',
    Isaiah: '以賽亞書',
    Jeremiah: '耶利米書',
    Lamentations: '耶利米哀歌',
    Ezekiel: '以西結書',
    Daniel: '但以理書',
    Hosea: '何西阿書',
    Joel: '約珥書',
    Amos: '阿摩司書',
    Obadiah: '俄巴底亞書',
    Jonah: '約拿書',
    Micah: '彌迦書',
    Nahum: '那鴻書',
    Habakkuk: '哈巴谷書',
    Zephaniah: '西番雅書',
    Haggai: '哈該書',
    Zechariah: '撒迦利亞書',
    Malachi: '瑪拉基書',
    Matthew: '馬太福音',
    Mark: '馬可福音',
    Luke: '路加福音',
    John: '約翰福音',
    Acts: '使徒行傳',
    Romans: '羅馬書',
    '1 Corinthians': '哥林多前書',
    '2 Corinthians': '哥林多後書',
    Galatians: '加拉太書',
    Ephesians: '以弗所書',
    Philippians: '腓立比書',
    Colossians: '歌羅西書',
    '1 Thessalonians': '帖撒羅尼迦前書',
    '2 Thessalonians': '帖撒羅尼迦後書',
    '1 Timothy': '提摩太前書',
    '2 Timothy': '提摩太後書',
    Titus: '提多書',
    Philemon: '腓利門書',
    Hebrews: '希伯來書',
    James: '雅各書',
    '1 Peter': '彼得前書',
    '2 Peter': '彼得後書',
    '1 John': '約翰一書',
    '2 John': '約翰二書',
    '3 John': '約翰三書',
    Jude: '猶大書',
    Revelation: '啟示錄',
    'resources.prompt': '添加聖經資源，更深入地瞭解您所翻譯的段落',
    'resources.add': '添加聖經資源',
    Manuscript: '原稿',
    Morphology: '詞型',
    Lexicon: '詞彙',
    'Syntactic Tree': '句法',
    'Go to project list': '轉到專案清單',
    'Project not found': '項目未找到或權限不足。',
    'error.referenceDoesNotExistForResource': '這聖經翻譯不包含所選段落的內容',
    'references.addAnother': '添加另一個翻譯',
    'references.referencePrompt': '添加其他聖經翻譯作參考',
    'references.add': '添加聖經翻譯',
    'translation.complete': '翻譯完成',
    Alignment: '對齊',
    Share: '分享',
    'Your Translation': '您的翻譯',
    'Enter your translation': '輸入您的翻譯',
    OK: 'OK',
    'error.verseTranslationEmpty': '翻譯內容不可為空白',
    'translation.hide.completed': '已隱藏',
    alignment: '對齊',
    'alignment.verify': '校驗',
    'alignment.backToEditor': '返回編輯器',
    'alignment.error': '有問題。 如果問題仍然存在，請與YouTranslate團隊聯繫',
    'alignment.add': '添加鏈接',
    'alignment.delete': '刪除鏈接',
    'alignment.clear': '清空選項',
    'alignment.reverse': '反轉顯示',
    'alignment.removeOrClear': '刪除選定的連結或刪除所有連結',
    'alignment.addOrRemove': '創建一個新的連結或刪除之前的一個',
    'alignment.selectAndAdd': "選定suo'you需連結的詞，然後按一下添加按鈕",
    'error.mustBeSignedToMakeChanges': '登錄後才能修改',
    'error.mustBeSignedToTranslateBible': '登錄後才能翻譯聖經',
    checking: '檢查',
    'verseEditor.verseSuggestions': '建議',
    verses: '按節',
    words: '按詞',
    'leftPanel.name': '聖經原文',
    'rightPanel.name': '聖經譯本',
    'bottomPanel.name': '聖經資源',
    'syntax.adv': '副詞',
    'syntax.advp': '副詞短語',
    'syntax.cl': '條款',
    'syntax.conj': '連詞',
    'syntax.cjp': '連詞',
    'syntax.np': '名詞短語',
    'syntax.o': '賓語',
    'syntax.io': '間接賓語',
    'syntax.p': '謂語',
    'syntax.pp': '介詞短語',
    'syntax.s': '學科',
    'syntax.v': '動詞',
    'syntax.vc': '動詞條款',
    'syntax.adv.description':
      '修改演講的另一部分。通常表達方式，地點，時間，頻率，程度，確定性的水平，等等。',
    'syntax.advp.description':
      '多詞表達語音的修飾另一部分。典型的表達方式，地點，時間，頻率，程度，確定性的水平，等等。',
    'syntax.cl.description': '它包含一個動詞的句子的一部分。',
    'syntax.conj.description': '連接單詞，短語和從句。',
    'syntax.cjp.description': '連接單詞，短語和從句。',
    'syntax.np.description': '多詞表達執行一個名詞的功能。',
    'syntax.o.description': '即在通過主題行動的實體。',
    'syntax.io.description': '這是一個很難解釋。',
    'syntax.p.description': '修改主題。通常以動詞明星。',
    'syntax.pp.description': '這是一個很難解釋。',
    'syntax.s.description': '其控制條款的動詞的實體。',
    'syntax.v.description': '傳達的動作。',
    'syntax.vc.description': '多詞表達執行動詞的功能（一個傳達動作）。',
    'general.close': '關',
    'general.action': '行動',
    'general.select': '選擇...',
    'general.back': '背部',
  },
};
export default languageObject;
