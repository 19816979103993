import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Accordion, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { AppState } from '../../reducers';
import Spinner from '../spinner';
import { TranslationProps, TranslationState, DefaultTranslationProps } from '../../types';
import {
  fetchReferenceDataAction,
  closeReferenceAction,
  rememberLastThingAction,
} from '../../actions';
import CustomAccordionToggle from './customAccordionToggle';
import getCurrentProjectId from '../../lib/getCurrentProjectId';
import { Translation } from '../../shared/structs';

export class ReferenceComp extends React.Component<TranslationProps, TranslationState> {
  public static defaultProps: TranslationProps = DefaultTranslationProps;

  public constructor(props: TranslationProps) {
    super(props);
  }

  public content(): ReactElement {
    const {
      referenceData,
      fetchData,
      selectedReference,
      selectedBookId,
      selectedChapter,
    } = this.props;
    if (
      referenceData[selectedReference.id] &&
      referenceData[selectedReference.id] !== 'fetching' &&
      Object.keys(referenceData).length
    ) {
      const response = referenceData[selectedReference.id].data;
      const { responseData } = response;
      if (response.error) {
        return (
          <p className="reference-text text-center">
            <FormattedMessage id={response.errorMessage} />
          </p>
        );
      }
      if (!response.responseData) {
        return (
          <p className="reference-text text-center">
            <FormattedMessage id="error.referenceDoesNotExistForResource" />
          </p>
        );
      }
      return (
        <div className="scripture-styles">
          <p className="reference-text" dangerouslySetInnerHTML={{ __html: responseData }} />
        </div>
      );
    }
    if (!referenceData[selectedReference.id]) {
      fetchData(selectedReference.id, selectedBookId, selectedChapter);
    }
    return <Spinner />;
  }

  public render(): ReactElement {
    const { selectedReference, closeReferenceFunc, rememberLastThing, references } = this.props;
    return (
      <Card key={`accordion-card-reference-${selectedReference.id}`} className="active-card">
        <CustomAccordionToggle eventKey={selectedReference.id} activeCard>
          <span className="reference-name float-left">{selectedReference.fullName}</span>
          <span className="float-right">
            <span
              className="close-reference"
              role="presentation"
              onClick={(): void => {
                closeReferenceFunc(selectedReference);
                rememberLastThing(
                  getCurrentProjectId(),
                  'references',
                  references.filter((reference: Translation): boolean => {
                    return reference.id !== selectedReference.id;
                  }),
                );
              }}
            >
              <i className="fa sm fa-times" />
            </span>
          </span>
        </CustomAccordionToggle>
        <Accordion.Collapse eventKey={selectedReference.id} className="show">
          <Card.Body>{this.content()}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  const props = {
    ...state.translation,
  };
  return props;
};

export const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchData: (selectedReference: string, bookId: string, chapterId: number): void => {
    dispatch(fetchReferenceDataAction(selectedReference, bookId, chapterId));
  },
  closeReferenceFunc: (reference: Translation): void => {
    dispatch(closeReferenceAction(reference));
  },
  rememberLastThing: (projectId: string, fieldName: string, fieldValue: any): void => {
    dispatch(rememberLastThingAction(projectId, fieldName, fieldValue));
  },
});

const Reference = connect(mapStateToProps, mapDispatchToProps)(ReferenceComp);

export default Reference;
