import React, { ReactElement } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { config } from '../../config';

import { ProjectState, ProjectProps, ProjectUsers as typeProjectUsers } from '../../types';
import {
  projectLoaded,
  ShowProjectCreation,
  fetchProjects,
  clearError,
  showLightboxRemovingProject,
  closeLightboxRemovingProject,
  deleteProject,
  showLightboxEditingProject,
  closeLightboxEditingProject,
  showLightboxManageProjectUsers,
  closeLightboxManageProjectUsers,
} from '../../actions';
import { AppState } from '../../reducers';
import { Lightbox } from '../lightbox';
import Spinner from '../spinner';
import { ProjectEditing } from './projectEditing';
import { ProjectUsers } from './projectUsers';
import * as userFuncs from '../../lib/user';
import noop from '../../lib/noop';

export class ProjectListApp extends React.Component<ProjectProps, ProjectState> {
  public static defaultProps: ProjectProps = {
    beginningDate: '',
    description: '',
    isFetching: false,
    loading: false,
    projectName: '',
    targetCompletionDate: '',
    userEmail: '',

    cancelAddingProjectUserFunc: noop,
    changeViewFunc: noop,
    clearErrorAndMessageFunc: noop,
    closeLightboxEditingProjectFunc: noop,
    closeLightboxManageProjectUsersFunc: noop,
    closeLightboxRemovingProjectFunc: noop,
    createProjectFunc: noop,
    deleteProjectFunc: noop,
    deleteProjectUserFunc: noop,
    editProjectFunc: noop,
    fetchProjectFunc: noop,
    fetchProjectsFunc: noop,
    handleUserEmailChangeFunc: noop,
    handleUserPermissionChangeFunc: noop,
    saveProjectUserFunc: noop,
    searchProjectUserEmailFunc: noop,
    showLightboxEditingProjectFunc: noop,
    showLightboxManageProjectUsersFunc: noop,
    showLightboxRemovingProjectFunc: noop,
    ShowProjectCreationFunc: noop,
    showViewOfAddingProjectUserFunc: noop,
  };

  public constructor(props: ProjectProps) {
    super(props);
  }

  public componentDidMount(): void {
    const { fetchProjectsFunc } = this.props;

    fetchProjectsFunc();
  }

  // delete project
  private deletingProject(projectID: string): void {
    const { showLightboxRemovingProjectFunc } = this.props;
    showLightboxRemovingProjectFunc(projectID);
  }

  private deleteProject(projectID: string): void {
    const { deleteProjectFunc } = this.props;

    deleteProjectFunc(projectID);
  }

  private lightboxRemovingProject(): ReactElement {
    const {
      showLightboxRemovingProjectVar,
      closeLightboxRemovingProjectFunc,
      removeProjectId,
      deletingProject,
    } = this.props;

    if (removeProjectId) {
      return (
        <Lightbox display={showLightboxRemovingProjectVar} key="lightbox-delete-project">
          <div className="modal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    <FormattedMessage id="project.list.deleteProject" />
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={(): void => {
                      closeLightboxRemovingProjectFunc();
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{ minHeight: '120px' }}>
                  <p>
                    <FormattedMessage id="project.list.areYouSure" />
                  </p>
                  <p>
                    <FormattedMessage id="project.list.workWillBeDeleted" />
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{ minWidth: '100px' }}
                    onClick={(): void => {
                      this.deleteProject(removeProjectId);
                    }}
                  >
                    <FormattedMessage id="delete" />
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ minWidth: '70px' }}
                    onClick={(): void => {
                      closeLightboxRemovingProjectFunc();
                    }}
                  >
                    <FormattedMessage id="cancel" />
                  </button>
                </div>

                {deletingProject && <Spinner />}
              </div>
            </div>
          </div>
        </Lightbox>
      );
    }

    return <></>;
  }

  // edit project - begin
  private editingProject(projectID: string): void {
    const { showLightboxEditingProjectFunc } = this.props;
    showLightboxEditingProjectFunc(projectID);
  }

  private lightboxEditingProject(): ReactElement {
    const { showLightboxEditingProjectVar, editProjectId } = this.props;

    return (
      <Lightbox display={showLightboxEditingProjectVar} key="lightbox-edit-project">
        {editProjectId && <ProjectEditing />}
      </Lightbox>
    );
  }

  // manage users - begin
  private manageProjectUsers(projectId: string): void {
    const { showLightboxManageProjectUsersFunc } = this.props;
    showLightboxManageProjectUsersFunc(projectId);
  }

  private lightboxManageUsers(): ReactElement {
    const { showLightboxManageUsersVar, projectIdManageUsers } = this.props;

    return (
      <Lightbox display={showLightboxManageUsersVar} key="lightbox-manage-users">
        {projectIdManageUsers && <ProjectUsers />}
      </Lightbox>
    );
  }

  private listProjectUsers(users: typeProjectUsers): ReactElement[] {
    const arr: ReactElement[] = [];

    let n = 0;

    Object.keys(users).forEach(key => {
      n += 1;
      const name = users[key].displayName;
      const commaEle: ReactElement = <span key={`comma-${n}`}>, </span>;

      if (users[key].permission === 'owner') {
        arr.unshift(commaEle);
        arr.unshift(
          <span key={`username-${name}`}>
            <b>{name}</b>
          </span>,
        );
      } else {
        arr.push(<span key={`username-${name}`}>{name}</span>);
        arr.push(commaEle);
      }
    });

    arr.pop();
    return arr;
  }

  private deleteProjectButton(docId: string): ReactElement {
    if (false) {
      return (
        <button
          className="btn btn-link btn-remove text-danger"
          type="button"
          onClick={(): void => {
            this.deletingProject(docId);
          }}
        >
          <i className="fas fa-times" />
          <FormattedMessage id="delete" />
        </button>
      );
    }
    return <></>;
  }

  // list projects
  private listProjects(projectList: any[] | undefined): ReactElement | any[] {
    const { deletingError, currentUid } = this.props;

    if (projectList && projectList.length) {
      const stack = [];

      if (deletingError) {
        stack.push(<div className="alert alert-danger mb-4">{deletingError}</div>);
      }

      const list = projectList.map(
        (doc: firebase.firestore.DocumentData): ReactElement => {
          return (
            <div
              className={
                userFuncs.userIsOwner(currentUid, doc.data.users)
                  ? 'card project-list-card'
                  : 'card project-list-card shared-project '
              }
              key={doc.id}
            >
              {((): ReactElement => {
                if (userFuncs.userIsOwner(currentUid, doc.data.users)) {
                  return this.deleteProjectButton(doc.id);
                }

                return (
                  <div className="project-indication">
                    <i className="fas fa-share-alt" />
                    <FormattedMessage id="project.shared" />
                  </div>
                );
                // end if
              })()}
              <h2 className="card-title text-center">{doc.data.projectName}</h2>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm">
                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.completion" />
                      </div>
                      <div className="col py-1 ">-</div>
                    </div>

                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.createdDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.createdAt.seconds * 1000).format(config.dateFormatUs)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm border-left">
                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.beginningDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.beginningDate).format(config.dateFormatUs)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 font-weight-bold px-0 py-1 text-right">
                        <FormattedMessage id="project.list.targetCompletionDate" />
                      </div>
                      <div className="col py-1 ">
                        {moment(doc.data.targetCompletionDate).format(config.dateFormatUs)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-3 font-weight-bold px-0 py-1 text-right">
                    <FormattedMessage id="project.list.description" />
                  </div>
                  <div className="col py-1 ">
                    <pre>{doc.data.description}</pre>
                  </div>
                </div>

                <div className="row">
                  <div className="col my-3 text-center">
                    {userFuncs.userIsOwner(currentUid, doc.data.users) && (
                      <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={(): void => {
                          this.editingProject(doc.id);
                        }}
                      >
                        <FormattedMessage id="project.edit" />
                      </button>
                    )}

                    <Link className="btn btn-ytb ml-2" to={`/project/${doc.id}`}>
                      <FormattedMessage id="project.list.translateBible" />
                    </Link>
                  </div>
                </div>

                <hr />

                <div className="row">
                  <div className="col-2 text-right px-0 mt-3">
                    <span className="user-icon">
                      <i className="fas fa-users" />
                    </span>
                  </div>
                  <div className="col px-3 mt-4 user-list">
                    {this.listProjectUsers(doc.data.users)}
                  </div>
                  <div className="col-2 px-0 mt-3">
                    {userFuncs.userIsOwner(currentUid, doc.data.users) && (
                      <button
                        type="button"
                        className="btn btn-light btn-manage-users "
                        onClick={(): void => {
                          this.manageProjectUsers(doc.id);
                        }}
                      >
                        <FormattedMessage id="project.manage-users" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        },
      );

      stack.push(list);

      return stack;
    }

    return (
      <div className="alert alert-info mb-4 py-4">
        <FormattedMessage id="project.list.empty" />
      </div>
    );
  }

  public render(): ReactElement {
    const { ShowProjectCreationFunc, isFetchingList, error, projectList } = this.props;

    return (
      <div className="project-list">
        <div className="row">
          <div className="col-8">
            <h1>
              <FormattedMessage id="project.list.myProjects" />
            </h1>
          </div>
          <div className="col-4 text-right mb-1">
            <button
              className="btn btn-ytb"
              type="button"
              onClick={ShowProjectCreationFunc.bind(this)}
            >
              <i className="fas fa-plus mr-1" />
              <FormattedMessage id="project.list.newProject" />
            </button>
          </div>
        </div>
        <hr />
        {((): any => {
          if (error) {
            return <div className="alert alert-danger mb-4">{error}</div>;
          }

          if (isFetchingList) {
            return <div className="loader" />;
          }

          return this.listProjects(projectList);
        })()}

        {this.lightboxRemovingProject()}
        {this.lightboxEditingProject()}
        {this.lightboxManageUsers()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ProjectProps => {
  const props = {
    ...state.project,
  };

  return props;
};

const mapDispatchToProps = (dispatch: Dispatch): object => ({
  loaded: (): void => {
    dispatch(projectLoaded());
  },

  ShowProjectCreationFunc: (): void => {
    dispatch(ShowProjectCreation());
  },

  fetchProjectsFunc: (): void => {
    dispatch(fetchProjects());
  },

  clearErrorAndMessageFunc: (): void => {
    dispatch(clearError());
  },

  showLightboxRemovingProjectFunc: (projectId: string): void => {
    dispatch(showLightboxRemovingProject(projectId));
  },

  closeLightboxRemovingProjectFunc: (): void => {
    dispatch(closeLightboxRemovingProject());
  },

  deleteProjectFunc: (projectId: string): void => {
    dispatch(deleteProject(projectId));
  },

  showLightboxEditingProjectFunc: (projectId: string): void => {
    dispatch(showLightboxEditingProject(projectId));
  },

  closeLightboxEditingProjectFunc: (): void => {
    dispatch(closeLightboxEditingProject());
  },

  // manage project users
  showLightboxManageProjectUsersFunc: (projectId: string): void => {
    dispatch(showLightboxManageProjectUsers(projectId));
  },

  closeLightboxManageProjectUsersFunc: (): void => {
    dispatch(closeLightboxManageProjectUsers());
  },
});

export const ProjectList = connect(mapStateToProps, mapDispatchToProps)(ProjectListApp);
