import { Panel } from './grid';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const TOGGLE_MANUSCRIPT = 'TOGGLE_MANUSCRIPT';

export interface ProfileState {
  [index: string]: any;
  isLeftPanelOpen: boolean;
  isRightPanelOpen: boolean;
  isBottomPanelOpen: boolean;
  language: string;
  uid?: string;
}

export interface ProfileProps {
  language: string;
  uid: string;
  profile: any;
  updateLanguageFunc?: any;
  updateProfileFunc?: any;
}

interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
  profile: ProfileState;
}

interface UpdateLanguageAction {
  type: typeof UPDATE_LANGUAGE;
  language: string;
}

interface ToggleManuscriptAction {
  type: typeof TOGGLE_MANUSCRIPT;
  panel: Panel;
}

interface UndefinedAction {
  type: typeof undefined;
}

export type ProfileActionTypes =
  | UpdateProfileAction
  | UpdateLanguageAction
  | ToggleManuscriptAction
  | UndefinedAction;
