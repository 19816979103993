import React, { ReactElement } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { MorphologyViewProps, MorphologyViewState, DefaultMorphologyViewProps } from '../../types';
import { ManuscriptData } from '../../shared/structs';
import { MorphologyParser } from '../../shared/morphologyParser';
import getDisplaySegments, { ManuscriptDisplay } from '../../lib/getDisplaySegments';

class MorphologyViewComp extends React.Component<MorphologyViewProps, MorphologyViewState> {
  public static defaultProps: MorphologyViewProps = DefaultMorphologyViewProps;

  public constructor(props: MorphologyViewProps) {
    super(props);
  }

  private popoverTitle(verseData: ManuscriptData[]): string {
    const gloss = verseData.reduce((accumulator, verseDatum: ManuscriptData): string => {
      return `${accumulator} ${verseDatum.english}`;
    }, '');
    const segmentList = verseData.reduce(
      (accumulator: string, verseDatum: ManuscriptData): string => {
        return `${accumulator} ${verseDatum.lemma} `;
      },
      '',
    );

    return `${segmentList.trim()}: ${gloss}`;
  }

  private sourceHover(
    verseData: ManuscriptData[],
    segmentText: string,
    verseSegment: ManuscriptDisplay,
    index: number,
  ): ReactElement {
    const morphologyParser = new MorphologyParser();
    return (
      <OverlayTrigger
        rootClose
        key={segmentText + verseSegment.positions.toString()}
        trigger="hover"
        placement="right"
        popperConfig={{
          modifiers: { preventOverflow: { boundariesElement: 'offsetParent' } },
        }}
        // prettier-ignore
        overlay={(
          <Popover id={segmentText + index}>
            <Popover.Title as="h3">{this.popoverTitle(verseData)}</Popover.Title>
            <Popover.Content>
              {verseData.map(
                (verseDatum: ManuscriptData): ReactElement => {
                  const fullMorphology = morphologyParser.parse(verseDatum.analysis);
                  return (
                    <p key={verseDatum.segment + verseDatum.positionId}>
                      <span>
                        {verseDatum.segment}
                        :
                        &nbsp;
                      </span>
                      <span>{fullMorphology}</span>
                    </p>
                  );
                },
              )}
            </Popover.Content>
          </Popover>
        )}
      >
        <span className="source-morph">{segmentText}</span>
      </OverlayTrigger>
    );
  }

  private glossHover(
    verseData: ManuscriptData[],
    segmentText: string,
    verseSegment: ManuscriptDisplay,
    index: number,
  ): ReactElement {
    const { lexiconData } = this.props;
    return (
      <OverlayTrigger
        rootClose
        key={segmentText + verseSegment.positions.toString()}
        trigger="hover"
        placement="right"
        popperConfig={{
          modifiers: { preventOverflow: { boundariesElement: 'offsetParent' } },
        }}
        // prettier-ignore
        overlay={(
          <Popover id={segmentText + index}>
            <Popover.Title as="h3">{this.popoverTitle(verseData)}</Popover.Title>
            <Popover.Content>
              {verseData.map(
                (verseDatum: ManuscriptData): ReactElement => {
                  return (
                    <p key={verseDatum.positionId}>
                      <span>
                        {verseDatum.segment}
                        :
                        &nbsp;
                      </span>
                      <span>{lexiconData.strongsX[verseDatum.strongsX]}</span>
                    </p>
                  );
                },
              )}
            </Popover.Content>
          </Popover>
        )}
      >
        <span className="gloss-morph">
          {verseData.reduce((accumulator, verseDatum: ManuscriptData): string => {
            return `${accumulator} ${verseDatum.english}`;
          }, '')}
        </span>
      </OverlayTrigger>
    );
  }

  private segmentTextForMorphology(verseManuscriptData: ManuscriptData[]): ReactElement[] {
    const displaySegments = getDisplaySegments(verseManuscriptData);
    return displaySegments.map(
      (verseSegment: ManuscriptDisplay, index: number): ReactElement => {
        const segmentText = verseSegment.text;
        const verseData = verseManuscriptData.filter((datum: ManuscriptData) => {
          return datum.positionId.startsWith(verseSegment.positions[0].substring(0, 3));
        });

        return (
          <tr key={verseData[0].positionId}>
            <td className="source-morph-cell">
              {this.sourceHover(verseData, segmentText, verseSegment, index)}
            </td>
            <td className="gloss-morph-cell">
              {this.glossHover(verseData, segmentText, verseSegment, index)}
            </td>
          </tr>
        );
      },
    );
  }

  public render(): ReactElement {
    const { manuscriptDataByVerse } = this.props;

    let verseNo = 0;

    return (
      <div key="morphology-list" className="morphology-list">
        {((): ReactElement[] => {
          return Object.keys(manuscriptDataByVerse).map(
            (verse: string): ReactElement => {
              const manuscriptData: ManuscriptData[] = manuscriptDataByVerse[verse];
              verseNo += 1;
              const verseNoDisplay = `-- ${verseNo} --`;
              return (
                <table
                  key={`verse-${verseNo}`}
                  id={`manuscript-verse-${verseNo}`}
                  data-id={`${verseNo}`}
                  className="morph-verse"
                >
                  <thead>
                    <tr>
                      <th colSpan={2} className="verse-group-header">
                        {verseNoDisplay}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.segmentTextForMorphology(manuscriptData)}</tbody>
                </table>
              );
            },
          );
        })()}
      </div>
    );
  }
}

export default MorphologyViewComp;
