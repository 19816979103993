import React, { ReactElement } from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ManuscriptViewProps, ManuscriptViewState, DefaultManuscriptViewProps } from '../../types';
import { ManuscriptData } from '../../shared/structs';
import getDisplaySegments, { ManuscriptDisplay } from '../../lib/getDisplaySegments';

export class ManuscriptViewComp extends React.Component<ManuscriptViewProps, ManuscriptViewState> {
  public static defaultProps: ManuscriptViewProps = DefaultManuscriptViewProps;

  public constructor(props: ManuscriptViewProps) {
    super(props);
  }

  private segmentTextForManuscript(verseManuscriptData: ManuscriptData[]): ReactElement[] {
    const displaySegments = getDisplaySegments(verseManuscriptData);
    return displaySegments.map(
      (verseSegment: ManuscriptDisplay, index: number): ReactElement => {
        const isLast = index === verseManuscriptData.length - 1;
        const segmentText = isLast ? verseSegment.text : verseSegment.text.concat(' ');
        const verseData = verseManuscriptData.filter((datum: ManuscriptData) => {
          return datum.positionId.startsWith(verseSegment.positions[0].substring(0, 3));
        });
        return (
          <OverlayTrigger
            rootClose
            key={segmentText + verseSegment.positions.toString()}
            trigger="hover"
            placement="right"
            popperConfig={{ modifiers: { preventOverflow: { boundariesElement: 'offsetParent' } } }}
            // prettier-ignore
            overlay={(
              <Popover id={segmentText + index}>
                <Popover.Title as="h3">
                  {verseData.reduce(
            (accumulator, verseDatum: ManuscriptData): string => {
              return `${accumulator} ${verseDatum.english}`;
            },
            '',
          )}
                </Popover.Title>
                <Popover.Content>
                  { verseData.map((verseDatum: ManuscriptData): ReactElement => { return (
                    <p key={verseDatum.segment + verseDatum.positionId}>
                      <span>{verseDatum.lemma}</span>
                      &nbsp;
                      <span>
                        (
                        {verseDatum.cat}
                        ):
                      </span>
                      &nbsp;
                      <span>
                        {verseDatum.english}
                        &nbsp;
                      </span>
                      <span>{verseDatum.strongsX}</span>
                    </p>
                  )}) }
                </Popover.Content>
              </Popover>
             )}
          >
            <span>{segmentText}</span>
          </OverlayTrigger>
        );
      },
    );
  }

  public render(): ReactElement {
    const { manuscriptDataByVerse, isOldTestament } = this.props;

    let verseNo = 0;

    return (
      <div key="manuscript-list" className={`manuscript-list ${isOldTestament ? 'rtl' : ''}`}>
        {((): ReactElement[] => {
          return Object.keys(manuscriptDataByVerse).map(
            (verse: string): ReactElement => {
              const manuscriptData: ManuscriptData[] = manuscriptDataByVerse[verse];
              verseNo += 1;
              return (
                <p
                  key={`verse-${verseNo}`}
                  id={`manuscript-verse-${verseNo}`}
                  data-id={`${verseNo}`}
                >
                  <sup>{verseNo}</sup>
                  {this.segmentTextForManuscript(manuscriptData)}
                </p>
              );
            },
          );
        })()}
      </div>
    );
  }
}

export default ManuscriptViewComp;
