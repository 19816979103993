import React, { ReactElement } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import * as types from '../../types';
import SegmentGroup from './segmentGroup';
import Segment from './segment';
import { SyntaxGroupData, ManuscriptData } from '../../shared/structs';

export class SourceTextComp extends React.Component<types.SourceTextProps, types.SourceTextState> {
  private renderFlatSyntax(): ReactElement {
    const { manuscriptSuggestions, verseManuscriptData } = this.props;
    const stack: ReactElement[] = [];

    let n = 0;
    verseManuscriptData.forEach((element: ManuscriptData): void => {
      n += 1;
      const linkSuggestions = manuscriptSuggestions.linkSuggestions[element.strongsX];
      const memorySuggestions = manuscriptSuggestions.memorySuggestions[element.strongsX];
      stack.push(
        <Segment
          position={n}
          manuscriptData={element}
          linkSuggestions={linkSuggestions}
          memorySuggestions={memorySuggestions}
          key={element.positionId}
        />,
      );
    });
    return (
      <div className="auto-suggestion-concordance flat" key="auto-suggestion-concordance">
        {stack}
      </div>
    );
  }

  private renderNestedSyntax(): ReactElement {
    const { manuscriptSuggestions, verseManuscriptData, syntaxGroupData } = this.props;

    const segmentGroups = syntaxGroupData
      .filter(datum => datum.level === 0)
      .map(
        (syntaxGroupDatum: SyntaxGroupData): ReactElement => {
          const childSegmentGroups = syntaxGroupData
            .filter(datum => datum.level > 0)
            .filter(
              datum =>
                datum.startIndex >= syntaxGroupDatum.startIndex &&
                datum.endIndex <= syntaxGroupDatum.endIndex,
            );
          return (
            <SegmentGroup
              key={`segment-group-${syntaxGroupDatum.startIndex}-${syntaxGroupDatum.endIndex}`}
              manuscriptSuggestions={manuscriptSuggestions}
              verseManuscriptData={verseManuscriptData || []}
              allSyntaxGroupData={syntaxGroupData}
              syntaxGroup={syntaxGroupDatum}
              childSyntaxGroupData={childSegmentGroups}
            />
          );
        },
      );

    return (
      <div className="auto-suggestion-concordance" key="auto-suggestion-concordance">
        {segmentGroups}
      </div>
    );
  }

  public render(): ReactElement {
    return (
      <Tabs defaultActiveKey="natural" id="verse-syntax" variant="pills">
        <Tab
          mountOnEnter
          eventKey="natural"
          id="natural-syntax-display"
          title={<i className="fas fa-grip-lines" />}
        >
          {this.renderFlatSyntax()}
        </Tab>
        <Tab
          mountOnEnter
          eventKey="functional"
          id="functional-syntax-display"
          title={<i className="fas fa-grip-horizontal" />}
        >
          {this.renderNestedSyntax()}
        </Tab>
      </Tabs>
    );
  }
}
const SourceText = SourceTextComp;
export default SourceText;
