import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeLightbox } from '../../actions';
import { LightboxProps, LightboxState } from '../../types';
import { AppState } from '../../reducers';

export class Lightbox extends React.Component<LightboxProps, LightboxState> {
  // readonly state: LightboxState;

  public static defaultProps: LightboxProps = {
    display: false,
  };

  public constructor(props: LightboxProps) {
    super(props);
  }

  public close(): void {
    const { closeHandler } = this.props;
    closeHandler();
  }

  public render(): any {
    let modalCssName = 'modal_dialog middle-animate ';
    const { display, extModalCssName, style, children } = this.props;

    if (display) {
      modalCssName += 'modal_dialog_active ';
    }

    if (extModalCssName) {
      modalCssName += extModalCssName;
    }

    return (
      <div
        className={
          display === true ? 'lightbox middle-animate lightbox_active' : 'lightbox middle-animate '
        }
      >
        <div style={style} className={modalCssName}>
          {children}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: AppState): any => {
  return {
    display: state.lightbox.display,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): any => {
  return {
    close: (): void => {
      dispatch(closeLightbox());
    },
  };
};

export const LightboxContainer = connect(mapStateToProps, mapDispatchToProps)(Lightbox);
