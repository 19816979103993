/* eslint-disable import/prefer-default-export */
import {
  CreateProjectActionTypes,
  CHANGE_DATA,
  CANCEL_FORM,
  FORM_ERROR,
  PROJECT_LOADED,
  ProjectData,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  SHOW_PROJECT_LIST,
  SHOW_PROJECT_CREATION,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  USER_IS_NOT_SIGNED,
  SHOW_LIGHTBOX_REMOVING_PROJECT,
  CLOSE_LIGHTBOX_REMOVING_PROJECT,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  SHOW_LIGHTBOX_EDITING_PROJECT,
  CLOSE_LIGHTBOX_EDITING_PROJECT,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAILURE,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_SUCCESS,
  SHOW_LIGHTBOX_MANAGE_PROJECT_USERS,
  CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS,
  SHOW_VIEW_OF_ADDING_PROJECT_USER,
  CANCEL_ADDING_PROJECT_USER,
  SEARCH_PROJECT_USER_EMAIL,
  HANDLE_USER_EMAIL_CHANGE,
  HANDLE_USER_PERMISSION_CHANGE,
  SAVE_PROJECT_USER_REQUEST,
  SAVE_PROJECT_USER_SUCCESS,
  SAVE_PROJECT_USER_FAILURE,
  DELETE_PROJECT_USER_REQUEST,
  DELETE_PROJECT_USER_SUCCESS,
  DELETE_PROJECT_USER_FAILURE,
} from '../types';

import { userIsMember } from '../lib/user';

import firebase from '../lib/firebase';

const FirebaseDb = firebase.createService('db');
const FirebaseAuth = firebase.createService('auth');

export const projectLoaded = (): CreateProjectActionTypes => {
  return {
    type: PROJECT_LOADED,
  };
};

export const projectChangeData = (key: string, value: string): CreateProjectActionTypes => {
  return {
    type: CHANGE_DATA,
    key,
    value,
  };
};

export const projectCancelForm = (): CreateProjectActionTypes => {
  return {
    type: CANCEL_FORM,
  };
};

export const projectFormHasError = (error: string): CreateProjectActionTypes => {
  return {
    type: FORM_ERROR,
    error,
  };
};

export const createProjectRequest = (): CreateProjectActionTypes => {
  return {
    type: CREATE_PROJECT_REQUEST,
  };
};

export const createProjectFailure = (error: string): CreateProjectActionTypes => {
  return {
    type: CREATE_PROJECT_FAILURE,
    error,
  };
};

export const createProjectSuccess = (message: string): CreateProjectActionTypes => {
  return {
    type: CREATE_PROJECT_SUCCESS,
    message,
  };
};

export const ShowProjectList = (): CreateProjectActionTypes => {
  return {
    type: SHOW_PROJECT_LIST,
  };
};

export const ShowProjectCreation = (): CreateProjectActionTypes => {
  return {
    type: SHOW_PROJECT_CREATION,
  };
};

export const createProject = (projectData: ProjectData): any => {
  return async (dispatch: any): Promise<void> => {
    try {
      dispatch(createProjectRequest());

      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const { projectName } = projectData;
        const result = await (await FirebaseDb.init()).getProjectByName(
          currentUser.uid,
          projectName,
        );

        if (result && result.length) {
          dispatch(
            createProjectFailure(
              `A project with the same name already exists in your project list.`,
            ),
          );
        } else {
          await (await FirebaseDb.init()).addProject(
            currentUser.uid,
            projectData,
            currentUser.displayName,
            currentUser.email,
          );
          dispatch(projectCancelForm());
          dispatch(ShowProjectList());
        }
      } else {
        throw new Error(USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(createProjectFailure(`You must be signed in to create project.`));
        } else {
          dispatch(createProjectFailure(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(createProjectFailure(error));
      } else {
        dispatch(createProjectFailure(String(error)));
      }
    }
  };
};

export const fetchProjectsRequest = (): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECTS_REQUEST,
  };
};
export const fetchProjectsFailureRequest = (error: string): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECTS_FAILURE,
    error,
  };
};

export const fetchProjectsSuccessRequest = (
  projectList: any[],
  currentUid: string,
): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    projectList,
    currentUid,
  };
};

export const fetchProjects = (): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchProjectsRequest());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getUserProjects(currentUser.uid);

        dispatch(fetchProjectsSuccessRequest(result, currentUser.uid));
      } else {
        throw new Error(USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(
            fetchProjectsFailureRequest(`You must be signed in to access your project list.`),
          );
        } else {
          dispatch(fetchProjectsFailureRequest(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchProjectsFailureRequest(error));
      } else {
        dispatch(fetchProjectsFailureRequest(String(error)));
      }
    }
  };
};

export const showLightboxRemovingProject = (projectId: string): CreateProjectActionTypes => {
  return {
    type: SHOW_LIGHTBOX_REMOVING_PROJECT,
    projectId,
  };
};

export const closeLightboxRemovingProject = (): CreateProjectActionTypes => {
  return {
    type: CLOSE_LIGHTBOX_REMOVING_PROJECT,
  };
};

export const deleteProjectRequest = (): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_REQUEST,
  };
};

export const deleteProjectFailureRequest = (error: string): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_FAILURE,
    error,
  };
};

export const deleteProjectSuccessRequest = (): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_SUCCESS,
  };
};

export const deleteProject = (projectId: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(deleteProjectRequest());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        await (await FirebaseDb.init()).removeProjectById(projectId, currentUser.uid);

        dispatch(deleteProjectSuccessRequest());
        dispatch(fetchProjects());
        dispatch(closeLightboxRemovingProject());
      } else {
        throw new Error(USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(deleteProjectFailureRequest(`You must be signed in to remove project.`));
        } else {
          dispatch(deleteProjectFailureRequest(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(deleteProjectFailureRequest(error));
      } else {
        dispatch(deleteProjectFailureRequest(String(error)));
      }

      dispatch(closeLightboxRemovingProject());
    }
  };
};

export const showLightboxEditingProject = (projectId: string): CreateProjectActionTypes => {
  return {
    type: SHOW_LIGHTBOX_EDITING_PROJECT,
    projectId,
  };
};

export const closeLightboxEditingProject = (): CreateProjectActionTypes => {
  return {
    type: CLOSE_LIGHTBOX_EDITING_PROJECT,
  };
};

export const editProjectRequest = (): CreateProjectActionTypes => {
  return {
    type: EDIT_PROJECT_REQUEST,
  };
};
export const editProjectFailureRequest = (error: string): CreateProjectActionTypes => {
  return {
    type: EDIT_PROJECT_FAILURE,
    error,
  };
};

export const editProjectSuccessRequest = (): CreateProjectActionTypes => {
  return {
    type: EDIT_PROJECT_SUCCESS,
  };
};

export const editProject = (projectId: string, projectData: ProjectData): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(editProjectRequest());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const { projectName } = projectData;

        const result = await (await FirebaseDb.init()).getProjectByName(
          currentUser.uid,
          projectName,
        );

        if (result && result.length && result[0].id !== projectId) {
          dispatch(
            editProjectFailureRequest(
              `A project with the same name already existed in your project list.`,
            ),
          );
        } else {
          await (await FirebaseDb.init()).editProject(currentUser.uid, projectId, projectData);
          dispatch(editProjectSuccessRequest());
          dispatch(fetchProjects());
        }
      } else {
        throw new Error(USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(editProjectFailureRequest(`You must be signed in to remove project.`));
        } else {
          dispatch(editProjectFailureRequest(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(editProjectFailureRequest(error));
      } else {
        dispatch(editProjectFailureRequest(String(error)));
      }
    }
  };
};

export const fetchProjectRequest = (): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECT_REQUEST,
  };
};

export const fetchProjectFailureRequest = (error: string): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECT_FAILURE,
    error,
  };
};

export const fetchProjectSuccessRequest = (
  projectData: any,
  projectId: string,
  userIsProjectMember: boolean,
): CreateProjectActionTypes => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    projectData,
    projectId,
    userIsProjectMember,
  };
};

export const fetchProject = (projectId: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(fetchProjectRequest());
      const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();

      if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
        const result = await (await FirebaseDb.init()).getProjectById(currentUser.uid, projectId);

        const userIsProjectMember = userIsMember(currentUser.uid, result.users);

        dispatch(fetchProjectSuccessRequest(result, projectId, userIsProjectMember));
      } else {
        throw new Error(USER_IS_NOT_SIGNED);
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(fetchProjectFailureRequest(`You must be signed in to access your project.`));
        } else {
          dispatch(fetchProjectFailureRequest(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(fetchProjectFailureRequest(error));
      } else {
        dispatch(fetchProjectFailureRequest(String(error)));
      }
    }
  };
};

// manage project users - begin
export const showLightboxManageProjectUsers = (projectId: string): CreateProjectActionTypes => {
  return {
    type: SHOW_LIGHTBOX_MANAGE_PROJECT_USERS,
    projectId,
  };
};

export const closeLightboxManageProjectUsers = (): CreateProjectActionTypes => {
  return {
    type: CLOSE_LIGHTBOX_MANAGE_PROJECT_USERS,
  };
};

export const showViewOfAddingProjectUser = (): CreateProjectActionTypes => {
  return {
    type: SHOW_VIEW_OF_ADDING_PROJECT_USER,
  };
};

export const cancelAddingProjectUserAction = (): CreateProjectActionTypes => {
  return {
    type: CANCEL_ADDING_PROJECT_USER,
  };
};

export const searchProjectUserEmailAction = (): CreateProjectActionTypes => {
  return {
    type: SEARCH_PROJECT_USER_EMAIL,
  };
};

export const handleUserEmailChangeAction = (email: string): CreateProjectActionTypes => {
  return {
    type: HANDLE_USER_EMAIL_CHANGE,
    email,
  };
};

export const handleUserPermissionChangeAction = (permission: string): CreateProjectActionTypes => {
  return {
    type: HANDLE_USER_PERMISSION_CHANGE,
    permission,
  };
};

// ************ SAVE USER TO PROJECT - BEGIN ************
export const saveProjectUserRequest = (): CreateProjectActionTypes => {
  return {
    type: SAVE_PROJECT_USER_REQUEST,
  };
};

export const saveProjectUserFailure = (error: string): CreateProjectActionTypes => {
  return {
    type: SAVE_PROJECT_USER_FAILURE,
    error,
  };
};

export const saveProjectUserSuccess = (): CreateProjectActionTypes => {
  return {
    type: SAVE_PROJECT_USER_SUCCESS,
  };
};

export const saveProjectUserAction = (projectId: string, email: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(saveProjectUserRequest());

      if (!projectId) {
        dispatch(saveProjectUserFailure('Project ID is required.'));
      } else if (!email) {
        dispatch(saveProjectUserFailure('User email is required.'));
      } else {
        const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();
        if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
          const result = await (await FirebaseDb.init()).getProjectById(currentUser.uid, projectId);

          if (currentUser.uid === result.author) {
            // validate current user's permission - only owner can manager project user
            // query user by email
            const user = await (await FirebaseDb.init()).getUserByEmail(email);

            if (user) {
              // user exits in system
              await (await FirebaseDb.init()).addProjectUser(
                projectId,
                user.uid,
                user.displayName,
                email,
                'user',
              );

              dispatch(fetchProject(projectId));
              dispatch(saveProjectUserSuccess());
              dispatch(fetchProjects());
            } else {
              // no such user exists.
              dispatch(saveProjectUserFailure('project.message.no-such-user'));
            }
          } else {
            // only project owner can manage project users
            dispatch(saveProjectUserFailure('project.message.only-owner-can-manage-users'));
          }
        } else {
          throw new Error(USER_IS_NOT_SIGNED);
        }
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(saveProjectUserFailure(`You must be signed in to access your project.`));
        } else {
          dispatch(saveProjectUserFailure(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(saveProjectUserFailure(error));
      } else {
        dispatch(saveProjectUserFailure(String(error)));
      }
    }
  };
};

// ************ DELETE USER FROM PROJECT - BEGIN ************
export const deleteProjectUserRequest = (): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_USER_REQUEST,
  };
};

export const deleteProjectUserFailure = (error: string): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_USER_FAILURE,
    error,
  };
};

export const deleteProjectUserSuccess = (): CreateProjectActionTypes => {
  return {
    type: DELETE_PROJECT_USER_SUCCESS,
  };
};

export const deleteProjectUserAction = (projectId: string, targetUid: string): any => {
  return async (dispatch: any): Promise<any> => {
    try {
      dispatch(deleteProjectUserRequest());

      if (!projectId) {
        dispatch(deleteProjectUserFailure('Project ID is required.'));
      } else if (!targetUid) {
        dispatch(deleteProjectUserFailure('User ID is required to delete user from project.'));
      } else {
        const currentUser = await (await FirebaseAuth.init()).getCurrentSignedInUser();
        if (currentUser && Object.prototype.hasOwnProperty.call(currentUser, 'uid')) {
          const result = await (await FirebaseDb.init()).getProjectById(currentUser.uid, projectId);

          // validate current user's permission - only owner can manager project user
          if (currentUser.uid === result.author) {
            // user exits in system
            await (await FirebaseDb.init()).deleteProjectUser(projectId, targetUid);

            // set 300ms delay because of firestore
            dispatch(fetchProject(projectId));
            dispatch(deleteProjectUserSuccess());
            dispatch(fetchProjects());
          } else {
            // only project owner can manage project users
            dispatch(saveProjectUserFailure('project.message.only-owner-can-manage-users'));
          }
        } else {
          throw new Error(USER_IS_NOT_SIGNED);
        }
      }
    } catch (error) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        if (error.message === USER_IS_NOT_SIGNED) {
          dispatch(saveProjectUserFailure(`You must be signed in to access your project.`));
        } else {
          dispatch(saveProjectUserFailure(error.message));
        }
      } else if (typeof error === 'string') {
        dispatch(saveProjectUserFailure(error));
      } else {
        dispatch(saveProjectUserFailure(String(error)));
      }
    }
  };
};
